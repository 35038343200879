import React from "react";

// Customizable Area Start
import {
  Card,
  Box,
  Typography,
  Container,
  Drawer,
  Button,
  styled
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { AppFooter } from "../../../components/src/AppFooter.web";
import { circleimg, chatavatar, grouponline, addImg } from "../src/assets"
import moment from "moment"

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import ChatController, { configJSON, IChat, Props } from "./ChatController";

export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  AddMembersidebaar() {
    return (
      <>
        <BoxMain>
          <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px" }}>
            <Typography style={{ fontFamily: "MyFont", fontWeight: "bold", letterSpacing: "0.8px", fontSize: "21px" }}>New Group</Typography>
            <span onClick={this.drawerClose} style={{ cursor: "pointer" }}><i className="fa-solid fa-xmark"></i></span>
          </Box>
          <Box style={webStyles.borderBottomstyle as any}></Box>
          <Container>

            <Box style={{ display: 'flex', flexDirection: "column", alignItems: "center", padding: "10px" }}>
              <Box style={{ position: "relative" }}>
              <label htmlFor="addmemberimg">
                <img src={this.state.selectedImage ? this.state.selectedImage : addImg} onClick={this.handleImage} style={{ width: "100px", height: "100px", borderRadius: "50%", marginTop: "10px" }} alt="" />
              </label>
                <input type="file" key={this.state.fileInputKey} name="" id="addmemberimg" onChange={this.handleImageselect} style={{ display:"none" }} />
              </Box>
              <Box style={{ width: "100%", marginBottom: "10px" }}>
                <label htmlFor="" style={{ fontFamily: "MyFont", fontWeight: "bold", letterSpacing: "0.8px" }}>Group Name</label> <br />
                <input type="text" onChange={this.handleinputchange} style={{ width: "100%", padding: "10px 5px", borderRadius: "5px", border: "1px solid #CBD5E1", marginBottom: "20px" }} name="groupname" id="" />

                <label htmlFor="" style={{ fontFamily: "MyFont", fontWeight: "bold", letterSpacing: "0.8px" }}>Description</label> <br />
                <textarea style={{ width: "100%", padding: "10px 5px", borderRadius: "5px", border: "1px solid #CBD5E1" }} onChange={this.handleinputchange} rows={4} name="groupdescription" id="" />

                <Box style={webStyles.searchbox}>
                  <Box style={{ padding: "10px" }}>
                    <i className="fa-solid fa-magnifying-glass"></i>&nbsp;&nbsp;
                    <input type="text" name="" onChange={this.searchvalue} style={webStyles.inputbox} placeholder='Search' id=""
                    />
                  </Box>
                </Box>

                <Box style={{ display: "flex", alignItems: "center",margin: "0 auto",flexWrap:"wrap" }}>
                  {
                    this.state.selectedmember && this.state.selectedmember.map((element: any, index: any) => (
                      <Box key={index} style={{ borderRadius: "20px", display: "flex", alignItems: "center", minWidth: "100px", background: "#F1F5F9", flexWrap: "wrap", marginBottom: "15px" }}>
                        <Typography style={{ color: "#475569",fontSize: "10px", marginRight: "8px",  padding: "5px" }}>{element.value}</Typography>
                        <span onClick={this.handleselectmemberDelete.bind(this, index)}><i className="fa-solid fa-xmark" style={{ fontSize: "11px"}}></i></span>  
                      </Box> 
                    ))
                  }
                </Box>
                <Box>
                  {
                    this.state.MemberListData.length > 0 && this.state.MemberListData.map((element: any, index: any) => {
                      const checkdata = this.state.selectedmember.map((ele: any) => ele.id == element.id)
                      return (
                        <>
                          <Box key={index} style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", display: "flex", justifyContent: "space-between", padding: "4px", alignItems: "center", height: "60px", marginBottom: "10px", marginTop: "10px" }}>
                            <Box style={{ display: "flex", borderRadius: "10px", alignItems: "center" }}>
                            <img src={element.profile_picture?.url} style={{ width: "40px", height: "40px", borderRadius: "50%" }} alt="" />
                              <Box style={{ marginLeft: "10px" }}>
                                <p>{element.first_name + element.last_name}</p>
                              </Box>
                            </Box>
                            <span><input type="checkbox" value={element.e} checked={checkdata.some((eleme:any)=>eleme)} onClick={(elementvalue) => this.getvaluemembercheck(elementvalue, element)} name="" id="" /></span>
                          </Box>
                        </>
                      )
                    })
                  }
                </Box>
              </Box>
            </Box>
          </Container>

          <Box>
            <Button variant="contained" style={{ width: "100%", background: "black", color: "white", marginTop: "400px", textTransform: "capitalize" }} onClick={this.saveButton}>Create Group</Button>
          </Box>
        </BoxMain>
      </>
    )
  }

  chatcontent() {
    return (
      <>
        {
          this.state.chatList.map((element: any, index: any) => {
            return (
              <>
                <Box style={webStyles.mainchatBox as any} onClick={this.handleGotoChat.bind(this, "chats", element)}>
                  <Box style={webStyles.profilechat as any}>
                    <img style={webStyles.profileImg as any} src={element.profile_image.url ? element.profile_image.url : chatavatar} alt="" />
                    <Box>
                      <Typography style={{ fontFamily: "MyFont", fontWeight: "bold", fontSize: "16px", letterSpacing: "0.8px" }}>{element.name} <img src={circleimg} alt="" /></Typography>
                      <Typography style={{ fontFamily: "MyFont", fontSize: "13px", letterSpacing: "0.8px" }}>{element.last_message?.length > 10 ? `${element.last_message?.slice(0, 10)}...`
                        : element.last_message}</Typography>
                    </Box>
                  </Box>
                  <Box style={webStyles.chatrightContent as any}>
                    <Typography style={{ fontFamily: "MyFont", fontSize: "12px", letterSpacing: "0.8px" }}>{element.last_message_received_at && moment.duration(moment().diff(element.last_message_received_at)).humanize().replace('ago', '').trim()}</Typography>
                    <Typography style={{ background: "#D4E5DE", padding: "3px", fontFamily: "MyFont", borderRadius: "50%", width: "18px", height: "18px", fontSize: "12px", textAlign: 'center' }}>{element.unread_count}</Typography>
                  </Box>
                </Box>
                <Box style={webStyles.borderBottomstyle as any}></Box>
              </>
            )
          })
        }
      </>
    )
  }

  groupChatContent() {
    return (
      <>
        {
          this.state.groupchatList.length > 0 && this.state.groupchatList.map((element: any) => {
            return (
              <>
                <Box style={webStyles.mainchatBox as any} onClick={this.handleGotoChat.bind(this, "groups", element)}>
                  <Box style={webStyles.profilechat as any}>
                    <img style={webStyles.profileImg as any} src={element.group_profile_image?.url} alt="" />
                    <Box>
                      <Typography style={{ fontFamily: "MyFont", fontWeight: "bold", fontSize: "16px", letterSpacing: "0.8px" }}>{element.friendly_name}<img src={grouponline} alt="" /></Typography>
                      <Typography style={{ fontFamily: "MyFont", fontSize: "13px", letterSpacing: "0.8px" }}>{element.last_message?.content.length > 10
                        ? `${element.last_message?.content.slice(0, 10)}...`
                        : element.last_message?.content}</Typography>
                    </Box>
                  </Box>
                  <Box style={webStyles.chatrightContent as any}>
                    <Typography style={{ fontFamily: "MyFont", fontSize: "12px", letterSpacing: "0.8px" }}>3h</Typography>
                    <Typography style={{ background: "#D4E5DE", padding: "3px", fontFamily: "MyFont", borderRadius: "50%", width: "18px", height: "18px", fontSize: "12px", textAlign: 'center' }}>{element.unread_messages_count}</Typography>
                  </Box>
                </Box>
                <Box style={webStyles.borderBottomstyle as any}></Box>
              </>
            )
          })
        }

      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <AppHeader />
        <Container>
          <Box key={1} style={{ display: "flex", flexDirection: "column", alignItems: "center", background: "#F8FAFC" }}>

            <Card style={{ maxWidth: 750, marginBottom: "25px", width: "100%", marginTop: "84px", fontFamily: "MyFont", minHeight: "130vh", borderRadius: "12px", padding: "10px" }}>
              <Box style={{ display: "flex", alignItems: "center", padding: "10px" }}>
                <Typography style={{ textAlign: "center", width: "100%", fontFamily: "MyFont", fontSize: "20px", fontWeight: "bold", letterSpacing: "0.8px" }} >Messages</Typography>
                {this.state.Tabview == "groups" && <i style={{ border: "1px solid #14362E", padding: "4px", borderRadius: "50%", cursor: "pointer" }} onClick={this.handleadddrawer} className="fa-solid fa-plus"></i>}
              </Box>

              <Box style={webStyles.borderBottomstyle as any}></Box>

              <Box style={webStyles.tabStyle as any}>
                <Typography style={this.state.Tabview == "chats" ? webStyles.posttab as any : { width: "50%",color: "#64748B", borderRadius: 25, textAlign: "center", cursor: "pointer", fontFamily: "MyFont" }} onClick={this.handleTabChange.bind(this, "chats")}>Chats</Typography>
                <Typography style={this.state.Tabview == "groups" ? webStyles.posttab as any : { background: "#F8FAFC", color: "#64748B", marginLeft: "10px", fontWeight:"bold",fontFamily: "MyFont", cursor: "pointer" }} onClick={this.handleTabChange.bind(this, "groups")}>Groups</Typography>
              </Box>


              {
                this.state.Tabview == "chats" ? (
                  <>
                    <Container style={{ position: "relative" }}>
                      <Box style={webStyles.borderBottomstyle as any}></Box>
                      {this.chatcontent()}
                    </Container>
                  </>
                ) : (
                  <>
                    <Container style={{ position: "relative" }} >
                      <Box style={webStyles.borderBottomstyle as any}></Box>
                      {this.groupChatContent()}
                    </Container>
                  </>
                )
              }


            </Card>
          </Box>
          <Drawer style={webStyles.drawer as any} anchor="right" open={this.state.open} onClose={this.toggleDrawer.bind(this, false)} >
            {this.AddMembersidebaar()}
          </Drawer>
        </Container>
        <AppFooter />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const BoxMain = styled(Box)({

  width: "400px",
  "@media(max-width: 600px)": {
    width: '100vw',
  },
})
const webStyles = {
  borderBottomstyle: {
    marginTop: "10px",

    border: "1px solid #E2E8F0",

  },

  tabStyle: {
    display: "flex",

    backgroundColor: "#F8FAFC",
    margin: "auto",
    marginTop: "20px",
    padding: "20px 8px",
    fontFamily: "MyFont",

    width: "300px",
  },


  posttab: {
    border: "2px solid #73A58E",
    width: "50%",
    fontWeight:"bold",
    textAlign: "center",
    cursor: "pointer",
    fontFamily: "MyFont",
    borderRadius: 25,
  },

  mainchatBox: {
    marginTop: "10px",
    width: "100%",
    display: "flex",

    justifyContent: "space-between",

    cursor: "pointer",

    marginBottom: "10px",

    alignItems: "center",

  },


  profilechat: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",


  },
  profileImg: {
    borderRadius: "50%",

    width: "50px",
    height: "50px",
    marginRight: "5px",
  },
  chatrightContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    right: "10"
  },

  drawer: {
    width: "501px",
    display: "block",
    overflowY: "unset",
    '@media (min-width: 600px)': {
      display: "none",
    },
    "& .MuiDrawer-paper": {
      boxSizing: "border-box",
      width: "240px",
    },
  },


  categoryDropwdown: {
    margin: "15px",
    width: "400px",

    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontFamily: "MyFont",
    fontWeight: "bold",

    paddingLeft: "12px",

  },

  searchbox: {
    marginTop: "20px",
    background: "white",
    border: "1px solid #CBD5E1",
    borderRadius: "10px",
    color: "#CBD5E1",

    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "MyFont",
    height: "40px",
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },


  inputbox: {

    outline: "none",
    fontFamily: "MyFont",
    letterSpacing: "1.1px",
    border: "none",


  }
};
// Customizable Area End
