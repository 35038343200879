import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
import { toast } from 'react-hot-toast';
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  emailNotifications: boolean;
  pushNotifications: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class NotificationSettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getNotifiSettingApiCallID: any;
  setNotificationSettingApiCallID: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.SessionResponseMessage), getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      emailNotifications: true,
      pushNotifications: true, 
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getNotifiSettingApiCallID) {
        this.defaultNotificationSetting(responseJson);
      } else if (apiRequestCallId === this.setNotificationSettingApiCallID) {
        this.handleNotificationSetting(responseJson);
      } 
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() { 
    this.fetchNotificationSettings();
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
  
    if (name in this.state) {
      this.setState((prevState) => ({
        ...prevState,
        [name]: checked
      } as Pick<S, keyof S>), () => {
        this.updateNotificationSettings();
      });
    } else {
      console.error(`${name} is not a recognized key in state`);
    }
  };
  
  fetchNotificationSettings = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("accessToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getNotifiSettingApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPrivacySettingsApiEndPoint}${getStorageData('account_id')}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateNotificationSettings = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("accessToken")
    };

    const data = {
      is_email_notification: this.state.emailNotifications, 
      is_push_notification: this.state.pushNotifications
    }

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setNotificationSettingApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.notificationSettingsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  defaultNotificationSetting = (responseJson: any) => {
    const attributes = responseJson.data?.attributes;
    if (attributes) {
        this.setState({ emailNotifications: responseJson.data?.attributes?.is_email_notification});
        this.setState({ pushNotifications: responseJson.data?.attributes?.is_push_notification});
    } else {
        console.error("Failed to retrieve notification settings from API response.");
    }
  };

  handleNotificationSetting = (responseJson: any) => {
    if (responseJson && responseJson.message === "Notification Setting Updated!") {
        toast.success('Notification settings updated successfully!');
    } else {
        toast.error('Failed to update notification settings.');
    }
  };
  // Customizable Area End  
}