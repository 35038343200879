import React from "react";

// Customizable Area Start
import {
  Box,
  IconButton,
  Typography,
  styled,
  Container,
  Hidden,
  FormControlLabel,
  FormLabel,
} from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import { ArrowBack } from "@material-ui/icons";
import { Switch, withStyles, Theme, createStyles, SwitchProps, SwitchClassKey } from '@material-ui/core';

// Define the extra styles you're adding to the switch
interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

// Extend SwitchProps for the IOSSwitch and rename it to avoid conflicts
interface IOSSwitchProps extends SwitchProps {
  classes: Styles;
}
const IOSSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#52d869',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }),
)(({ classes, ...props }: IOSSwitchProps) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}  // Spread the rest of the props here.
    />
  );
})
// Customizable Area End

import NotificationSettingsController, {
  Props,
} from "./NotificationSettingsController.web";

class NotificationSettings extends NotificationSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  notificationSettingsWeb() {
    return (
      <Setting>
        <Box className="outerBox">
          <Box className="arrow">
            <a href="/Settings"
            style={{color: "inherit", textDecoration: "none"}}
            >
              <ArrowBack />
            </a>
          </Box>
          <Box className="heading">Notification Settings</Box>
          <Box className="mainBox">
            <Box className="items">
              <Box className="icon-content">
                <Typography className="content">
                  In-app notifications can't be turned off.
                </Typography>
              </Box>
              <Box className="notificationBox"> 
                <FormLabel className="notificationBoxTxt">Email Notifications</FormLabel>
                <FormGroup>
                <FormControlLabel
                  control={<IOSSwitch checked={this.state.emailNotifications} onChange={this.handleChange} name="emailNotifications" />}
                  color="#73A58E"
                  label=""
                />
              </FormGroup>
              </Box>
              <Box className="notificationBox"> 
                <FormLabel className="notificationBoxTxt">Push Notifications</FormLabel>
                <FormGroup>
                <FormControlLabel
                  control={<IOSSwitch checked={this.state.pushNotifications} onChange={this.handleChange} name="pushNotifications" />}
                  color="#73A58E"
                  label=""

                />
              </FormGroup>
              </Box>
            </Box>
          </Box>
        </Box>
      </Setting>
    );
  }

  notificationSettingsMobile() {
    return (
      <Box style={{ minHeight: "100vh" }}>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: "relative",
            width: "100%",
            borderBottom: "2px solid #E2E8F0",
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          <IconButton style={{ position: "absolute", left: 0 }}>
            <a href="/Settings"
              style={{ color: "inherit", textDecoration: "none" }} >
              <ArrowBack />
            </a>
          </IconButton>
          <Typography variant="h6" style={webStyle.mobileHeading}>
            Notification Settings
          </Typography>
          <div style={{ width: 48 }}></div>{" "}
        </Box>

        <Box
          style={{
            width: "100%",
            maxWidth: "600px",
            margin: "0 auto",
            borderRadius: "16px",
            padding: "14px",
            backgroundColor: "#FFFFFF", }} >

          <Box style={webStyle.mobileMainBox}>
            <Box style={{ ...webStyle.mobileItems, position: "relative" }}>
              <Typography style={webStyle.mobileAccount}>Profile</Typography>
            </Box>

            <Box style={{  position: "relative", ...webStyle.mobileItems, }}>
              <Box style={webStyle.mobileIconContent}>
                <Typography style={webStyle.mobileContent}>
                  In-app notifications can't be turned off.
                </Typography>
              </Box>
              <Box style={webStyle.parentBoxBody}>
                <Box style={webStyle.notificationBox}> 
                  <FormLabel style={webStyle.notificationBoxTxt}>Email Notifications</FormLabel>
                  <FormGroup>
                  <FormControlLabel
                    control={<IOSSwitch checked={this.state.emailNotifications} onChange={this.handleChange} name="emailNotifications" />}
                    color="#73A58E"
                    label=""
                  />
                </FormGroup>
                </Box>
                <Box style={webStyle.notificationBox}> 
                  <FormLabel style={webStyle.notificationBoxTxt}>Push Notifications</FormLabel>
                  <FormGroup>
                  <FormControlLabel
                    control={<IOSSwitch checked={this.state.pushNotifications} onChange={this.handleChange} name="pushNotifications" />}
                    color="#73A58E"
                    label=""
                    
                  />
                </FormGroup>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Container maxWidth={false}>
        <Hidden smDown>{this.notificationSettingsWeb()}</Hidden>
        <Hidden mdUp>{this.notificationSettingsMobile()}</Hidden>
      </Container>
      // Customizable Area End
    );
  }
}
// Customizable Area Start

export default NotificationSettings;

const Setting = styled(Box)({
  "& .outerBox": {
    width: "100%",
    height: "100%",
    backgroundColor: "#f2fff2",
  },
  "& .arrow": {
    height: "48px",
    display: "flex",
    alignItems: "center",
    padding: "0px 2%",
    backgroundColor: "#FFFFFF",  
  },
  "& .heading": {
    height: "56px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "6%",
    fontSize: "24px",
    fontWeight: 700,
    fontFamily: "MyFont",
    backgroundColor: "#FFFFFF",  
  },
  "& .mainBox": {
    width: "816px",
    height: "929px",
    borderRadius: "16px",
    padding: "24px 40px",
    backgroundColor: "#FFFFFF",
    margin: "2% auto",   
  },
  "& .items": {
    height: "42px",
    alignItems: "center",
    // display: "flex",
    // borderBottom: "1px solid #E2E8F0",
    padding: "12px 0px",   
    position: "relative",
  },
  "& .account": {
    fontSize: "16px",
    fontWeight: 800,
    fontFamily: "MyFont",
    color: "#64748B",
  },
  "& .icon-content": {
    gap: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .content": {
    color: "#0F172A",
    fontSize: "16px",
    fontWeight: 700,
    fontFamily: "MyFont",
  },
  "& .contact": {
    height: "120px",
    padding: "28px 0px",
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  "& .notificationBox": {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "15px",
    marginBottom: "10px",
    color: "#0F172A",
    fontSize: "16px",
    fontWeight: 700,
    fontFamily: "MyFont",
    paddingLeft: "70px",
    paddingRight: "70px",
    borderBottom: "1px solid #E2E8F0",
  },
  "& .notificationBoxTxt" :{
    fontFamily: "MyFont", 
    color:"#0F172A",
    fontSize:"16px", 
    letterSpacing:"0.05em"
    }
});

const webStyle = {
  mobileHeading: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#14362E",
    height: "56px",
    fontSize: "24px",
    fontWeight: 700,
    fontFamily: "MyFont",
    backgroundColor: "#FFFFFF",   
  },
  mobileMainBox: {
    margin: "1% auto",
    borderRadius: "16px",
    backgroundColor: "#FFFFFF",  
  },
  mobileItems: {
    alignItems: "center",
    height: "42px",
    marginRight: "40px",
    marginTop: "18px",
     // display: "flex",
    // borderBottom: "1px solid #E2E8F0",    
  },
  mobileAccount: {
    color: "#334155",
    marginBottom: "18px",
    fontSize: "18px",
    fontWeight: 900,
    fontFamily: "MyFont",  
    letterSpacing: "0.05em",
  },
  mobileIconContent: {   
    // display: "flex",    
    alignItems: "center",
    gap: "10px",
    marginBottom: "18px",
  },
  mobileContent: {
    color: "#0F172A",
    marginButtom: "10px",
    fontFamily: "MyFont",
    fontSize: "16px",
    fontWeight: 700,   
    letterSpacing: "0.05em",
  },
  mobileContact: {
    display: "flex",
    alignItems: "center",
    height: "120px",
    paddingTop: "28px",  
  },
  mobileIconContentContact: {   
    display: "flex",
    alignItems: "center",
    gap: "10px",
    marginBottom: "18px",
    // marginRight: "-200px"
  },
  notificationBox: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "15px",
    marginBottom: "10px",
    color: "#0F172A",
    fontSize: "16px",
    fontWeight: 700,
    fontFamily: "MyFont",
    borderBottom: "1px solid #E2E8F0",
  },
  notificationBoxTxt :{
    fontFamily: "MyFont", 
    color:"#0F172A",
    fontSize:"16px", 
    letterSpacing:"0.05em"
  },
  parentBoxBody :{
    marginTop: "30px",
  }
};
// Customizable Area End
