import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  otp: string;
  otpAuthToken: string;
  userAccountID: string;
  labelInfo: string;
  toMessage: string;
  isFromForgotPassword: boolean;
  otpvalue: string;
  showAlertError: boolean;
  alertErrorMessage: string;
  resenOtpSucess: boolean
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OTPInputAuthController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  otpAuthApiCallId: any;
  btnTxtSubmitOtp: string;
  placeHolderOtp: string;
  labelInfo: string = "";
  submitButtonColor: any = configJSON.submitButtonColor;
  emailVerificationApiCallID: any;
  resendotpid: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      otp: "",
      otpAuthToken: "",
      userAccountID: "",
      labelInfo: configJSON.labelInfo,
      toMessage: "",
      isFromForgotPassword: false,
      otpvalue: "",
      showAlertError: false,
      alertErrorMessage: "",
      resenOtpSucess: false
    };

    this.btnTxtSubmitOtp = configJSON.btnTxtSubmitOtp;
    this.placeHolderOtp = configJSON.placeHolderOtp;
    // Customizable Area End
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    const messageType = message.id;
    const responseData = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseSuccess = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const responseError = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    switch (messageType) {
      case getName(MessageEnum.RestAPIResponceMessage):
        this.handleApiResponse(responseData, responseSuccess, responseError);
        break;
      case getName(MessageEnum.NavigationPayLoadMessage):
        this.handleNavigationPayload(message);
        break;
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async submitOtp() {
    if (!this.state.otp || this.state.otp.length === 0) {
      this.showAlert(configJSON.errorTitle, configJSON.errorOtpNotValid);
      return;
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (this.state.isFromForgotPassword) {
      // console.log("entered is from forgot password!");

    } else {
      const headers = {
        "Content-Type": configJSON.apiVerifyOtpContentType,
        token: this.state.otpAuthToken,
      };

      this.otpAuthApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_forgot_password/otp_confirmation" + this.state.otp
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(JSON.stringify({}))
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiVerifyOtpMethod
    );
    // console.log("requestMessage.id is: " + requestMessage.id);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleApiResponse(responseData: any, responseSuccess: any, responseError: any) {
    console.log("response", responseData)
    if (this.otpAuthApiCallId === responseData) {
      if (responseSuccess && (responseSuccess.messages || (responseSuccess.meta && responseSuccess.meta.token))) {
      } else {
        this.handleErrorResponse(responseSuccess);
      }

      if (responseError) {
        this.parseApiCatchErrorResponse(responseError);
      }
    } else if (this.emailVerificationApiCallID === responseData) {
      this.handleEmailVerificationResponse(responseSuccess);
    } else if (this.resendotpid === responseData) {
      this.handleresentOtp(responseSuccess)
    }
  }



  handleErrorResponse(responseSuccess: any) {
    this.parseApiErrorResponse(responseSuccess);
  }

  handleNavigationPayload(message: any) {
    const phoneAuthToken = message.getData(getName(MessageEnum.AuthTokenDataMessage));
    const phoneNumber = message.getData(getName(MessageEnum.AuthTokenPhoneNumberMessage));
    const forgotPasswordBool = message.getData(getName(MessageEnum.EnterOTPAsForgotPasswordMessage));
    const emailValue = message.getData(getName(MessageEnum.AuthTokenEmailMessage));
    const userAccountID = phoneNumber ? phoneNumber.toString() : emailValue.toString();

    let updatedLabel = this.state.labelInfo;
    if (userAccountID && userAccountID !== "undefined") {
      updatedLabel = updatedLabel.replace("phone", userAccountID);
    }

    this.setState({
      otpAuthToken: phoneAuthToken && phoneAuthToken.length > 0 ? phoneAuthToken : this.state.otpAuthToken,
      userAccountID,
      labelInfo: updatedLabel,
      isFromForgotPassword: forgotPasswordBool === undefined ? this.state.isFromForgotPassword : forgotPasswordBool,
    });
  }

  handleEmailVerificationResponse(responseSuccess: any) {
    if (responseSuccess && responseSuccess.messages && responseSuccess.messages.length > 0) {
      const firstMessage = responseSuccess.messages[0];
      if (firstMessage.token) {
        this.setState({ showAlertError: false})
        localStorage.setItem("token", firstMessage.token);
        this.saveLoggedInUserData(responseSuccess);
        window.location.href = "AccountDetails";
      }
    } else {
      this.setState({ resenOtpSucess: false })
      this.setState({ showAlertError: true, alertErrorMessage: responseSuccess?.errors && responseSuccess?.errors[0]?.otp });
    }
  }

  handleresentOtp(responseSuccess: any) {
    if (responseSuccess?.meta && responseSuccess.meta.token) {
      this.setState({ showAlertError: false})
      this.setState({ resenOtpSucess: true })
      localStorage.setItem("userotptoken",responseSuccess?.meta && responseSuccess.meta.token)
    }
  }

  submitOtpWeb = () => {
    const headers = {
      "Content-Type": configJSON.apiVerifyOtpContentType,
    };

    const data = {
      token: localStorage.getItem('userotptoken'),
      otp_code: this.state.otpvalue
    }

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.emailVerificationApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_forgot_password/otp_confirmation" + this.state.otp
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiVerifyOtpMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  resentOtpWeb = () => {
    const headers = {
      "Content-Type": configJSON.apiVerifyOtpContentType,
    };

    const data = {
      attributes: {
        email: localStorage.getItem("useremail")
      }
    }

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resendotpid = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/send_otps"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiVerifyOtpMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  saveLoggedInUserData(responseJson: any) {

    if (responseJson && responseJson.messages && responseJson.messages.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.messages.token
      );

      this.send(msg);
    }
  }

  btnSubmitOTPProps = {
    onPress: () => this.submitOtp(),
  };

  txtMobilePhoneOTPWebProps = {
    onChangeText: (text: string) => this.setState({ otp: text }),
  };

  txtMobilePhoneOTPMobileProps = {
    ...this.txtMobilePhoneOTPWebProps,
    keyboardType: "numeric",
  };

  txtMobilePhoneOTPProps = this.isPlatformWeb()
    ? this.txtMobilePhoneOTPWebProps
    : this.txtMobilePhoneOTPMobileProps;

  handlesetOtp = (e: any) => {
    this.setState({ otpvalue: e })
  }
  // Customizable Area End
}
