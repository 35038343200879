import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    subscriptionDetailsOpen: boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class PaymentController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];

        this.state = {
            subscriptionDetailsOpen: false
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        this.handleCloseSubscriptionDetails = this.handleCloseSubscriptionDetails.bind(this)
        this.handleOpenSubscriptionDetails = this.handleOpenSubscriptionDetails.bind(this)

        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("on recieive==>" + JSON.stringify(message));

        // Customizable Area End
    }

    // Customizable Area Start
    handleOpenSubscriptionDetails() {
        this.setState({subscriptionDetailsOpen: true});
    }
    handleCloseSubscriptionDetails() {
        this.setState({subscriptionDetailsOpen: false});
    }
    goToBilling() {
        window.location.href = '/Billing'
    }
    goBack() {
        window.history.back();
    }
    handleEdit() {
        window.location.href = '/EditPayment'
    }
    // Customizable Area End
}
