import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import toast, { Renderable, Toast, ValueFunction } from 'react-hot-toast';
import { removeStorageData, setStorageData } from "framework/src/Utilities";
import React from "react";
import { countries } from "countries-list";
type accounts = {
  id: number;
  display: string;
  profile_picture: string;
};
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  firstName: any;
  lastName: any;
  email: any;
  phoneNumber: any;
  currentCountryCode: any;
  data: any[];
  passwordHelperText: String;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;

  currentPasswordText: any;
  newPasswordText: any;
  reTypePasswordText: any;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  countries: any;
  saveButtonDisable: boolean;
  openMenu: any;
  openVerticalMenu: any;
  otheruserprofileData: any,
  MyprofileData: any,
  invitedUsers: any[],
  acceptedUsers: any[],
  MyprofilePostData: any,
  myFavoritePosts: any[],
  otherFavoritePosts: any[],
  myRecomendationPosts: any[],
  otherRecomendationPosts: any[],
  OtheruserPostData: any,
  openBlockDrawer: any;
  changeTabValue: any;
  otheruserparamsid: any;
  setBlockreson: any;
  openblockModal: any;
  openInvitedDrawer: boolean;
  openAcceptedDrawer: boolean;
  setBlockKey: any;
  openMen1: null,
  connectionToRemove: string,
  isModalOpen: boolean,
  deletePostId: string,
  openMenpop: boolean,
  postTitle: string,
  editDeletePostId: number,
  deletePostTitle: string,
  sidebaarOpen: boolean,
  postId: string,
  sharemessage: string,
  shareconnectiondata: any,
  shareconnectiondata2: any,
  selectedmember: any,
  selectedPostId: number,
  isCommentDrawerOpen: boolean;
  comment: string;
  commentString: string;
  commentData: any;
  mentions_ids: any;
  isCommentsOpen: boolean;
  commentID: number;
  replyCommentString: string;
  errorMsg: string;
  loading: boolean;
  selectedComment: number;
  open3: boolean,
  commentReplyOpen: any;
  profile: any;
  users: any;
  replyComment: string;
  selectedmember3: any;
  sharemessage3: string;
  showEmoji: boolean;
  emoji: any;
  replyShowEmoji: boolean;
  openMenuId: number | null;
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  labelFirstName: string;
  lastName: string;
  labelArea: string;
  labelMobile: string;
  labelEmail: string;
  labelCurrentPassword: string;
  labelNewPassword: string;
  labelRePassword: string;
  btnTextCancelPasswordChange: string;
  btnTextSaveChanges: string;
  labelHeader: any;
  btnTextChangePassword: string;

  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  apiCallMessageUpdateProfileRequestId: any;
  getOtheruserprofileDataId: any;
  getMyuserprofileDataId: any;
  getInvitedUsersDataId: string = '';
  getAcceptedUsersDataId: string = '';
  getMyuserpostDataId: any;
  getMyFavoritePostsMessageId: string = '';
  getOtherFavoritePostsMessageId: string = ''
  getMyRecomendationPostsMessageId: string = '';
  getOtherRecomendationPostsMessageId: string = ''
  likePostCallId: string = '';
  addFavDataCallId: string = '';
  getOtheruserpostDataId: any;
  blockuserID: any;
  unblockuserID: any;
  addInviteId: any;
  validationApiCallId: string = "";
  apiChangePhoneValidation: any;
  registrationAndLoginType: string = "";
  deletepostDataId: string = "";
  authToken: any;
  uniqueSessionRequesterId: any;
  userProfileGetApiCallId: any;
  userAttr: any;
  getShareconnectionCallId: string = ""
  sharePostId: string = "";
  notificationshowID: string = "";
  apiCommentItemCallId: string = "";
  tagandMentionapiId: string = "";
  createCommentId: string = "";
  replycommentID: string = "";
  likeCommentCallID: string ="";
  shareCommentId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      currentCountryCode: configJSON.hintCountryCode,
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,

      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,

      currentPasswordText: "",
      newPasswordText: "",
      reTypePasswordText: "",

      countries,
      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      openMenu: false,
      openVerticalMenu: null,
      openMen1: null,
      otheruserprofileData: [],
      MyprofileData: [],
      MyprofilePostData: [],
      invitedUsers: [],
      acceptedUsers: [],
      OtheruserPostData: [],
      myFavoritePosts: [],
      otherFavoritePosts: [],
      myRecomendationPosts: [],
      otherRecomendationPosts: [],
      openBlockDrawer: false,
      changeTabValue: 0,
      otheruserparamsid: "",
      setBlockreson: {},
      openblockModal: false,
      openInvitedDrawer: false,
      openAcceptedDrawer: false,
      setBlockKey: "",
      connectionToRemove: '',
      isModalOpen: false,
      deletePostId: "",
      openMenpop: false,
      postTitle: "",
      editDeletePostId: 0,
      deletePostTitle: "",
      sidebaarOpen: false,
      postId: "",
      sharemessage: "",
      shareconnectiondata: [],
      selectedmember: [],
      selectedPostId: 0,
      isCommentDrawerOpen: false,
      comment: "",
      commentString: "",
      commentData: [],
      mentions_ids: [],
      isCommentsOpen: false,
      commentID: null as unknown as number,
      replyCommentString: "",
      errorMsg: "",
      loading: false,
      selectedComment: null as unknown as number,
      open3: false,
      commentReplyOpen: [],
      profile:[],
      users: [],
      replyComment: "",
      selectedmember3: [],
      shareconnectiondata2: [],
      sharemessage3: "",
      showEmoji: false,
      emoji: null,
      replyShowEmoji: false,
      openMenuId: null
    };

    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelArea = configJSON.labelArea;
    this.labelMobile = configJSON.labelMobile;
    this.labelEmail = configJSON.labelEmail;
    this.labelCurrentPassword = configJSON.labelCurrentPassword;
    this.labelNewPassword = configJSON.labelNewPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.btnTextCancelPasswordChange = configJSON.btnTextCancelPasswordChange;
    this.btnTextSaveChanges = configJSON.btnTextSaveChanges;
    this.labelHeader = configJSON.labelHeader;
    this.btnTextChangePassword = configJSON.btnTextChangePassword;
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on receive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId && responseJson) {
        this.handleApiResponse(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }


  // Customizable Area Start

  handleApiResponse(apiRequestCallId: any, responseJson: any) {
    switch (apiRequestCallId) {
      case this.getOtheruserprofileDataId:
        this.handleGetOtherUserProfileResponse(responseJson);
        break;
      case this.addInviteId:
        this.handleAddInviteResponse(responseJson);
        break;
      case this.blockuserID:
        this.handleBlockUserResponse(responseJson);
        break;
      case this.unblockuserID:
        this.handleUnblockUserResponse(responseJson);
        break;
      case this.getMyuserprofileDataId:
        this.handleGetMyUserProfileResponse(responseJson);
        break;
      case this.getMyuserpostDataId:
        this.handleGetMyUserPostResponse(responseJson);
        break;
      case this.getOtheruserpostDataId:
        this.handleGetOtherUserPostResponse(responseJson);
        break;
      case this.deletepostDataId:
        this.handleDeletePost(responseJson);
        break;
      case this.getInvitedUsersDataId:
        this.handleGetInvitedUsersResponse(responseJson)
        break;
      case this.getAcceptedUsersDataId:
        this.handleGetAcceptedUsersResponse(responseJson)
        break;
      case this.getMyFavoritePostsMessageId:
        this.handleGetMyFavoritePostsResponse(responseJson);
        break;
      case this.getOtherFavoritePostsMessageId:
        this.handleGetOtherFavoritePostsResponse(responseJson);
        break;
      case this.getMyRecomendationPostsMessageId:
        this.handleGetMyRecomendationPostsResponse(responseJson);
        break;
      case this.getOtherRecomendationPostsMessageId:
        this.handleGetOtherRecomendationPostsResponse(responseJson);
        break;
      case this.likePostCallId:
        this.handlepsotlikemanage()
        break;
      case this.addFavDataCallId:
        this.refreshPostsData({ data: responseJson })
        break;
      case this.getShareconnectionCallId:
        this.getShareconnection(responseJson)
        break;
      case this.sharePostId:
        this.sharePostData(responseJson)
        break;
      case this.notificationshowID:
        this.getNotificationdata(responseJson)
        break;
      case this.apiCommentItemCallId:
        this.setState({ commentData: responseJson.data, errorMsg: "", loading: false, });
        this.getEnablenotification()
        break;
      case this.likeCommentCallID:
        this.getCommentData(this.state.selectedPostId)
        break;
      case this.createCommentId:
        this.getCommentData(this.state.selectedPostId)
        break;
      case this.replycommentID:
        this.getCommentData(this.state.selectedPostId)
        break;
      case this.tagandMentionapiId:
        this.fetchUsers(responseJson)
        break;
      case this.shareCommentId:
        this.sharCommentData(responseJson)
        break;
      default:
        break;
    }

  }

  async componentDidUpdate(prevProps: any, prevState: { commentData: any; }) {
    if (prevState.commentData !== this.state.commentData) {
      this.initializeCommentReplyOpen();
    }
  }

  handleGohomepage=()=>{
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'NewsFeed');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
  }

  handleGoMyCircle = ()=>{
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'AddFriends');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
  }

  handlepsotlikemanage() {
    const otheruserid = window.location.href.split("/")[4]
    if (otheruserid) {
      this.getOtheruserPostData();
      this.getOtherFavoritePostData();
      this.getOtherRecomendationPostData();
    } else {
      this.getMyuserPostData();
      this.getMyFavoritePostData();
      this.getMyRecomendationPostData();
    }
  }
  refreshPostsData(responseJson: any) {
    if (responseJson.data) {
      const otheruserid = window.location.href.split("/")[4];
      if (otheruserid) {
        this.getOtherFavoritePostData();
        this.getOtherRecomendationPostData();
      } else {
        this.getMyFavoritePostData();
        this.getMyRecomendationPostData();
      }
    } else {
      toast.error(responseJson.errors[0].message);
    }

  }
  handleGetOtherUserProfileResponse(responseJson: any) {
    if (!responseJson.data) {
      toast.error(responseJson.errors[0].message);
    } else {
      this.setState({ otheruserprofileData: responseJson.data });
    }
  }

  handleAddInviteResponse(responseJson: any) {
    if (responseJson.data) {
      toast.success("Invitation request successfully sent!");
      this.getOtheruserProfileData();
    } else if (responseJson.message) {
      toast.success(responseJson.message);
      this.getOtheruserProfileData();
    } else if (responseJson.errors.length) {
      toast.error(responseJson.errors);
    }
  }

  handleBlockUserResponse(responseJson: any) {
    if (responseJson.data) {
      toast.success("User blocked successfully!");
      this.getOtheruserProfileData();
      this.cancelButton();
    } else if (responseJson.errors.length > 0) {
      toast.error(responseJson.errors[0].message);
      this.getOtheruserProfileData();
      this.cancelButton();
    }
  }

  handleUnblockUserResponse(responseJson: any) {
    if (responseJson.message) {
      this.getOtheruserProfileData();
      toast.success(responseJson.message);
    } else if (responseJson.errors.length > 0) {
      toast.error(responseJson.errors[0].message);
    }
  }

  handleGetMyUserProfileResponse(responseJson: any) {
    if (responseJson.data) {
      this.setState({ MyprofileData: responseJson.data });
    } else {
      toast.error(responseJson.errors[0].message);
    }
  }
  handleGetInvitedUsersResponse(responseJson: any) {
    if (responseJson.accounts) {
      this.setState({ invitedUsers: responseJson.accounts });
    } else {
      toast.error(responseJson?.errors[0].message);
    }
  }

  handleGetAcceptedUsersResponse(responseJson: any) {
    if (responseJson.accounts) {
      this.setState({ acceptedUsers: responseJson.accounts });
    } else {
      toast.error(responseJson?.errors[0].message);
    }
  }

  handleGetMyUserPostResponse(responseJson: any) {
    if (responseJson.data) {
      this.setState({ MyprofilePostData: responseJson.data });
    } else {
      toast.error(responseJson.errors[0].message);
    }
  }
  handleGetMyFavoritePostsResponse(responseJson: any) {
    if (responseJson.data) {
      this.setState({ myFavoritePosts: responseJson.data });
    } else {
      toast.error(responseJson.errors[0].message);
    }
  }
  handleGetOtherFavoritePostsResponse(responseJson: any) {
    if (responseJson.data) {
      this.setState({ otherFavoritePosts: responseJson.data });
    } else {
      toast.error(responseJson.errors[0].message);
    }
  }
  handleCloseMenu = () => {
    this.setState({
      openMenuId: null,
      openVerticalMenu: false,
    });
  };
  handleGetMyRecomendationPostsResponse(responseJson: any) {
    if (responseJson.data) {
      this.setState({ myRecomendationPosts: responseJson.data });
    } else {
      toast.error(responseJson.errors[0].message);
    }
  }
  handleGetOtherRecomendationPostsResponse(responseJson: any) {
    if (responseJson.data) {
      this.setState({ otherRecomendationPosts: responseJson.data });
    } else {
      toast.error(responseJson.errors[0].message);
    }
  }
  handleDeletePost(responseJson: { data: string; errors: { message: Renderable | ValueFunction<Renderable, Toast>; }[]; }) {
    if (responseJson.data) {
      toast.success("Post Deleted!");
    } else {
      toast.error(responseJson.errors[0].message);
    }
  }


  handleGetOtherUserPostResponse(responseJson: any) {
    if (responseJson.data) {
      this.setState({ OtheruserPostData: responseJson.data });
    } else {
      toast.error(responseJson.errors[0].message);
    }
  }
  drawerSidebaar = (elementid: string) => {
    this.setState({ postId: elementid })
    this.setState({ sidebaarOpen: true })
  }
  drawerSidebaarClose = () => {
    this.setState({ sidebaarOpen: false })
  }
  handlewritemessage = (event: any) => {
    this.setState({ sharemessage: event.target.value })
  }
  handlesearchTag = (event: any) => {
    this.handleSearchconnections(event)
  }

  handleSearchconnections = (data: any) => {
    const token = localStorage.getItem("accessToken")

    const getShareconnectionMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getShareconnectionCallId = getShareconnectionMessage.messageId;

    getShareconnectionMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_share/share?search=${data.target.value}`
    );

    getShareconnectionMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: `${token}`,
      })
    );

    getShareconnectionMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );

    runEngine.sendMessage(getShareconnectionMessage.id, getShareconnectionMessage);
  }

  getShareconnection = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({ shareconnectiondata: responseJson.data,shareconnectiondata2:responseJson.data })
    }
  }

  getvalueconnectioncheck = (elementdata: any, data: any) => {

    if (elementdata.target.checked) {
      const IteamIndex = this.state.selectedmember.findIndex((iteam: any) => iteam.id === data.id);


      if (IteamIndex < 0) {
        const storetype = [...this.state.selectedmember, { id: data.id, value: data.name }];
        this.setState({ selectedmember: storetype });
      }
    } else {
      const deleteSelectedType = this.state.selectedmember.filter((element: any) => {
        return element.id !== data.id
      })

      this.setState({ selectedmember: deleteSelectedType });
    }
  }

  handleselectmemberDelete = (elementdata: number) => {
    const deleteSelectedType = this.state.selectedmember.filter((element: any, index: number) => {
      return index !== elementdata
    })

    this.setState({ selectedmember: deleteSelectedType });
  }

  handlePostshare = () => {
    const token = localStorage.getItem("accessToken");

    const memberid = this.state.selectedmember.map((element: any) => element.id);
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };


    let formdata = new FormData()
    for (let iteams of memberid) {
      formdata.append("room_ids[]", iteams)
    }
    formdata.append("content", this.state.sharemessage)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sharePostId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `posts/${this.state.postId}/share`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `POST`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  sharePostData = (responseJson: any) => {
    if (responseJson.message) {
      this.getEnablenotification()
      this.setState({ shareconnectiondata: [], sharemessage: "", sidebaarOpen: false })
    }
  }

  getEnablenotification = () => {
    const token = localStorage.getItem("accessToken");

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notificationshowID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/unreads`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  getNotificationdata = (responseJson: any) => {
    if (responseJson.data) {
      localStorage.setItem("unreadnotification", responseJson.data.unread_notification)
    }
  }

  handleCommentfetch = (postId: number) => {
    this.getCommentData(postId)
    this.getUserData()
  }
  handleClick = (postId: number, event: React.MouseEvent<HTMLElement>) => {
    this.toggleCommentDrawer(true, postId);
    this.setState({ selectedPostId: postId });
    this.handleCommentfetch(postId)
  }
  toggleCommentDrawer = (isOpen: boolean, postId: number) => {
    this.setState({ isCommentDrawerOpen: isOpen, selectedPostId: postId });
  }

  handleClose2 = (event: React.MouseEvent<HTMLElement>) => {
    this.toggleCommentDrawer(false, 0);
    this.setState({showEmoji: false });
    this.setState({ replyShowEmoji: false });
  }
 

  handleCommentChange = (event: any) => {
    this.setState({ comment: event.target.value });
  };
  handleCommentSubmit = () => {
    const { comment, commentData } = this.state;
    const mentionRegex = /@\[(.+?)\]\((\d+)\)/g;
    let mentionIds: number[] = [];
    let formattedComment = comment.replace(mentionRegex, (_, display, id) => {
      mentionIds.push(id);
      return `@${display}`;
    });
    if (comment.trim()) {
      const newComment = {
        attributes: {
          comment: formattedComment,
          post_id: this.state.selectedPostId,
          mentions_ids: mentionIds
        }
      };

      this.setState({
        commentData: [newComment, ...commentData],
        commentString: formattedComment,
        mentions_ids: mentionIds,
        comment: '',
        isCommentsOpen: true,
      }, () => {
        this.createComment();
        this.getCommentData(this.state.selectedPostId)
      });
    }
  };
  toggleComments = () => {
    this.setState((prevState) => ({ isCommentsOpen: !prevState.isCommentsOpen }));
  };

  initializeCommentReplyOpen = () => {
    const commentReplyOpen = this.state.commentData.map(() => false);
    this.setState({ commentReplyOpen });
  };

  handleReplycomment = () => {
    const { replyComment, commentData } = this.state;
    if (replyComment.trim()) {
      const newReply = {
        attributes: {
          comment: replyComment,
          post_id: this.state.selectedPostId,
          parent_comment_id: this.state.commentID,
        }
      };

      const updatedComments = commentData.map((comment: any) => {
        if (comment.attributes.id === this.state.commentID) {
          return {
            ...comment,
            attributes: {
              ...comment.attributes,
              replies: [newReply, ...comment.attributes.replies]
            }
          };
        }
        return comment;
      });

      this.setState({
        commentData: updatedComments,
        replyCommentString: this.state.replyComment,
        replyComment: '',
        commentReplyOpen: []
      }, () => {
        this.createReplyComment();
      });
    }
    this.getCommentData(this.state.selectedPostId)
  }

  likeComment = (e: any) => {
    const header = {
      "Content-Type": 'application/json',
      token: localStorage.getItem("accessToken")
    };

    const attrs = {
      "post_id": e.attributes.post_id,
      "likeable_id": e.id,
      "likeable_type": "BxBlockMentionsAndTagging::Comment"
    };
    const data = {
      "type": e.attributes.is_liked ? "unlike" : "like",
      "attributes": attrs
    };

    const finaldata = {
      data
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.likeCommentCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_like/likes'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(finaldata)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleShareCommentClick = (id: number) => {
    this.setState({ selectedComment: id, open3: true });
  };

  getvalueconnectioncheck3 = (elementdata: any, data: any) => {

    if (elementdata.target.checked) {
      const IteamIndex = this.state.selectedmember3.findIndex((iteam: any) => iteam.id === data.id);


      if (IteamIndex < 0) {
        const storetype = [...this.state.selectedmember3, { id: data.id, value: data.name }];
        this.setState({ selectedmember3: storetype });
      }
    } else {
      const deleteSelectedType = this.state.selectedmember3.filter((element: any) => {
        return element.id !== data.id
      })

      this.setState({ selectedmember3: deleteSelectedType });
    }
  }
  handleselectmemberDelete3 = (elementdata: number) => {
    const deleteSelectedType = this.state.selectedmember3.filter((element: any, index: number) => {
      return index !== elementdata
    })

    this.setState({ selectedmember3: deleteSelectedType });
  }

  drawerSidebaarClose3 = () => {
    this.setState({ open3: false, selectedmember3: [], shareconnectiondata2: [] })
  }

  fetchUsers = (data: { accounts: { id: number; first_name: string; last_name: string; profile_picture: { url: string } }[] }) => {
    const mappedUsers: accounts[] = data.accounts.map((user) => ({
      id: user.id,
      display: `${user.first_name} ${user.last_name}`,
      profile_picture: user.profile_picture.url,
    }));
    this.setState({ users: mappedUsers });
  };

  sharCommentData = (responseJson: any) => {
    if (responseJson.message) {
      this.getEnablenotification()
      this.setState({ shareconnectiondata2: [], sharemessage3: "", open3: false })
    }
  }

  handlewriteCommentmessage = (event: any) => {
    this.setState({ sharemessage3: event.target.value })
  }

  handleCommentshare = () => {
    const token = localStorage.getItem("accessToken");

    const memberid = this.state.selectedmember3.map((element: any) => element.id);
    const header = {
      token,
    };

    let formdata = new FormData()
    for (let iteams of memberid) {
      formdata.append("room_ids[]", iteams)
    }
    formdata.append("content", this.state.sharemessage3)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.shareCommentId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `comments/${this.state.selectedComment}/share`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `POST`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  createComment = () => {
    const header = {
      "Content-Type":'application/json',
      token: localStorage.getItem("accessToken")
    };

    const httpBody = {
      post_id: this.state.selectedPostId,
      comment: this.state.commentString,
      mentions_ids: this.state.mentions_ids
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createCommentId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_mentions_and_tagging/comments'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  createReplyComment() {
    const header = {
      "Content-Type": 'application/json',
      token: localStorage.getItem("accessToken")
    };

    const replyData = {
      parent_comment_id: this.state.commentID,
      comment: this.state.replyCommentString,
      mentions_ids: []
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.replycommentID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_mentions_and_tagging/comments/create_reply'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(replyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getCommentData(postId: number): any {
    const header = {
      "Content-Type":'application/json',
      token: localStorage.getItem("accessToken")
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiCommentItemCallId = requestMessage.messageId;

    const endpoint = `bx_block_mentions_and_tagging/comments?post_id=${postId}`;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getUserData(): any {
    const header = {
      "Content-Type": 'application/json',
      token: localStorage.getItem("accessToken")
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.tagandMentionapiId = requestMessage.messageId;

    const endpoint = `bx_block_addfriends/connections`;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  calculateTimeGap = (createdAt: string): string => {
    const createdDate = new Date(createdAt);
    const currentDate = new Date();
    const diffInMillis = currentDate.getTime() - createdDate.getTime();
    const diffInSeconds = diffInMillis / 1000;
    const diffInMinutes = diffInSeconds / 60;
    const diffInHours = diffInMinutes / 60;
    const diffInDays = diffInHours / 24;
    const diffInWeeks = diffInDays / 7;

    if (diffInMinutes < 1) {
      return 'few mins ago';
    } else if (diffInHours < 1) {
      return `${Math.floor(diffInMinutes)}m`;
    } else if (diffInHours < 24) {
      if (diffInHours < 1.5) {
        return 'few hrs ago';
      }
      return `${Math.floor(diffInHours)}h`;
    } else if (diffInDays < 7) {
      return `${Math.floor(diffInDays)}d`;
    } else {
      return `${Math.floor(diffInWeeks)}w`;
    }
  };

  handleOpenMenu = (e: any) => {
    this.setState({ openMenu: e.currentTarget })
  }
  handleOpenVerticalMenu = (e: any, id: number) => {
    this.setState({ openVerticalMenu: e.currentTarget,openMenuId: id, editDeletePostId: id,
    })
  }

  handleOpenMenu1 = (event: { currentTarget: any }, element: { id: number, attributes: { body: string } }) => {
    this.setState({
      openMen1: event?.currentTarget, openMenpop: true,
      editDeletePostId: element.id,
      deletePostTitle: element.attributes.body,
    })
  }

  handleOpenInvitedDrawer = () => {
    this.setState({ openInvitedDrawer: true })
  }
  handleCloseIntivedDrawer = () => {
    this.setState({ openInvitedDrawer: false })
  }
  handleOpenAcceptedDrawer = () => {
    this.setState({ openAcceptedDrawer: true })
  }
  handleCloseAcceptedDrawer = () => {
    this.setState({ openAcceptedDrawer: false })
  }
  handleClose = (event: string, reason: string) => {
    if (reason === 'backdropClick') {
      this.setState({ openMenu: false })
      return;
    }
    this.setState({ openMenu: false })
    this.setState({ openBlockDrawer: true })
  }

  handleCloseCus = () => {
    this.setState({
      openMenpop: false,
      isModalOpen: false
    })
  }

  handleCopyLink = (link: string) => {
    navigator.clipboard.writeText(link)
  };
  handleMenuItemClick = (value: string, deleteId: string) => {
    if (value === 'Delete') {
      this.deletePostData(deleteId)
    }
    setTimeout(() => {
      this.setState({
        openMenpop: false,
        isModalOpen: false
      }, () => {
        this.getMyuserPostData();
      })
    }, 3000)
  };

  setblockcolor = (element: any, index: any) => {
    this.setState({ setBlockreson: element, setBlockKey: index })
  }

  OpenBlockmodel = () => {
    this.setState({ openblockModal: true })
  }

  cancelButton = () => {
    this.setState({ openblockModal: false })
    this.setState({ openBlockDrawer: false })
  }

  OpenBlockDrawerclose = () => {
    this.setState({ openBlockDrawer: false });
  }

  redirectsettings = () => {
    window.location.href = "Settings"
  }

  redirectpostcreation = () => {
    window.location.href = "PostCreation"
  }

  handleCloseModal = () => {
    this.setState({
      isModalOpen: false
    })
  }

  setdeleteItemClick = (postTitle: string, deleteId: string) => {
    this.setState({
      deletePostId: `${this.state.editDeletePostId}`,
      isModalOpen: true,
      postTitle: this.state.deletePostTitle
    })
  }

  gotoPostCreation = async (postId: string) => {
    await setStorageData("editPostId", this.state.editDeletePostId);
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "PostCreation"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };

  confirmButton = () => {

    const token = localStorage.getItem("accessToken")
    const otheruserid = window.location.href.split("/")[4]


    const data = {
      "blocker_id": otheruserid,
      "reason_title": this.state.setBlockreson.title,
      "reason_description": this.state.setBlockreson.value,
    }


    const blockuser = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.blockuserID = blockuser.messageId;

    blockuser.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_block_users/blocked_user`
    );

    blockuser.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.validationApiContentType,
        token: `${token}`,
      })
    );


    blockuser.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );


    blockuser.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    )


    runEngine.sendMessage(blockuser.id, blockuser);
  }

  unblockuser = () => {
    const otheruserid = window.location.href.split("/")[4]

    const header = {
      token: localStorage.getItem("accessToken")
    };


    let formdata = new FormData()
    formdata.append("account_id", otheruserid);

    const unblockuser = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.unblockuserID = unblockuser.messageId;

    unblockuser.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_block_users/unblock_user`
    );

    unblockuser.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    unblockuser.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );


    unblockuser.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );


    runEngine.sendMessage(unblockuser.id, unblockuser);
  }

  changeTabvalue = (event: any, newValue: any) => {
    this.setState({ changeTabValue: newValue })
  }

  async componentWillMount() {
    await removeStorageData("editPostId");
    const otheruserid = window.location.href.split("/")[4]
    if (otheruserid) {
      this.getOtheruserProfileData()
      this.getOtheruserPostData()
      this.getOtherFavoritePostData();
      this.getOtherRecomendationPostData();

      this.setState({ otheruserparamsid: window.location.href.split("/")[4] })
    } else {
      this.getMyuserprofileData()
      this.getMyuserPostData()
      this.getInvitedUsersData();
      this.getAcceptedUsersData();
      this.getMyFavoritePostData();
      this.getMyRecomendationPostData();

    }
  }

  handleReplySection = (index: number, commentId: number) => {
    const updatedCommentReplyOpen = this.state.commentReplyOpen.map((isOpen: boolean, i: number) => (i === index ? !isOpen : isOpen));
    this.setState({ commentReplyOpen: updatedCommentReplyOpen, commentID: commentId });
  };
  handleReplyCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ replyComment: event.target.value });
  };
  getInvitedUsersData() {
    const token = localStorage.getItem("accessToken")

    const getInvitedMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getInvitedUsersDataId = getInvitedMessage.messageId;

    getInvitedMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.invitedUsersApiEndPoint
    );

    getInvitedMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.applicationJsonContentType,  // Move to config file
        token,
      })
    );

    getInvitedMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(getInvitedMessage.id, getInvitedMessage);
  }

  onClickEmoji() {
    this.setState({ showEmoji: !this.state.showEmoji })
  }
  EmojiClick(emojiObject: any) {
    const { comment } = this.state;
    const newText = comment.slice(0) + emojiObject.emoji
    this.setState({ comment: newText, showEmoji: false });
  }

  onClickReplyEmoji() {
    this.setState({ replyShowEmoji: !this.state.replyShowEmoji });
  }
  ReplyEmojiClick(emojiObject: any) {
    const { replyComment } = this.state;
    const newText = replyComment.slice(0) + emojiObject.emoji
    this.setState({ replyComment: newText, replyShowEmoji: false });
  }

  getAcceptedUsersData() {
    const token = localStorage.getItem("accessToken")

    const getAcceptedMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAcceptedUsersDataId = getAcceptedMessage.messageId;

    getAcceptedMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.acceptedUsersApiEndPoint
    );

    getAcceptedMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.applicationJsonContentType,
        token
      })
    );

    getAcceptedMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(getAcceptedMessage.id, getAcceptedMessage);
  }

  getToFavriouteData(addFavId: string) {
    const token = localStorage.getItem("accessToken")

    const getNewsFeedMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addFavDataCallId = getNewsFeedMessage.messageId;

    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AddToFavIdPoint + addFavId + '/mark_as_favorite'
    );

    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.applicationJsonContentType,
        token: `${token}`,
      })
    );

    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(getNewsFeedMessage.id, getNewsFeedMessage);
  }
  likePost = (e: any) => {
    const token = localStorage.getItem("accessToken")
    const account_id = localStorage.getItem("account_id")

    const attrs = {
      "post_id": e.id,
      "account_id": account_id
    };

    const data = {
      type: e.attributes.is_liked ? "unlike" : "like",
      attributes: attrs
    };

    const finaldata = {
      data
    }


    const LikePostMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.likePostCallId = LikePostMessage.messageId;

    LikePostMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_like_a_post/like_posts`
    );

    LikePostMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.applicationJsonContentType,
        token: `${token}`,
      })
    );

    LikePostMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    LikePostMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(finaldata)
    );

    runEngine.sendMessage(LikePostMessage.id, LikePostMessage);
  }
  getMyuserprofileData() {
    const token = localStorage.getItem("accessToken")
    const accountId = localStorage.getItem("account_id")


    const getMyuserMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getMyuserprofileDataId = getMyuserMessage.messageId;

    getMyuserMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${accountId}`
    );

    getMyuserMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentTypeApiGetUserProfile,
        token: `${token}`,
      })
    );

    getMyuserMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(getMyuserMessage.id, getMyuserMessage);
  }

  getMyFavoritePostData() {
    const token = localStorage.getItem("accessToken")

    const getMyFavoritePostsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getMyFavoritePostsMessageId = getMyFavoritePostsMessage.messageId;

    getMyFavoritePostsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.myFavoritePosts
    );

    getMyFavoritePostsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.applicationJsonContentType,
        token: `${token}`,
      })
    );

    getMyFavoritePostsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(getMyFavoritePostsMessage.id, getMyFavoritePostsMessage);
  }

  getOtherFavoritePostData() {
    const token = localStorage.getItem("accessToken")
    const accountId = window.location.href.split("/")[4]

    const getOtherFavoritePostsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getOtherFavoritePostsMessageId = getOtherFavoritePostsMessage.messageId;

    getOtherFavoritePostsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.myFavoritePosts}?account_id=${accountId}`
    );

    getOtherFavoritePostsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.applicationJsonContentType,
        token: `${token}`,
      })
    );

    getOtherFavoritePostsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(getOtherFavoritePostsMessage.id, getOtherFavoritePostsMessage);
  }
  getMyRecomendationPostData() {
    const token = localStorage.getItem("accessToken")

    const getMyRecomendationPostsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getMyRecomendationPostsMessageId = getMyRecomendationPostsMessage.messageId;

    getMyRecomendationPostsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.myRcomendationPosts
    );

    getMyRecomendationPostsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.applicationJsonContentType,
        token: `${token}`,
      })
    );

    getMyRecomendationPostsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(getMyRecomendationPostsMessage.id, getMyRecomendationPostsMessage);
  }

  getOtherRecomendationPostData() {
    const token = localStorage.getItem("accessToken")
    const accountId = window.location.href.split("/")[4]

    const getOtherRecomendationPostsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getOtherRecomendationPostsMessageId = getOtherRecomendationPostsMessage.messageId;

    getOtherRecomendationPostsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.myRcomendationPosts}?account_id=${accountId}`
    );

    getOtherRecomendationPostsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.applicationJsonContentType,
        token: `${token}`,
      })
    );

    getOtherRecomendationPostsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(getOtherRecomendationPostsMessage.id, getOtherRecomendationPostsMessage);
  }

  getMyuserPostData() {
    const token = localStorage.getItem("accessToken")

    const getMyuserPostMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getMyuserpostDataId = getMyuserPostMessage.messageId;

    getMyuserPostMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/my_post`
    );

    getMyuserPostMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentTypeApiGetUserProfile,
        token: `${token}`,
      })
    );

    getMyuserPostMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(getMyuserPostMessage.id, getMyuserPostMessage);
  }

  getOtheruserPostData() {
    const token = localStorage.getItem("accessToken")
    const otheruserid = window.location.href.split("/")[4]

    const getOtheruserPostMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getOtheruserpostDataId = getOtheruserPostMessage.messageId;

    getOtheruserPostMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/my_post?account_id=${otheruserid}`
    );

    getOtheruserPostMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentTypeApiGetUserProfile,
        token: `${token}`,
      })
    );

    getOtheruserPostMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(getOtheruserPostMessage.id, getOtheruserPostMessage);
  }

  getOtheruserProfileData() {
    const token = localStorage.getItem("accessToken")
    const otheruserid = window.location.href.split("/")[4]

    const getOtheruserMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getOtheruserprofileDataId = getOtheruserMessage.messageId;

    getOtheruserMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/${otheruserid}`
    );

    getOtheruserMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentTypeApiGetUserProfile,
        token: `${token}`,
      })
    );

    getOtheruserMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(getOtheruserMessage.id, getOtheruserMessage);
  }
  inviteCircle = (e: any) => {
    const header = {
      token: localStorage?.getItem("accessToken")
    };

    let formdata = new FormData()
    formdata.append("receipient_id", e);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addInviteId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_addfriends/connections'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deletePostData(deleteId: string) {
    const token = localStorage.getItem("accessToken")

    const getMyuserPostMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getMyuserPostMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentTypeApiGetUserProfile,
        token: `${token}`,
      })
    );

    this.deletepostDataId = getMyuserPostMessage.messageId;

    getMyuserPostMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteMypostApiEnd + deleteId
    );

    getMyuserPostMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );

    runEngine.sendMessage(getMyuserPostMessage.id, getMyuserPostMessage);
  }

  // Customizable Area End

}