Object.defineProperty(exports, '__esModule', {
  value: true
});

// Customizable Area Start
exports.apiContentType = 'application/json';
exports.apiFormContentType = 'multipart/form-data';
exports.getApiMethod = 'GET';
exports.postApiMethod = 'POST';

exports.getTermsCondsApiEndPoint = 'terms_and_conditions/latest_record';
exports.getAllTermsCondsApiEndPoint = 'terms_and_conditions/terms_and_conditions';
exports.createTermsCondsApiEndPoint = 'terms_and_conditions/terms_and_conditions';
exports.getAccountGroupsApiEndPoint = 'account_groups/groups';
exports.setTermsCondsAcceptanceApiEndPoint = 'terms_and_conditions/accept_and_reject';

exports.createTermsConds = 'Create New'
// web agree button
exports.agreeTermsConds = 'Agree'

exports.created = 'Created'
exports.tickAccept = 'Please Tick To Accept';
exports.updateTermsConds = 'Update';
exports.acceptedUsers = 'Accepted Users';
exports.saveTermsConds = 'Save';
exports.termsCondsUpdatedMassage = 'Terms and Conditions Updated Successfully';
exports.termsCondsList = 'Terms & Conditions List';
exports.termsConds = 'Terms & Conditions';
exports.termsCondsUserList = 'Users That Accepted List';
exports.dateFormat = "MM-DD-YYYY";
exports.termConditionList = [{
  name: 'Terms & Conditions',
  description: 'Test',
}, {
  name: 'General Data Protection Regulation (EU GDPR)',
  desccription: 'test'
},
{
  name: 'Privacy Policy',
  description: 'test' 
}];
exports.acceptTermsCondsObjList = [
  {heading:`Terms & Conditions`,
  details:[
    "Kella Leadership Terms and Conditions",
    "This page (together with the documents referred to on it) tells you the terms on which you may make use of our website www.KellaLeadership.com (“our site”), whether as a guest or a registered user, a Kella Leadership member. Please read these terms of use carefully before you start to use the site.",
    "By using this website you are deemed to have read and agreed to these terms and conditions:",
    "INFORMATION ABOUT US",
    "Our site is operated by Kella Leadership Limited (“this Company/We”), a company registered in Ireland at",
    "℅ Hourigan Rowsome\n3rd floor,\n Howleys Quay\nLimerick\nIreland\nCSO number 728681 Tax Registration Number  4098489CH ",
    "PRIVACY STATEMENT (INCLUDING COOKIES)",
    "We are committed to protecting your privacy. Please refer to our Privacy Policy  and our Cookies Policy) for details on how we do this.",
    "NO INFORMATION FROM CHILDREN UNDER AGE 18 IF YOU ARE UNDER AGE 18, PLEASE DO NOT ATTEMPT TO REGISTER ON THIS WEBSITE OR PROVIDE ANY PERSONAL INFORMATION ABOUT YOURSELF TO US. IF WE LEARN THAT WE HAVE COLLECTED PERSONAL INFORMATION FROM A CHILD UNDER AGE 18, WE WILL DELETE THAT INFORMATION AS QUICKLY AS POSSIBLE. IF YOU BELIEVE THAT WE MIGHT HAVE ANY INFORMATION FROM A CHILD UNDER AGE 18, PLEASE CONTACT US.",
    "DISCLAIMER AND EXCLUSIONS AND LIMITATIONS",
    "The information on this web site is provided on an “as is” basis. To the fullest extent permitted by law, this Company:",
    "excludes all representations and warranties relating to this website and its contents or which is or may be provided by any affiliates or any other third party, including in relation to any inaccuracies or omissions in this website and/or the Company’s literature; and",
    "exclude all liability for damages arising out of or in connection with your use of this website. This includes, without limitation, direct loss, loss of business or profits (whether or not the loss of such profits was foreseeable, arose in the normal course of things or you have advised this Company of the possibility of such potential loss), damage caused to your computer, computer software, systems and programs and the data thereon or any other direct or indirect, consequential and incidental damages.",
    "This Company does not however exclude liability for death or personal injury caused by its negligence. The above exclusions and limitations apply only to the extent permitted by law. None of your statutory rights as a consumer are affected.",
    "TERMINATION OF AGREEMENTS AND REFUNDS POLICY",
    "Both the Client and ourselves have the right to terminate any Services Agreement for any reason, including the ending of services that are already underway. No refunds shall be offered, where a Service is deemed to have begun and is, for all intents and purposes, underway.", 
    "All delgate passes, awards bookings and sponsorship fees paid for Kella Leadership events are non-refundable. Delegate passes and awards tickets may be transferred to an alternative guest if notice is given in writing to events@KellaLeadership.com no less than 5 working days prior to the event.",
    "Any Kella Leadership Founding Member wishing to resign must do so in writing to Membership Committee, Kella Leadership Ltd, ℅ Hourigan Rowsome 3rd floor, Howleys Quay Limerick Ireland or via email to hello@KellaLeadership.com. ",
    "Any Kella Leadership Network member wishing to cancel their account should email hello@KellaLeadership.com.  No refunds will be made whether on unsubscription, expiry or other termination of membership. All memberships are based on a yearly or half yearly contract, and any unsubscription mid-way throughout the contract period will not be refunded.",
    "OUR RIGHT TO SUSPEND OR CANCEL YOUR REGISTRATION:",
    "We may suspend or cancel your registration immediately at our reasonable discretion or if you breach any of your obligations under these Terms and Conditions.",
    "You can cancel your registration at any time by informing us in writing at Kella Leadership Limited, ℅ Hourigan Rowsome 3rd floor, Howleys Quay Limerick Ireland If you do so, you must stop using our site.",
    "The suspension or cancellation of your registration and your right to use our site shall not affect either party’s statutory rights or liabilities.",
    "AVAILABILITY",
    "You are solely responsible for evaluating the fitness for a particular purpose of any download, program and text available through this site. Redistribution or republication of any part of this site or its content is prohibited, including such by framing or other similar or any other means, without the express written consent of the Company. The Company does not warrant that the service from this site will be uninterrupted, timely or error free, although it is provided to the best ability. By using this service you thereby indemnify this Company, its employees, agents and affiliates against any loss or damage, in whatever manner, howsoever caused.",
    "LINKS TO THIS WEBSITE",
    "We reserve the right to ask you to remove links to our website from sites which go against the values of Kella Leadership, or may cause the Kella Leadership brand to be compromised. If you do create a link to a page of this website you do so at your own risk and the exclusions and limitations set out above will apply to your use of this website by linking to it.",
    "LINKS FROM THIS WEBSITE",
    "We do not monitor or review the content of other party’s websites which are linked to from this website. Opinions expressed or material appearing on such websites are not necessarily shared or endorsed by us and should not be regarded as the publisher of such opinions or material. Please be aware that we are not responsible for the privacy practices, or content, of these sites. We encourage our users to be aware when they leave our site & to read the privacy statements of these sites. You should evaluate the security and trustworthiness of any other site connected to this site or accessed through this site yourself, before disclosing any personal information to them. This Company will not accept any responsibility for any loss or damage in whatever manner, howsoever caused, resulting from your disclosure to third parties of personal information.",
    "YOUR CONTENT",
    "You own any copyright in the text that you post or resources you upload on our website. However, when you post text or upload resources, you expressly grant us a perpetual, unlimited free license to republish that text/resource on our site and to redistribute/make available and/or sell that text/resource in print or electronic form anywhere in the world as part of an edited compilation or otherwise.",
    "DISCLAIMER OF WARRANTIES AND LIABILITY",
    "We provide our site in good faith but we can’t and don’t warrant the completeness, truth or accuracy of the information or other content or postings found on our site, or their usefulness for any particular purpose. You acknowledge and agree that you bear full responsibility for your own contributions to the website and that we shall not be liable for any action that you or others take or don’t take based on your use of or reliance on information provided by us or other users of this website.",
    "We don’t promise that your access to our site, or its content will be delivered uninterrupted, timely or error-free, or that the site will be free from viruses or other harmful properties. It is your responsibility to implement satisfactory safeguards and procedures to make sure any files you obtain through our site are free from such contaminants or other harmful properties.",
    "The effect of what is set out here is that you agree that under no circumstances will we be held liable for any direct, indirect, incidental or other type loss or injury resulting from your use, or downloading of any content on our site.",
    "DISCUSSION AND USER COMMUNICATION SYSTEMS",
    "We encourage debate and the sharing of information between our users.",
    "Your details: You must ensure that the details provided by you on registration or at any time are correct and complete. You must inform us immediately of any changes to the information that you provided when registering by updating your personal details in order that we can communicate with you effectively.",
    "Password and security: When you register to use our platforms you will be asked to create a password. In order to prevent fraud, you must keep this password confidential and must not disclose it or share it with anyone. If you know or suspect that someone else knows your password you should notify us by contacting Kella Leadership Ltd, ℅ Hourigan Rowsome 3rd floor, Howleys Quay Limerick Ireland immediately.",
    "If we have reason to believe that there is likely to be a breach of security or misuse of our forum, we may require you to change your password or we may suspend your account.",
    "You may not use any of our communication systems for any illegal purpose and in particular you will not:",
    "post or transmit material that infringes the intellectual property rights or other rights of others or post or transmit any material that is unlawful, obscene, defamatory, threatening, harassing, abusive, hateful, or embarrassing to any other person as determined by us in our sole discretion",
    "post or transmit advertisements for or solicitations of business",
    "after receiving a warning, continue to disrupt the normal flow of dialogue, or post or transmit comments that are not related to the topic being discussed",
    "post or transmit chain letters or pyramid schemes",
    "impersonate another person",
    "disguise the IP address of the connection used to post any message",
    "post or transmit any files containing viruses or other harmful computer code",
    "harvest or otherwise collect or use information about others, including e-mail addresses, without their explicit consent",
    "allow any other person or entity to use your identification for posting or viewing comments or for communicating with other users",
    "post the same note more than once or “spam”; or",
    "engage in any other conduct that restricts or inhibits any other person from using or enjoying these areas of our site, or which, in our judgment, exposes us to any liability or detriment of any type",
    "intentionally make false or misleading statements about investments, their price or their prospects. If you do, you may be committing a criminal offence.",
    "post either intentionally or otherwise, any material that could have the effect of manipulating the market value of any investment. This may be a criminal offence.",
    "give investment advice by way of business or make financial promotions.",
    "Please note that any posting of information on our platforms is the opinion of the person posting only and in no way reflects our opinions or attitudes. We operate an open forum and sometimes messages are posted that are misleading, deceptive, or incorrect. You should not rely on information being accurate or complete. If you do, you do so at your own risk.",
    "We don’t commit to monitoring the use of the chat forums or our communications systems; we rely on you to inform us if you spot any abuse or inappropriate behaviour, in which case we will review specific postings. If you feel you have been threatened, damaged or abused in our chat forums or via our communications systems that we operate or if you believe any infringement of your rights may have occurred through our site please contact us.",
    "We reserve the right (but we are not obliged) to do any or all of the following:",
    "•  record the communications in chat forums and communications systems",
    "•	investigate a claim that any one or more communication does not conform to the terms of this clause and determine in our sole discretion to remove or request the removal of the communication(s)",
    "•	remove without notice communications which are abusive, illegal, or disruptive, or that otherwise fail to conform with these Terms",
    "•	terminate a user’s access to post messages to any or all of these areas",
    "•	monitor, edit, or disclose any communication in these areas.",
    "•	edit or remove any communication(s) posted on our website, regardless of whether such communication(s) breaches these Terms.",
    "If you disagree with a decision of a member (e.g. comment flagged as inappropriate), you should appeal via contact us.",
    "Any decision we make to remove or request the removal of any communication or to terminate or suspend the posting privileges of any member shall be final and binding. The termination or suspension of posting privileges shall apply to any and all user accounts that may have been used by that person, whether or not opened by that person.",
    "INDEMNITY",
    "You agree to indemnify, defend and hold harmless us, our directors, officers, employees and licensors from and against any claim, liability, cost, damage or loss we may incur (including reasonable legal fees) as a result of any material that you post or transmit on our communication forums or via our communication systems, any actions you take which disrupt access to and/or the functioning of our site or any breach by you of your obligations under these Terms.",
    "INTELLECTUAL PROPERTY",
    "We are the owner or the licensee of all intellectual property rights in our site, and in the material published on it.  Those works are protected by copyright laws and treaties around the world.  All such rights are reserved.",
    "You may print off one copy, and may download extracts, of any page(s) from our site for your personal reference and you may draw the attention of others within your organisation to material posted on our site. ",
    "You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.",
    "Our status (and that of any identified contributors) as the authors of material on our site must always be acknowledged.",
    "You must not use any part of the materials on our site for commercial purposes without obtaining a licence to do so from us or our licensors.",
    "If you print off, copy or download any part of our site in breach of these terms of use, your right to use our site will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.",
    "The Kella Leadership logo is a registered trademark of this Company in Ireland and other countries.",
    "COMMUNICATION",
    "We have several different e-mail addresses for different queries. These, and other contact information, can be found on our Contact Us link on our website or via Company literature or via the Company’s stated telephone.",
    "If you have joined the Kella Leadership Network you are automatically opted in to receive emails from Kella Leadership and chosen third parties. You can opt out of these emails in your profile page, selecting ‘Edit’, followed by ‘Profile’ and unchecking the boxes in the ‘Opt In’ section.",
    "FORCE MAJEURE",
    "We shall not be liable to you for any failure to perform any obligation under any Agreement which is due to an event beyond our control including but not limited to any Act of God, terrorism, war, Political insurgence, insurrection, riot, civil unrest, act of civil or military authority, uprising, earthquake, flood or any other natural or man made eventuality outside of our control, which causes the termination of an agreement or contract entered into, nor which could have been reasonably foreseen. If we are affected by such event, we shall forthwith inform you of the same and shall use all reasonable endeavours to comply with the terms and conditions of any Agreement contained herein.",
    "GENERAL",
    "The laws of Ireland govern these Terms and Conditions and the Irish courts shall have non-exclusive jurisdiction in any claim arising from, or related to, a visit to our site including any non-contractual obligations.",
    "If any of these Terms and Conditions are deemed invalid or unenforceable for any reason (including, but not limited to the exclusions and limitations set out above), then the invalid or unenforceable provision will be severed from these Terms and Conditions and the remaining terms will continue to apply.",
    "Failure of the Company to enforce any of the provisions set out in these Terms and Conditions and any Agreement, or failure to exercise any option to terminate, shall not be construed as waiver of such provisions and shall not affect the validity of these Terms and Conditions or of any Agreement or any part thereof, or the right thereafter to enforce each and every provision.", 
    "NOTIFICATION OF CHANGES",
    "The Company reserves the right to change these Terms and Conditions from time to time as it sees fit and your continued use of the site will signify your acceptance of any adjustment to these Terms and Conditions. If there are any changes to our Privacy Policy, we will announce that these changes have been made on our home page and on other key pages on our site. Any changes to our privacy policy will be posted on our website 30 days prior to these changes taking place. You are therefore advised to re-read this statement on a regular basis.", 
    ]
    },
    {heading:`General Data Protection Regulation (EU GDPR)`,
    details:[
      "Last updated: [4th January 2024]",
      "Introduction",
      '[Kella Leadership Limited] ("we," "us," or "our") is committed to protecting the privacy and rights of individuals concerning their personal data. This GDPR Privacy Policy outlines how we collect, process, store, and protect your personal information in compliance with the General Data Protection Regulation (GDPR).',
      "Data Controller",
      "[Kella Leadership Limited] is the data controller for the personal data processed through our web-based application.",
      "Information We Collect",
      "We collect and process the following categories of personal data:",
      "•	Identity Data: Includes name, username, and other identifiers provided by users.",
      "•	Contact Data: Includes email address, phone number, and other contact information.",
      "•	Usage Data: Information about how you use our application, including log files, IP address, device information, and browsing history.",
      "•	Transaction Data: Details about transactions you perform using our application.",
      "Legal Basis for Processing",
      "We process personal data based on one or more of the following legal bases:",
      "•	Contractual Necessity: Processing is necessary for the performance of a contract with the data subject.",
      "•	Legitimate Interests: Processing is necessary for our legitimate interests or the legitimate interests of a third party, except where overridden by the interests or fundamental rights and freedoms of the data subject.",
      "•	Consent: Processing is based on the data subject's consent.",
      "Purposes of Processing",
      "We process personal data for the following purposes:",
      "•	Providing Services: To deliver and improve our services, personalize your experience, and respond to your inquiries.",
      "•	Legal Compliance: To comply with legal obligations, including GDPR requirements.",
      "Data Subject Rights",
      "Under the GDPR, data subjects have the following rights:",
      "•	Right of Access: The right to obtain confirmation as to whether or not personal data is being processed and access to that data.",
      "•	Right to Rectification: The right to have inaccurate personal data corrected.",
      "•	Right to Erasure: The right to have personal data erased under certain circumstances.",
      "•	Right to Restriction of Processing: The right to restrict processing under certain circumstances.",
      "•	Right to Data Portability: The right to receive personal data in a structured, commonly used, and machine-readable format.",
      "•	Right to Object: The right to object to processing based on legitimate interests or for direct marketing purposes.",
      "Security Measures",
      "We implement appropriate technical and organizational measures to ensure a level of security appropriate to the risk of processing personal data.",
      "Data Breach Notification",
      "In the event of a personal data breach, we will notify the appropriate supervisory authority and affected data subjects in accordance with GDPR requirements.",
      "International Data Transfers",
      "We may transfer personal data to countries outside the European Economic Area (EEA) when necessary, ensuring appropriate safeguards are in place.",
      "Cookies and Similar Technologies",
      "We use cookies and similar technologies. By using our application, you consent to the use of such technologies. Please refer to our Cookie Policy for more information.",
      "Updates to This Policy",
      "We may update this GDPR Privacy Policy to reflect changes in our practices or for legal reasons. We encourage you to review this policy periodically.",
      "Contact Us",
      "If you have questions about our GDPR Privacy Policy, please contact us at info@kellaleadership.com",
      ]}, 
    {heading:`Privacy Policy`,
    details:[
      "Kella Leadership Privacy Policy",
      "This policy explains what you can expect from us and what we need from you in relation to your personal data. Kella Leadership respects your privacy and is committed to protecting your personal data. This Privacy Policy will tell you how we look after your personal data when you visit our website and/or use our Kella platform, together referred to as the “website” and inform you of your privacy rights and how the law protects you.",
      "IMPORTANT INFORMATION AND WHO WE ARE",
      "This Privacy Policy aims to give you information on how Kella Leadership collects and processes your personal data through your use of this website, including any data you may provide through the website when you sign up to receive our marketing material, register as an Kella Leadership platform member, or purchase a product or service from us.",
      "All personal data collected and stored by us is held in accordance with the Data Protections act of 1988, 2003 and May 2018 ",
      "This website is not intended for children and we do not knowingly collect data relating to children.",
      "It is important that you read this Privacy Policy together with any other privacy notice on our website from time to time so that you are fully aware of how and why we are using your data.",
      "CONTROLLER",
      "For the purpose of the relevant data protection regulations, Kella Leadership Ltd is the controller and responsible for your personal data (collectively referred to as, “we”, “us” or “our” in this Privacy Policy).",
      "CONTACT DETAILS",
      "Our full details are: Kella Leadership Limited,",
      "℅ Hourigan Rowsome",
      "3rd floor,",
      "Howleys Quay",
      "Limerick",
      "Ireland",
      "If you have any questions about how we protect or use your data, please email us at hello@KellaLeadership.com.",
      "You have the right to make a complaint at any time to the Data Protection Commission, the Irish supervisory authority for data protection issues. In Ireland, please read the page  Exercising Your Rights for details of how to do this. We would, however, appreciate the chance to deal with your concerns before you approach the DTC, so please contact us in the first instance. ",
      "HOW WE PROTECT YOUR PERSONAL INFORMATION",
      "We are serious about guarding the security of your personal information and use secure servers to store your personal information. All information you provide to us is stored on our secure servers.  We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed.",
      "In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.",
      "Unfortunately, transmission of information over the internet is not completely secure. We will do our best to protect your personal information, but we cannot guarantee its security and any transmission is at your own risk.",
      "INFORMATION WE MAY COLLECT FROM YOU",
      "We collect name, email, address, year of birth, job level, and company, if you use Kella Leadership products or services, including our membership service the Kella Leadership platform.",
      "We may collect other data you have provided while contacting us, especially using the contact, download, or signup forms on our website.",
      "If you have requested a marketing asset or have participated in a marketing event, we may use your personal information to follow up on the effectiveness of the marketing activity and to ascertain your interest in our products and services.",
      "We collect data that you have sent to us through an online survey, event attendance application, event nomination form, event attendance form or job application.",
      "We collect anonymous information sent by your browser when you visit our websites, including IP address, operating system, and browser version. If you identify yourself by filling out a form, some data (such as what pages you view on our websites) will be connected to your personal information.",
      "Personal data",
      "Like most websites, we gather statistical and other analytical information collected on an aggregate basis of all visitors to the Site. This non-personal data comprises information that cannot be used to identify or contact you, such as demographic information regarding, i.e. user IP addresses where they have been clipped or anonymised, browser types and other anonymous statistical data involving the use of the site.",
      "Non personal data gathered from visitors to the site helps us get a better general understanding of where our visitors come from and helps us to better design and organise our site",
      "Please note that we do not pass on our member details to any other entity for marketing purposes.",
      "How long do we store your data for ",
      "Unless you have requested for your details to be permanently deleted from our system, we hold all records on our system for a period of 7 years. After this date we will contact you and advise you that your account will be deleted in compliance with our obligation under the Data Protection Act.",
      "COOKIES",
      "We use cookies to distinguish you from other users of our website. This helps us to provide you with a good experience and also allows us to improve our website and services (for example, to remember your login details and improve or personalise our services to you).  You may adjust the settings on your browser to refuse cookies, but some of our services may not work if you do so. For detailed information on the cookies we use and why we use them, please read our Cookie Policy.",
      "What is the legal basis for processing your data",
      "All personal data is collected and processed according to the GDPR 2018 guidelines. These guidelines protect users' privacy and data in Europe. In some cases, we process data on a server outside of your own country including on servers in the United States of America and in other countries. When data is collected in another country but processed and held on our servers in Ireland for Kella Leadership.",
      "Where personal data is collected in a third country but processed and held on our servers in Ireland in order for Kella Leadership to provide the service for which subscribers have subscribed, please note that any transfer of such data will only be such as is necessary for Kella Leadership to perform the contract to which the subscriber is party. All such transfers, when taking place within Kella Leadership Limited, are governed by EU- approved Binding Corporate Rules for International Data Transfer. Where such transfers are to a United States affiliate or subsidiary, we shall use reasonable endeavours to procure that such affiliate or subsidiary subscribes to the US “Safe Harbor” principles or such data transfers are governed by the provisions of an EU-approved Standard Form Model Contract for international transfers of data.",
      "HOW WE USE YOUR INFORMATION",
      "We will only use your personal data when the law allows us to. We never sell or share your personal information to third parties.  Most commonly, we will use your personal data in the following circumstances:",
      "•	Where we need to perform the contract we are about to enter into or have entered into with you.",
      "•	Where you have provided your consent for us to use your personal information to market to you, or to people who have previously bought, or have shown an interest in buying, similar products from us and this is in our legitimate interests.",
      "•	Where it is necessary for our legitimate interests, and your interests and fundamental rights do not override those interests.",
      "•	Where we need to comply with a legal or regulatory obligation.",
      "If you have requested a marketing asset, or have attended one of our events, we may use your personal information to follow up with you, or measure the effectiveness of the marketing or event.",
      "If you are a member, customer or a partner of ours, we may use your contact information to send you product or service updates and information that is relevant to your use of our products and services, and other products and services we provide.",
      "We may share information with vendors that act on our behalf (see below), such as services we use to maintain our contact records. These vendors act on our instructions and adhere to the policies described in this document.",
      "Use of Vendors:",
      "Required site features – vendors who may collect Personal Data about you on our behalf:",
      "•	Stripe: We use Stripe to process all of our payments and financial transactions. More information can be found on Stripes’ privacy policy. ",
      "•	Salesforce (including Pardot): We use Salesforce to collect and store consent, and to send consent-based email communications. We also use Salesforce to track web visits if you have registered on our website or you have filled out one of our forms. More information can be found on Salesforce’s Privacy Policy.",
      "•	Zoom: We use Zoom to provide our live webinar service. We use information collected by Webex to monitor event attendance and to improve the service we provide. More information can be found on Zoom’s Data Privacy.",
      "•	MailChimp: We use MailChimp for all electronic communication with our members.",  
      "•	Xero:  We use Xero to process invoices and day to day financial management.", 
      "•	EventBrite: We use EventBrite to communicate and host certain events. ",
      "•	Twillio: We use Twillio to engage with customers and members. ",
      "•	Hubspot: We use Hubspot for generating leads and creating customer experiences.",
      "Functional site features – vendors who may collect anonymous data about you:",
      "•	Google Analytics: We use Google Analytics to analyze the performance of our websites and follow up on the effectiveness of our marketing efforts. Google Analytics allow us to analyse data in aggregate, we do not collect or store any personal information in Google Analytics. More information can be found on Google’s Privacy Policy.",
      "•	Hotjar: We use Hotjar to improve the user experience on our websites. Hotjar collects anonymous usage information from our website, such as mouseclicks, page scrolling and text keyed into forms. Hotjar does not collect form submissions or any personal information. More information can be found on Hotjar’s Privacy Policy.",
      "We may transfer, store or process your personal information outside of the European Economic Area (“EEA”). The laws in some countries may not provide as much legal protection for your personal information as in the EEA.",
      "By submitting your personal information, you agree to this transfer, storing or processing. Where we use service providers outside the EEA, we rely on approved data transfer mechanisms (for example, the EU Standard Contractual Clauses and the EU-US Privacy Shield) to ensure that your personal information is adequately safeguarded in the recipient country.",
      "We keep your personal information for as long as is necessary for the purposes for which it is processed. These periods vary depending on the nature of the information and your interactions with us. ",
      "LINKS TO THIRD PARTY WEBSITES",
      "Our websites and apps may contain links to other websites to fulfil the provision of our service, including, but not limited to, Cisco Webex for live webinar content, and media players such as iTunes and Google Playstore to listen to our Podcast content. This privacy policy does not apply to those other websites and we do not accept liability for the content of any other website. Please check the privacy policy of any other website before submitting any personal information to it.",
      "YOUR RIGHTS",
      "You have the right to:",
      "•	Ask us not to process your personal information for marketing purposes. You can do this at any time by updating your email preferences by clicking the link provided in any marketing email we send you, or by clicking on the “unsubscribe” link. Members of the Kella Leadership platform can also opt out of marketing by updating their email preferences via their profile page. If you cannot fulfil your request using these tools, please email hello@KellaLeadership.com, via our Contact Page, or contact us using the address above.",
      "•	Request access to your personal information. This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.",
      "•	Request correction of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.",
      "•	Request erasure of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it, for example where you consider that we do not need it any longer for the purposes for which we originally collected it as explained to you in this Privacy Policy.",
      "•	We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.",
      "Payment Security",
      "Your Credit Card details are secure. We process all payments through a third party provider; Stripe Ireland or Stripe USA. No credit card details are stored on our servers. All payments are processed on a secure HTTPS payment page.",
      "CHANGES TO OUR PRIVACY POLICY",
      "We keep this privacy policy under regular review and will place any updates on this page and, where appropriate, notify you by e-mail. Please check back frequently to see any updates or changes to our Privacy Policy.",
      "It is important that the personal data we hold about you is accurate and up to date. Please keep us informed if your personal data changes during your relationship with us and periodically review your profile settings on our website.",
      "The privacy policy was last updated May 2024.",
      ]}
  ];
  // Customizable Area End