import React from "react";

// Customizable Area Start
import ReactHtmlParser from 'react-html-parser';
import DOMPurify from 'dompurify';
import {
  Box,
  IconButton,
  Typography,
  styled,
  Hidden,
  Container,
  Modal,
  Button,
  Drawer
} from "@material-ui/core";
import { ArrowBack} from "@material-ui/icons";
import { image_chevron_gray, termsandcondi } from "./assets";
const configJSON = require("./config");
import CloseIcon from "@material-ui/icons/Close";
import ContactUs from "./ContactUs.web";
import { Toaster } from 'react-hot-toast';
const dummyProps = {
  navigation: {},
  id: "FAQ"
};
// Customizable Area End

import HelpCentreController, { Props } from "./HelpCentreController.web";

class HelpCentre extends HelpCentreController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  //istanbul ignore next
  helpCentreForWeb() {
    return (
      <Setting>
        <Box className="outerBox">
          <Box className="arrow">
            <IconButton>
              <a href="/Settings" style={{color: "inherit", textDecoration: "none"}}>
                <ArrowBack />
              </a>
            </IconButton>
          </Box>
          <Box className="heading">Help Centre</Box>
          <Box className="mainBox">
            <Box className="items">
              <Typography className="account">Help Centre </Typography>
            </Box>
            <Box className="items">
              <Box className="icon-content">
                <Typography className="content">FAQs</Typography>
                <IconButton style={{ position: "absolute", right: 0 ,padding:"0"}}>
                  <a href="/FAQ">
                    <img src={image_chevron_gray} alt="image_chevron_gray" />
                  </a>
                </IconButton>
              </Box>
            </Box>
            <Toaster
              position="top-center"
              reverseOrder={false}
            />
            <Box className="items">
              <Box className="icon-content">
                <Typography className="content" 
                onClick={() => this.handleTermsOnClick(configJSON.termsAndConditionEndData)}>
                  Terms and Conditions
                </Typography>
                <img
                  onClick={() => this.handleTermsOnClick(configJSON.termsAndConditionEndData)}
                  src={image_chevron_gray}
                  alt="image_chevron_gray"
                  style={{ position: "absolute", right: 0 }}
                />
              </Box>
              {this.termsConditionDrawerWeb()}
            </Box>
            <Box className="items">
              <Typography className="account">Policies </Typography>
            </Box>
            {this.policyDrawerWeb()}
            <Box className="items">
              <Box className="icon-content">
                <Typography className="content" onClick={() => this.handlePrivacyOnClick(configJSON.privacyPolicy)}>{configJSON.privacyPolicy}</Typography>
                <img 
                  onClick={() => this.handlePrivacyOnClick(configJSON.privacyPolicy)}
                  src={image_chevron_gray}
                  alt="image_chevron_gray"
                  style={{ position: "absolute", right: 0 }}
                />
              </Box>
              
            </Box>
            <Box className="items">
              <Box className="icon-content">
                <Typography className="content" onClick={() => this.handlePrivacyOnClick(configJSON.securityPolicy)}>{configJSON.securityPolicy}</Typography>
                <img
                  onClick={() => this.handlePrivacyOnClick(configJSON.securityPolicy)}
                  src={image_chevron_gray}
                  alt="image_chevron_gray"
                  style={{right: 0, position: "absolute"}}
                />
              </Box>
            </Box>
            <Box className="items">
              <Box className="icon-content">
                <Typography className="content" onClick={() => this.handlePrivacyOnClick(configJSON.membershipAgreement)}>{configJSON.membershipAgreement}
                </Typography>
                <img
                  onClick={() => this.handlePrivacyOnClick(configJSON.membershipAgreement)}
                  src={image_chevron_gray}
                  alt="image_chevron_gray"
                  style={{ position: "absolute", right: 0 }}
                />
              </Box>
            </Box>{" "}
            <Box className="items">
              <Box className="icon-content">
                <Typography className="content" onClick={() => this.handlePrivacyOnClick(configJSON.communityStandards)}>{configJSON.communityStandards}</Typography>
                <img
                  onClick={() => this.handlePrivacyOnClick(configJSON.communityStandards)}
                  src={image_chevron_gray}
                  alt="image_chevron_gray"
                  style={{ position: "absolute", right: 0 }}
                />
              </Box>
            </Box>{" "}
            <Box className="items">
              <Box className="icon-content">
                <Typography className="content" onClick={() => this.handlePrivacyOnClick(configJSON.consentManagement)}>{configJSON.consentManagement}</Typography>
                <img
                  onClick={() => this.handlePrivacyOnClick(configJSON.consentManagement)}
                  src={image_chevron_gray}
                  alt="image_chevron_gray"
                  style={{ position: "absolute", right: 0 }}
                />
              </Box>
            </Box>{" "}
            <Box className="items">
              <Box className="icon-content">
                <Typography className="content" onClick={() => this.handlePrivacyOnClick(configJSON.complaintsHandlingPolicy)}>{configJSON.complaintsHandlingPolicy}
                </Typography>
                <img
                  onClick={() => this.handlePrivacyOnClick(configJSON.complaintsHandlingPolicy)}
                  src={image_chevron_gray}
                  alt="image_chevron_gray"
                  style={{ position: "absolute", right: 0 }}
                />
              </Box>
            </Box>{" "}
            <Box className="items">
              <Box className="icon-content">
                <Typography className="content" onClick={() => this.handlePrivacyOnClick(configJSON.nonSolicitationPolicy)}>{configJSON.nonSolicitationPolicy}
                </Typography>
                <img
                  onClick={() => this.handlePrivacyOnClick(configJSON.nonSolicitationPolicy)}
                  src={image_chevron_gray}
                  alt="image_chevron_gray"
                  style={{ position: "absolute", right: 0 }}
                />
              </Box>
            </Box>
            <Box>
              <ContactUs {...dummyProps} />
            </Box>
          </Box>
        </Box>
      </Setting>
    );
  }

  // For Mobile view
   //istanbul ignore next
  helpCentreForMobile() {
    return (
      <Box style={{ minHeight: "100vh" , overflow:"hidden"}}>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: "relative",
            width: "100%",
            borderBottom: "1px solid #CBD5E1",
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          <IconButton style={{ position: "absolute", left: 0 }}>
            <a
              href="/Settings"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <ArrowBack />
            </a>
          </IconButton>
          <Typography variant="h6" style={webStyle.mobileHeading}>
            Help Center
          </Typography>
          <div style={{ width: 48 }}></div>{" "}
        </Box>

        <Box
          style={{
            width: "100%",
            maxWidth: "600px",
            margin: "0 auto",
            backgroundColor: "#FFFFFF",
            borderRadius: "16px",
            padding: "14px",
          }}
        >
          <Box style={webStyle.mobileMainBox}>
            <Box style={{ ...webStyle.mobileItems, position: "relative" }}>
              <Typography style={webStyle.mobileAccount}>
                Help Centre
              </Typography>
            </Box>
            <Box style={{ ...webStyle.mobileItems, position: "relative" }}>
              <Box style={webStyle.mobileIconContent}>
                <Typography style={webStyle.mobileContent}>FAQs</Typography>
                <IconButton style={{ position: "absolute", right: 0, padding:"0" }}>
                  <a href="/FAQ">
                    <img src={image_chevron_gray} alt="image_chevron_gray" />
                  </a>
                </IconButton>
              </Box>
            </Box>
            {this.termsConditionDrawerMobile()}
            <Box style={{ ...webStyle.mobileItems, position: "relative" }}>
              <Box style={webStyle.mobileIconContent}>
                <Typography style={webStyle.mobileContent}
                  onClick={() => this.handleTermsOnClick(configJSON.termsAndConditionEndData)} >
                  Terms and Conditions
                </Typography>
                <IconButton style={{ position: "absolute", right: 0 }}>
                    <img
                      src={image_chevron_gray}
                      alt="image_chevron_gray"
                      style={{ position: "absolute", right: 0 }}
                      onClick={() => this.handleTermsOnClick(configJSON.termsAndConditionEndData)}
                    />
                </IconButton>
              </Box>
            </Box>
            <Box style={{ ...webStyle.mobileItems, position: "relative" }}>
              <Typography style={webStyle.mobileAccount}>Policies </Typography>
            </Box>
            <Box style={{ ...webStyle.mobileItems, position: "relative" }}>
            {this.policyDrawerMobile()}
              <Box style={webStyle.mobileIconContent}>
                <Typography style={webStyle.mobileContent} onClick={() => this.handlePrivacyOnClick(configJSON.privacyPolicy)}>{configJSON.privacyPolicy}
                </Typography>
                <IconButton style={{ position: "absolute", right: 0 }}>
                    <img
                      src={image_chevron_gray}
                      alt="image_chevron_gray"
                      style={{ position: "absolute", right: 0 }}
                      onClick={() => this.handlePrivacyOnClick(configJSON.privacyPolicy)}
                    />
                </IconButton>
              </Box>
            </Box>
            <Box style={{ ...webStyle.mobileItems, position: "relative" }}>
              <Box style={webStyle.mobileIconContent}>
                <Typography style={webStyle.mobileContent}
                onClick={() => this.handlePrivacyOnClick(configJSON.securityPolicy)}>
                  {configJSON.securityPolicy}
                </Typography>
                  <img
                    src={image_chevron_gray}
                    alt="image_chevron_gray"
                    onClick={() => this.handlePrivacyOnClick(configJSON.securityPolicy)}
                    style={{ position: "absolute", right: 0 }}
                  />
              </Box>
            </Box>
            <Box style={{ ...webStyle.mobileItems, position: "relative" }}>
              <Box style={webStyle.mobileIconContent}>
                <Typography style={webStyle.mobileContent}
                onClick={() => this.handlePrivacyOnClick(configJSON.membershipAgreement)}>{configJSON.membershipAgreement}
                </Typography>
                  <img
                    src={image_chevron_gray}
                    onClick={() => this.handlePrivacyOnClick(configJSON.membershipAgreement)}
                    alt="image_chevron_gray"
                    style={{ position: "absolute", right: 0 }}
                  />
              </Box>
            </Box>
            <Box style={{ ...webStyle.mobileItems, position: "relative" }}>
              <Box style={webStyle.mobileIconContent}>
                <Typography style={webStyle.mobileContent}
                onClick={() => this.handlePrivacyOnClick(configJSON.communityStandards)}>{configJSON.communityStandards}
                </Typography>
                  <img
                    src={image_chevron_gray}
                    alt="image_chevron_gray"
                    style={{ position: "absolute", right: 0 }}
                    onClick={() => this.handlePrivacyOnClick(configJSON.communityStandards)}
                  />
              </Box>
            </Box>
            <Box style={{ ...webStyle.mobileItems, position: "relative" }}>
              <Box style={webStyle.mobileIconContent}>
                <Typography style={webStyle.mobileContent}
                onClick={() => this.handlePrivacyOnClick(configJSON.consentManagement)}>{configJSON.consentManagement}
                </Typography>
                  <img
                    src={image_chevron_gray}
                    onClick={() => this.handlePrivacyOnClick(configJSON.consentManagement)}
                    alt="image_chevron_gray"
                    style={{ position: "absolute", right: 0 }}
                  />
              </Box>
            </Box>
            <Box style={{ ...webStyle.mobileItems, position: "relative" }}>
              <Box style={webStyle.mobileIconContent}>
                <Typography style={webStyle.mobileContent}
                onClick={() => this.handlePrivacyOnClick(configJSON.complaintsHandlingPolicy)}>{configJSON.complaintsHandlingPolicy}
                </Typography>
                  <img
                    src={image_chevron_gray}
                    alt="image_chevron_gray"
                    onClick={() => this.handlePrivacyOnClick(configJSON.complaintsHandlingPolicy)}
                    style={{ position: "absolute", right: 0 }}
                  />
              </Box>
            </Box>
            <Box style={{ ...webStyle.mobileItems, position: "relative" }}>
              <Box style={webStyle.mobileIconContent}>
                <Typography style={webStyle.mobileContent}
                onClick={() => this.handlePrivacyOnClick(configJSON.nonSolicitationPolicy)}>{configJSON.nonSolicitationPolicy}
                </Typography>
                  <img
                    src={image_chevron_gray}
                    alt="image_chevron_gray"
                    style={{ position: "absolute", right: 0 }}
                    onClick={() => this.handlePrivacyOnClick(configJSON.nonSolicitationPolicy)}
                  />
              </Box>
            </Box>
            <Box>
              <ContactUs {...dummyProps} />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  // We'll use this later
  termsandConditionModal() {
    return (
      <Modal
        open={this.state.termsConditionOpen}
        onClose={this.termsDrawerClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box sx={{ ...webStyle.modalStyle, borderRadius: "10px 10px 30px 10px" }}>
          <img src={termsandcondi} alt="Img" style={webStyle.modalImg} />
          <Box sx={{ ...webStyle.modalStyleTypo }}>
            Terms and Conditions have been updated
          </Box>
          <Box sx={{ ...webStyle.modalStyleTypoSecond }}>
          Please take a moment to review the changes in our Terms & Conditions in order to keep using Kella.
          </Box>
          <Box style={{width:"100%",marginTop:"30px", display:"flex",justifyContent:"center", alignItems:"center"}}>
          <Button
            fullWidth
            variant="contained"
            data-test-id="submitButton"
            style={{
              ...webStyle.contactDrawerSubmitBtn,
              position: "relative",
              textTransform: "capitalize",
              backgroundColor: "#14362E"
            }}
          >
            Review
          </Button>
          </Box>
        </Box>
      </Modal>
    );
  }

  termsConditionDrawerWeb() {
    return(
      <Drawer
        anchor="right"
        open={this.state.termsDrawerOpen}
        onClose={this.termsDrawerClose}
        PaperProps={{
          style: {
            borderTopLeftRadius: "20px",
            width: "40%",
          },
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            borderBottom: "2px solid #E2E8F0"
          }}
        >
          <Box style={{ display: "flex", padding: "10px", backgroundColor:""}}>
            <Typography
              style={{
                fontSize: "24px",
                fontWeight: "bolder",
                color: "#14362E",
                fontFamily: "MyFont",
                letterSpacing: "1.4",
              }}
            >
              {this.state.termsName}
            </Typography>
          </Box>
          <Box>
            <IconButton
              style={{ right: "10px" }}
              onClick={this.termsDrawerClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box
          style={{
            marginTop: "10px",
            fontFamily: "MyFont",
            letterSpacing: "1.4",
          }}
        >
          <Box style={{ marginTop: "10px", paddingLeft: "20px" }}>
            <Typography component='div' >
              {ReactHtmlParser(DOMPurify.sanitize(this.state.termsDescription))}
            </Typography>
          </Box>
        </Box>
        <Box style={{ padding: "20px"}}>
          <ContactUs {...dummyProps} />
        </Box>
      </Drawer>
    );
  }

  termsConditionDrawerMobile() {
    return(
      <Drawer
        anchor="right"
        open={this.state.termsDrawerOpen}
        onClose={this.termsDrawerClose}
        PaperProps={{
          style: {
            width: "100%",
          },
        }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            borderBottom: "2px solid #E2E8F0",
            position: "relative",              
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          <IconButton style={{ position: "absolute", left: 0 }}>
            <a
              href=""
              onClick={this.termsDrawerClose}
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <ArrowBack />
            </a>
          </IconButton>
          <Typography variant="h6" style={webStyle.mobileHeading}>
          {this.state.termsName}
          </Typography>
          <div style={{ width: 48 }}></div>{" "}
        </Box>
        <Box
          style={{
            marginTop: "10px",
            fontFamily: "MyFont",
            letterSpacing: "1.4",
          }}
        >
          <Box style={{ marginTop: "10px", paddingLeft: "10px" }}>
          <Typography component='div' >
              {ReactHtmlParser(DOMPurify.sanitize(this.state.termsDescription))}
            </Typography>
          </Box>
        </Box>
        <Box style={{paddingLeft: "10px",bottom:"15px"}}>
          <ContactUs {...dummyProps} />
        </Box>
      </Drawer>
    );
  }

  policyDrawerWeb() {
    return(
        <Drawer
          anchor="right"
          open={this.state.policyDrawerOpen}
          onClose={this.policyDrawerClose}
          PaperProps={{
            style: {
              borderTopLeftRadius: "20px",
              width: "40%",
            },
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              borderBottom: "2px solid #E2E8F0"
            }}
          >
            <Box style={{ display: "flex", padding: "10px" }}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "bolder",
                  color: "#14362E",
                  fontFamily: "MyFont",
                  letterSpacing: "1.4",
                }}
              >
                {this.state.policyTitle}
              </Typography>
            </Box>
            <Box>
              <IconButton
                style={{ right: "10px" }}
                onClick={this.policyDrawerClose}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Box
            style={{
              marginTop: "10px",
              fontFamily: "MyFont",
              letterSpacing: "1.4",
            }}
          >
            <Box style={{ marginTop: "10px", paddingLeft: "20px" }}>
              <Typography component='div' >
                {ReactHtmlParser(DOMPurify.sanitize(this.state.policyDescription))}
              </Typography>
            </Box>
          </Box>
          <Box style={{marginTop:"20px"}}>
            <ContactUs {...dummyProps} />
          </Box>
        </Drawer>
    );
  }

  policyDrawerMobile() {
    return(
        <Drawer
          anchor="right"
          open={this.state.policyDrawerOpen}
          onClose={this.policyDrawerClose}
          PaperProps={{
            style: {
              width: "100%",
            },
          }}
        >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                borderBottom: "2px solid #E2E8F0",
                position: "relative",              
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <IconButton style={{ position: "absolute", left: 0 }}>
                <a
                  href=""
                  onClick={this.policyDrawerClose}
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <ArrowBack />
                </a>
              </IconButton>
              <Typography variant="h6" style={webStyle.mobileHeading}>
                {this.state.policyTitle}
              </Typography>
              <div style={{ width: 48 }}></div>{" "}
            </Box>
          <Box
            style={{
              marginTop: "10px",
              fontFamily: "MyFont",
              letterSpacing: "1.4",
            }}
          >
            <Box style={{ marginTop: "10px", paddingLeft: "20px" }}>
            <Typography component='div' >
                {ReactHtmlParser(DOMPurify.sanitize(this.state.policyDescription))}
              </Typography>
            </Box>
          </Box>
          <Box style={{position:"relative", bottom:"0px"}}>
            <ContactUs {...dummyProps} />
          </Box>
        </Drawer>
    );
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Container maxWidth={false}>
        <Hidden smDown>{this.helpCentreForWeb()}</Hidden>
        <Hidden mdUp>{this.helpCentreForMobile()}</Hidden>
      </Container>
      // Customizable Area End
    );
  }
}
// Customizable Area Start

export default HelpCentre;

const Setting = styled(Box)({
  "& .outerBox": {
    width: "100%",
    height: "100%",
    backgroundColor: "#f2fff2",
  },
  "& .arrow": {
    height: "48px",
    padding: "0px 2%",
    backgroundColor: "#FFFFFF",
    display: "flex",
    alignItems: "center",
  },
  "& .heading": {
    height: "56px",
    fontSize: "24px",
    fontWeight: 700,
    fontFamily: "MyFont",
    backgroundColor: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    paddingLeft: "6%",
    color: "#14362E",
  },
  "& .mainBox": {
    width: "816px",
    height: "929px",
    backgroundColor: "#FFFFFF",
    margin: "2% auto",
    borderRadius: "16px",
    padding: "24px 40px",
  },
  "& .items": {
    height: "42px",
    borderBottom: "1px solid #E2E8F0",
    padding: "12px 0px",
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  "& .account": {
    fontSize: "18px",
    fontWeight: 800,
    fontFamily: "MyFont",
    color: "#334155",
    letterSpacing: "0.05em",
  },
  "& .icon-content": {
    gap: "10px",
    display: "flex",
    alignItems: "center",
  },
  "& .content": {
    color: "#0F172A",
    fontSize: "16px",
    fontWeight: 700,
    fontFamily: "MyFont",
  },
  "& .contact": {
    // height: '120px',
    padding: "28px 0px",
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
});

const webStyle = {
  mobileHeading: {
    height: "56px",
    fontSize: "24px",
    fontWeight: 700,
    fontFamily: "MyFont",
    backgroundColor: "#FFFFFF",
    color: "#14362E",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%", // Ensures the text can use the full width for centering
  },
  mobileMainBox: {
    backgroundColor: "#FFFFFF",
    margin: "1% auto",
    borderRadius: "16px",
  },
  mobileItems: {
    height: "42px",
    borderBottom: "1px solid #E2E8F0",
    display: "flex",
    alignItems: "center",
    marginRight: "40px",
    marginTop: "18px",
  },
  mobileAccount: {
    fontSize: "18px",
    fontWeight: 900,
    fontFamily: "MyFont",
    color: "#334155",
    marginBottom: "18px",
    letterSpacing: "0.05em",
  },
  mobileIconContent: {
    gap: "10px",
    display: "flex",
    alignItems: "center",
    marginBottom: "18px",
  },
  mobileContent: {
    color: "#0F172A",
    fontSize: "16px",
    fontWeight: 700,
    fontFamily: "MyFont",
    marginButtom: "10px",
  },
  mobileContact: {
    height: "120px",
    paddingTop: "28px",
    display: "flex",
    alignItems: "center",
  },
  mobileIconContentContact: {
    gap: "10px",
    display: "flex",
    alignItems: "center",
    marginBottom: "18px",
    // marginRight: "-200px"
  },
  contactDrawerSubmitBtn: {
    height: "45px",
    color: "white",
    fontWeight: 700,
    fontSize: "16px",
    borderRadius: "8px",
    fontFamily: "'MyFont', 'Halyard Display'",
    letterSpacing: "1px",
    width: "90%",
    justifyContent: "center",
    alignItem: "center",
    // marginTop:"30px",
    //   marginTop: "25%",
  },
  newPasswordSuccessTypo: {
    fontSize: "13px",
    fontWeight: 400,
    letterSpacing: "0.08em",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  modalStyle: {
    position: "absolute" as "absolute",
    top: "50%",
    left: { xs: "50%", md: "55%" },
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    width: { xs: "70%", md: "26%" },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: 24,
    p: 4,
  },
  modalImg: {
    width: "14%",
    height: "auto",
  },
  modalStyleTypo: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#14362E",
    marginTop: "5%",
    // width: { xs: "90%", md: "70%" },
    fontFamily: "'MyFont', 'Halyard Display'",
    textAlign: "center",
  },
  modalStyleTypoSecond: {
    fontSize: "14px",
    marginTop: "3%",
    color: "#0F172A",
    width: "100%",
    fontFamily: "'MyFont', 'Halyard Display'",
    textAlign: "center",
  },
};
// Customizable Area End
