import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  faqDrawerOpen: boolean;
  contactUsDrawerOpen: boolean;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  subject: string;
  questions_arr: [];
  question: string;
  answer: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class FAQController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getQuestionsApiCallID: any;
  getQuestionAnswerApiCallID: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      faqDrawerOpen: false,
      contactUsDrawerOpen: false,
      firstName: "",
      lastName: "",
      phoneNumber: "",
      subject: "",
      questions_arr: [],
      question: "",
      answer: "",
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getQuestionsApiCallID) {
        this.handleQuestionsArray(responseJson);
      }
      else if (apiRequestCallId === this.getQuestionAnswerApiCallID) {
          this.handleQuesAnswerData(responseJson);
        }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getQuestionsList();
  };

  getQuestionsList() {
    const header = {
      "Content-Type": configJSON.accountDetailsAPiContentType,
      token: localStorage.getItem("accessToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getQuestionsApiCallID = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     configJSON.questionsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getQuestionAnswers(ques_id="") {
    const header = {
      "Content-Type": configJSON.accountDetailsAPiContentType,
      token: localStorage.getItem("accessToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getQuestionAnswerApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.questionAnswerApiEndPoint}${ques_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleQuestionsArray = (responseJson:any) =>{
    if (responseJson.data) {
        this.setState({ questions_arr: responseJson.data });
    }
  };

  handleQuesAnswerData = (responseJson:any) => {
    this.setState({question:responseJson.data?.attributes?.question})
    this.setState({answer:responseJson.data?.attributes?.answer})
  };

  toggleFAQsDrawer = (open: any,ques_id:any) => () => {
    this.setState({ faqDrawerOpen: open });

    this.getQuestionAnswers(ques_id);
  };

  FAQsDrawerClose = () => {
    this.setState({ faqDrawerOpen: false , question: "", answer:""});
  };

  toggleContactDrawer = (open: any) => () => {
    this.setState({ contactUsDrawerOpen: open });
  };

  handlecheckboxfilled = () => {
      let check2 = true;
      if (check2) {
       this.getQuestionsList()
      }
    }

  // Customizable Area End
}
