Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json"
exports.endPoint = "bx_block_push_notifications/push_notifications";
exports.getDataMethod = "GET";
exports.markAsReadMethod = "PUT";
exports.deleteMethod = "DELETE";

exports.notifications = "Mark all as read";
exports.deleteText = "DELETE"
exports.okText = "OK"
exports.deleteMessage = "Notifications deleted!"
exports.getNotificationsDataEndpoint="bx_block_push_notifications/push_notifications"
exports.updateNotificationsDataEndpoint="bx_block_push_notifications/push_notifications/read_all"
// Customizable Area End