import { Box, Drawer, Typography, styled } from "@material-ui/core"
import React from "react";
import CloseIcon from "@material-ui/icons/Close";

interface TProps {
    title: string;
    handleClose(): void;
    open: boolean;
    children: React.ReactNode
}

const ResponsiveDrawer = ({title, handleClose, open, children}: TProps) => {
    return <CustomDrawer open={open} onClose={handleClose} anchor="right" style={{maxWidth:'500px', width:'100%'}} className="overrideDrawer">
        <Box style={{borderBottom: "1.04px solid #E2E8F0", padding: '0 28px 20px 28px'}}>
              <Box style={{padding: "12px 0px 0px 0px",display: "flex", justifyContent:'space-between', alignItems:'center'}}>
                  <Typography style={{ fontSize:"24px",fontWeight:700, color: '#14362E', }}>{title}</Typography>
                <Box>
                  <CloseIcon onClick={handleClose} fontSize="small"/>
                </Box>
              </Box>
        </Box>
        {children}
    </CustomDrawer>
}

const CustomDrawer = styled(Drawer)({
    '& .MuiDrawer-paper': {
        borderTopLeftRadius: '24px'
      },
    '@media (max-width: 600px)': {
      '& .MuiDrawer-paper': {
        bottom: '0',
        top: 'unset',
        height: '80vh',
        paddingBottom: '24px',
        width:'100%'
      },
  },
})

export default ResponsiveDrawer