import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
import { toast } from 'react-hot-toast';
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  privacyOption: string;
  token:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class PrivacySettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPrivacySettingApiCallID: any;
  setPrivacySettingApiCallID: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    
    // Customizable Area Start
    this.subScribedMessages = [ getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.SessionResponseMessage), 
        getName(MessageEnum.CountryCodeMessage), ];

    this.state = {
        privacyOption: 'public',
        token:""
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getPrivacySettingApiCallID) {
        this.defaultPrivacySetting(responseJson);
      } else if (apiRequestCallId === this.setPrivacySettingApiCallID) {
        this.setPrivacySetting(responseJson);
      } 
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() { 
    this.fetchPrivacySettings();
  };

  handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ privacyOption: event.target.value });
  };
  
  fetchPrivacySettings = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("accessToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPrivacySettingApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPrivacySettingsApiEndPoint}${getStorageData('account_id')}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updatePrivacySettings = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("accessToken")
    };

    const isPublic = this.state.privacyOption === "public";

    const httpBody = {
        "is_public": isPublic
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setPrivacySettingApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.setPrivacySettingsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  defaultPrivacySetting = (responseJson: any) => {
    const isPublicSetting = responseJson.data?.attributes?.personal_information?.is_public;
    if (isPublicSetting !== undefined) {
        this.setState({ privacyOption: isPublicSetting ? "public" : "private" });
        console.log(" Your Privacy Setting is:", isPublicSetting ? "public" : "private");
    } else {
        console.error("Failed to retrieve 'is_public' from API response.");
    }
  };

  setPrivacySetting = (responseJson: any) => {
    if (responseJson && responseJson.message === "Profile setting saved.") {
        toast.success('Privacy Settings updated!!')
    } else {
        toast.error("Failed to update privacy settings.")
    }
  };
  // Customizable Area End
}
