Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AddFriends";
exports.labelBodyText = "AddFriends Body";

exports.btnExampleTitle = "CLICK ME";

//add friend
exports.addConnectionEndPoint = "bx_block_addfriends/connections";

// API Methods
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPatchMethod = "PATCH";
exports.httpDeleteMethod = "DELETE";

// API EndPoint
exports.myCircleApiContentType = "application/json";
exports.getConnectionEndPoint = "bx_block_addfriends/connections";
exports.getRequestEndPoint = "bx_block_addfriends/connection_requests_received";
exports.getInvitationEndPoint = "bx_block_addfriends/my_connections";
exports.getBlockedUserEndPoint = "bx_block_block_users/block_users";
exports.acceptRequestEndPoint = "/bx_block_addfriends/connections/";
exports.declineRequestEndPoint = "/bx_block_addfriends/connections/";
exports.removeFromMyCircleEndPoint = "bx_block_addfriends/connections/";
exports.blockUserEndPoint = "/bx_block_block_users/blocked_user";
exports.unBlockUserEndPoint = "/bx_block_block_users/unblock_user";

// Block Reasons
exports.blockReasons = [
  {
    id: 1,
    title: "Unwanted Interactions",
    description: "Blocking helps prevent harassment, bullying, and unwanted attention from other users.",
    backgroundColor: "#F1E3E4",
  },
  {
    id: 2,
    title: "Content Shielding",
    description: "Blocking shields users from offensive or inappropriate content posted by others.",
    backgroundColor: "#E7E3F1",
  },
  {
    id: 3,
    title: "Privacy Control",
    description: "Users can block others to control who views their profile, posts, and personal information, addressing privacy concerns.",
    backgroundColor: "#D4E5DE",
  },
  {
    id: 4,
    title: "Maintaining a Positive Environment",
    description: "Blocking aids in creating a positive online experience by preventing distractions, trolls, and disruptive behavior.",
    backgroundColor: "#E5E3E3",
  },
  {
    id: 5,
    title: "Safety and Trust",
    description: "Blocking serves as a layer of protection against safety concerns, trust breaches, and unwanted solicitations, ensuring a safer and more trustworthy online presence.",
    backgroundColor: "#E3F1E5",
  },
];
// Customizable Area End