import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isPromoOpen: boolean;
  step: number;
  viewDetailDrawer1 : boolean;
  viewPlan:boolean;
  cancelModal: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EditPaymentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isPromoOpen: false,
      step: 1,
      viewDetailDrawer1: false,
      viewPlan:false,
      cancelModal: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    // Customizable Area End
  }
 
  
  // web events
 


  // Customizable Area Start

  handleViewDrawer1=()=>{
    this.setState({ viewPlan: true, viewDetailDrawer1: true,});
  }
 
  handleViewDrawerClose=()=>{
    this.setState({viewDetailDrawer1: false,});
  }
  toggleDrawer=(open: boolean)=>{
    this.setState({isPromoOpen:open})
  }

  handlePromo=()=>{
    this.toggleDrawer(true);
  }

  handlepromoClose=()=>{
    this.toggleDrawer(false);
  }

  handleNextStep=()=> {
    this.setState({
      step: 2
    })
  }
  handlestepBack=()=>{
    this.setState((prevState)=>({
      step : prevState.step - 1,
    }))
  }
  handlePlanSelect=()=>{
    this.handleNextStep();
  }
  handleGoBack() {
    window.history.back();
}
  handleChangeCancelModal = (value: boolean)=> {
    this.setState({cancelModal: value})
  }
  // Customizable Area End
}
