import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start

  Drawer,
  styled,
  Modal,
  Card,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { ArrowBack, } from "@material-ui/icons";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CloseIcon from "@material-ui/icons/Close";

type CardOptionT = {
  title: string;
  description?: string;
} 
// Customizable Area End

import EditPaymentController, {
  Props,
} from "./EditPaymentController.web";
import ResponsiveDrawer from "../../../components/src/ResponsiveDrawer.web";


export default class EditPayment extends EditPaymentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  cardOption({title, description}:CardOptionT){
    return (
      <Box style={{display:'flex', gap: '12px'}}>
        <CheckCircleIcon fontSize='small' style={{fill:"#73A58E", width: '16px', height:'16px'}}/>
        <Box>
          <Typography style={webStyle.cardItemTitle}>{title}</Typography>
          {description && <Typography style={webStyle.cardItemDescription}>{description}</Typography>}
        </Box>
      </Box>
    )
  }
  card(id: number, title?: string){
     return(
      <Box style={webStyle.box1LayoutStep1}>
        <Box style={{display:'flex', gap:'8px', flexDirection:'column'}}>
          {title && <Typography style={{fontWeight: 700, fontSize:'16px' }}>{title}</Typography>}
          {this.cardOption({title: 'KELLA Platform', description: 'connecting, engaging, supporting'})}
          {this.cardOption({title: 'KELLA Content', description: 'Updated content and disscusion in your areas of interset'})}
          {this.cardOption({title: 'KELLA Connect'})}
          {id === 2 && this.cardOption({title: 'Get One Month Free'})}
        </Box>
        <Box style={{display: 'flex', justifyContent: 'space-between', marginTop: '16px'}}>
            <Box style={{ width:"212px", height:"28px",display:"flex"}}>
              <CustomTypography style={{lineHeight:"28px", fontFamily:"Montserrat",fontWeight:700,fontSize:"20px",}}>&#x20AC; {id=== 1 ? '315': '3,465'}</CustomTypography>
              <Typography style={{lineHeight:"16px", fontWeight:400,fontSize:"11px",fontFamily:"MyFont",textAlign:"center",color:"#94A3B8",paddingTop:"5px"}}>+VAT </Typography>
              <Typography style={{lineHeight:"22px", fontWeight:500,fontSize:"14px",fontFamily:"MyFont",textAlign:"center",color:"#94A3B8",paddingTop:"1px"}}> / annual</Typography>
            </Box>
            <Typography style={{lineHeight:"22px",
              fontSize:"12px",
              fontWeight:700,
              textAlign:"center",
              color:"#14362E",
                cursor:"pointer",
              }}
              onClick={this.handleViewDrawer1}
              >View details</Typography>
        </Box>
    </Box>
     )
  }
  selectSubscription =()=>{
    return(
      <>
        <Box 
          style={{
            marginTop: "50px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            gap: "25px",
          }}>  
            {this.card(1)}
            {this.card(2)}        
            <Box sx={{maxWidth:'450px', margin: '0 auto', flexDirection:'column', marginTop: 'auto'}}>
              <Button variant="contained" fullWidth style={webStyle.changeSubscriptionBtn} onClick={this.handlePlanSelect}>Change Subscription</Button>
              <Button fullWidth style={webStyle.cancelSubscriptionBtn} onClick={this.handleChangeCancelModal.bind(null, true)}>Cancel Subscription</Button>
            </Box>
        </Box>



        {this.cancelModal()}
        {this.viewDetailDrawerweb()}
      </>
    )
  }
  changeSubscription = () => {
    return (
      <Box style={{
        maxWidth:'450px',
        width:'100%',
        padding:'0 5px',
        boxSizing:'border-box',
        margin: '0 auto',
        marginTop: "50px",
      }}>
        <Box 
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            gap: "25px",
          }}>  
            {this.card(2, 'KELLA Empover')}
            <Box onClick={this.handlePromo} style={{ width:'100%', alignItems:'center', marginTop: '38px', display:' flex', justifyContent:' space-between', borderTop:'1px solid #E2E8F0', borderBottom:'1px solid #E2E8F0'}}>
              <Box style={{display:'flex', gap: '10px', padding: '20px 0', }}>
                <CardGiftcardIcon/>
                <Typography style={{fontWeight:"bold", fontSize:"16px",color: '#0F172A'}}>
                  Promo code
                </Typography>
              </Box>
              <ArrowForwardIosIcon style={{fontSize:'15px !important'}}/>
            </Box>
            <Box style={{marginTop:'30px'}}>
              <Typography style={{fontWeight:'bold', fontSize:'16px', color:'#334155'}}>Coupon details</Typography>
            </Box>
            <Box style={{width:'100%'}}>
              <Box style={{marginBottom:'12px', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                <Typography style={ {fontSize:'16px',}}>Promo code</Typography>
                <Typography style={{fontWeight:'bold', fontSize:'14px', }}>12333</Typography>
              </Box>
              <Box style={{marginBottom:'12px', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                <Typography style={ {fontSize:'16px',}}>Amount off</Typography>
                <Typography style={{fontWeight:'bold', fontSize:'14px', }}>- €60</Typography>
              </Box>
              <Box style={{marginBottom:'12px', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                <Typography style={ {fontSize:'16px',}}>Total amount</Typography>
                <Typography style={{fontWeight:'bold', fontSize:'14px',color: '#73A58E' }}>€ 3,405</Typography>
              </Box>
            </Box>

            <Box sx={{flexDirection:'column', marginTop: 'auto', width:'100%', marginBottom:'10px'}}>
              <Button variant="contained" fullWidth style={webStyle.changeSubscriptionBtn} onClick={this.handlePlanSelect}>Change Subscription</Button>
            </Box>
        </Box>
        {this.subscriptionDrawerWeb()}
        {this.viewDetailDrawerweb()}
      </Box>
    )
  }
  viewDetailDrawerweb = () => {
    return (
      <ResponsiveDrawer open={this.state.viewDetailDrawer1} handleClose={this.handleViewDrawerClose} title='Plan details'>
            <DrawerContent style={{ padding: "0px 28px 0px 28px"}}>
              <Box className= "viewdrawerBoxStyle">
                <Typography className="viewDrawerTypoStyle" style={{margin:"8px",height:"24px"}}>KELLA Empower</Typography>
                <Typography
                style={{
                  fontWeight:400,
                  fontSize:"11px",
                  fontFamily:"MyFont",
                  lineHeight:"16px",
                  color:"#000000",
                  margin:"8px",
                }}
                >All memberships are subject to a minimum 12 month contract</Typography>
                <Box style={{height:"308px", width:"396px", margin:"8px"}}>
                  {this.cardOption({title:'KELLA Platform', description: 'connecting, engaging, supporting'})}
                  {this.cardOption({title:'KELLA Content', description: 'Updated content and disscusion in your areas of interset'})}
                  {this.cardOption({title:'KELLA Content', description: 'Find other members at events'})}
                  {this.cardOption({title:'KELLA Learn', description: 'Masterclasses & Speaker Series'})}
                  {this.cardOption({title:'KELLA Community', description: 'Connect with leaders all over the world'})}
                  {this.cardOption({title:'KELLA Peer-to-peer',})}
                  {this.cardOption({title:'My Circle', description: 'Your handpicked community'})}
                  {this.cardOption({title:'Get One Month Free'})}
                  <Box style={{ paddingTop:"2%",height:"68px", width:"396px",}}>
                  <Typography
                      style={{
                      fontWeight:400,
                      color:"#000000",
                      margin:"8px",
                      fontSize:"11px",
                      fontFamily:"MyFont",
                      lineHeight:"16px",
                    }}
                      >You can Pay
                  </Typography>
                    <Box style={{height:"28px", display:"flex", width:"212px",}}>
                      {
                        this.state.viewPlan ? (
                          <CustomTypography style={{lineHeight:"28px", fontWeight:700,fontSize:"20px",fontFamily:"Montserrat"}}>&#x20AC;315</CustomTypography>
                        ):
                        (<Typography style={{lineHeight:"28px", fontWeight:700,fontSize:"20px",fontFamily:"Montserrat"}}>&#x20AC;3,465</Typography>)
                      }
                      <Typography style={{lineHeight:"16px", fontWeight:400,fontSize:"11px",fontFamily:"MyFont",textAlign:"center",color:"#94A3B8",paddingTop:"5px"}}>+VAT </Typography>
                      <Typography style={{lineHeight:"22px", fontWeight:500,fontSize:"14px",fontFamily:"MyFont",textAlign:"center",color:"#94A3B8",paddingTop:"1px"}}> / annual</Typography>
                    </Box>
                    <Box style={{height:"16px", width:"416px", display:"flex"}}>
                      <Typography style={{textDecoration:"line-through" ,fontFamily:"MyFont",lineHeight:"16px", fontWeight:400,fontSize:"11px",}}>&#x20AC;3,780</Typography>
                      <Typography style={{lineHeight:"16px", fontWeight:400,fontSize:"11px",fontFamily:"MyFont",color:"#000000",marginLeft: "4px", }}>only pay for 11 months</Typography>
                    </Box>
                  </Box>
                </Box>
                  <Box style={{height:"136px", padding:"20px 0px 50px 0px"}}>
                    <Button style={{maxWidth:'350px'}} variant="contained" fullWidth className="viewDrawerButton" onClick={this.handlePlanSelect}>Change Subscription</Button>
                  </Box>
              </Box>
      </DrawerContent>
      </ResponsiveDrawer>
    )
  }
  cancelModal = () => {
    return (<Modal open={this.state.cancelModal}>
      <Card style={{maxWidth:'500px', width:'80%', padding: '40px 24px 20px 24px', position: 'absolute', top:'50%', bottom:'50%', left:'50%', right:'50%', transform:'translate(-50%,-50%)', minHeight:'190px'}}>
          <Typography style={{color:"#14362E", fontSize:'24px', fontWeight: 700, textAlign:'center', marginBottom:' 16px'}}>Are you sure you want to cancel your subscription?</Typography>
          <Typography style={{color:"#0F172A", fontSize:'16px', fontWeight: 500, textAlign:'center', marginBottom:' 16px'}}>Once your subscription is cancelled, you can no longer use the KELLA app</Typography>

          <Box style={{display:'flex', gap:'12px'}}>
              <Button fullWidth variant="outlined" style={{borderColor: '#73A58E', color: '#14362E', fontWeight: 700, textTransform: 'none', fontSize:"16px"}} onClick={this.handleChangeCancelModal.bind(this, false)}>Go Back</Button>
              <Button fullWidth variant="contained"style={{ fontSize:'16px',background: '#14362E', color: 'white', fontWeight: 700, textTransform: 'none'}}  onClick={this.handleChangeCancelModal.bind(this, false)}>Cancel</Button>

          </Box>
      </Card>
    </Modal>)
  }
  subscriptionDrawerWeb=()=>{
    return(
      <ResponsiveDrawer  handleClose={this.handlepromoClose} title='Promo Code' open={this.state.isPromoOpen}>
            <DrawerContent>
              <Box style={{  padding: "0px 28px 0px 28px"}}>
                <Box style={{height: "48px",  
                borderLeft: "4px solid #73A58E",
                borderRadius: "4px",
                backgroundColor: "#D4E5DE",
                padding: "12px 8px 12px 16px",
                display:"flex"}}
                >
                  <Typography  className="boxTextStyle">Promo code added</Typography>
                  <Box style={{height: "24px", width:"24px", padding:"12px" }}>
                    <CloseIcon style={{height: "16px", width:"16px"}}/>
                  </Box>
                </Box>
                <Box style={{height: "86px", gap:"12px !important", marginTop: "12px"}}>
                  <Typography className="Typostyle" style={{  width:"109px",height: "26px",
                  fontFamily:"Montserrat",
                  }}>Promo code</Typography>
                  <Box style={{height: "48px",
                  display:"flex",
                  border: "1px solid #CBD5E1",
                  borderRadius: "8.55px",
                  justifyContent:'space-between'
                }}
                  >
                    <Typography className="Typostyle" style={{ height: "9.52px", paddingTop:"12px", paddingLeft:"8.55px",}}>
                    EMPOWER12</Typography>
                  <Box style={{height: "25.65px", width:"25.65x",paddingTop:"11.17px",}}>
                    <CardGiftcardIcon  style={{ height: "20.31px",left:"2.41px",color:"#64748B", width:"21.38x",top:"2.67px",}}/>
                  </Box>
                  </Box>
                </Box>
                <hr style={{ border: "1px solid #CBD5E1"}}/>
                <Box style={{height: "595px",}}> 
                  <Box style={{height: "48px", border:"0p 0px 1px 0px", padding:"12px, 0px, 12px, 0px"}}>
                    <Typography style={{height: "24px"}} className="Typostyle"
                    >Coupon details</Typography>
                  </Box>
                  <Box style={{height: "118px",
                    padding:"20px, 16px, 20px, 16px",
                    background: "#F8FAFC",
                  }}
                  >
                    <Box style={{justifyContent:"space-between", height: "18px",  display:"flex", margin:"15px"}}>
                      <Box style={{height:"18px",}}>
                      <Typography className="boxTypoStyle1">Promo code</Typography>
                      </Box>
                      <Box style={{height:"18px", }}>
                      <Typography className="boxTypoStyle2">EMPOWER12</Typography>
                      </Box>
                      
                    </Box>
                    <Box style={{display:"flex",margin:"15px", height: "18px", justifyContent:"space-between", }}>
                      <Typography className="boxTypoStyle1">Amount off</Typography>
                      <Typography className="boxTypoStyle2" style={{color:"#73A58E"}}>-&#x20AC;60</Typography>
                    </Box>
                    <Box style={{height: "18px", display:"flex", justifyContent:"space-between", margin:"15px"}}>
                      <Typography className="boxTypoStyle1">Total Amount</Typography>
                      <Typography className="boxTypoStyle2">&#x20AC;3,450</Typography>
                    </Box>
                  </Box>
                </Box>
                <Button className="buttonStyle" variant="contained" >Add Promo Code</Button>
              </Box>
            </DrawerContent>
          </ResponsiveDrawer>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <Box>
            <StyledHeaderWrapper>
                <ArrowBack style={{marginLeft: '12px'}} onClick={this.handleGoBack}/>
                <Typography className="pageTitle">Subscription Billing</Typography>
            </StyledHeaderWrapper>
            {this.state.step === 1 && this.selectSubscription()}
            {this.state.step === 2 && this.changeSubscription()}
        </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledHeaderWrapper = styled(Box)({
    marginTop:'5px',
    '& .pageTitle': {
        color:'#14362E',
        fontSize:'24px',
        fontWeight:'bold',
        paddingLeft:'80px',
        marginTop:'24px'
    },
    '@media (max-width: 900px)': {
        display:'flex',
        alignItems: 'center',
        '& .pageTitle': {
            marginTop: '0px'
        }
    },
  

})
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  textStyle: {
    color: "#334155",
    fontSize:"16px",
    fontFamily: "MyFont !important",
    fontWeight:500,
  },
  CheckoutBox:{
    backgroundColor:"#F8FAFC !important",
    borderRadius:"16px",
  },
  box1LayoutStep1:{
    // height: "250px",
    maxWidth: "450px",
    width:'100%',
    boxShadow: "0px 8px 32px 0px #0000000F",
    borderRadius: "8px 8px 32px 8px",
    padding:"16px",
    boxSizing: 'border-box' as const,
  },
  box1LayoutStep2:{
    height: "215px",
    width: "334px",
    boxShadow: "0px 8px 32px 0px #0000000F",
    borderRadius: "8px 8px 32px 8px",
    padding:"16px",
    gap:"15px",
  },
  boxLayout:{
    height: "222px",
    width: "448px",
    border: "1px solid #14362E",
    borderRadius: "8px 8px 32px 8px",
    padding:"16px",
    gap:"16px",
  },
  boxLayout2:{
    height: "250px",
    width: "344px",
    border: "1px solid #14362E",
    borderRadius: "8px 8px 32px 8px",
    padding:"10px",
    gap:"16px",
  },
  boxTextStyle:{
    fontSize:"16px",
    fontFamily: "MyFont !important",
    fontWeight:700,
    letterSpacing:"8%",
    lineHeight:"24px",
  },
  boxTextFormat:{
    fontSize:"12px",
    fontFamily: "MyFont ",
    fontWeight:700,
    letterSpacing:"8%",
    lineHeight:"18px",
  },
  promoBox:{
    height: "64px",
    width: "448px",
    borderTop: "1px solid #E2E8F0",
    borderBottom: "1px solid #E2E8F0",
    padding:"20px 0px 20px 0px",
    gap:"10px !important",
  },
  promoBox2:{
    height: "24px",
    width: "350px",
    borderTop: "1px solid #E2E8F0",
    borderBottom: "1px solid #E2E8F0",
    padding:"20px 0px 20px 0px",
    gap:"10px !important",
  },
  backbuttonStyle:{
    height: "56px",
    width: "234px",
    border: "1px solid #73A58E",
    padding: "10px",
    boderRadius: "8px",
    fontSize:"16px",
    fontFamily: "MyFont !important",
    fontWeight:500,
  },
  backbuttonStyle2:{
    height: "56px",
    width: "169px",
    border: "1px solid #73A58E",
    padding: "10px",
    boderRadius: "8px",
    fontSize:"16px",
    fontFamily: "MyFont ",
    fontWeight:500,
  },
  step1ButtonStyle:{
    height: "56px",
    marginTop:'90px',
    padding:"16px",
    width: "350px",
    boderRadius:"8px",
    backgroundColor: "#14362E",
    color:"#FFFFFF",
    textTrnasform: 'unset'
  },
  fontStyle:{
    fontFamily: "MyFont ",
  },
  ThankyouBoxStyle:{
    fontSize:"30px",
    fontFamily: "MyFont",
    fontWeight:700,
    lineHeight:"40px",
    textAlign:"center",
    color:"#14362E",
  },
  cardItemTitle: {
    fontSize:"12px",
    fontFamily: "MyFont",
    fontWeight:700,
    letterSpacing:"8%",
    lineHeight:"18px",
  },
  cardItemDescription: {
    fontSize:"12px",
    fontFamily: "MyFont",
    fontWeight:500,
    letterSpacing:"8%",
    lineHeight:"18px",
    color: '#64748B'
  },
  changeSubscriptionBtn: {
    backgroundColor: "#14362E",
    color:"#FFFFFF",
    textTransform: 'unset' as const,
    fontSize:'16px'
  },
  cancelSubscriptionBtn: {
    color: "#14362E",
    fontSize:'16px',
    textTransform: 'unset' as const
  }
};

const CustomTypography = styled(Typography)({
  '& .MuiTypography-body1': {
    fontFamily: 'MyFont',
  },

});

const DrawerContent = styled(Box)({

  '& .TextStyle': {
    fontSize:"24px",
    fontFamily: "MyFont !important",
    fontWeight:500,
    lineHeight:"32px",
  },
  '& .TextStyle2': {
    fontSize:"20px",
    fontFamily: "MyFont !important",
    fontWeight:500,
    lineHeight:"28px",
  },
  '& .boxTextStyle': {
    height: "18px",
    width: "372px",
    paddingTop: "3%",
    fontSize:"12px",
    fontFamily: "MyFont !important",
    fontWeight:400,
    lineHeight:"18px",
    color: "#73A58E"
  },
  '& .Typostyle':{
    fontFamily:"MyFont !imporatnt",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#334155",
  },
  '& .boxTypoStyle1':{
   
    fontFamily:"MyFont !important",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "8px",
  },
  '& .boxTypoStyle2':{
    fontFamily:"MyFont !important",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "8px",
  },
  '& .buttonStyle':{
    height: "56px",
    margin:'15px',
    width: "421px",
    boderRadius:"8px",
    backgroundColor: "#14362E",
    color:"#FFFFFF"
  },
  '& .buttonStyle2':{
    height: "56px",
    margin:'15px',
    width: "350px",
    boderRadius:"8px",
    backgroundColor: "#14362E",
    color:"#FFFFFF",
    top:"79%",
  },
  '& .mainViewDetailDrawerStyle':{
    height: "1024px",
    width: "484px",
    left:"956px",
    borderRadiusadius:"24px, 0px, 0px, 0px",
    Padding:"0px, 0px, 40px, 0px",
    Gap:"24px",
    boxShadow: "0px 8.31px 33.24px 0px #0000000F",
  },
  
  '& .viewdrawerStyle':{
    height: "73px",
    width: "484px",
    padding: "12px 28px 12px 28px !important"

  },
  '& .viewdrawerBoxStyle':{
    height: "468px",
    marginTop:"25px",
    gap: "6px !important",
    border : "1px solid #73A58E",
    borderRadius: '8px 8px 32px 8px'
  },
  '& .viewdrawerBoxStyle2':{
    height: "480px",
    marginTop:"25px",
    gap: "6px !important",
    border : "1px solid #73A58E",
    borderRadius: '8px 8px 32px 8px'
  },
  '& .viewDrawerTypoStyle':{
    fontFamily:"MyFont !important",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  '& .viewDrawerButton':{
    margin:'15px',
    backgroundColor: "#14362E",
    color:"#FFFFFF",
    top:"79%",
    textTransform: 'unset'
  },
  '& .viewDrawerButton2':{
    height: "56px",
    margin:'15px',
    boderRadius:"8px",
    backgroundColor: "#14362E",
    color:"#FFFFFF",
    top:"79%",
    right:"20px"
  },
})
// Customizable Area End
