import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  styled,
  Select,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Chip,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
// Customizable Area End

import BillingController, { Props } from "./BillingController.web";

class Billing extends BillingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
        <Box>
            <StyledHeaderWrapper>
                <ArrowBack style={{marginLeft: '12px'}} onClick={this.handleGoBack}/>
                <Typography className="pageTitle">Subscription Billing</Typography>
            </StyledHeaderWrapper>
            <StyledTableWrapper>
                <Box className='tableTitle'>
                    <Typography style={webStyle.TableTitleText}>KELLA Empower - Monthly Plan</Typography>
                    <Select/>
                </Box>
                <StyledTable>
                    <TableHead>
                        <TableRow>
                            <TableCell>Payment Date</TableCell>
                            <TableCell>Promo Code</TableCell>
                            <TableCell>Total Const</TableCell>
                            <TableCell >Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>10/10/24</TableCell>
                            <TableCell>50$</TableCell>
                            <TableCell>100$+VAT</TableCell>
                            <TableCell className='status'> <Chip size="small" style={{color: '#E1B667', background: '#FEF3C7', fontWeight: 'bold'}} label='PENDING'/> </TableCell>
                        </TableRow>
                    </TableBody>
                </StyledTable>
            </StyledTableWrapper>

        </Box>      
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export default Billing;

const StyledTable = styled(Table)({
    marginTop:'10px',
    '& .MuiTableCell-head': {
        borderBottomColor: '#E2E8F0',
        paddingBottom: '0px',
        color: '#A8CEBE',
        fontWeight: 'bold',
        fontSize: '12px'
    },
    '@media (max-width: 900px)': {
        '& .MuiTableCell-body, & .MuiTableCell-head,': {
            padding: '10px 0'
        },
    },
})
const StyledHeaderWrapper = styled(Box)({
    marginTop:'5px',
    '& .pageTitle': {
        color:'#14362E',
        fontSize:'24px',
        fontWeight:'bold',
        paddingLeft:'80px',
        marginTop:'24px'
    },
    '@media (max-width: 900px)': {
        display:'flex',
        alignItems: 'center',
        '& .pageTitle': {
            marginTop: '0px'
        }
    },
  

})
const StyledTableWrapper = styled(Box)({
    maxWidth:'750px',
    margin:' 0 auto',
    padding: '5px',
    '& .tableTitle': {
        display:'flex',
        justifyContent: 'space-between',
        marginTop:'100px',
        padding: '0 20px'
    },
    '@media (max-width: 900px)': {
        '& .tableTitle': {
            justifyContent: 'flex-start',
            flexDirection: 'column',
            marginTop:'20px'
        }, 
    },
});
const webStyle = {
    TableTitleText: {
        fontSize:"14px",
        color:'#14362E',
        fontWeight:700
    },

};

// Customizable Area End
