import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
  } from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
import { countries } from "countries-list";
const dummy1 = [
  { label: "CEO/President", value: "CEO/President" },
  { label: "C-Suite Level", value: "C-Suite Level" },
  { label: "Director", value: "Director" },
  { label: "Manager", value: "Manager" },
  { label: "Founder", value: "Founder" },
];
const dummy2 = [
  { label: "0", value: "0" },
  { label: "1 - 10", value: "1 - 10" },
  { label: "11 - 15", value: "11 - 15" },
  { label: "16 - 49", value: "16 - 49" },
  { label: "50 - 99", value: "50 - 99" },
  { label: "100+", value: "100+" },
];
const dummy3 = [
  {
    label: "Large Enterprise Leaders (5,000+ employees)",
    value: "Large Enterprise Leaders (5,000+ employees)",
  },
  {
    label: "Medium Enterprise Leaders (1,000-4,999 employees)",
    value: "Medium Enterprise Leaders (1,000-4,999 employees)",
  },
  {
    label: "Small Enterprise Leaders (Up to 999 employees)",
    value: "Small Enterprise Leaders (Up to 999 employees)",
  },
  { label: "Entrepreneurial Leaders", value: "Entrepreneurial Leaders" },
];
const dummy4 = [
  { label: "Kella event", value: "Kella event" },
  { label: "Kella member", value: "Kella member" },
  { label: "Colleague/friend", value: "Colleague/friend" },
  { label: "Linkedin", value: "Linkedin" },
  { label: "Social", value: "Social" },
  { label: "Press coverage", value: "Press coverage" },
  { label: "Other", value: "Other" },
];
import { createRef } from "react";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  bio: string;
  phoneNumber: string;
  country: string;
  email: string;
  password: string;
  enablePassword: boolean;
  errorPasswordFlag: boolean;
  isValidPasswordField: boolean;
  error: string;
  isOneCapital: boolean;
  isOneLower: boolean;
  isOneNumber: boolean;
  isValidLength: boolean;
  passwordError: any;
  isValidEmail: boolean;
  profilePicture: any;
  profileImage: any;
  openImageModalWeb: boolean;
  openImageModalMobile: boolean;
  currentView: string;
  countryWebDrawer: boolean;
  options: any;
  searchQuery: any;
  token: any;
  errorMessageImage:string;
  successPopupOpen:boolean;
  errorFirstNameFlag:boolean;
  errorLastNameFlag:boolean;
  errorCompanyNameFlag:boolean;
  errors: {
    firstName: boolean;
    lastName: boolean;
    companyName:boolean;
  }

  // Account Detail states
  companyFieldValid: boolean;
  companyTextFieldValue: string;
  roleFieldValid: boolean;
  roleTextFieldValue: string;
  roleWebDrawerOpen: boolean;
  role_list: [];
  selectedRole: any;
  selectedIndustry: any;
  searchRoleQuery: any;
  searchIndustryQuery: any;
  industry_list: [];
  industryFieldValid: boolean;
  industryTextFieldValue: string;
  industryWebDrawerOpen: boolean;
  linkedInProfile: any;
  linkedInProfileError: boolean;

  //Professional Detail states
  professionalLevel: string;
  drawerHeading: string;
  webDrawerOpen: boolean;
  mobileDrawerOpen: boolean;
  dummyRadioOptions: { label: string; value: string }[];
  selectedNumberValue: number;
  dummyState: number;
  employeesReporting: string;
  organizationSize: string;
  supportedByCompany: string;
  selectedOptionProfessional: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class EditUserProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getEditProfileApiCallID: any;
  getRolesApiCallID: any;
  getIndustryApiCallID: any;
  removeProfileImageApiCallID: any;
  createEditProfileApiCallID: any;
  fileInputRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess),
        getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIResponceSuccessMessage),];

    this.state = {
      firstName: "",
      lastName: "",
      bio: "",
      phoneNumber: "",
      country: "",
      email: "",
      password: "",
      enablePassword: false,
      errorPasswordFlag: false,
      isValidPasswordField: false,
      error: "",
      isOneCapital: false,
      isOneLower: false,
      isOneNumber: false,
      isValidLength: false,
      passwordError: "",
      isValidEmail: false,
      profilePicture: "",
      profileImage: "",
      openImageModalWeb: false,
      openImageModalMobile: false,
      currentView: "personal",
      countryWebDrawer: false,
      options: countries,
      searchQuery: "",
      token: "",
      errorMessageImage:"",
      successPopupOpen:false,
      errorFirstNameFlag:false,
      errorLastNameFlag:false,
      errorCompanyNameFlag:false,
      errors: {
        firstName: false,
        lastName: false,
        companyName:false,
      },

      //Account Detail States
      companyFieldValid: true,
      companyTextFieldValue: "",
      roleFieldValid: true,
      roleTextFieldValue: "",
      roleWebDrawerOpen: false,
      role_list: [],
      selectedRole: "",
      selectedIndustry: "",
      searchRoleQuery: "",
      searchIndustryQuery: "",
      industry_list: [],
      industryFieldValid: true,
      industryTextFieldValue: "",
      industryWebDrawerOpen: false,
      linkedInProfile: "",
      linkedInProfileError: false,

      //Professional Detail states
      professionalLevel: "",
      drawerHeading: "",
      webDrawerOpen: false,
      mobileDrawerOpen: false,
      dummyRadioOptions: [],
      selectedNumberValue: 0,
      dummyState: 0,
      employeesReporting: "",
      organizationSize: "",
      supportedByCompany: "",
      selectedOptionProfessional: ""
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.fileInputRef = createRef();
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getEditProfileApiCallID) {
        this.handleEditProfileData(responseJson);
      } else if (apiRequestCallId === this.getRolesApiCallID) {
        this.handleRoleData(responseJson);
      } else if (apiRequestCallId === this.getIndustryApiCallID) {
        this.hahandleIndustryData(responseJson);
      } else if (apiRequestCallId === this.removeProfileImageApiCallID) {
        this.handleremoveProfile(responseJson);
      } else if (apiRequestCallId === this.createEditProfileApiCallID) {
        this.handleCreateEditProfileData(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() { 
    const token =  localStorage.getItem("accessToken");
    this.setState({ token }, () => {
      this.getEditProfileData();
      this.getRoleList();
      this.getIndustryList();
    });
  };
  
  getEditProfileData = () => {
    const header = {
      "Content-Type": configJSON.accountDetailsAPiContentType,
      token: this.state.token,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEditProfileApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editProfileApiEndPoint}${getStorageData('account_id')}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getRoleList() {
    const header = {
      "Content-Type": configJSON.accountDetailsAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRolesApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountDetailsRoleApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getIndustryList() {
    const header = {
      "Content-Type": configJSON.accountDetailsAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getIndustryApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountDetailsIndustryApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  removeProfileImage = () => {
    this.setState({ profilePicture: null })
    const header = {
      "Content-Type": configJSON.accountDetailsAPiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removeProfileImageApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.removeProfileImageApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  validateFormData = () => {
    const { firstName, lastName, companyTextFieldValue } = this.state;
    const errors = {
      firstName: !firstName,
      lastName: !lastName,
      companyName: !companyTextFieldValue,
    };
  
    this.setState({ errors });
  
    return !Object.values(errors).some(error => error);
  };

  handleSaveButton = () => {
    if (!this.validateFormData()) {
      console.error("Form data is invalid");
      return;
    }
    try{
    const header = {
      token: localStorage.getItem("accessToken")
    };
    const formData = this.createFormData();

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createEditProfileApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createEditProfileApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPatchMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    }catch (error) {
      console.error("Error submitting edit Profile form:", error);
    }
  };

  createFormData = () => {
    const formData = new FormData();
    if (this.state.profileImage) {
      formData.append("account[profile_picture]", this.state.profileImage);
    }
    formData.append("account[first_name]", this.state.firstName);
    formData.append("account[last_name]", this.state.lastName);
    formData.append("account[bio]", this.state.bio);
    formData.append("account[full_phone_number]", this.state.phoneNumber);
    formData.append("account[country]", this.state.country);
    formData.append("account[email]", this.state.email);
    formData.append("account[password]", this.state.password);
    formData.append("account[company]", this.state.companyTextFieldValue);
    if (this.state.selectedRole) {
      formData.append("account[role_id]", this.state.selectedRole.id);
    }
    if (this.state.selectedIndustry) {
      formData.append("account[industry_id]", this.state.selectedIndustry.id);
    }
    formData.append("account[linked_in_profile]", this.state.linkedInProfile);
    formData.append("account[professional_level]", this.state.professionalLevel);
    formData.append("account[employees_reporting]", this.state.employeesReporting);
    formData.append("account[organization_size]", this.state.organizationSize);
    formData.append("account[supported_by_company]", this.state.supportedByCompany);

    return formData;
  };

  setProfileState = (personalInfo: any, accountInfo: any, professionalInfo: any): void => {
    const { profile_picture, first_name, last_name, bio, full_phone_number, country, email } = personalInfo;
    const { company, role, industry, linked_in_profile } = accountInfo;
    const { professional_level, employees_reporting, organization_size, supported_by_company } = professionalInfo;

    this.setState({
      profilePicture: profile_picture?.url || "", firstName: first_name || "",
      lastName: last_name || "", bio: bio || "", phoneNumber: full_phone_number || "",
      country: country || "", email: email || "",
      companyTextFieldValue: company || "", roleTextFieldValue: role?.name || "", 
      industryTextFieldValue: industry?.name || "", linkedInProfile: linked_in_profile || "", 
      professionalLevel: professional_level || "", employeesReporting: employees_reporting || "",
      organizationSize: organization_size || "", supportedByCompany: supported_by_company || "",
    });
  };

  handleEditProfileData = (responseJson: any): void => {
    if (responseJson && responseJson.data) {
      const { attributes } = responseJson.data;
      const { personal_information, account_details, professional_details } = attributes;
  
      this.setProfileState(personal_information, account_details, professional_details);
    } else {
      console.error('Data is undefined', responseJson);
    }
   };
  handleRoleData = (responseJson: any) => {
    if (responseJson.roles) {
      this.setState({ role_list: responseJson.roles });
    }
  };

  hahandleIndustryData = (responseJson: any) => {
    if (responseJson.industries) {
      this.setState({ industry_list: responseJson.industries });
    }
  };

  handleremoveProfile = (responseJson: any) => {
    if (responseJson && responseJson.success) {
      console.log("Profile Image deleted successfully!"); 
      this.setState({ profilePicture: null });
    }
    this.handleCloseImageModalWeb();
    this.handleCloseImageModalMobile();

  };

  handleCreateEditProfileData = (responseJson: any) => {
      if (responseJson) {
        localStorage.setItem("userdata", JSON.stringify(responseJson.data));
        this.setState({ successPopupOpen: true }); 
      }     
  };

  handleBack = () => {
    // Handle the back action
  };

  handleConfirm = () => {
    // Handle the confirm action
  };

  handleUploadClick = () => {
    // Handle the upload action
  };
  setViewToPersonal = () => {
    this.setState({ currentView: "personal" });
  };

  setViewToAccount = () => {
    this.setState({ currentView: "account" });
  };

  setViewToProfessional = () => {
    this.setState({ currentView: "professional" });
  };

  getButtonStyle = (buttonName: any) => {
    const { currentView } = this.state;
    const baseStyle = {
      fontWeight: "bold" as "bold",
      fontFamily: "MyFont",
      letterSpacing: "1px",
      fontSize: "16px",
      textTransform: "capitalize" as "capitalize",
    };

    if (buttonName === currentView) {
      return {
        ...baseStyle,
        border: "1px solid #73A58E",
        borderRadius: "50px",
        color: "#14362E",
      };
    } else {
      return {
        ...baseStyle,
        color: "#64748B",
      };
    }
  };

  setView = (view: any) => {
    this.setState({ currentView: view });
  };

  toggleWebRoleDrawer = (open: any) => () => {
    this.setState({ roleWebDrawerOpen: open });
  };

  toggleWebIndustryDrawer = (open: any) => () => {
    this.setState({ industryWebDrawerOpen: open });
  };

  handleChangeLinkedInProfile = (event: any) => {
    const inputValue = event.target.value;
    this.setState({
      linkedInProfile: inputValue,
      linkedInProfileError: !this.isValidURL(inputValue),
    });
  };

  isValidURL = (url: any) => {
    const urlRegex =  /^(https?:\/\/)?([\w\-]+\.)+[a-zA-Z]{2,}$/;
    return urlRegex.test(url);
  };

  toggleDrawer = (anchor: string, open: any, heading: string, dummyState: number) => () => {
    if (dummyState === 1) {
      this.setState({ drawerHeading: configJSON.profDetailsHeading1, dummyRadioOptions: dummy1, selectedNumberValue: 1 })
    }
    else if (dummyState === 2) {
      this.setState({ drawerHeading: configJSON.profDetailsHeading2, dummyRadioOptions: dummy2, selectedNumberValue: 2 })
    }
    else if (dummyState === 3) {
      this.setState({ drawerHeading: configJSON.profDetailsHeading3, dummyRadioOptions: dummy3, selectedNumberValue: 3 })
    }
    else if (dummyState === 4) {
      this.setState({ drawerHeading: configJSON.profDetailsHeading4, dummyRadioOptions: dummy4, selectedNumberValue: 4 })
    }
    if (anchor === 'web') {
      this.setState({ webDrawerOpen: open, drawerHeading: heading, dummyState });
    } else if (anchor === 'mobile') {
      this.setState({ mobileDrawerOpen: open, drawerHeading: heading, dummyState });
    }
  };

  handleChange = (event: any) => {
    this.setState({ supportedByCompany: event.target.value });
  };

  toggleCountryWebDrawer = (open: any) => () => {
    this.setState({ countryWebDrawer: open });
  };

  handleSearch = (event: any) => {
    this.setState({ searchQuery: event.target.value });
  };

  searchQueryfunc = () => {
    this.setState({ searchQuery: "" });
  };

  handleChangeCountry = (event: any) => {
    this.setState({
      country: event.target.value,
      countryWebDrawer: false,
    });
    this.setState({
      country: event.target.value,
      countryWebDrawer: false,
    });
  };

  setEmail = (text: string) => {
    const regexForEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    this.setState({
      isValidEmail: regexForEmail.test(text),
      email: text,
    });
  };

  setPassword = (password: any) => {
    
    const regexOneCapital = /^(?=.*[A-Z]).+$/;
    const regexOneLower = /^(?=.*[a-z]).+$/;
    const regexOneNumber = /^(?=.*\d).+$/;
    const regexLength = /^.{8,}$/;

    const isOneCapital = regexOneCapital.test(password);
    const isOneLower = regexOneLower.test(password);
    const isOneNumber = regexOneNumber.test(password);
    const isValidLength = regexLength.test(password)

    const isValidPassword = isOneCapital && isOneLower && isOneNumber && isValidLength;
    this.setState({
      password: password,
        isOneCapital,
        isOneLower,
        isOneNumber,
        isValidLength,
        isValidPasswordField: isValidPassword,
        errorPasswordFlag: !isValidPassword,
        error: !isValidPassword ? "Password must contain at least one uppercase letter, one lowercase letter, one number, and be at least 8 characters long." : "",
    });
  };

  handleClickShowPassword = () => {
    this.setState({ enablePassword: !this.state.enablePassword });
  };

  handleRoleSearch = (event: any) => {
    this.setState({ searchRoleQuery: event.target.value });
  };

  handleIndustrySearch = (event: any) => {
    this.setState({ searchIndustryQuery: event.target.value });
  };

  searchRoleQueryfunc = () => {
    this.setState({ searchRoleQuery: "" });
  };

  searchIndustryQueryfunc = () => {
    this.setState({ searchIndustryQuery: "" });
  };

  handleSelectRole = (role: any) => {
    this.setState({ selectedRole: role });
  };

  handleSelectIndustry = (industry: any) => {
    this.setState({ selectedIndustry: industry });
  };

  handleRoleSelectButtonWeb = () => {
    const selectedRole = this.state.selectedRole;
    if (selectedRole) {
      this.setState({
        searchRoleQuery: selectedRole.name,
        roleWebDrawerOpen: false,
        roleTextFieldValue: selectedRole.name,
      });
    }
  };

  handleIndustrySelectButtonWeb = () => {
    const selectedIndustry = this.state.selectedIndustry;
    if (selectedIndustry) {
      this.setState({
        searchIndustryQuery: selectedIndustry.name,
        industryWebDrawerOpen: false,
        industryTextFieldValue: selectedIndustry.name,
      });
    }
  };

  handleCloseImageModalWeb = () => {
    this.setState({ openImageModalWeb: false });
  };

  handleCloseImageModalMobile = () => {
    this.setState({ openImageModalMobile: false });
  };

  
  
  handleOpenPhotoLibrary = (event: any) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      this.setState({ profileImage: file });
        const fileReader = new FileReader();
        fileReader.onload = (event: any) => {
            const imageUrl = event.target.result;
            this.setState({ profilePicture: imageUrl });
            this.handleCloseImageModalWeb();
            this.handleCloseImageModalMobile();
        };
        fileReader.readAsDataURL(file);
    }
  };

  handleSelectButtonProfessional = () => {
    const { selectedOptionProfessional } = this.state
    this.setState({ webDrawerOpen: false, mobileDrawerOpen: false });

    if (this.state.selectedNumberValue === 1) {
      this.setState({ professionalLevel: selectedOptionProfessional })
    } else if (this.state.selectedNumberValue === 2) {
      this.setState({ employeesReporting: selectedOptionProfessional })
    } else if (this.state.selectedNumberValue === 3) {
      this.setState({ organizationSize: selectedOptionProfessional })
    }
  };

  handleOptionChangeProfessional = (event: any) => {
    this.setState({ selectedOptionProfessional: event.target.value })
  }

  handleBioChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = event.target.value;
    if (text.length > 140) return;
  
    if (!this.state.bio && text.trim().length === 0) return;
  
    this.setState({ bio: text });
  };

  handleCloseSuccessPopup = () => {
    this.setState({successPopupOpen: false})
    this.handleRedirect("/UserProfileBasicBlock")
  };

  handleRedirect = (link: string) => {
    window.open(link, '_self');
  };

  isMobileDevice() {
    return window.innerWidth <= 600;
  };

  handleAvatarClick = () => {
    if (this.isMobileDevice()) {
      this.setState({ openImageModalMobile: true });
    } else {
      this.setState({ openImageModalWeb: true });
    }
  };
  handleFirstName = (firstName: any) => {
    const newfirstname = firstName.trim()
    if (newfirstname) {
      this.setState({ errorFirstNameFlag: true })
    }
    this.setState({ firstName: newfirstname });
  }
  
  handleLastName = (lastName: any) => {
    const newlastname = lastName.trim()
    if (newlastname) {
      this.setState({ errorLastNameFlag: true })
    }
    this.setState({ lastName: newlastname });
  }

  handleCompanyName = (companyName: any) => {
    const newcompanyName = companyName.trim()
    if (newcompanyName) {
      this.setState({ errorCompanyNameFlag: true })
    }
    this.setState({ companyTextFieldValue: newcompanyName });
  }
  // Customizable Area End
}