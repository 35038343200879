import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "framework/src/Utilities";
const navigation = require("react-navigation")

type blockCategory = {
  id: number;
  title: string;
  description: string;

}
type accounts = {
  id: number;
  display: string;
  profile_picture: string;
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  route:{
    params:{
     item :{
        attributes: {
          availability: number;
          breadth: number;
          description: string;
          discount: string;
          height: number;
          images: [
            {
              id: number;
              url: string;
              type: string;
              filename: string;
            }
          ];
          length: number;
          manufacture_date: string;
          name: string;
          on_sale: true;
          price: number;
          recommended: false;
          sale_price: string;
          sku: string;
          stock_qty: number;
          weight: string;
        };
        id: string;
        type: string;
      }
    }
  }
  navigation:  typeof navigation;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  item: string;
  categoryDataset:object;
  groupDataset:string[];
  groupinfo:string;
  open1: boolean,
  open3: boolean,
  sidebaarOpen: boolean,
  postId: string,
  shareconnectiondata: any,
  shareconnectiondata2:any,
  sharemessage: any,
  sharemessage3: any,
  selectedmember: any,
  selectedmember3: any,
  openBlockModal: boolean
  commentReplyOpen: boolean[];
  replyComment: string;
  commentID: number;
  replyCommentString: string;
  flagNoData: boolean;
  profile: any;
  peopleData: any;
  serachDataType: string;
  users: accounts[];
  selectedComment: number;
  showEmoji : boolean,
  emoji : any,
  replyShowEmoji: boolean,
  setSearchvalue:string,
  selectedPostId: number,
  selectedpostId: any,
  commentData: any,
  mentions_ids: any,
  isCommentsOpen: boolean,  
  errorMsg: string ,
  loading: boolean,
  isCommentDrawerOpen: boolean;
  comment: string;
  commentString: string;
  openPosts: boolean,
  errorstate:string,
  followedGroup: boolean,
  followData: string,
  groupFollowed: any,
  groupinfoName: string,
  openMenuId: any,
  openMenu: any,
  reasonDescription: string,
  reasonTitle: string,
  reportId: number,
  categoryid: any,
  subcategoryid: any,
  subcategoryname: string,
  indexBlockList: number,
  deleteReasonData: blockCategory[],
  open:any,
  Contentchange: string,
  successModalOpen: boolean,
  profileImage: string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  [x: string]: any;
  id: string|number;
  error?: string;
  data?: string[];
  // Customizable Area End
}

export default class TrendingProductDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  categoryListingdataId:string ="";
  groupListData:string ="";
  getShareconnectionCallId: string = ""
  sharePostId: string = "";
  notificationshowID: string = "";
  apiCommentItemCallId: string = "";
  tagandMentionapiId: string = "";
  createCommentId: string = "";
  replycommentID: string = "";
  likeCommentCallID: string ="";
  shareCommentId: string = "";
  likePostCallId: string = "";
  addFolloeGroupId: string = "";
  addFavDataCallId: string = "";
  addReportPostId: string = "";
  addBlockUserId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
      // Customizable Area End
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      item: localStorage.getItem('item') ? JSON.parse(localStorage.getItem('item') as string):'',
      categoryDataset:{},
      groupDataset:[],
      groupinfo:"",
      errorstate:"",
      open1: false,
      open3: false,
      indexBlockList: 0,
      openBlockModal: false,
      sidebaarOpen: false,
      postId: "",
      shareconnectiondata: [],
      shareconnectiondata2: [],
      sharemessage: "",
      replyComment: "",
      commentID: null as unknown as number,
      replyCommentString: "",
      profile: [],
      flagNoData: false,
      peopleData: [],
      serachDataType: "",
      sharemessage3: "",
      selectedmember: [],
      selectedmember3: [],
      commentReplyOpen: [],
      users: [],
      selectedComment: null as unknown as number,
      replyShowEmoji: false,
      showEmoji:false,
      emoji:null,
      setSearchvalue:"",
      selectedPostId: 0,
      selectedpostId: "",
      isCommentDrawerOpen: false,
      comment: "",
      commentString: "",
      commentData: [],
      mentions_ids: [],
      isCommentsOpen: false,   
      errorMsg: '',
      loading: false,
      openPosts: false,
      followedGroup: false,
      followData: "",
      groupFollowed: {},
      groupinfoName: "",
      openMenuId: "",
      openMenu: false,
      reasonDescription: "",
      reasonTitle: "",
      reportId: 0,
      categoryid: 0,
      subcategoryid: 0,
      subcategoryname:"",
      deleteReasonData: [],
      open: false,
      Contentchange: "",
      successModalOpen: false,
      profileImage:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId2 = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson12 = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponse11 = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
  
      if (errorResponse11) {
        this.parseApiCatchErrorResponse(errorResponse11);
      } else {

        this.handleApiResponse(apiRequestCallId2, responseJson12);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    setStorageData('exploreRedirection', "fromViewGroup");
    let categoriesId =  await getStorageData("categoriesId")
     let subcategoryId = await getStorageData( "subcategoryId")
     let subcategoryName = await getStorageData( "subcategoryName" )
     let profileImage = await getStorageData( "userprofile" )
     this.setState({profileImage: profileImage})
     this.handleviewgroup(categoriesId, subcategoryId, subcategoryName)
     this.getCategoryAllData()
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.commentData !== this.state.commentData) {
      this.initializeCommentReplyOpen();
    }

    if (this.state.showEmoji && !prevState.showEmoji) {
      document.addEventListener('mousedown', this.handleClickOutside);
    } else if (!this.state.showEmoji && prevState.showEmoji) {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }
    if (this.state.replyShowEmoji && !prevState.replyShowEmoji) {
      document.addEventListener('mousedown', this.handleClickOutside);
    } else if (!this.state.replyShowEmoji && prevState.replyShowEmoji) {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }
  }

  handleClickOutside=()=>{
    this.setState({showEmoji: false, replyShowEmoji: false});
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  getCategoryAllData = async()=>{
    const categoryid = window.location.href.split("/")[4]
    this.setState({
        openPosts: false
    })
    let getid =  await getStorageData("categoriesId")
    const token = localStorage.getItem("accessToken")
    const header = {
      "token": token
    };

    let urlweb = `bx_block_categories/categories/${getid}`;
    

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.categoryListingdataId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${urlweb}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  redirecttoExplore = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'Trending');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
  }

  redirectprevios = () => {
  
      this.setState({  openPosts : false , groupinfo :""});

  }

  handleApiResponse(apiRequestCallId:string | number, responseJson1:SS) {
    switch (apiRequestCallId) {
      case this.categoryListingdataId:
        this.handleCategoryResponse(responseJson1);
        break;
      case this.groupListData:
        this.handleGroupresponse(responseJson1);
        break;
      case this.addFolloeGroupId:
        this.handleFollowresponse(responseJson1);
        break;
      case this.addFavDataCallId:
        this.AddToFavData(responseJson1);
        break;
      case this.addBlockUserId:
        this.postBlockData();
        break;
      default:
        this.parseApiErrorResponse(responseJson1);
        break;
        case this.getShareconnectionCallId:
          this.getShareconnection(responseJson1)
          break;
        case this.sharePostId:
          this.sharePostData(responseJson1)
          break;
        case this.notificationshowID:
          this.getNotificationdata(responseJson1)
          break;
        case this.apiCommentItemCallId:
          this.setState({ commentData: responseJson1.data, errorMsg: "", loading: false, });
          this.getEnablenotification()
          break;
        case this.likeCommentCallID:
          this.getCommentData(this.state.selectedPostId)
          break;
        case this.createCommentId:
          this.getCommentData(this.state.selectedPostId)
          break;
        case this.replycommentID:
          this.getCommentData(this.state.selectedPostId)
          break;
        case this.tagandMentionapiId:
          this.fetchUsers(responseJson1 as any)
          break;
        case this.shareCommentId:
          this.sharCommentData(responseJson1)
          break;
      case this.likePostCallId:
        this.handleLikeResponse(responseJson1)
          break;
    }
  }

    //istanbul ignore next
  AddToFavData = async (responseJson1: any) => {
    if (responseJson1.message == "group follow succefully!") {
      this.setState({
        followedGroup: true
      })
    } else {
      this.setState({
        followedGroup: false
      }) 
     }
     let categoriesId =  await getStorageData("categoriesId")
     let subcategoryId = await getStorageData( "subcategoryId")
     let subcategoryName = await getStorageData( "subcategoryName" )
     this.handleviewgroup(categoriesId, subcategoryId, subcategoryName)
  }

  handlePostReport = (postId: number) => {
    
  }

  postBlockData = () => {
    this.setState({
      open1: false,
      openBlockModal: true,
      indexBlockList: 0,
    })
  }


  handleOpenMenu = (event: any, id: string) => {
    this.setState({
      openMenuId: id,
      openMenu: event?.currentTarget,
    });
  };


  handleClose = () => {
    this.setState({
      openMenuId: null,
      openMenu: false,
    });
  };

  handleBlockUser = (elementId: number) => {
    this.setState({
      deleteReasonData: configJSON.blockReasons,
      selectedpostId: elementId
    })
    this.setState({ open1: true })
  }

  toggleDrawer = (e: boolean, content: string) => {
    this.setState({ open: e, Contentchange: content })
  }


  toggleDrawer1 = (e: boolean, content: string) => {
    this.setState({ open1: e, Contentchange: content })
  }

  drawerClose = () => {
    this.setState({ open: false ,  open1: false })
  }


  handleCloseSuccessModal = () => {
    this.setState({
      successModalOpen: false,
      openBlockModal: false
    })
  }

  closeOkayModal = () => {
    this.setState({
      successModalOpen: false,
      openBlockModal: false
    })
  }

  handleSelect1 = (item: { title: string, description: string }, index: number) => {
    this.setState({
      reasonTitle: item.title,
      reasonDescription: item.description,
      indexBlockList: index
    });
  }


  handleLikeResponse =  async (responseJson1:SS) => {
    let categoriesId =  await getStorageData("categoriesId")
    let subcategoryId = await getStorageData( "subcategoryId")
    let subcategoryName = await getStorageData( "subcategoryName" )
    this.handleviewgroup(categoriesId, subcategoryId, subcategoryName)
    
  }
  handleFollowresponse = async (responseJson1: any) => {
    if (responseJson1.message == "group follow succefully!") {
      this.setState({
        followedGroup: true
      })
    } else {
      this.setState({
        followedGroup: false
      }) 
     }
     let categoriesId =  await getStorageData("categoriesId")
     let subcategoryId = await getStorageData( "subcategoryId")
     let subcategoryName = await getStorageData( "subcategoryName" )
     this.handleviewgroup(categoriesId, subcategoryId, subcategoryName)
  }

  handleCategoryResponse(responseJson1:SS) {
    
    if (responseJson1.error) {
      this.setState({errorstate:""})
    } else {
      this.setState({categoryDataset:responseJson1.data || []})
    }
  }

  handleGroupresponse(responseJson1:SS){
    if (responseJson1.error) {
      this.setState({errorstate:""})
    } else {
      this.setState({
        groupDataset:responseJson1.data || [],
        groupFollowed: responseJson1.meta 
      })
    }
    
  }


  drawerSidebaar = (elementid: string) => {
    this.setState({ postId: elementid })
    this.setState({ sidebaarOpen: true })
  }
  drawerSidebaarClose = () => {
    this.setState({ sidebaarOpen: false,shareconnectiondata:[],selectedmember:[], shareconnectiondata2: [] })
  }
  handlewritemessage = (event: any) => {
    this.setState({ sharemessage: event.target.value })
  }
  handlesearchTag = (event: any) => {
    this.handleSearchconnections(event)
  }

  likePost = (e: any) => {
    const account_id = localStorage.getItem("account_id")

    const token = localStorage.getItem("accessToken")

    const attrs = {
      "post_id": e.id,
      "account_id": account_id
    };

    const data = {
      type: e.attributes.is_liked ? "unlike" : "like",
      attributes: attrs
    };

    const finaldata = {
      data
    }


    const LikePostMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.likePostCallId = LikePostMessage.messageId;

    LikePostMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_like_a_post/like_posts`
    );

    LikePostMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.validationApiContentType,
        token: `${token}`,
      })
    );

    LikePostMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );


    LikePostMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(finaldata)
    );

    runEngine.sendMessage(LikePostMessage.id, LikePostMessage);
  }

  handleSearchconnections = (data: any) => {
    const token = localStorage.getItem("accessToken")

    const getShareconnectionMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getShareconnectionCallId = getShareconnectionMessage.messageId;


    getShareconnectionMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: `${token}`,
      })
    );

    getShareconnectionMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_share/share?search=${data.target.value}`
    );

    getShareconnectionMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );

    runEngine.sendMessage(getShareconnectionMessage.id, getShareconnectionMessage);
  }

  getShareconnection = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({ shareconnectiondata: responseJson.data,shareconnectiondata2:responseJson.data })
    }
    
  }

  getvalueconnectioncheck = (elementdata: any, data: any) => {

    if (elementdata.target.checked) {
      const IteamIndex = this.state.selectedmember.findIndex((iteam: any) => iteam.id === data.id)


      if (IteamIndex < 0) {
        const storetype = [...this.state.selectedmember, { id: data.id, value: data.name }]
        this.setState({ selectedmember: storetype });
      }
    } else {
      const deleteSelectedType = this.state.selectedmember.filter((elem: any) => {
        return elem.id !== data.id
      })

      this.setState({ selectedmember: deleteSelectedType });
    }
  }

  handleselectmemberDelete = (elementdata: number) => {
    const deleteSelectedType = this.state.selectedmember.filter((element: any, index: number) => {
      return index !== elementdata
    })

    this.setState({ selectedmember: deleteSelectedType });
  }

  handlePostshare = () => {
    const token = localStorage.getItem("accessToken");

    const memberId = this.state.selectedmember.map((element: any) => element.id);
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };


    let formdata = new FormData()
    for (let iteams of memberId) {
      formdata.append("room_ids[]", iteams)
    }
    formdata.append("content", this.state.sharemessage)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sharePostId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `posts/${this.state.postId}/share`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `POST`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  sharePostData = (responseJson: any) => {
    if (responseJson.message) {
      this.getEnablenotification()
      this.setState({ shareconnectiondata: [], sharemessage: "", sidebaarOpen: false })
    }
  }

  getEnablenotification = () => {
    const token = localStorage.getItem("accessToken");

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notificationshowID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/unreads`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  getNotificationdata = (responseJson: any) => {
    if (responseJson.data) {
      localStorage.setItem("unreadnotification", responseJson.data.unread_notification)
    }
  }

  handleCommentfetch = (postId: number) => {
    this.getCommentData(postId)
    this.getUserData()
  }
  handleClick = (postId: number, event: React.MouseEvent<HTMLElement>) => {
    this.toggleCommentDrawer(true, postId);
    this.setState({ selectedPostId: postId });
    this.handleCommentfetch(postId)
  }
  toggleCommentDrawer = (isOpen: boolean, postId: number) => {
    this.setState({ isCommentDrawerOpen: isOpen, selectedPostId: postId });
  }

  handleClose2 = (event: React.MouseEvent<HTMLElement>) => {
    this.toggleCommentDrawer(false, 0);
  }

  drawerCloses = () => {
    this.setState({isCommentDrawerOpen : false})
  }
 

  handleCommentChange = (event: any) => {
    this.setState({ comment: event.target.value });
  };
  handleCommentSubmit = () => {
    const { comment, commentData } = this.state;
    let mentionIds: number[] = [];
    const mentionRegex = /@\[(.+?)\]\((\d+)\)/g;
    let formattedComment = comment.replace(mentionRegex, (_, display, id) => {
      mentionIds.push(id);
      return `@${display}`;
    });
    if (comment.trim()) {
      const newComment = {
        attributes: {
          post_id: this.state.selectedPostId,
          mentions_ids: mentionIds,
          comment: formattedComment,
          
        }
      };

      this.setState({
        mentions_ids: mentionIds,
        commentData: [newComment, ...commentData],
        comment: '',
        isCommentsOpen: true,
        commentString: formattedComment,
      }, () => {
        this.createComment();
        this.getCommentData(this.state.selectedPostId)
      });
    }
  };
  toggleComments = () => {
    this.setState((prevState) => ({ isCommentsOpen: !prevState.isCommentsOpen }));
  };

  initializeCommentReplyOpen = () => {
    const commentReplyOpen = this.state.commentData.map(() => false);
    this.setState({ commentReplyOpen });
  };

  handleReplycomment = () => {
    const { replyComment, commentData } = this.state;
    if (replyComment.trim()) {
      const newReply = {
        attributes: {
          post_id: this.state.selectedPostId,
          parent_comment_id: this.state.commentID,
          comment: replyComment,
        }
      };

      const updatedComments = commentData.map((comment: any) => {
        if (comment.attributes.id === this.state.commentID) {
          return {
            ...comment,
            attributes: {
              ...comment.attributes,
              replies: [newReply, ...comment.attributes.replies]
            }
          };
        }
        return comment;
      });

      this.setState({
        commentData: updatedComments,
        replyComment: '',
        commentReplyOpen: [],
        replyCommentString: this.state.replyComment,
      }, () => {
        this.createReplyComment();
      });
    }
    this.getCommentData(this.state.selectedPostId)
  }

  likeComment = (e: any) => {
    const header = {
      "Content-Type": 'application/json',
      token: localStorage.getItem("accessToken")
    };

    const attrs = {
      "post_id": e.attributes.post_id,
      "likeable_id": e.id,
      "likeable_type": "BxBlockMentionsAndTagging::Comment"
    };
    const data = {
      "type": e.attributes.is_liked ? "unlike" : "like",
      "attributes": attrs
    };

    const finaldata = {
      data
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.likeCommentCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_like/likes'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(finaldata)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleShareCommentClick = (id: number) => {
    this.setState({ selectedComment: id, open3: true });
  };

  getvalueconnectioncheck3 = (elementdata: any, data: any) => {

    if (elementdata.target.checked) {
      const IteamIndex = this.state.selectedmember3.findIndex((iteam: any) => iteam.id === data.id);


      if (IteamIndex < 0) {
        const storetype = [...this.state.selectedmember3, 
          { value: data.name , id: data.id }
        
        ];
        this.setState({ selectedmember3: storetype });
      }
    } else {
      const deleteSelectedType = this.state.selectedmember3.filter((element: any) => {
        return element.id !== data.id
      })

      this.setState({ selectedmember3: deleteSelectedType });
    }
  }

  drawerSidebaarClose3 = () => {
    this.setState({ open3: false, selectedmember3: [], shareconnectiondata2: [] })
  }

  handleselectmemberDelete3 = (elementdata: number) => {
    const deleteSelectedType = this.state.selectedmember3.filter((element: any, index: number) => {
      return index !== elementdata
    })

    this.setState({ selectedmember3: deleteSelectedType });
  }


  fetchUsers = (data: { accounts: { id: number; first_name: string; last_name: string; profile_picture: { url: string } }[] }) => {
    const mappedUsers: accounts[] = data.accounts.map((user) => ({
      id: user.id,
      display: `${user.first_name} ${user.last_name}`,
      profile_picture: user.profile_picture.url,
    }));
    this.setState({ users: mappedUsers });
  };

  sharCommentData = (responseJson: any) => {
    if (responseJson.message) {
      this.getEnablenotification()
      this.setState({ shareconnectiondata2: [], sharemessage3: "", open3: false })
    }
  }

  handlewriteCommentmessage = (event: any) => {
    this.setState({ sharemessage3: event.target.value })
  }

    //istanbul ignore next
  handleCommentshare = () => {
    const token = localStorage.getItem("accessToken");

    const memberid = this.state.selectedmember3.map((element: any) => element.id);
    const header = {
      token,
    };

    let formdata = new FormData()
    for (let iteams of memberid) {
      formdata.append("room_ids[]", iteams)
    }
    formdata.append("content", this.state.sharemessage3)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.shareCommentId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `comments/${this.state.selectedComment}/share`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `POST`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  createComment = () => {
    const header = {
      "Content-Type":'application/json',
      token: localStorage.getItem("accessToken")
    };

    const httpBody = {
      post_id: this.state.selectedPostId,
      comment: this.state.commentString,
      mentions_ids: this.state.mentions_ids
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createCommentId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_mentions_and_tagging/comments'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  createReplyComment() {
    const header = {
      "Content-Type": 'application/json',
      token: localStorage.getItem("accessToken")
    };

    const replyData = {
      parent_comment_id: this.state.commentID,
      comment: this.state.replyCommentString,
      mentions_ids: []
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.replycommentID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_mentions_and_tagging/comments/create_reply'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(replyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getCommentData(postId: number): any {
    const header = {
      "Content-Type":'application/json',
      token: localStorage.getItem("accessToken")
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiCommentItemCallId = requestMessage.messageId;

    const endpoint = `bx_block_mentions_and_tagging/comments?post_id=${postId}`;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getUserData(): any {
    const header = {
      "Content-Type": 'application/json',
      token: localStorage.getItem("accessToken")
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.tagandMentionapiId = requestMessage.messageId;

    const endpoint = `bx_block_addfriends/connections`;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  handleReplySection = (index: number, commentId: number) => {
    const updatedCommentReplyOpen = this.state.commentReplyOpen.map((isOpen: boolean, i: number) => (i === index ? !isOpen : isOpen));
    this.setState({ commentReplyOpen: updatedCommentReplyOpen, commentID: commentId });
  };

  calculateTimeGap = (createdAt: string): string => {
    const createdDateTime = new Date(createdAt);
    const currentDate = new Date();
    const diffInMillis = currentDate.getTime() - createdDateTime.getTime();
    const diffInSecond = diffInMillis / 1000;
    const diffInMinutes = diffInSecond / 60;
    const diffInHours = diffInMinutes / 60;
    const diffInDays = diffInHours / 24;
    const diffInWeek = diffInDays / 7;

    if (diffInMinutes < 1) {
      return 'few mins ago';
    } else if (diffInHours < 1) {
      return `${Math.floor(diffInMinutes)}m`;
    } else if (diffInHours < 24) {
      if (diffInHours < 1.5) {
        return 'few hrs ago';
      }
      return `${Math.floor(diffInHours)}h`;
    } else if (diffInDays < 7) {
      return `${Math.floor(diffInDays)}d`;
    } else {
      return `${Math.floor(diffInWeek)}w`;
    }
  };
  handleReplyCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ replyComment: event.target.value });
  };

  EmojiClick(emojiObject:any){
    const { comment } = this.state;
      const newText = comment.slice(0) + emojiObject.emoji 
      this.setState({ comment: newText, showEmoji: false });
    }

  onClickEmoji(){
    this.setState({showEmoji: !this.state.showEmoji})
  }

  onClickReplyEmoji(){
    this.setState({replyShowEmoji: !this.state.replyShowEmoji});
  }
  ReplyEmojiClick(emojiObject:any){
  const { replyComment } = this.state;
    const newText = replyComment.slice(0) + emojiObject.emoji 
    this.setState({ replyComment: newText, replyShowEmoji: false });
  }
   
  handleViewGroupFunc = (categoryid:string,subcategoryid:string,subcategoryname:string) => {
    this.setState({
      categoryid: categoryid,
      subcategoryid:subcategoryid,
      subcategoryname:subcategoryname
    })
    setStorageData("subcategoryId" , subcategoryid)
    this.handleviewgroup(categoryid,subcategoryid,subcategoryname) 
  }

  handleviewgroup=(categoryid:string,subcategoryid:string,subcategoryname:string) => {
    this.setState({groupinfo:subcategoryid, groupinfoName:subcategoryname })
    setStorageData("subcategoryId" , subcategoryid)
    const token = localStorage.getItem("accessToken")
    const header = {
      "token": token
    };

    let urlweb = `bx_block_posts/posts?category_id=${categoryid}&sub_category_id=${subcategoryid}`;
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.groupListData = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${urlweb}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  //istanbul ignore next
  followGroup = async () => {
    let categoriesId =  await getStorageData("categoriesId")
    let subcategoryId = await getStorageData( "subcategoryId")
      this.setState({
          followedGroup: true
      })
        const token = localStorage.getItem("accessToken")
        let data = {
          "category_id":categoriesId,
          "sub_category_id": subcategoryId
      }
        const header = {
          "Content-Type": configJSON.validationApiContentType,
          token,
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.addFolloeGroupId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.addToFollowGroup}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.exampleAPiMethodPost
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(data)
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  //istanbul ignore next
  getToFavriouteData(addFavId: string) {
    const token = localStorage.getItem("accessToken")
    this.setState({
      followedGroup: true
  })
    const getNewsFeedMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addFavDataCallId = getNewsFeedMessage.messageId;

    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AddToFavIdPoint +  addFavId + '/mark_as_favorite'
    );

    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: `${token}`,
      })
    );

    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(getNewsFeedMessage.id, getNewsFeedMessage);
  }
  //istanbul ignore next
  addedReport = () => {
    const token = localStorage.getItem("accessToken")
    let data = {
      post_id: this.state.selectedpostId,
      flag_category_id: this.state.reportId
    }

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addReportPostId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getReportApiPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


   //istanbul ignore next
  addedToBlock = () => {
    const token = localStorage.getItem("accessToken")
    let data = {
      blocker_id: this.state.selectedpostId,
      reason_title: this.state.reasonTitle,
      reason_description: this.state.reasonDescription
    }

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addBlockUserId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addToBlockApiPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
