import React from "react";

// Customizable Area Start
import {
    Box, Button, Container, Grid, Step, StepLabel, Stepper, ThemeProvider, Typography, createTheme,
} from "@material-ui/core";
import { Check } from "@material-ui/icons";
import { history_toggle_off, newlogo, women } from "./assets";
import { styled } from '@material-ui/core/styles';
const configJSON = require("./config");
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';

const theme = createTheme({
    palette: {
        primary: {
            main: "#14362E",
            contrastText: "#fff",
        },
    },
});

// Customizable Area End

import ApprovalStatesController, {
    Props,
} from "./ApprovalStatesController";


class ApprovalStates extends ApprovalStatesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Container style={{ display: "flex" }}>
                    {this.state.confirmationCall &&
                        <ImgStyling container spacing={5}>
                            <Grid item md={9}>
                                <Grid container spacing={4}>
                                    <Grid
                                        item
                                        xs={12}
                                        className={"head"}
                                    >
                                        <img
                                            src={newlogo}
                                            className={"logo"}
                                        />
                                        <Box style={{ position: "relative" }}>
                                            <div
                                                style={{
                                                    zIndex: -1,
                                                    top: "50%",
                                                    right: 0,
                                                    width: "125px",
                                                    left: 0,
                                                    position: "absolute",
                                                    backgroundColor: "gray",
                                                    background: "#14362E",
                                                    margin: "auto",
                                                    height: "2px",
                                                }}
                                            />
                                            <Stepper
                                                connector={<></>}
                                                style={{ backgroundColor: "transparent" }}
                                                activeStep={this.state.activeStep}
                                                className=""
                                            >
                                                {[0, 1, 2].map((index) => (
                                                    <Step key={index}>
                                                        <StepLabel
                                                            className="stepperlable"
                                                            style={webStyle.stepperSteps}
                                                            StepIconComponent={Check}
                                                        />
                                                    </Step>
                                                ))}
                                                <Step>
                                                    <StepLabel
                                                        className="stepperlable"
                                                        style={{
                                                            ...webStyle.stepperSteps,
                                                            background: "#000000",
                                                        }}
                                                    >
                                                        {this.state.activeStep}
                                                    </StepLabel>
                                                </Step>
                                            </Stepper>
                                        </Box>
                                        <Typography
                                            className="heading"
                                        >
                                            {configJSON.confrmCall}
                                        </Typography>
                                    </Grid>

                                    <Grid xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Box className="confirmContentBox">
                                            <Typography className="msgContent">Thank you for applying! Please schedule a convenient time for a 10-minute call with one of our team members at</Typography>
                                            <Typography data-test-id='calendar-link' className="msgContent" onClick={this.openCalendar} style={{
                                                margin: '4% 0',
                                                fontWeight: 600,
                                                textAlign:'center',
                                                display:'block',
                                                cursor:'pointer'
                                            }}>www.calendly.com/kella</Typography>
                                            <Typography className="msgContent">This call is designed to evaluate your compatibility with our community and also serves as an opportunity for you to pose any questions you may have. We look forward to connecting with you soon!</Typography>
                                        </Box>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        className="btnGrid"
                                    >
                                        <Box className="btnSize">
                                            <Button
                                                data-test-id='understood'
                                                variant="contained"
                                                fullWidth
                                                className="buttonStyle"
                                                onClick={() => this.handleChange(2)}
                                            >
                                                Understand
                                            </Button>
                                        </Box>
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item md={3}>
                                <img
                                    src={women}
                                    alt="Women"
                                    className="womenBg"
                                />
                            </Grid>
                        </ImgStyling>}
                    {this.state.pending &&
                        <ImgStyling container spacing={5}>
                            <Grid item md={9} >
                                <Grid container spacing={4}>
                                    <Grid
                                        item
                                        xs={12}
                                        className={"head"}
                                    >
                                        <img src={newlogo}
                                            className={"logo"}
                                        />
                                        <Typography
                                            className="heading"
                                        >
                                            Application Status
                                        </Typography>
                                        <img
                                            src={history_toggle_off}
                                            className="icons"
                                        />
                                    </Grid>

                                    <Grid xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Box className="contentBox">
                                            <Typography className="stateHeading">{configJSON.pending}</Typography>
                                            <Typography className="msgContent">Your application is pending, you will receive an update through your email. You can also check back later to view your application status.</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        className="btnGrid"
                                    >
                                        <Box className="btnSize">

                                            <Button
                                                data-test-id='pending'
                                                fullWidth
                                                variant="contained"
                                                className="buttonStyle"
                                              
                                            >
                                                <Box component={'span'} data-test-id='back-to-login' onClick={this.moveToLogin} style={{ textDecoration: "none", color: 'white' }}>
                                                    Back to login
                                                </Box>
                                            </Button>
                                        </Box>
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item md={3}>
                                <img
                                    alt="Women"
                                    src={women}
                                    className="womenBg"
                                />
                            </Grid>
                        </ImgStyling>}

                        {this.state.approved &&
                        <ImgStyling container spacing={5}>
                            <Grid item md={9} >
                                <Grid container spacing={4}>
                                    <Grid
                                        item
                                        xs={12}
                                        className={"head"}
                                    >
                                        <img
                                            src={newlogo}
                                            className={"logo"}
                                        />
                                        <Typography
                                            className="heading"
                                        >
                                            Application Status
                                        </Typography>
                                        <CheckCircleOutlineIcon className="checkedCircle" />

                                    </Grid>

                                    <Grid xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Box className="contentBox">
                                            <Typography className="stateHeading">{configJSON.approved}</Typography>
                                            <Typography className="msgContent">Congratulations, your application is approved! You can now join our community by choosing a Kella membership plan.</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        className="btnGrid"
                                    >
                                        <Box className="btnSize">
                                            <Button
                                             fullWidth
                                             className="buttonStyle"
                                                data-test-id='pending'
                                                variant="contained"
                                               
                                            >
                                                <Box component='span' data-test-id='proceed' onClick={this.moveToOnboarding} style={{ textDecoration: "none", color: 'white' }}>
                                                    Proceed
                                                </Box>
                                            </Button>
                                        </Box>
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item md={3}>
                                <img
                                 alt="Women"
                                 className="womenBg"
                                    src={women}
                                   
                                />
                            </Grid>
                        </ImgStyling>}

                        {this.state.waitlisted &&
                        <ImgStyling container spacing={5}>
                            <Grid item md={9} >
                                <Grid container spacing={4}>
                                    <Grid     
                                        className={"head"}
                                        item
                                        xs={12}
                                    >
                                        <img
                                        className={"logo"}
                                            src={newlogo}
                                        />
                                        <Typography
                                            className="heading"
                                        >
                                            Application Status
                                        </Typography>
                                        <HourglassEmptyIcon className="checkedWaiter" />

                                    </Grid>

                                    <Grid xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Box className="contentBox">
                                            <Typography className="stateHeading">{configJSON.waitlisted}</Typography>
                                            <Typography className="msgContent">Your application is waitlisted due to a huge amount of applications, but you can apply again in 3 months!</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        className="btnGrid"
                                    >
                                        <Box className="btnSize">
                                            <Button
                                             fullWidth
                                             data-test-id='pending'
                                             className="buttonStyle"
                                               
                                                variant="contained"
                                               
                                            >
                                                <Box component='span' onClick={this.moveToLogin} style={{ textDecoration: "none", color: 'white' }}>
                                                Back to login
                                                </Box>
                                            </Button>
                                        </Box>
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item md={3}>
                                <img
                                    src={women}
                                    alt="Women"
                                    className="womenBg"
                                />
                            </Grid>
                        </ImgStyling>}
                </Container>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}
// Customizable Area Start


const webStyle = {
    stepperSteps: {
        background: "#14362E",
        borderRadius: "200px",
        width: "24px",
        height: "24px",
        gap: "10px",
        color: "white",
        padding: "3px",
    },
};

export const ImgStyling: any = styled(Grid)({
    "& .head": {
        marginTop: "50px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    "& .logo": {
        width: "249.88px",
        height: "45.59px"
    },
    "& .checkedCircle" : {
        width:"129.17px",
        height: "129.17px",
        // top: 12.92px;
        // left: 149.42px;
        gap: "0px",
        color: "#73A58E"
        
    },
    '& .checkedWaiter': {
        width:"129.17px",
        height: "129.17px",
        color: "#0F172A"
    },
    "& .heading": {
        fontSize: "20px",
        color: "#14362E",
        fontFamily: "MyFont",
        letterSpacing: "1.3",
        fontWeight: 800,
        margin: "15px 0"
    },
    "& .msgContent": {
        textAlign: 'center',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#0F172A',
        fontFamily: "MyFont",
    },
    "& .womenBg": {
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "contain",
        position: "fixed",
        right: 0,
    },
    "& .buttonStyle": {
        backgroundColor: "#14362E",
        borderRadius: "8px",
        height: "45px",
        color: "white",
        fontWeight: 700,
        fontFamily: "MyFont",
        letterSpacing: "1px",
        fontSize: "16px",
        textTransform: "capitalize",
    },
    "& .btnSize": {
        width: "380px",
        height: "56px"
    },
    "& .btnGrid": {
        display: "flex",
        justifyContent: "center",
        gap: "10px"
    },
    "& .stateHeading": {
        textAlign: 'center',
        fontWeight: 600,
        fontSize: '30px',
        lineHeight: '55px',
        color: '#14362E',
        fontFamily: "MyFont",
    },
    "& .icons": {
        width: "155px",
        height: "155px",
        margin: "20px 0"
    },
    "& .contentBox": {
        width: '380px',
        height: '264px'
    },
    "& .confirmContentBox":{
        width: '380px',
        height: '380px'
    },

    "@media(max-width: 480px)": {

        "& .heading": {
            fontSize: "20px",
            color: "#14362E",
            fontFamily: "MyFont",
            letterSpacing: "1.3",
            fontWeight: 800,
            margin: "15px 0"
        },
        "& .msgContent": {
            textAlign: 'center',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '24px',
            color: '#0F172A',
            fontFamily: "MyFont",
        },
        "& .womenBg": {
            display: 'none'
        },
        "& .btnSize": {
            width: "350px",
        },
        "& .stateHeading": {
            textAlign: 'center',
            fontWeight: 500,
            color: '#14362E',
            fontFamily: "MyFont",
            fontSize: '30px',
            lineHeight: '40px',
        },
        "& .contentBox": {
            width: '350px',
        },
        "& .confirmContentBox":{
            width: '350px',
        },
    },

    "@media(max-width: 360px)": {
        "& .womenBg": {
            display: 'none'
        },
        "& .btnSize": {
            width: "300px",
        },
        "& .contentBox": {
            width: '300px',
        },
        "& .confirmContentBox":{
            width: '300px',
        },
    },

    "@media(max-width: 810px)": {
        "& .womenBg": {
            display: 'none'
        },
    }

});
export default ApprovalStates

// Customizable Area End
