Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";

exports.btnExampleTitle = "CLICK ME";

exports.privacyPolicy = "Privacy Policy";
exports.securityPolicy = "Security Policy";
exports.membershipAgreement = "Membership Agreement";
exports.communityStandards = "Community Standards";
exports.consentManagement = "Consent Management";
exports.complaintsHandlingPolicy = "Complaints Handling Policy";
exports.nonSolicitationPolicy = "Non Solicitation Policy";
exports.termsAndConditionEndData = "Terms %26 Conditions";

// API Methods
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.httpPatchMethod = "PATCH";

// API EndPoints
exports.contactUsAPiContentType = "application/json";
exports.questionsApiEndPoint = "bx_block_help_centre/question_answer/";
exports.questionAnswerApiEndPoint = "bx_block_help_centre/question_answer/";
exports.getPrivacySettingsApiEndPoint = "account_block/accounts/";
exports.setPrivacySettingsApiEndPoint = "bx_block_privacy_setting/privacy_profile_setting";
exports.notificationSettingsEndPoint = "bx_block_notifsettings/setting"
exports.subjectsApiEndPoint = "bx_block_custom_forms/subjects";
exports.contactUsApiEndPoint = "bx_block_custom_forms/contact_form";
exports.termsAndConditionApiEndPoint = "bx_block_terms_and_conditions/terms_and_conditions";
exports.policiesApiEndPoint = "bx_block_privacy_setting/policy";
// Customizable Area End