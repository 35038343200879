Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.exampleAPiEndPoint = "https://nickcoingophase1v2-158847-ruby.b158847.stage.eastus.az.svc.builder.ai/bx_block_trending/trendings";
exports.exampleAPiMethod = "GET";
exports.exampleAPiMethodPost = "POST";

exports.description = "Description";
exports.price = "Price";
exports.discount = "Discount";
exports.availbleQuantity = "Availble Quantity";
exports.trendingList = "Trending List";
exports.TrendingProductDetails ='TrendingProductDetails';
exports.TrendingDetails ='TrendingDetails';
exports.AddToFavIdPoint = 'bx_block_posts/posts/';
exports.getReportApiPoint = 'bx_block_contentflag/contents';
exports.addToBlockApiPoint = 'bx_block_block_users/blocked_user';
exports.addToFollowGroup = "bx_block_posts/follow_group";
exports.validationApiMethodType = "GET";

exports.demoDetailsData ={
  description:
    "Jeans are a type of pants or trousers made from denim or dungaree cloth",
  stock_qty: 80,
  price: 900.0,
  discount:100
}

exports.blockReasons = [
  {
    id: 1,
    title: "Unwanted Interactions",
    description: "Blocking helps prevent harassment, bullying, and unwanted attention from other users.",
    backgroundColor: "#F1E3E4",
  },
  {
    id: 2,
    title: "Content Shielding",
    description: "Blocking shields users from offensive or inappropriate content posted by others.",
    backgroundColor: "#E7E3F1",
  },
  {
    id: 3,
    title: "Privacy Control",
    description: "Users can block others to control who views their profile, posts, and personal information, addressing privacy concerns.",
    backgroundColor: "#D4E5DE",
  },
  {
    id: 4,
    title: "Maintaining a Positive Environment",
    description: "Blocking aids in creating a positive online experience by preventing distractions, trolls, and disruptive behavior.",
    backgroundColor: "#E5E3E3",
  },
  {
    id: 5,
    title: "Safety and Trust",
    description: "Blocking serves as a layer of protection against safety concerns, trust breaches, and unwanted solicitations, ensuring a safer and more trustworthy online presence.",
    backgroundColor: "#E3F1E5",
  },
];

// Customizable Area End