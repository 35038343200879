import React from "react";

// Customizable Area Start
import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  Menu,
  MenuItem,
  Container,
  Grid,
  Button,
  Modal,
  Paper,
  Drawer,
  Hidden,
  ClickAwayListener,
} from "@material-ui/core";
import {
  ArrowBack,
  ExpandLess, ExpandMore,
  Close as CloseIcon,
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
} from "@material-ui/icons";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { msgImg, contactImg, blockImg, unblockImg, groupImg, checkCircle } from "./assets";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Toaster } from 'react-hot-toast';
const configJSON = require("./config");
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import AddFriendsController, { Props } from "./AddFriendsController.web";

export default class AddFriends extends AddFriendsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  myCircleForWeb = () => {
    return (
      <Box style={webStyle.outerBox}>
        <AppHeader />
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#f2fff2",
          }}
        >
          <Grid
            item
            xs={12}
            sm={8}
            md={6}
            style={{
              position: "relative",
              marginTop: "10%",
              borderRadius: "8px",
              minHeight: "100vh",
              backgroundColor: "#ffffff",
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <IconButton style={{ marginRight: "auto" }} href="./UserProfileBasicBlock">
                  <ArrowBack />
              </IconButton>
              <Typography
                style={{
                  left: "50%",
                  position: "absolute",
                  transform: "translateX(-50%)",
                  color: "#14362E",
                  fontSize: "20px",
                  fontWeight: 700,
                  letterSpacing: "0.05em",
                  fontFamily: "'MyFont', 'Halyard Display'",
                }}
              >
                My Circle
              </Typography>
            </Box>
            <hr style={webStyle.horizontalLine} />
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box style={webStyle.parentOfMainButtons}>
                <Button
                  data-test-id="connectionButton"
                  style={this.getButtonStyle("connections")}
                  onClick={() => this.setView("connections")}
                >
                  Connections
                </Button>
                <Button
                  data-test-id="requestButton"
                  style={this.getButtonStyle("requests")}
                  onClick={() => this.setView("requests")}
                >
                  Requests
                </Button>
                <Button
                  data-test-id="invitationButton"
                  style={this.getButtonStyle("invitations")}
                  onClick={() => this.setView("invitations")}
                >
                  Invitations
                </Button>
                <Button
                  data-test-id="blockedButton"
                  style={this.getButtonStyle("blocked")}
                  onClick={() => this.setView("blocked")}
                >
                  Blocked
                </Button>
              </Box>
            </Box>
            <Box>
              {this.state.currentView === "connections" && (
                <div className="connections-view">
                  {this.connectionsViewWeb()}
                </div>
              )}
              {this.state.currentView === "requests" && (
                <div className="requests-view">{this.requestsViewWeb()}</div>
              )}
              {this.state.currentView === "invitations" && (
                <div className="invitations-view">
                  {this.invitationsViewWeb()}
                </div>
              )}
              {this.state.currentView === "blocked" && (
                <div className="blocked-view">{this.blockedViewWeb()}</div>
              )}
            </Box>
          </Grid>
        </Box>
      </Box>
    );
  };

  connectionsViewWeb = () => {
  //istanbul ignore next
    return (
      <Box style={webStyle.viewsBox}>
        <Box style={webStyle.searchBox}>
          <Toaster
            position="top-center"
            reverseOrder={false}
          />
          <TextField
            variant="outlined"
            fullWidth
            inputProps={{ "data-test-id": "searchField" }}
            value={this.state.searchQuery}
            placeholder="Search"
            onChange={this.handleSearchChange}
            style={{ margin: "20px 0" }}
            InputProps={{
              style: {
                marginTop: "5px",
                borderRadius: "8px",
                fontFamily: "'MyFont', 'Halyard Display'",
              },
              endAdornment: this.state.searchQuery && (
                <InputAdornment position="end">
                  <IconButton onClick={this.clearSearch}>
                    {" "}
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <SearchIcon />{" "}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <List>
          {this.state.connections.map((item: any, index: number) => {
            const searchQuery = this.state.searchQuery?.toLowerCase();
            const connectionName = item.first_name?.toLowerCase();
            if (!searchQuery || connectionName.includes(searchQuery)) {
              const duration = this.formatDuration(item.created_at);
              return (
                <React.Fragment key={index}>
                  <ListItem
                    style={{
                      fontFamily: "'MyFont', 'Halyard Display'",
                      display: "flex",
                      alignItems: "flex-start",
                    }}
                  >
                    <ListItemAvatar>
                    <a href={`UserProfileBasicBlock/${item.id}`}>
                      <Avatar src={item.profile_picture?.url}>
                        <span style={webStyle.usernameFirstLetter}>
                          {item.first_name?.charAt(0)}
                        </span>
                      </Avatar>
                    </a>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <a href={`UserProfileBasicBlock/${item.id}`}
                        style={{textDecoration: "none"}}>
                        <span style={webStyle.userNameStyle}>
                          {item.first_name} {item.last_name}
                        </span>
                        </a>
                      }
                      secondary={
                        <>
                          <Typography variant="body2" style={webStyle.position}>
                            {item.role}
                          </Typography>
                          <Typography
                            variant="body2"
                            style={webStyle.mutualFriends}
                          >
                            {item.mutual_friends?.count} Mutual Friends
                          </Typography>
                        </>
                      }
                      style={{ fontFamily: "'MyFont', 'Halyard Display'" }}
                    />
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={webStyle.duration}
                    >
                      {duration}
                    </Typography>
                    <IconButton
                      data-test-id="icon-button"
                      edge="start"
                      aria-haspopup="true"
                      aria-controls={`menu-${index}`}
                      aria-label="more"
                      onClick={(event) => this.handleClick(event, index)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      keepMounted
                      id={`menu-${index}`}
                      anchorEl={this.state.anchorEl}
                      open={this.state.selectedIndex === index}
                      onClose={this.handleClose}
                    >
                      <MenuItem
                        onClick={this.handleClose}
                        style={webStyle.menuItemConnections}
                      >
                        <img src={msgImg} onClick={this.redirectToChat.bind(this,item)}  style={{ marginRight: 10 }}></img>
                        Message
                      </MenuItem>
                      <MenuItem
                        style={webStyle.menuItemConnections}
                        onClick={() => {
                          this.handleClose();
                          this.handleOpenModal(item);
                        }}
                      >
                        <img src={contactImg} style={{ marginRight: 10 }}></img>
                        Remove from My Circle
                      </MenuItem>
                      <MenuItem
                        onClick={() => this.handleBlockDrawer(item)}
                        style={webStyle.menuItemBlocked}
                      >
                        <img src={blockImg} style={{ marginRight: 10 }}></img>
                        Block User
                      </MenuItem>
                    </Menu>
                    {this.renderRemoveModal()}
                    {this.renderBlockUserDrawer()}
                    {this.blockUserConfirmationModal()}
                  </ListItem>
                </React.Fragment>
              );
            } else {
              return null;
            }
          })}
        </List>
      </Box>
    );
  };

  renderRemoveModal = () => {
    const { isModalOpen, connectionToRemove } = this.state;
    if (!connectionToRemove) return null;
    return (
      <Modal open={isModalOpen} onClose={this.handleCloseModal}>
        <Paper
          style={{ ...webStyle.removeFromMyCircleModal, position: "absolute" }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" style={webStyle.modalTitle}>
              Remove from My Circle?
            </Typography>
            <Avatar src={connectionToRemove.profile_picture.url}></Avatar>
            <Typography style={webStyle.modalUserName}>
              {connectionToRemove.first_name} {connectionToRemove.last_name}
            </Typography>
            <Typography style={webStyle.modalPosition}>
              {connectionToRemove.role}
            </Typography>
            <Typography variant="body1" style={webStyle.modalContent}>
              Do you really want to remove {connectionToRemove.first_name} from your
              circle?
            </Typography>
          </Box>

          <Box style={webStyle.modalActions}>
            <Button data-test-id="cancel-btn-removeMyCircle"
              variant="contained"
              style={{
                ...webStyle.cancelButton,
                textTransform: "capitalize",
              }}
              onClick={this.handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              data-test-id="remove-btn-removeMyCircle"
              variant="outlined"
              style={{
                ...webStyle.removeButton,
                textTransform: "capitalize",
              }}
              onClick={() => this.handleRemoveUser(connectionToRemove.connection_id, 'removeFromCircle')}
            >          
              Remove
            </Button>
          </Box>
        </Paper>
      </Modal>
    );
  };
 
  renderBlockUserDrawer = () => {
    const { isDrawerOpen, connectionToBlock, reasonTitle } = this.state;
    if (!connectionToBlock) return null;
  
    const isReasonSelected = !!reasonTitle; 
    return (
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={this.closeBlockDrawer}
        PaperProps={{
          style: {
            borderTopLeftRadius: "20px",
            width: "484px",
          },
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            borderBottom: "1.04px solid #CBD5E1",
          }}
        >
          <Box
            style={{ display: "flex", padding: "4% 0 0 4%", width: "350px" }}
          >
            <Typography
              style={{
                fontSize: "24px",
                fontWeight: 600,
                color: "#14362E",
                fontFamily: "MyFont",
                letterSpacing: "1px",
              }}
            >
              Block {connectionToBlock.first_name} {connectionToBlock.last_name}
            </Typography>
          </Box>
          <Box>
            <IconButton
              style={{ right: "10px", margin: "15px 5px" }}
              onClick={this.closeBlockDrawer}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <hr style={webStyle.horizontalLineDrawer} />
        <Box
          style={{
            marginTop: "15px",
            color: "#0F172A",
            fontSize: "16px",
            fontFamily: "MyFont",
            fontWeight: 500,
            letterSpacing: "0.05em",
            marginLeft: "10px",
          }}
        >
          Why are you blocking this user?
        </Box>
        <List>
          {configJSON.blockReasons.map((reason: any) => (
            <ListItem key={reason.id}              
              button
              onClick={() => this.handleReasonSelect(reason.title, reason.description)}
              style={{ backgroundColor: reason.title === reasonTitle ? "#D4E5DE" : "#FFF",
              }}
            >
              <ListItemText
                primary={
                  <Typography
                    style={{ fontFamily: "MyFont",
                      fontSize: "16px",  
                      fontWeight: 500,
                      letterSpacing: "0.05em",
                      color: reason.title === reasonTitle ? "#14362E" : "#0F172A", }}
                  >
                    {reason.title}
                  </Typography>
                }
                secondary={
                  <Box style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography
                      style={{ fontWeight: 500,
                        fontSize: "14px",
                        fontFamily: "MyFont", 
                        color: reason.title === reasonTitle ? "#14362E" : "#0F172A",
                        letterSpacing: "0.05em",
                      }}
                    >
                      {reason.description}
                    </Typography>
                    {reason.title === reasonTitle && (
                      <Avatar src={checkCircle} style={{ width: "5%", height: "5%" }}  />
                    )}
                  </Box>
                } />
            </ListItem>
          ))}
        </List>
        <Box
          style={{
            width: "428px",
            height: "56px",
            position: "relative",
            bottom: 0,
            padding: "0 30px",
          }}
        >
          <Button
            variant="contained"
            fullWidth
            style={{
              backgroundColor: isReasonSelected ? "#14362E" : "#D4E5DE",
              borderRadius: "8px",
              height: "45px",
              color: isReasonSelected ? "white" : "#14362E",
              fontWeight: 700,
              fontFamily: "MyFont",
              letterSpacing: "1px",
              fontSize: "16px",
              textTransform: "capitalize",
            }}
            data-test-id={"continueBtn"}
            onClick={isReasonSelected ? this.handleContinueBlock : undefined}
            disabled={!isReasonSelected}
          >
            Continue
          </Button>
        </Box>
      </Drawer>
    );
  };

  blockUserConfirmationModal = () => {
    const { continueBlockUserModal, connectionToBlock } = this.state;
    if (!connectionToBlock) return null;
    return (
      <Modal open={continueBlockUserModal} onClose={this.handleCloseContinueBlockModal}>
        <Paper
          style={{ ...webStyle.continueBlockModal, position: "absolute", borderBottomRightRadius:"20px" }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign:"center",
            }}
          >
            <Typography variant="h6" style={webStyle.modalTitle}>
              Block User
            </Typography>
            <Typography style={webStyle.continueModalDescription}>
              We understand that sometimes you need to remove certain people from your circle, that's ok! If it's the right thing for you, then it's the right thing to do! And don't worry, we will keep this between us, they won't know you have blocked them.
            </Typography>
          </Box>

          <Box style={webStyle.continueBlockModalBox}>
            <Button fullWidth
              variant="contained"
              style={{
                ...webStyle.cancelBtnContinueModal,
                textTransform: "capitalize",
              }}
              data-test-id="cancel-btn-blockModal"
              onClick={this.handleCloseContinueBlockModal}
            >
              Cancel
            </Button>
            <Button fullWidth
              data-test-id="confirm-btn-blockModal"
              variant="outlined"
              style={{
                ...webStyle.confirmButton,
                textTransform: "capitalize",
              }}
              onClick={() => this.handleBlockUser(connectionToBlock.id)}
            >
              Confirm
            </Button>
          </Box>
        </Paper>
      </Modal>
    );
  };
  
  requestsViewWeb = () => {
    return (
      <Box style={webStyle.viewsBox}>
        <Box style={webStyle.searchBox}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search"
            value={this.state.searchQuery}
            onChange={this.handleSearchChange}
            style={{ margin: "20px 0" }}
            InputProps={{
              style: {
                borderRadius: "8px",
                marginTop: "5px",
                fontFamily: "'MyFont', 'Halyard Display'",
              },
              endAdornment: this.state.searchQuery && (
                <InputAdornment position="end">
                  <IconButton onClick={this.clearSearch}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <List>
          {this.state.requests.map((item: any, index: number) => {
              const sender = item.attributes.sender;
              const searchQuery = this.state.searchQuery.toLowerCase();
              const requestorName = sender.first_name.toLowerCase();
              if (!searchQuery || requestorName.includes(searchQuery)) {
              return (
                <React.Fragment key={index}>
                  <ListItem
                    style={{ display: "flex", alignItems: "flex-start" }}
                  >
                    <ListItemAvatar>
                    <a href={`UserProfileBasicBlock/${item.attributes.sender_id}`}>
                      <Avatar src={sender.profile_picture.url}>
                        <span style={webStyle.usernameFirstLetter}>
                          {sender.first_name.charAt(0)}
                        </span>
                      </Avatar>
                    </a>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                      <a href={`UserProfileBasicBlock/${item.attributes.sender_id}`}
                      style={{textDecoration: "none"}}>
                        <span style={webStyle.userNameStyle}>
                          {sender.first_name}
                          {sender.last_name}
                        </span>
                      </a>
                      }
                      secondary={
                        <>
                          <Typography variant="body2" style={webStyle.position}>
                            {sender.role}
                          </Typography>
                          <Typography
                            variant="body2"
                            style={webStyle.mutualFriends}
                          >
                            {sender.mutual_friends.count} Mutual Friends
                          </Typography>
                        </>
                      }
                    />
                     <Toaster
                        position="top-center"
                        reverseOrder={false}
                      />
                    <Button
                      onClick={() => this.acceptUsersRequest(item.id)}
                      variant="contained"
                      style={{
                        ...webStyle.acceptBtn,
                        textTransform: "capitalize",
                      }}
                    >
                      Accept
                    </Button>
                    <Button
                      variant="outlined"
                      style={{
                        ...webStyle.cancelBtn,
                        textTransform: "capitalize",
                      }}
                      onClick={() => this.declineUsersRequest(item.id)}
                    >
                      Decline
                    </Button>
                  </ListItem>
                  </React.Fragment>
              );
            } else {
              return null;
            }
          })}
        </List>
      </Box>
    );
  };

  invitationsViewWeb = () => {
    const { cancelInvite } = this.state
    return (
      <Box style={webStyle.viewsBox}>
        <Box style={webStyle.searchBox}>
          <TextField placeholder="Search"           
            variant="outlined"     
            fullWidth     
            value={this.state.searchQuery}
            onChange={this.handleSearchChange}
            style={{ margin: "20px 0" }}
            InputProps={{
              style: { fontFamily: "'MyFont', 'Halyard Display'",
                borderRadius: "8px",
                marginTop: "5px",  },
              endAdornment: this.state.searchQuery && (
                <InputAdornment position="end">
                  <IconButton onClick={this.clearSearch}>
                    <CloseIcon />
                  </IconButton> </InputAdornment>              
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>  <SearchIcon />               
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Toaster
            position="top-center"
            reverseOrder={false}
          />
        <List>
        {this.state.invitations.map((item: any, index: number) => {
            const searchQuery = this.state.searchQuery.toLowerCase();
            const invitationName = item.first_name?.toLowerCase();
            if (!searchQuery || invitationName.includes(searchQuery)) {
              return (
              <React.Fragment key={index}>
                <ListItem
                  style={{ display: "flex", alignItems: "flex-start" }}
                >
                  <ListItemAvatar>
                  <a data-test-id="profilebtn" href={`UserProfileBasicBlock/${item.id}`}>
                    <Avatar  src={item.profile_picture?.url} style={{marginTop:"12px"}}>
                      <span style={webStyle.usernameFirstLetter}>
                        {item.first_name?.charAt(0)} </span>
                    </Avatar>
                    </a>
                  </ListItemAvatar>
                  <ListItemText primary={
                    <a href={`UserProfileBasicBlock/${item.id}`} 
                    style={{textDecoration:"none"}}>
                      <span style={webStyle.userNameStyle}>
                        {item.first_name} {item.last_name}
                      </span>
                      </a> }
                    secondary={
                      <>
                        <Typography style={webStyle.position} variant="body2" >
                          {item.role}
                        </Typography>
                        <Typography style={webStyle.mutualFriends} variant="body2">
                          {item.mutual_friends?.count} Mutual Friends
                        </Typography>
                      </>
                    }
                  />
                  <Button
                    variant="contained"
                    style={{
                      ...webStyle.invitedBtn,
                      textTransform: "capitalize",
                      marginTop:"10px",
                    }}
                    aria-controls={`menu-invited-${index}`}
                    aria-haspopup="true"
                    onClick={(event) => this.handleInviteClick(event, index, item)}
                  >
                    Invited
                    {this.state.selectedIndex === index ? (
                      <ExpandMore />
                      
                    ) : (
                      <ExpandLess />
                    )}
                  </Button>
                  <ClickAwayListener onClickAway={this.handleClickAway}>
                  <Menu
                    id={`menu-invited-${index}`}
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={this.state.selectedIndex === index}
                    onClose={this.handleInviteClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    getContentAnchorEl={null}
                  >
                    <MenuItem
                      onClick={() => {
                        this.handleInviteClose();
                        this.handleRemoveUser(cancelInvite.connection_id, 'cancelInvite')
                      }}
                      style={{...webStyle.cancelInviteStyle, padding: 0}}  
                    >
                      Cancel Invitation
                    </MenuItem>
                  </Menu>
                  </ClickAwayListener>
                  
                </ListItem>
              </React.Fragment>
              );
            } else {
              return null;
            }
          })}
        </List>
      </Box>
    );
  };

  blockedViewWeb = () => {
    return (
      <Box style={webStyle.viewsBox}>
        <Box style={webStyle.searchBox}>
          <TextField placeholder="Search"
            fullWidth
            variant="outlined" 
            style={{ margin: "20px 0" }}           
            value={this.state.searchQuery}
            onChange={this.handleSearchChange}          
            InputProps={{ style: { marginTop: "5px",
                borderRadius: "8px",   
                fontFamily: "'MyFont', 'Halyard Display'",
              },
              endAdornment: this.state.searchQuery && (
                <InputAdornment position="end">
                  <IconButton onClick={this.clearSearch}>
                    <CloseIcon /> </IconButton>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <SearchIcon />  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <List>
        {this.state.blockedUsers.map((item: any, index: number) => {
            const searchQuery = this.state.searchQuery.toLowerCase();
            const blockerName = item.first_name.toLowerCase();
            if (!searchQuery || blockerName.includes(searchQuery)) {
              return (
              <React.Fragment key={index}>
            <ListItem
              style={{ display: "flex", alignItems: "flex-start" }}
            >
              <ListItemAvatar>
              <a href={`UserProfileBasicBlock/${item.id}`}>
                <Avatar style={{marginTop:"12px"}} src={item.profile_picture.url}>
                  <span style={webStyle.usernameFirstLetter}>
                    {item.first_name.charAt(0)}
                  </span>
                </Avatar>
                </a>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <a href={`UserProfileBasicBlock/${item.id}`}
                  style={{textDecoration: "none"}}>
                  <span style={webStyle.userNameStyle}>{item.first_name} {item.last_name}</span>
                  </a>
                }
                secondary={
                  <>
                    <Typography variant="body2" style={webStyle.position}>
                      {item.role}
                    </Typography>
                    <Typography variant="body2" style={webStyle.mutualFriends}>
                      {item.mutual_friends.count} Mutual Friends
                    </Typography>
                  </>
                }
              />
              <Button
                variant="outlined"
                style={{ ...webStyle.unblockBtn, marginTop:"10px", textTransform: "capitalize" }}
                onClick={() => this.handleUnblockUser(item.id)}
              >
                <img src={unblockImg} style={{ marginRight: "6px" }} />
                Unblock
              </Button>
            </ListItem>
            </React.Fragment>
              );
            } else {
              return null;
            }
          })}
        </List>
      </Box>
    );
  };

  emptyCircleForWeb = () => {
    return (
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "10%",
          marginRight: "10%",
          marginTop: "5%",
        }}
      >
        <Box>
          <img width={100} src={groupImg}></img>
        </Box>
        <Typography
          style={{
            color: "#14362E",
            fontSize: "28px",
            fontWeight: 700,
            fontFamily: "'MyFont', 'Halyard Display'",
            marginTop: "20px",
            letterSpacing: "0.05em",
            textAlign: "center",
          }}
        >
          Build Your Circle
        </Typography>
        <Typography
          style={{
            color: "#334155",
            fontSize: "14px",
            fontFamily: "'MyFont', 'Halyard Display'",
            fontWeight: 500,
            letterSpacing: "0.05em",
            textAlign: "center",
            padding: "15px",
          }}
        >
          It looks like you haven't added anyone to your Circle yet. It may be
          that you haven't had the time or you are just getting to know Kella
          but we would encourage you to start building your inner circle, it
          really is a great resource and an important part of the Kella
          experience.
        </Typography>
        <Button
          variant="contained"
          style={{
            backgroundColor: "#14362E",
            color: "#ffffff",
            borderRadius: "7px",
            fontWeight: 500,
            fontSize: "16px",
            fontFamily: "'MyFont', 'Halyard Display'",
            textTransform: "capitalize",
            marginBottom: "20px",
            marginTop: "20px",
            width: "60%",
          }}
        >
          Get Help to Build My Circle
        </Button>
      </Box>
    );
  };

  myCircleForMobile = () => {
    return (
      <Grid item
        xs={12}
        style={{ marginTop: "5%",
          position: "relative",
          borderRadius: "8px",
          backgroundColor: "#ffffff", }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" >
          <IconButton href="/UserProfileBasicBlock" style={{ marginRight: "auto" }}>
            <ArrowBack />
          </IconButton>
          <Typography  style={{ left: "50%",
              position: "absolute",  
              transform: "translateX(-50%)",
              color: "#14362E", fontSize: "20px",    
              fontFamily: "'MyFont', 'Halyard Display'",         
              fontWeight: 700, letterSpacing: "0.05em",             
            }}
          >
            My Circle
          </Typography>
        </Box>
        <hr style={mobileStyle.horizontalLineMobile} />
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box style={{  ...mobileStyle.parentOfMainButtonsMobile,
              margin: "6%", width: "85%",
            }}
          >
            <Button data-test-id="connectionButtonMobile"
              style={this.getButtonStyleMobile("connections")}
              onClick={() => this.setView("connections")}
            >
              Connections
            </Button>
            <Button data-test-id="requestButtonMobile"
              onClick={() => this.setView("requests")}
              style={this.getButtonStyleMobile("requests")} 
            >
              Requests
            </Button>
            <Button
              data-test-id="invitationButtonMobile"
              style={this.getButtonStyleMobile("invitations")}
              onClick={() => this.setView("invitations")}
            >
              Invitations
            </Button>
            <Button data-test-id="blockedButtonMobile"
              style={this.getButtonStyleMobile("blocked")}
              onClick={() => this.setView("blocked")} >
              Blocked
            </Button>
          </Box>
        </Box>
        <Box>
          {this.state.currentView === "connections" && (
            <div className="connections-view">
              {this.connectionsViewMobile()} </div> )}
          {this.state.currentView === "requests" && (
            <div className="requests-view">{this.requestsViewMobile()}</div> )}
          {this.state.currentView === "invitations" && (
            <div className="invitations-view">{this.invitationsViewWeb()}</div> )}
          {this.state.currentView === "blocked" && (
            <div className="blocked-view">{this.blockedViewWeb()}</div> )}
        </Box>
      </Grid>
    );
  };

  connectionsViewMobile = () => {
    return (
      <Box>
        <Box  style={{ marginRight: "5%", marginLeft: "5%", }}>
          <TextField fullWidth variant="outlined"
            placeholder="Search"
            data-test-id="searchField"
            value={this.state.searchQuery}
            onChange={this.handleSearchChange}
            InputProps={{
              style: {  borderRadius: "8px", marginTop: "5px",
                fontFamily: "'MyFont', 'Halyard Display'",
              },
              endAdornment: this.state.searchQuery && (
                <InputAdornment position="end">  <IconButton onClick={this.clearSearch}>
                    <CloseIcon /> </IconButton>
                </InputAdornment> ),
              startAdornment: ( <InputAdornment position="start">
                  <IconButton> <SearchIcon /> </IconButton>
                </InputAdornment> ),
            }}
          />
        </Box>
        <List>
        {this.state.connections.map((item: any, index: number) => {
            const searchQuery = this.state.searchQuery?.toLowerCase();
            const connectionName = item.first_name?.toLowerCase();
            if (!searchQuery || connectionName.includes(searchQuery)) {
              const duration = this.formatDuration(item.created_at);
              return (
            <React.Fragment key={index}>
            <ListItem  key={index}
              style={{  display: "flex", alignItems: "flex-start",
                fontFamily: "'MyFont', 'Halyard Display'", }}
            >
              <ListItemAvatar>
              <a href={`UserProfileBasicBlock/${item.id}`}>
                <Avatar style={{marginTop:"12px"}} src={item.profile_picture?.url}>
                  <span style={webStyle.usernameFirstLetter}>
                    {item.first_name?.charAt(0)}
                  </span>
                </Avatar>
              </a>
              </ListItemAvatar>
              <ListItemText
                primary={ 
                  <a href={`UserProfileBasicBlock/${item.id}`} style={{textDecoration:"none"}}>
                  <span style={webStyle.userNameStyle}>{item.first_name} {item.last_name}</span>
                  </a>
                }
                secondary={ <>
                    <Typography variant="body2" style={webStyle.position}>
                      {item.role} </Typography>
                    <Typography variant="body2" style={webStyle.mutualFriends}>
                      {item.mutual_friends?.count} Mutual Friends </Typography>
                  </>
                }
                style={{ fontFamily: "'MyFont', 'Halyard Display'" }}
              />
              <Typography variant="body2"
                color="textSecondary" style={webStyle.duration}
              >
                {duration}
              </Typography>
              <IconButton edge="start"
                aria-label="more"  aria-controls={`menu-${index}`}
                aria-haspopup="true" onClick={(event) => this.handleClick(event, index)} >
                <MoreVertIcon />
              </IconButton>
              <Menu id={`menu-${index}`}   keepMounted
                anchorEl={this.state.anchorEl}            
                open={this.state.selectedIndex === index}
                onClose={this.handleClose} 
                >
                <MenuItem onClick={this.handleClose}
                  style={webStyle.menuItemConnections} >
                  <img src={msgImg}  onClick={this.redirectToChat.bind(this,item)}  style={{ marginRight: 10 }}></img>
                  Message
                </MenuItem>
                <MenuItem style={webStyle.menuItemConnections}
                  onClick={() => { this.handleClose();
                    this.handleOpenModal(item);
                  }} >
                  <img src={contactImg} style={{ marginRight: 10 }}></img>
                  Remove from My Circle
                </MenuItem>
                <MenuItem  onClick={() => this.handleBlockDrawer(item)}
                  style={webStyle.menuItemBlocked}
                >
                  <img src={blockImg} style={{ marginRight: 10 }}></img>
                  Block User
                </MenuItem>
              </Menu>
            </ListItem>
            {this.removeUserDrawerMobile()}
            {this.blockUserDrawerMobile()}
            {this.blockUserConfirmationDrawer()}
            </React.Fragment>
              );
            } else {
              return null;
            }
          })}
        </List>
      </Box>
    );
  };

  removeUserDrawerMobile = () => {
    const { isModalOpen, connectionToRemove } = this.state;
    if (!connectionToRemove) return null;
    return (
      <Drawer anchor="bottom"
        open={isModalOpen}
        onClose={this.handleCloseModal}
        PaperProps={{
          style: { borderTopRightRadius: "20px", },
        }} >
        <Box
          style={{ display: "flex",
            justifyContent: "space-between",
            alignItems: "center", width: "100%",          
            borderBottom: "1.04px solid #CBD5E1",
          }} >
          <Box style={{ display: "flex", padding: "4% 0 0 4%" }}>
            <Typography
              style={{ fontSize: "20px",
                fontWeight: 600, color: "#14362E",
                fontFamily: "MyFont", letterSpacing: "1px",
              }} >
              Remove from my circle
            </Typography>
          </Box>
          <Box>
            <IconButton onClick={this.closeBlockDrawer} >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box style={{ display: "flex", marginTop: "30px", marginLeft: "15px" }}>
          <Avatar style={{ marginTop: "5px" }}
            src={connectionToRemove.profile_picture.url}></Avatar>
          <Box style={{ display: "flex",
              flexDirection: "column",
              marginLeft: "15px", }} >
            <Typography style={webStyle.modalUserName}>
              {connectionToRemove.first_name} {connectionToRemove.last_name}
            </Typography>
            <Typography style={webStyle.modalPosition}>
              {connectionToRemove.role}
            </Typography>
          </Box>
        </Box>
        <Typography variant="body1" style={mobileStyle.removeDrawerContent}>
          Do you really want to remove {connectionToRemove.first_name} from your
          circle?
        </Typography>

        <Box style={mobileStyle.drawerActionsMobile}>
          <Button variant="contained"
            style={{ ...mobileStyle.cancelButtonDrawer,
                textTransform: "capitalize", }}
            onClick={this.handleCloseModal}
          >
            Cancel
          </Button>
          <Button variant="outlined"
            style={{ ...mobileStyle.removeButtonDrawer,
              textTransform: "capitalize", }}
              onClick={() => this.handleRemoveUser(connectionToRemove.connection_id, 'removeFromCircle')} >
            Remove
          </Button>
        </Box>
      </Drawer>
    );
  };

  blockUserDrawerMobile = () => {
    const { isDrawerOpen, connectionToBlock, reasonTitle } = this.state;
    if (!connectionToBlock) return null;
  
    const isReasonSelected = !!reasonTitle; 
    return (
      <Drawer anchor="bottom"
        open={isDrawerOpen}
        onClose={this.closeBlockDrawer}
        PaperProps={{ 
          style: { borderTopLeftRadius: "20px" },
        }} >
        <Box style={{ display: "flex", 
            justifyContent: "space-between",
            alignItems: "center", width: "100%",        
            borderBottom: "1.04px solid #CBD5E1",}}
        >
          <Box style={{ display: "flex", padding: "4% 0 0 4%" }}>
            <Typography  style={{ color: "#14362E",
                fontSize: "24px", fontFamily: "MyFont",
                fontWeight: 600, letterSpacing: "1px", }}
            >
              Block {connectionToBlock.first_name} {connectionToBlock.last_name}
            </Typography>
          </Box>
          <Box>
            <IconButton style={{ right: "10px", margin: "15px 5px" }}
              onClick={this.closeBlockDrawer}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <hr style={webStyle.horizontalLineDrawer} />
        <Box style={{          
            color: "#0F172A", marginTop: "15px",           
            fontSize: "16px", fontFamily: "MyFont",            
            fontWeight: 500, letterSpacing: "0.05em",          
            marginLeft: "4%", marginRight: "3%", }}
        >
          Why are you blocking this user?
        </Box>
        <List>
          {configJSON.blockReasons.map((reason: any) => (
            <ListItem
              key={reason.id}
              button
              onClick={() => this.handleReasonSelect(reason.title, reason.description)}
              style={{
                backgroundColor: reason.title === reasonTitle ? "#D4E5DE" : "#FFF",
                margin: "10px",
                borderRadius: "8px",
              }}
            >
              <ListItemText
                primary={
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontFamily: "MyFont",
                      fontWeight: 500,
                      letterSpacing: "0.05em",
                      color: reason.title === reasonTitle ? "#14362E" : "#0F172A",
                    }}
                  >
                    {reason.title}
                  </Typography>
                }
                secondary={
                  <Box style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontFamily: "MyFont",
                        fontWeight: 500,
                        letterSpacing: "0.05em",
                        color: reason.title === reasonTitle ? "#14362E" : "#0F172A",
                      }}
                    >
                      {reason.description}
                    </Typography>
                    {reason.title === reasonTitle && (
                      <Avatar style={{ width: "5%", height: "5%" }} src={checkCircle} />
                    )}
                  </Box>
                }
              />
            </ListItem>
          ))}
        </List>
        <Box
          style={{ 
            paddingLeft:"30px",
            paddingRight:"30px",
            marginTop: "10px",
            marginBottom: "10px", }}
        >
          <Button variant="contained" fullWidth
            style={{  color: isReasonSelected ? "white" : "#14362E",
              backgroundColor: isReasonSelected ? "#14362E" : "#D4E5DE",
              borderRadius: "8px", height: "45px",            
              fontWeight: 700, fontFamily: "MyFont",
              letterSpacing: "1px", fontSize: "16px",             
              textTransform: "capitalize", position: "relative",}}
            data-test-id={"continueBtn"}
            onClick={isReasonSelected ? this.handleContinueBlockMobile : undefined}
            disabled={!isReasonSelected}
          >
            Continue
          </Button>
        </Box>
      </Drawer>
    );
  };

  blockUserConfirmationDrawer = () => {
    const { continueBlockDrawer, connectionToBlock } = this.state;
    if (!connectionToBlock) return null;
    return (
      <Drawer anchor="bottom"
        open={continueBlockDrawer}
        onClose={this.handleCloseContinueBlockDrawer}
        PaperProps={{ 
          style: { borderTopLeftRadius: "20px" },
        }} >
            <Box style={{ display: "flex", 
              justifyContent: "space-between",
              alignItems: "center", width: "100%",        
              borderBottom: "1.04px solid #CBD5E1",}}
            >
              <Box style={{ display: "flex", padding: "4% 0 0 4%" }}>
                <Typography  style={{ color: "#14362E",
                    fontSize: "24px", fontFamily: "MyFont",
                    fontWeight: 600, letterSpacing: "1px", }}
                >
                  Block User
                </Typography>
              </Box>
              <Box>
                <IconButton style={{ right: "10px", margin: "15px 5px" }}
                  onClick={this.handleCloseContinueBlockDrawer}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <Box style={{padding:"10px", alignItems:"center", }}>
              <Typography style={webStyle.continueModalDescription}>
                We understand that sometimes you need to remove certain people from your circle, that's ok! If it's the right thing for you, then it's the right thing to do! And don't worry, we will keep this between us, they won't know you have blocked them.
              </Typography>
            </Box>
            <Box style={{...webStyle.continueBlockModalBox, paddingLeft:"12px", paddingRight:"12px", marginBottom:"10px"}}>
              <Button fullWidth
                variant="contained"
                style={{
                  ...webStyle.cancelBtnContinueModal,
                  textTransform: "capitalize",
                }}
                onClick={this.handleCloseContinueBlockModal}
              >
                Cancel
              </Button>
              <Button fullWidth
                variant="outlined"
                style={{
                  ...webStyle.confirmButton,
                  textTransform: "capitalize",
                }}
                onClick={() => this.handleBlockUser(connectionToBlock.id)}
              >
                Confirm
              </Button>
            </Box>
      </Drawer>
    );
  }

  requestsViewMobile = () => {
    return (
      <Box>
        <Box style={{ marginRight: "5%", marginLeft: "5%", }}>
          <TextField fullWidth
            variant="outlined"          
            value={this.state.searchQuery}
            onChange={this.handleSearchChange}
            placeholder="Search"
            style={{ margin: "20px 0" }}
            InputProps={{
              style: { marginTop: "5px",
                borderRadius: "8px",               
                fontFamily: "'MyFont', 'Halyard Display'",
              },
              endAdornment: this.state.searchQuery && (
                <InputAdornment position="end">
                  <IconButton onClick={this.clearSearch}> <CloseIcon /> </IconButton>
                </InputAdornment>  ),
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton> <SearchIcon /> </IconButton>
                </InputAdornment> ),
            }}
          />
        </Box> 
        <List>
           {this.state.requests.map((item: any, index: number) => {
              const sender = item.attributes.sender;
              const searchQuery = this.state.searchQuery.toLowerCase();
              const requestorName = sender.first_name.toLowerCase();
              if (!searchQuery || requestorName.includes(searchQuery)) {
              return (
                <React.Fragment key={index}>
                  <ListItem>
                    <Box style={{ display: "flex",
                        flexDirection: "column",
                        width: "100%", }} >
                      <Box style={{ display: "flex", alignItems: "flex-start" }}>
                        <ListItemAvatar>
                        <a href={`UserProfileBasicBlock/${item.attributes.sender_id}`}>
                          <Avatar src={sender.profile_picture.url}>
                            <span style={webStyle.usernameFirstLetter}>
                              {sender.first_name.charAt(0)}
                            </span>  </Avatar>
                        </a>
                        </ListItemAvatar>
                        <ListItemText
                          primary={ 
                          <a href={`UserProfileBasicBlock/${item.attributes.sender_id}`}>
                          <span style={webStyle.userNameStyle}>{sender.first_name} {sender.last_name}</span>
                          </a>
                          }
                          secondary={ <>                    
                              <Typography variant="body2" style={webStyle.position}>
                                {sender.role}
                              </Typography>
                              <Typography variant="body2"                         
                                style={webStyle.mutualFriends} >
                                {sender.mutual_friends.count} Mutual Friends
                              </Typography>
                            </> }
                        />
                      </Box>
                      <Box style={{ display: "flex",
                          width: "100%", marginTop: "6px",                   
                          marginLeft: "55px", }} >
                        <Button variant="contained"                  
                          style={{ ...mobileStyle.acceptBtnMobile,
                            textTransform: "capitalize",
                          }} 
                          onClick={() => this.acceptUsersRequest(item.id)} >
                          Accept
                        </Button>
                        <Button variant="outlined"
                          style={{ ...mobileStyle.cancelBtnMobile,
                            textTransform: "capitalize",
                          }} 
                          onClick={() => this.declineUsersRequest(item.id)} >
                          Decline
                        </Button>
                      </Box>
                    </Box>
                  </ListItem>
                </React.Fragment>
              );
            } else {
              return null;
            }
          })}
        </List>
      </Box>
    );
  };

  emptyCircleMobile = () => {
    return (
      <Box
        style={{ display: "flex", flexDirection: "column",
          justifyContent: "center", alignItems: "center",
          padding: "6%", marginTop: "6%", }}>
        <Box> <img width={100} src={groupImg}></img> </Box>
        <Typography
          style={{ color: "#14362E", fontSize: "24px",
            fontWeight: 700, fontFamily: "'MyFont', 'Halyard Display'",
            marginTop: "20px", letterSpacing: "0.05em",
            textAlign: "center",
          }} >
          Build Your Circle
        </Typography>
        <Typography
          style={{ color: "#334155", textAlign: "center",
            fontSize: "14px", letterSpacing: "0.05em",
            fontWeight: 500, marginTop: "15px",
            fontFamily: "'MyFont', 'Halyard Display'",           
          }} >
          It looks like you haven't added anyone to your Circle yet. It may be
          that you haven't had the time or you are just getting to know Kella
          but we would encourage you to start building your inner circle, it
          really is a great resource and an important part of the Kella
          experience.
        </Typography>
        <Button variant="contained"
          style={{  color: "#ffffff", borderRadius: "7px",
            backgroundColor: "#14362E",  
            fontWeight: 500, fontSize: "16px",           
            fontFamily: "'MyFont', 'Halyard Display'",
            textTransform: "capitalize",
            marginTop: "20px", width: "100%", }}
        >
          Get Help to Build My Circle
        </Button>
      </Box>
    );
  };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={false}>
          <Hidden smDown>{this.myCircleForWeb()}</Hidden>
          <Hidden mdUp>{this.myCircleForMobile()}</Hidden>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  outerBox: {
    width: "100%",
    height: "100vh",
    minHeight: "100vh",
    backgroundColor: "#f2fff2",
    // backgroundColor: "pink",
  },
  horizontalLine: {
    border: "none",
    borderBottom: "1px solid #CBD5E1",
    margin: "0",
  },
  parentOfMainButtons: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px",
    marginBottom: "20px",
    width: "83%",
    backgroundColor: "#f2fff2",
    borderRadius: "20px",
  },
  viewsBox: {
    marginLeft: "5%",
    marginRight: "5%",
  },
  searchBox: {
    marginLeft: "3%",
    marginRight: "3%",
  },
  usernameFirstLetter: {
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  userNameStyle: {
    color: "#0F172A",
    letterSpacing: "0.05em",
    fontSize: "16px",
    fontWeight: "bolder",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  position: {
    color: "#64748B",
    fontSize: "12px",
    letterSpacing: "0.05em",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  mutualFriends: {
    letterSpacing: "0.05em",
    color: "#73A58E",
    fontSize: "12px",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  duration: {
    fontSize: "12px",
    letterSpacing: "0.05em",
    color: "#64748B",
    marginTop: "15px",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  menuItemConnections: {
    color: "#0F172A",
    fontSize: "16px",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  menuItemBlocked: {
    color: "#D1584C",
    fontSize: "16px",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  acceptBtn: {
    marginRight: 10,
    backgroundColor: "#14362E",
    color: "#ffffff",
    borderRadius: "7px",
    fontWeight: 500,
    fontSize: "16px",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  cancelBtn: {
    borderRadius: "7px",
    borderColor: "73A58E",
    color: "#14362E",
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  invitedBtn: {
    backgroundColor: "#D4E5DE",
    color: "#14362E",
    borderRadius: "7px",
    fontWeight: 500,
    fontSize: "16px",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  cancelInviteStyle: {
    fontFamily: "'MyFont', 'Halyard Display'",
    top:"20%"
  },
  unblockBtn: {
    color: "#14362E",
    fontSize: "16px",
    borderColor: "#14362E",
    borderRadius: "7px",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  nested: {
    paddingLeft: "",
  },
  removeFromMyCircleModal: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "white",
    padding: 20,
    borderRadius: 8,
  },
  modalTitle: {
    marginBottom: 15,
    fontFamily: "'MyFont', 'Halyard Display'",
    color: "#14362E",
    fontSize: "24px",
    fontWeight: 500,
    letterSpacing: "0.05em",
  },
  modalContent: {
    marginTop: 20,
    marginBottom: 20,
    color: "#0F172A",
    fontSize: "16px",
    fontWeight: 500,
    letterSpacing: "0.05em",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  modalActions: {
    display: "flex",
    justifyContent: "center",
    gap: 8,
  },
  cancelButton: {
    backgroundColor: "#14362E",
    color: "white",
    borderRadius: "7px",
    letterSpacing: "0.05em",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  removeButton: {
    backgroundColor: "white",
    borderRadius: "7px",
    color: "#14362E",
    borderColor: "#14362E",
    letterSpacing: "0.05em",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  modalUserName: {
    color: "#0F172A",
    letterSpacing: "0.05em",
    fontSize: "16px",
    fontWeight: "bolder",
    marginTop: "5px",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  modalPosition: {
    color: "#64748B",
    fontSize: "12px",
    letterSpacing: "0.08em",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  horizontalLineDrawer: {
    border: "none",
    borderBottom: "0.5px solid #CBD5E1",
    margin: "0",
  },
  continueBlockModal: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    backgroundColor: "white",
    padding: 20,
    borderRadius: 8,
  },
  continueModalDescription: {
    color: "#0F172A",
    fontSize: "16px",
    letterSpacing: "0.05em",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  continueBlockModalBox: {
    display: "flex",
    justifyContent: "space-between",
    gap: 10,
    marginTop:"20px"
  },
  cancelBtnContinueModal: {
    backgroundColor: "#14362E",
    color: "white",
    borderRadius: "7px",
    letterSpacing: "0.05em",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  confirmButton: {
    backgroundColor: "white",
    borderRadius: "7px",
    color: "#14362E",
    borderColor: "#14362E",
    letterSpacing: "0.05em",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
};

const mobileStyle = {
  parentOfMainButtonsMobile: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
    marginBottom: "20px",
    backgroundColor: "#f2fff2",
    borderRadius: "20px",
  },
  horizontalLineMobile: {
    border: "none",
    borderBottom: "1px solid #CBD5E1",
    width: "100%",
    margin: "0",
  },
  removeDrawerContent: {
    color: "#0F172A",
    fontSize: "16px",
    fontWeight: 500,
    letterSpacing: "0.05em",
    fontFamily: "'MyFont', 'Halyard Display'",
    marginTop: "25px",
    marginLeft: "20px",
    marginRight: "8px",
  },
  drawerActionsMobile: {
    display: "flex",
    justifyContent: "center",
    margin: "20px",
    gap: 8,
  },
  cancelButtonDrawer: {
    backgroundColor: "#14362E",
    color: "white",
    borderRadius: "7px",
    letterSpacing: "0.05em",
    fontFamily: "'MyFont', 'Halyard Display'",
    width: "50%",
  },
  removeButtonDrawer: {
    backgroundColor: "white",
    borderRadius: "7px",
    color: "#14362E",
    borderColor: "#14362E",
    letterSpacing: "0.05em",
    fontFamily: "'MyFont', 'Halyard Display'",
    width: "50%",
  },
  acceptBtnMobile: {
    marginRight: 10,
    backgroundColor: "#14362E",
    color: "#ffffff",
    borderRadius: "7px",
    fontWeight: 500,
    fontSize: "16px",
    fontFamily: "'MyFont', 'Halyard Display'",
    width: "38%",
    height: "32px",
  },
  cancelBtnMobile: {
    borderRadius: "7px",
    borderColor: "73A58E",
    color: "#14362E",
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "'MyFont', 'Halyard Display'",
    width: "38%",
    height: "32px",
  },
  invitedBtnMobile: {
    backgroundColor: "#D4E5DE",
    color: "#14362E",
    borderRadius: "7px",
    fontWeight: 500,
    fontSize: "16px",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
};
// Customizable Area End
