//@ts-nocheck
import React from "react";

// Customizable Area Start
import { CardHeader, Avatar, CardContent,Card, Typography, Box, MenuItem, Menu, IconButton, styled, Drawer, Container, Button, Modal, Divider, InputAdornment, List, ListItem, Collapse, TextField, StyledComponentProps } from "@material-ui/core";
import TrendingProductDetailsController, { Props } from "./TrendingProductDetailsController.web";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { LinkImg } from "./assets";
import { Mention, MentionsInput, MentionsInputProps, SuggestionDataItem } from "react-mentions";
import CloseIcon from "@material-ui/icons/Close";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Toaster } from 'react-hot-toast';
import moment from "moment";
import Carousel from "react-multi-carousel";
import {
  MoreVert as MoreVertIcon,
} from "@material-ui/icons";
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import ReportIcon from '@material-ui/icons/Report';
import BlockIcon from '@material-ui/icons/Block';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';

import EmojiPicker from "emoji-picker-react";
  //istanbul ignore next
// Customizable Area End

export default class TrendingProductDetails extends TrendingProductDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.redirectprevios = this.redirectprevios.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start

  formSubmittedSuccessfully = () => {
    return (
      <Modal
        data-test-id="testmodalclose"
        open={this.state.successModalOpen}
        onClose={() => this.handleCloseSuccessModal()}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box sx={{ ...webStyle.modalStyle, borderRadius: "0 10px 0 0" }}>
          <CheckCircleOutlineOutlinedIcon style={webStyle.checkCircleCss} />
          <Box sx={{ ...webStyle.modalStyleTypo }}>
            Report Added
          </Box>
          <Box sx={{ ...webStyle.modalStyleTypoSecond }}>
            Thank you for added report for  harmful content. Our moderation team will be looking into it
          </Box>
          <Button
            data-test-id="closeReportPop"
            fullWidth
            variant="contained"
            style={{
              ...webStyle.contactDrawerSubmitBtn,
              position: "relative",
              textTransform: "capitalize",
              backgroundColor: "#14362E"
            }}
            onClick={this.closeOkayModal}
          >
            Okay
          </Button>
        </Box>
      </Modal>
    );
  }

  blockSuccessfully = () => {
    return (
      <Modal
        open={this.state.openBlockModal}
        onClose={() => this.handleCloseSuccessModal()}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box sx={{ ...webStyle.modalStyle, borderRadius: "0 10px 0 0" }}>
          <Box sx={{ ...webStyle.modalStyleTypo }}>
            User Blocked
          </Box>
          <Box sx={{ ...webStyle.modalStyleTypoSecond }}>
            They won't be able to find your profile or posts. We won't let them know you blocked them.
          </Box>
          <Button
            fullWidth
            variant="contained"
            data-test-id="closeReportPop"
            style={{
              ...webStyle.contactDrawerSubmitBtn,
              position: "relative",
              textTransform: "capitalize",
              backgroundColor: "#14362E"
            }}
            onClick={this.closeOkayModal}
          >
            Understand
          </Button>
        </Box>
      </Modal>
    );
  }
  categoryAlllist() {
    return (
      <>
        {
          this.state.categoryDataset && this.state.categoryDataset.attributes?.sub_categories?.map((element: object, index: number) => {
            return (
              <CardBox key={index} >
                <Box style={webStyle.innercontent}>
                  <Box style={webStyle.inercontentheader}>
                    <Typography style={webStyle.headercontent}>{element.attributes?.name}</Typography>
                    <Typography data-test-id="viewGroupId" style={webStyle.headersecondcontent} onClick={this.handleviewgroup.bind(this, this.state.categoryDataset.id, element.id, element.attributes?.name)}>View Group</Typography>
                  </Box>
                  <Box style={webStyle.innermaincontent}>
                    <Typography style={webStyle.innermaincontenttext}>{element.attributes?.description}</Typography>
                  <Box style={ webStyle.imgflexbox}>
                    {
                      element.attributes && element.attributes.profiles.accounts?.map((secondelement: object, index: number) => {
                            if (index < 4) {
                              return (
                                <img
                                  key={index}
                                  src={secondelement.profile_picture.url}
                                  alt=""
                                  style={webStyle.imgRound}
                                />
                              );
                            } else if (index === 4) {
                              return (
                                <div
                                  key={index}
                                  style={webStyle.roundBox}
                                >
                                  +{element.attributes.profiles.accounts.length - 4}
                                </div>
                              );
                            } else {
                              return null;
                            }
                      })
                    }
                    </Box>
                  </Box>
                </Box>
              </CardBox >)
          })
        }
      </>
    )
  }

  getSendIconStyle = (comment: string) => {
    return {
        borderRadius: '50px',
        background: comment.trim() ? '#73A58E' : '#E0E0E0',
        color: comment.trim() ? '#FFFFFF' : '#9E9E9E',
        padding: '6px 17px',
        margin: '6px',
    };
};
  
  replyRender = (index: number) => {
    return (<Collapse data-test-id="collapse" in={this.state.commentReplyOpen[index]} timeout="auto" unmountOnExit>
      <Box style={{
        padding: "10px", margin: "auto", display: "flex", alignItems: "baseline",
        gap: "15px", justifyContent: "end"
      }}>
        <TextField
          data-test-id="replytext"
          fullWidth
          placeholder="Write a Reply...."
          value={this.state.replyComment}
          onChange={this.handleReplyCommentChange}
          maxRows={4}
          variant="outlined"
          style={{ marginBottom: "10px", width: "50%", height: "48px" }}
          InputProps={{
            style: {
              fontFamily: "MyFont",
              fontSize: "16px",
              fontWeight: "500",
              borderRadius: "50px"
            },
            endAdornment: (
              <InputAdornment position="end">
                <i className="fa-regular fa-face-smile custom-icon" 
                 onClick={this.onClickReplyEmoji.bind(this)}
                style={{ cursor: "pointer", right: "auto"}} 
               >
                </i>
                {this.state.replyShowEmoji && <EmojiPicker
                onEmojiClick={this.ReplyEmojiClick.bind(this)}
                
                className="reply-emoji-picker"
                />}
                <IconButton
                  aria-label="Submit"
                  edge="end"
                  style={{
                    borderRadius: "8px",
                    padding: "0",
                  }}
                  onClick={this.handleReplycomment}
                  disabled={!this.state.replyComment?.trim()}

                >
                  <ArrowUpwardIcon
                    style={{
                      borderRadius: "50px",
                      color: this.state.replyComment?.trim() ? '#FFFFFF' : '#9E9E9E',
                      background: this.state.replyComment?.trim() ? '#73A58E' : '#E0E0E0',
                      padding: "6px 17px",
                      margin: "6px"
                    }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Collapse>)
  }

renderReplycomment = (element: any, index: number) => {
    return (
        element.attributes.replies.slice().reverse().map((reply: any, replyIndex: number) => {
            const commentUser = reply.attributes.comment_user;
            if (!commentUser || !commentUser.first_name || !commentUser.last_name) {
                return null;
            }
            return (
                <Box key={replyIndex} sx={{ pl: 4, display: 'flex', flexDirection: 'column' }}>
                    <Box style={{ display: "flex", fontFamily: "MyFont", justifyContent: "end" }}>
                        <Avatar style={{ margin: "16px" }} src={reply.attributes.comment_user.profile_picture.url} aria-label="recipe" />
                        <Box style={{ fontSize: "14px",padding: "12px 16px", backgroundColor: "#F1F5F9",  width: "50%" }}>
                            <Box style={{ display: "flex", justifyContent: "space-between", alignContent: "center", alignItems: "center" }}>
                                <Typography style={{ fontSize: "16px", fontWeight: "500", fontFamily: "MyFont" }}>
                                    {`${commentUser.first_name} ${commentUser.last_name}`}
                                </Typography>
                                <Typography style={{ fontSize: "12px", fontWeight: "500" }}>
                                    {this.calculateTimeGap(reply.attributes.created_at)}
                                </Typography>
                            </Box>
                            {reply.attributes.comment}
                        </Box>
                    </Box>
                    <CardContent>
                        <Box style={{ display: "flex",alignItems: "center", justifyContent: "end",  fontFamily: "MyFont", gap: "50px", marginRight: "50px" }}>
                            <Box>
                                {reply.attributes.is_liked ? (
                                    <>
                                        <i className="fa-solid fa-heart" onClick={this.likeComment.bind(this, reply)} style={{ color: "red", marginRight: "5px", cursor: "pointer" }}></i>
                                        {reply.attributes.like_counts}
                                    </>
                                ) : (
                                    <>
                                        <i className="fa-regular fa-heart" style={{  cursor: "pointer", marginRight: "5px", }} onClick={this.likeComment.bind(this, reply)}></i>
                                        {reply.attributes.like_counts === 0 ? "Like" : reply.attributes.like_counts}
                                    </>
                                )}
                                &nbsp;&nbsp;
                            </Box>
                        </Box>
                    </CardContent>
                </Box>
            );
        })
    );
}

renderDrawerfunction() {
    return (
        <CustomDrawer
            anchor="right"
            open={this.state.isCommentDrawerOpen}
            onClose={this.drawerCloses}
            data-test-id="Drawer-closed"
        >
            <Box

                role="presentation"
            >
                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography style={{ fontSize: "24px", fontWeight: "900", color: "#14362E", paddingLeft: "25px" }}>
                        Post
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
                        <IconButton onClick={this.handleClose2}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <Divider />
                <Box>
                    {
                        this.state.selectedPostId !== null && (
                            <Box>
                                <Typography>
                                    { this.state.groupDataset.length > 0 && this.state.groupDataset.filter((element: any) => element.id === this.state.selectedPostId)
                                        .map((element: any) => (
                                            <Card
                                                key={element.id}
                                                style={{
                                                    maxWidth: 750,
                                                    width: "100%",
                                                    fontFamily: "MyFont",
                                                    boxShadow: 'none',
                                                    marginBottom: "25px",
                                                   
                                                }}
                                            >
                                                <CardHeader
                                                    data-test-id="commentsCard"
                                                    style={{ fontFamily: "MyFont" }}
                                                    avatar={
                                                        <Avatar src={element.attributes.posted_user.profile_picture.url} aria-label="recipe">
                                                        </Avatar>
                                                    }
                                                    action={
                                                        <i className="fa-solid fa-ellipsis-vertical" style={{ marginTop: "12px" }}></i>
                                                    }
                                                    title={
                                                        <div style={{  fontSize: "16px", fontWeight: "700",fontFamily: "MyFont", letterSpacing: "1px" }}>
                                                            {element.attributes.posted_user.first_name + ' ' + element.attributes.posted_user.last_name} .
                                                            <span style={{ fontFamily: "MyFont", fontSize: "11px", fontWeight: "500", letterSpacing: "1px", color: "#334155" }}>
                                                                {element.attributes.posted_user.role}
                                                            </span>
                                                        </div>
                                                    }
                                                    subheader={
                                                        <div style={{  fontSize: "14px", fontWeight: "400",fontFamily: "MyFont", letterSpacing: "1px" }}>
                                                            {moment(element.attributes.updated_at).format('dddd, hh:mm A')}
                                                            <span style={{ fontFamily: "MyFont", fontSize: "11px", fontWeight: "500", letterSpacing: "1px", color: "#14362E" }}>
                                                                {element.attributes.category}
                                                            </span>
                                                        </div>
                                                    }
                                                />
                                                <Typography
                                                    style={{
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        paddingLeft: "17px",
                                                        fontFamily: "MyFont",
                                                        letterSpacing: "1px",
                                                        fontStyle: "normal"
                                                    }}
                                                >
                                                    {element.attributes.body}
                                                </Typography>
                                                {
                                                    (element.attributes.link_title && element.attributes.external_link) && (
                                                        <Box style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", display: "flex", padding: "4px", justifyContent: "space-between", alignItems: "center", height: "60px", marginBottom: "10px", marginTop: "10px", width: "95%", margin: "15px auto", borderRadius: "10px" }}>
                                                            <Box style={{ display: "flex", borderRadius: "10px", alignItems: "center" }}>
                                                                <img src={LinkImg} alt="" />
                                                                <Box style={{ marginLeft: "10px" }}>
                                                                    <p>{element.attributes.link_title}</p>
                                                                </Box>
                                                            </Box>
                                                            <span style={{ fontSize: "20px" }}><i className="fa-regular fa-copy"></i></span>
                                                        </Box>
                                                    )
                                                }
                                                <CardContent>
                                                    <Box style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", fontFamily: "MyFont" }}>
                                                        <Box>
                                                            {element.attributes.is_liked ? (
                                                                <>
                                                                    <i data-test-id="likecomment" className="fa-solid fa-heart" onClick={this.likePost.bind(this, element)} style={{ color: "red",  cursor: "pointer", marginRight: "5px"}}></i>{element.attributes.like_counts}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <i className="fa-regular fa-heart" style={{ marginRight: "5px", cursor: "pointer" }} onClick={this.likePost.bind(this, element)}></i>
                                                                    {element.attributes.like_counts === "0" ? "Like" : element.attributes.like_counts}
                                                                </>
                                                  )}
                                                  &nbsp;&nbsp;
                                                </Box>
                                                <Box onClick={this.handleClick.bind(this, element.id)}>
                                                  <i className="fa-regular fa-message"></i>&nbsp;&nbsp;
                                                  Comment
                                                </Box>
                                                <Box onClick={this.drawerSidebaar.bind(this, element.id)}>
                                                  <i className="fa-solid fa-arrow-up-from-bracket"></i>&nbsp;&nbsp;
                                                  Share
                                                </Box>
                                              </Box>
                                            </CardContent>
                                            </Card>
                                        ))}

                                </Typography>
                            </Box>
                        )
            }

            <Box style={{ display: 'flex', alignItems: 'center', gap: "1", justifyContent: "center" }}>
              <Avatar
                src={this.state.profileImage}
                aria-label="recipe"
              />
              <StyledMentionsInput
                style={{ width: "80%"}}
                onChange={this.handleCommentChange}
                placeholder="Write a comment..."
                value={this.state.comment}
              >
                <Mention
                  trigger="@"
                  data={this.state.users}
                  renderSuggestion={(suggestion: SuggestionDataItem) => (<>
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <Avatar style={{ margin: "8px" }} src={(suggestion as UserSuggestion).profile_picture} />
                      <Box style={{ display: "flex", alignItems: "center" }}>{suggestion.display}</Box></Box>
                  </>
                  )}
                />

              </StyledMentionsInput>
              <InputAdornment position="end"
                style={{position: "absolute", marginLeft: "75%"}}
              
              >
                <i  className="fa-regular fa-face-smile custom-icon "
                  style={{ paddingRight: "0px", cursor: "pointer"}}
                  onClick={this.onClickEmoji.bind(this)}>
                </i>
                {this.state.showEmoji && <EmojiPicker
                  onEmojiClick={this.EmojiClick.bind(this)}

                  className="emoji-picker"
                />}
                <IconButton
                  data-test-id="commentSubmit"
                  aria-label="send comment"
                  edge="end"
                  onClick={this.handleCommentSubmit}
                  className="button-icon custom-icon"
                  disabled={!this.state.comment?.trim()}
                >
                  <ArrowUpwardIcon
                    style={this.getSendIconStyle(this.state.comment)}
                  />
                </IconButton>
              </InputAdornment>
            </Box>
            <List>
              <ListItem button onClick={this.toggleComments} disableRipple
                style={{
                  display: "flex",
                  fontSize: "16px",
                  fontWeight: "500",
                  alignItems: "center",
                  justifyContent: "end",
                                fontFamily: "MyFont"
                            }}
                        >
                            <Box>All Comments</Box>
                            {this.state.isCommentsOpen ? <ExpandMoreIcon /> : < ExpandLessIcon />}
                        </ListItem>
                        <Collapse in={this.state.isCommentsOpen} timeout="auto" unmountOnExit>
                            <Box style={{ display: "flex", flexDirection: "column" }}>
                                {this.state.commentData.filter((comment: { attributes: { parent_comment_id: null; comment: string; }; }) => comment.attributes.parent_comment_id === null).map((element: {
                                    attributes: {
                                        comment_user: { first_name: string, last_name: string, profile_picture: { url: string } };
                                        is_liked: boolean;
                                        created_at: string;
                                        like_counts: number;
                                        replies: any;
                                        id: number; comment: string | null | undefined; parent_comment_id: number;
                                    };
                                }, index: number) => (
                                    <Box key={index}>
                                        <Box style={{ display: "flex", fontFamily: "MyFont" }}>
                                            <Avatar style={{ margin: "16px" }} src={element.attributes.comment_user.profile_picture.url} aria-label="recipe" />
                                            <Box style={{  backgroundColor: "#F1F5F9",fontSize: "14px", padding: "12px 16px", width: "90%" }}>
                                                <Box style={{ display: "flex", justifyContent: "space-between", alignContent: "center", alignItems: "center" }}>
                                                    <Typography style={{ fontSize: "16px", fontWeight: "500", fontFamily: "MyFont" }}>{`${element.attributes.comment_user.first_name} ${element.attributes.comment_user.last_name}`}</Typography>
                                                    <Typography style={{ fontSize: "12px", fontWeight: "500" }}>{this.calculateTimeGap(element.attributes.created_at)}</Typography>
                                                </Box>
                                                {element.attributes.comment}
                                            </Box>
                                        </Box>
                                        <CardContent>
                                            <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly",  fontFamily: "MyFont" }}>
                                                <Box>
                                                    {element.attributes.is_liked ? (
                                                        <>
                                                            <i className="fa-solid fa-heart" onClick={this.likeComment.bind(this, element)} style={{ color: "red", marginRight: "5px", cursor: "pointer" }}></i>{element.attributes.like_counts}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <i className="fa-regular fa-heart" style={{ marginRight: "5px", cursor: "pointer" }} onClick={this.likeComment.bind(this, element)}></i>
                                                            {element.attributes.like_counts === 0 ? "Like" : element.attributes.like_counts}
                                                        </>
                                                    )}
                                                    &nbsp;&nbsp;

                                                </Box>
                                                <Box>
                                                    <i data-test-id="likeReply" className="fa-regular fa-message" onClick={() => this.handleReplySection(index, element.attributes.id)}></i>&nbsp;&nbsp;
                                                    Reply
                                                </Box>
                                                <Box>
                                                    <i className="fa-solid fa-arrow-up-from-bracket" onClick={this.handleShareCommentClick.bind(this, element.attributes.id)}></i>&nbsp;&nbsp;
                                                    Share
                                                </Box>
                                            </Box>
                                        </CardContent>
                                        {this.renderReplycomment(element, index)}
                                        {this.replyRender(index)}
                                    </Box>
                                ))}
                            </Box>
                        </Collapse>
                    </List>
                </Box>

            </Box>
        </CustomDrawer>)
}

renderSharefunction = () => {

    const DrawerHeader: React.FC<DrawerHeaderProps> = ({ title, onClose }) => (
        <Box style={{ display: 'flex',  alignItems: 'center',justifyContent: 'space-between', padding: '10px' ,width:"100%" }}>
            <Typography className="selecttype">{title}</Typography>
            <span onClick={onClose}>
                <i className="fa-solid fa-xmark"></i>
            </span>
        </Box>
    );
    const SearchInput: React.FC<SearchInputProps> = ({ onChange, placeholder }) => (
        <Box style={{
            display: 'flex',
            flexDirection: "column",
            fontFamily: "myfont",
            justifyContent: "center",
            alignItems: "center",
            padding: "15px"
        }}>
            <Box style={{
                width: "99%",    
                marginTop: "21px",
                display: "flex",
                alignItems: "center",
                marginBottom: "9px",
                border: "1px solid #CBD5E1",
                borderRadius: "8px"
            }}>
                <i className="fa-solid fa-magnifying-glass" style={{ marginLeft: "9px" }}></i>
                <input type="text" style={{
                    width: "99%",
                    padding: "11px 4px",
                    borderRadius: "6px",
                    fontFamily: "myfont",
                    border: "none",
                    outline: "none"
                }} onChange={onChange} placeholder={placeholder} />
            </Box></Box>
    );
    const SelectedMembers: React.FC<SelectedMembersProps> = ({ members, onDelete }) => (
        <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
            {members.map((member, index) => (
                <Box key={index} style={{ display: 'flex', borderRadius: '19px', alignItems: 'center', background: '#F1F5F9', margin: '6px', padding: '6px' }}>
                    <Typography style={{ fontFamily: "myfont", fontSize: '11px',  marginRight: '9px', color: '#475569' }}>{member.value}</Typography>
                    <span onClick={() => onDelete(index)}>
                        <i className="fa-solid fa-xmark" style={{ fontSize: '11px' }}></i>
                    </span>
                </Box>
            ))}
        </Box>
    );
    const ContinueButton: React.FC<ContinueButtonProps> = ({ onClick }) => (
        <Box style={{ display: "flex", justifyContent: "center" }}>
            <Button variant="contained" style={{
                width: "90%",
                background: "#14362E",
                fontFamily: "myfont",
                color: "white",
                textTransform: "none",
                margin: "auto"
            }} onClick={onClick}>
                Continue
            </Button></Box>
    );
    return (
      <>

        <Drawer style={webStyle.drawer as any}
         anchor="right" onClose={this.drawerSidebaarClose3} open={this.state.open3} >
          <BoxMain >
            <Box style={{ display: "flex", 
            alignItems: "center", 
            justifyContent: "space-between", 
            padding: "10px" }}>
              <DrawerHeader onClose={this.drawerSidebaarClose3} 
              title="Share comment" />
            </Box >
            <Box style={{ borderBottom: '1px solid #CBD5E1' }}></Box>

            <Box style={{ padding: "10px" }}>
              <Box 
                style={{ display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "14px" }}>
                <Box 
                  style={{ width: "100%", marginBottom: "10px", border: "1px solid #CBD5E1", marginTop: "20px", display: "flex", alignItems: "center" }}>
                  <i className="fa-solid fa-magnifying-glass" 
                    style={{ padding: "0 0 0 10px" }}>
                  </i>
                  <input type="text" 
                    style={{ width: "100%", borderRadius: "5px", padding: "10px 5px", border: "none", outline: "none" }} onChange={this.handlesearchTag} name="exlink" id="" />
                </Box >
              </Box >
              <Box style={{ display: "flex", flexWrap: 'wrap' }}>
                { this.state.selectedmember && this.state.selectedmember.map((element: any, index: any) => (
                    <Box key={index} 
                      style={{ display: 'flex', borderRadius: '19px', alignItems: 'center', background: '#F1F5F9', margin: '6px', padding: '6px' }}>
                      <Typography 
                        style={{ fontFamily: "myfont", fontSize: '11px', marginRight: '9px', color: '#475569' }}>
                          {element.value}
                        </Typography>
                      <span onClick={this.handleselectmemberDelete.bind(this, index)}><i className="fa-solid fa-xmark" style={{ fontSize: "11px" }}>
                        </i>
                        </span>
                    </Box >
                  ))
                }
              </Box >
              <Box >
                { this.state.shareconnectiondata2.length > 0 && this.state.shareconnectiondata2.map((element: any, index: any) => {
                    return(
                      <>
                        <Box key={index} 
                          style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "4px", height: "60px", marginBottom: "10px", marginTop: "10px" }}>
                          <Box 
                            style={{ display: "flex", borderRadius: "10px", alignItems: "center" }}>
                            <img src={element.profile_picture?.url} 
                              style={{ height: "40px", borderRadius: "50%", width: "40px" }} alt="" />
                            <Box style={{ marginLeft: "10px" }} >
                              <p> {element?.name}
                              </p>
                              <p> {element?.role}
                              </p>
                            </Box >
                          </Box >
                          <span>
                            <input value={element.e} onClick={(elementvalue) => this.getvalueconnectioncheck(elementvalue, element)} type="checkbox" name="" id="" />
                            </span>
                        </Box >
                      </>
                    )
                  })
                }
              </Box >
              <Box 
                style={{ display: 'flex', flexDirection: "column", justifyContent: "center", padding: "14px", alignItems: "center", marginBottom: "100px" }}>
                <Box 
                  style={{ width: "100%", marginBottom: "10px", border: "1px solid #CBD5E1", display: "flex", marginTop: "20px", alignItems: "center" }} >
                  <input type="text" style={{ width: "100%", padding: "10px 5px", borderRadius: "5px", outline: "none", border: "none", }} onChange={this.handlewritemessage} name="exlink" placeholder="Write Message" id=""/>
                </Box >
              </Box >

              <Container className="button-container" >
                <Button data-test-id="addedToBlock" variant="contained" className="selectbtn" onClick={this.handlePostshare}>
                  Send
                </Button>
              </Container >
            </Box >
          </BoxMain >
          <Toaster />
        </Drawer >
      </>
    )
}

   mapMediaItems(mediaItems: { content_type: string; url: string; }[]) {
    return mediaItems.map((item: { content_type: string; url: string; }) => ({
      type: item?.content_type?.startsWith('image/') ? 'image' : 'video',
      url: item?.url,
    }));
  }

  getCards = () => {
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 0 },
        items: 1,
        slidesToSlide: 1
      },
    };
    return (
      <Box key={1} style={{ display: "flex", flexDirection: "column", background: "#F8FAFC", alignItems: "center" }}>

      {
       this.state.groupDataset.map((element: object, index: number) => {

    
  
        const imagesAndVideos = element.attributes.images_and_videos ? this.mapMediaItems(element.attributes.images_and_videos) : [];
        const media = element.attributes.media ? this.mapMediaItems(element.attributes.media) : [];
  
        const combinedMedia = [...imagesAndVideos, ...media];
        const images = combinedMedia.filter(media => media.type === 'image');
        const videos = combinedMedia.filter(media => media.type === 'video');
        const isFavorite = element.attributes.is_favorite;
          return (
  <Card key={element.id} style={{ maxWidth: 750, marginBottom: "25px", width: "100%", marginTop: index === 0 ? "84px" : "0px", fontFamily: "MyFont" }}>
    <Box style={webStyle.cardflexbox}>
      <CardHeader
        style={{ fontFamily: "MyFont" }}

        avatar={
          <a href={`UserProfileBasicBlock/${element?.attributes?.account_id}`}>
            <Avatar src={element.attributes?.posted_user?.profile_picture?.url} aria-label="recipe">

            </Avatar>
          </a>

        }

        title={<div style={{ fontFamily: "MyFont", fontSize: "18px", fontWeight: "700", letterSpacing: "1px" }}>{element.attributes.posted_user.first_name + element.attributes.posted_user.last_name} . <span style={{ fontFamily: "MyFont", fontSize: "11px", fontWeight: "500", letterSpacing: "1px", color: "#334155" }}>{element.attributes.posted_user?.role}</span></div>}
        subheader={<div style={{ fontFamily: "MyFont", fontSize: "14px", fontWeight: "400", letterSpacing: "1px" }}>{moment(element.attributes.updated_at).format('dddd, hh:mm A')} <span style={{ fontFamily: "MyFont", fontSize: "11px", fontWeight: "500", letterSpacing: "1px", color: "#14362E" }}>{element.attributes?.category}</span>
        <span style={webStyle.citytest}>{element.attributes?.city}</span></div>}
      />

      <Box key={element.id} style={{ cursor: "pointer", textAlign: "right" }}>
        {isFavorite && (
          <IconButton
            data-test-id="icon-button"
            edge="start"
            aria-haspopup="true"
            aria-controls={`menu-${index}`}
            aria-label="more"
            onClick={() => this.getToFavriouteData(element.id)}
          >
            <BookmarkIcon style={{ color: "#14362E" }} />
          </IconButton>
        )}

        <IconButton
          data-test-id="handleOpenMenu"
          edge="start"
          aria-haspopup="true"
          aria-controls={`menu-${index}`}
          aria-label="more"
          onClick={(event) => this.handleOpenMenu(event, element.id)}
        >
          <MoreVertIcon />
        </IconButton>
        {/* menu box start */}

        <MenuBox style={{ position: "relative" }}>
          <Menu
            key={index}
            anchorEl={this.state.openMenu}
            id={`menu-${index}`}
            open={this.state.openMenuId === element.id}
            onClick={this.handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'top',
            }}
            transformOrigin={{
              horizontal: 'right',
              vertical: 'top',
            }}
            style={{
              marginTop: "34px"
            }}
          >
            <MenuItem
              data-test-id="addtofavBtn"
              onClick={() => this.getToFavriouteData(element.id)}
              style={{
                fontFamily: "myfont",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "22px",
                letterSpacing: "0.08em",
                textAlign: "left",
              }}
            >
              {isFavorite ? (
                <>
                  <BookmarkBorderIcon
                    style={{
                      height: "20px",
                      fontWeight: "500",
                    }}
                  />
                  Remove from favourites
                </>
              ) : (
                <>
                  <BookmarkBorderIcon
                    style={{
                      height: "20px",
                      fontWeight: "500",
                    }}
                  />
                  Add to favourites
                </>
              )}
            </MenuItem>
         { element?.attributes?.account_id != this.state.accountId &&  (<><MenuItem
              data-test-id="addtoReportBtn"
              onClick={(event) => this.handlePostReport(element.id)}
              style={{
                color: "#D1584C",
                fontFamily: "myfont",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "22px",
                letterSpacing: "0.08em",
                textAlign: "left",
              }}
            >
              <ReportIcon
                style={{
                  height: "20px",
                  fontWeight: "500",
                }} />
              &nbsp; Report
            </MenuItem><MenuItem
              data-test-id="addtoBlockBtn"
              onClick={() => this.handleBlockUser(element.id)}
              style={{
                color: "#D1584C",
                fontFamily: "myfont",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "22px",
                letterSpacing: "0.08em",
                textAlign: "left",
              }}
            >
                <BlockIcon
                  style={{
                    height: "20px",
                    fontWeight: "500",
                  }} />
                &nbsp; Block user
              </MenuItem></>)}
          </Menu>
        </MenuBox>
      </Box>
    </Box>
    {element.attributes.is_recommendation ? <Box style={webStyle.recommendationBox}>Recommendation</Box> : ""}

    {element.attributes.is_recommendation ? <Box style={{ marginTop: "20px"}}>
      <Box style={webStyle.recommendationBoxtitle}>{element.attributes.name}</Box>

      <Box style={{ display: 'flex', alignItems: 'center', marginLeft: "10px" }}>
        <Box style={webStyle.recommendationBoxType}>
          {element.attributes.recommendation_type.name}
        </Box>
        <Typography
          variant="body1"
          style={{ margin: '0 8px', alignSelf: 'flex-end', position: 'relative', bottom: '-5px' }}
        >
          &bull;
        </Typography>
        <Box style={webStyle.recommendationBoxCity}>
          {element.attributes.city}
        </Box>
      </Box>

    </Box> : ""}

    <Typography style={{
      fontSize: "14px", paddingLeft: "16px", fontFamily: "MyFont", fontWeight: 500, letterSpacing: "1px",
      fontStyle: "normal", marginTop: "10px"
    }}>{element.attributes.body}</Typography>
    {
      (element.attributes.link_title && element.attributes.external_link) && (
        <Box style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", display: "flex", justifyContent: "space-between", padding: "4px", alignItems: "center", height: "60px", marginBottom: "10px", marginTop: "10px", width: "95%", margin: "15px auto", borderRadius: "10px" }}>
          <Box style={{ display: "flex", borderRadius: "10px", alignItems: "center" }}>
            <img data-test-id="handleRedirect" src={LinkImg} onClick={() => this.handleRedirect(element.attributes.external_link)} alt="" />
            <Box style={{ marginLeft: "10px" }}>
              <p>{element.attributes.link_title}</p>
            </Box>
          </Box>
          <Button data-test-id="handleCopyLink" onClick={() => this.handleCopyLink(element.attributes.external_link)}>
            <span style={{ fontSize: "20px" }}><i className="fa-regular fa-copy"></i></span>

          </Button>
        </Box>
      )
    }
    <Carousel
      responsive={responsive}
      autoPlay={false}
      swipeable={true}
      draggable={true}
      showDots={false}
      infinite={false}
      partialVisible={false}
      dotListClass="custom-dot-list-style"
    >
      {images.map((media, index) => (
        <div key={`image-${index}`} style={{display:"flex",alignItems:"center",height:"200px"}}>
          <img style={{ width: "100%" }} src={media?.url} alt={`media-${index}`} />
        </div>
      ))}
               {videos.map((media, index) => (
                 <div key={`video-${index}`}>
                   <video style={{ width: "100%", height: "250px" }} controls>
                     <source src={media.url} type="video/mp4" />
                     Your browser does not support the video tag.
                   </video>
                 </div>
               ))}
             </Carousel>
             <CardContent>
               <Box style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", fontFamily: "MyFont" }}>
                 <Box>
                   {element.attributes.is_liked ? (
                     <>
                       <i data-test-id="likecomment" className="fa-solid fa-heart" onClick={this.likePost.bind(this, element)} style={{ color: "red", cursor: "pointer", marginRight: "5px", }}></i>{element.attributes.like_counts}
                     </>
                   ) : (
                     <>
                       <i className="fa-regular fa-heart" style={{ marginRight: "5px", cursor: "pointer" }} onClick={this.likePost.bind(this, element)}></i>
                       {element.attributes.like_counts === "0" ? "Like" : element.attributes.like_counts}
                     </>
                   )}
                   &nbsp;&nbsp;
                 </Box>
                 <Box onClick={this.handleClick.bind(this, element.id)}>
                   <i className="fa-regular fa-message"></i>&nbsp;&nbsp;
                   Comment
                 </Box>
                 <Box onClick={this.drawerSidebaar.bind(this, element.id)}>
                   <i className="fa-solid fa-arrow-up-from-bracket"></i>&nbsp;&nbsp;
                   Share
                 </Box>
               </Box>
             </CardContent>
           </Card>
         )
       })
      }
      </Box>
    )
  }

  subcategoryList() {
    return (
      <>
        <Box>
        <Box>
              {  this.state.groupFollowed?.followed  ? 
                 <Button data-test-id="followedId" onClick={() => this.followGroup()} style={webStyle.followedBtn}>
                     Followed
                 </Button>
                 :  <Button data-test-id="followedId" onClick={() => this.followGroup()} style={webStyle.followBtn}>
                     Follow Group
            </Button>
              }
              </Box>
            { this.getCards()}
          <Drawer style={webStyle.drawer as any} anchor="right" open={this.state.open1} onClose={this.toggleDrawer1.bind(this, false, "")} >
            <BoxMain>
              <Box style={webStyle.categoryDropdown}>
              <Typography className="selecttype">Block User</Typography>
              <span onClick={this.drawerClose}>
                <i className="fa-solid fa-xmark"></i>
              </span>
            </Box>
            <Box style={webStyle.borderBottomStyle}></Box>
            <Box className="flaggingcontent">Why are you blocking this user?</Box>
            <Box style={webStyle.borderBottomStyle}></Box>
            <Container className="content-container">
              {
                this.state.deleteReasonData.map((item: { id: number, title: string, description: string }, index: number) => {
                  return <Box
                    data-test-id="selectBlockMsg"
                    className="typebox"
                    key={index}
                    onClick={() => this.handleSelect1(item, index)}
                    style={{ backgroundColor: this.state.indexBlockList === index ? '#D4E5DE' : 'transparent' }}
                  >
                    <Box>
                      <Typography className="titlemyfont" >{item.title}</Typography>
                      <Typography className="titleReason">{item.description}</Typography>
                    </Box>
                    {this.state.indexBlockList === index && <CheckCircleOutlineOutlinedIcon className="checkedCircle" />}
                  </Box>
                })
              }
              <Divider />

            </Container>
            <Container className="button-container">
              <Button data-test-id="addedToBlock" variant="contained" className="selectbtn" onClick={this.addedToBlock}>Continue</Button>
            </Container>
          </BoxMain>
        </Drawer>
        {this.formSubmittedSuccessfully()}
        {this.blockSuccessfully()}
        </Box>
        <Drawer style={webStyle.drawer as any} anchor="right" open={this.state.sidebaarOpen} onClose={this.drawerSidebaarClose} >
                                                    <BoxMain>
                                                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px" }}>
                                                            <Typography className="selecttype">Share Post</Typography>
                                                            <span onClick={this.drawerSidebaarClose}>
                                                                <i className="fa-solid fa-xmark"></i>
                                                            </span>
                                                        </Box>
                                                        <Box style={{ borderBottom: '1px solid #CBD5E1' }}></Box>

                                                        <Box style={{ padding: "10px" }}>
                                                            <Box style={{ display: 'flex', flexDirection: "column",  alignItems: "center", justifyContent: "center", padding: "14px" }}>
                                                                <Box style={{ width: "100%", marginBottom: "10px", border: "1px solid #CBD5E1", marginTop: "20px", display: "flex", alignItems: "center" }}>
                                                                    <i className="fa-solid fa-magnifying-glass" style={{padding:"0 0 0 10px"}}></i>
                                                                    <input type="text" style={{ width: "100%", borderRadius: "5px",padding: "10px 5px",  border: "none", outline: "none" }} onChange={this.handlesearchTag} name="exlink"  id="" />
                                                                </Box>
                                                            </Box>
                                                            <Box style={{ display: "flex",  flexWrap: 'wrap' }}>
                                                                {
                                                                    this.state.selectedmember && this.state.selectedmember.map((element: any, index: any) => (
                                                                      <Box key={index} style={{ display: 'flex', borderRadius: '19px', alignItems: 'center', background: '#F1F5F9', margin: '6px', padding: '6px' }}>
                                                                           <Typography style={{ fontFamily: "myfont", fontSize: '11px',  marginRight: '9px', color: '#475569' }}>{element.value}</Typography>
                                                                            <span onClick={this.handleselectmemberDelete.bind(this, index)}><i className="fa-solid fa-xmark" style={{ fontSize: "11px" }}></i></span>
                                                                        </Box>
                                                                    ))
                                                                }
                                                            </Box>
                                                            <Box>
                                                                {
                                                                    this.state.shareconnectiondata.length > 0 && this.state.shareconnectiondata.map((element: any, index: any) => {
                                                                        return (
                                                                            <>
                                                                                <Box key={index} style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", display: "flex", justifyContent: "space-between",  alignItems: "center",padding: "4px", height: "60px", marginBottom: "10px", marginTop: "10px" }}>
                                                                                    <Box style={{ display: "flex", borderRadius: "10px", alignItems: "center" }}>
                                                                                        <img src={element.profile_picture?.url} style={{ height: "40px",  borderRadius: "50%", width: "40px"}} alt="" />
                                                                                        <Box style={{ marginLeft: "10px" }}>
                                                                                            <p>{element?.name}</p>
                                                                                            <p>{element?.role}</p>
                                                                                        </Box>
                                                                                    </Box>
                                                                                    <span><input value={element.e} onClick={(elementvalue) => this.getvalueconnectioncheck(elementvalue, element)}  type="checkbox" name="" id="" /></span>
                                                                                </Box>
                                                                            </>
                                                                        )
                                                                    })
                                                                }

                                                            </Box>

                                                            <Box style={{ display: 'flex', flexDirection: "column", justifyContent: "center",  padding: "14px",alignItems: "center", marginBottom: "100px" }}>
                                                                <Box style={{ width: "100%", marginBottom: "10px", border: "1px solid #CBD5E1",  display: "flex",marginTop: "20px", alignItems: "center" }}>
                                                                    <input type="text" style={{ width: "100%", padding: "10px 5px", borderRadius: "5px", outline: "none", border: "none", }} onChange={this.handlewritemessage} name="exlink" placeholder="Write Message" id="" />
                                                                </Box>
                                                            </Box>

                                                            <Container className="button-container">
                                                                <Button data-test-id="addedToBlock" variant="contained" className="selectbtn" onClick={this.handlePostshare}>Send</Button>
                                                            </Container>

                                                        </Box>
                                                    </BoxMain>
                                                    <Toaster />
        </Drawer>
        {this.renderDrawerfunction()}
         {this.renderSharefunction()}
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
     //istanbul ignore next
    return (
      <>
        <AppHeader component={"explore"} />
        <Box key={1} style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "100px", background: "#F8FAFC" }}>
          <Card style={{ maxWidth: 750, marginBottom: "25px", width: "100%", marginTop: "20px", fontFamily: "MyFont" }}>
            <CardContent>
              <Box>
                <Typography>
                  <a onClick={this.redirectprevios} style={{ textDecoration: "none", color: "#fff" }}>
                    <span style={{ marginRight: "5px", color: "black" , cursor:"pointer" }}><i className="fa-solid fa-arrow-left"></i></span>
                  </a>
                  <span style={{marginLeft:"10px",fontFamily:"MyFont", fontWeight: 600, letterSpacing: "0.05em", color: "#14362E", fontSize: "21px"}}>{this.state.categoryDataset?.attributes?.name}</span>
                </Typography>
              </Box>
              <Box style={{marginTop:"10px"}}>
                <span style={{fontFamily:"MyFont", fontWeight: 500, letterSpacing: "0.05em", color:"#475569", fontSize: "14px" , cursor:"pointer"}} onClick={this.redirecttoExplore}>For you</span> &nbsp; 
                <span style={{color:"#475569", marginLeft:"10px"}}><i style={{fontSize:"14px"}} className="fa-solid fa-greater-than"></i></span>&nbsp;
                <a style={{fontFamily:"MyFont", fontWeight: 500, letterSpacing: "0.05em", color:"#475569", fontSize: "14px",marginLeft:"10px", textDecoration:"none"}}  onClick={this.redirectprevios}>
                  {this.state.categoryDataset?.attributes?.name}
                </a>&nbsp;
                {this.state.groupinfo && <i  style={{color:"#475569", marginLeft:"10px", fontSize: "14px"}} className="fa-solid fa-greater-than"></i>}&nbsp;
                <span style={{fontFamily:"MyFont", fontWeight: 500, letterSpacing: "0.05em", color:"#0F172A", fontSize: "14px",marginLeft:"10px",}}> {this.state.groupinfo ? this.state.groupinfoName : ""} </span>
              </Box>
           
              {
               !this.state.openPosts && (!this.state.groupinfo) ? (
                  <>
                    <Box style={webStyle.innercontentParent}>
                      {this.categoryAlllist()}
                    </Box>
                  </>
                ) : (
                  <>
                    {this.subcategoryList()}
                  </>
                )
              }
            </CardContent>

          </Card>
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const StyledMentionsInput = styled(MentionsInput)<MentionsInputProps & StyledComponentProps>(() => ({
  marginLeft: "8px",
  width: '385px',
  height: '48px',
  backgroundColor: '#F1F5F9',
  border: '1px solid #E2E8F0',
  borderRadius: "50px",
  "& textarea": {
      padding: '12px 16px',
      width: '100%',
      height: '100%',
      fontSize: "16px",
      fontWeight: "500",
      fontFamily: "MyFont",
      border: "none",
      borderRadius: "50px",
      "&:focus": {
          outline: 'none',
          border: 'none',
      },
      "&:hover": {
          border: 'none',
      },
  },
  "&:hover": {
    border: '1px solid #E2E8F0',
},

  "&:focus": {
      outline: 'none',
      border: '1px solid #E2E8F0',
  },

  '& [class*="__suggestions__list"]': {
      border: "none",
  },


  '& [class*="__suggestions"]': {
    top: "25px !important",
    minWidth: "333px",
    fontFamily: "myfont",
    fontSize: "16px",
    fontWeight: "500",
    borderRadius: "16px",
    border: "1px solid #E2E8F0",
    padding: "8px 12px",
},

'& [class*="__suggestions__item"]': {
  border: "none",
  borderBottom: "1px solid #E2E8F0",
  borderRadius: "0",
  padding: "8px"
},
  "@media(max-width: 600px)": {
      "& textarea": {
          fontSize: "13px !important"
      },
      '& [class*="__suggestions"]': {
          minWidth: "100%",
          fontSize: "14px",
      },
  }

}));

const HeaderContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",

  '& .flexBoxCus': {
      position: "relative",
      textAlign: "right" as 'right',
      flexWrap: "wrap",
      width: "30px",
      cursor: "pointer",
 
  },
});



const CustomDrawer = styled(Drawer)({
  '& .MuiDrawer-paper': {
    width: '540px',
  },
  '& .button-icon': {
    borderRadius: "8px",
    padding: "0",
    position: "absolute",
    right: "17%"
  },
  '& .custom-icon': {
    borderRadius: "8px",
    position: "relative",
    padding: "0",
    right: "44%",
    display: 'block'
  },
  '& .emoji-picker': {
    height:"400px !important",
    width: "400px !important",
    position: "absolute",
    right: "32% ",
    top :"26px",
    zIndex : 1000
  },
  '& .reply-emoji-picker': {
    height:"400px !important",
    width: "450px !important",
    position: "absolute",
    top :'103% ',
    right : '0%',
    zIndex : 1000
  },
  '& .display_arrow':{
    display : 'none'
  },
  '@media (max-width: 601px)': {
    '& .MuiDrawer-paper': {
      width: '100%',
    },
    '& .emoji-picker':{
      right : '5%',
      width: "370px !important",
    },
    '& .button-icon': {
      right: "7%"
    },

    '& .reply-emoji-picker':{
      top: "103%",
      right : '0%',
      height: '400px !important',
      width: '415px !important'
    },
    '& .display_arrow':{
      display:'flex'
    },
}})
const CardBox = styled(Box)({
  borderRadius: "10px",
  border: "1px solid #73A58E",
  padding: "10px",
  flexBasis: 'calc(50% - 20px)',
  marginBottom: "10px",
  boxSizing: 'border-box',

  "@media (max-width: 601px)": {
    flexBasis: '100%', 
    padding: '10px',
  },
})

const BoxMain = styled(Box)({
  width: "400px",
  fontFamily: "myfont",
  "@media(max-width: 600px)": {
    width: '200px',
  },
  '& .titleReason': {
    fontSize: "14px",
    fontFamily: "myfont"
  },
  '& .titlemyfont': {
    fontFamily: "myfont"
  },
  "& .mainBox": {
    padding: '4px',
    fontWeight: "400",
    height: '30px',
    fontFamily: "Halyard Display",
    justifyContent: 'space-between',
    marginBottom: '5px',
    paddingLeft: "10px",
    display: 'flex',
    fontSize: "12px"
  },
  "& .mainBoxDivider": {
    width: "95%",
    marginLeft: "10px"
  },
  "& .mediatitle": {
    display: "flex",
    width: "370px",
    alignItems: "center",
    justifyContent: "space-between",
    fontWeight: "bold",
    fontFamily: "MyFont",
    margin: "15px",
    paddingLeft: "12px",
  },
  "& .content-container": {
    paddingBottom: "80px", // Give some padding at the bottom for better UX
    overflowY: "auto",
    height: "calc(100vh - 200px)", // Adjust height based on your layout
  },
  "@media (max-width: 600px)": {
    "& .mediatitle": {
      width: "150px",
    },
  },
  "& .button-container": {
    "@media (max-width: 600px)": {
      width: '200px',
    },
    width: "400px",
    position: "fixed",
    background: "white",
    boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
    bottom: 0,
    padding: "10px 0", // Adjust as needed
  },
  '@media (max-width: 601px)': {
    '& .selectbtn': {
      marginTop: "0", // No need for extra margin
    },
  },
  '& .selectbtn': {
    color: "white",
    textTransform: "none",
    background: "#14362E",
    width: "100%",
  },
  '& .selecttype': {
    fontSize: "24px",
    color: '#14362E',
    fontWeight: "bold",
    paddingLeft: '5px',
    fontFamily:"myfont"
  },
  '& .sideBarlabel': {
    letterSpacing: "8%",
    fontFamily: "Sans-serif",
    color: "#475569",
  },
  '& .flaggingcontent': {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'start',
    padding: '14px',
    justifyContent: 'center',
    color: "#0F172A",
    letterSpacing: "0.08em",
    fontWeight: "700",
    fontSize: "16px",
    fontFamily: "myfont"
  },
  "& .typebox": {
    paddingLeft: "18px",
    paddingBottom: "15px",
    display: "flex",
    paddingTop: "15px",
  },
  "& .checkedCircle": {
    paddingTop: "10px",
    color: "#14362E",
    width: "27.7px",
    height: "27.7px",
  },
  "& .typebox:hover": {
    backgroundColor: "#D4E5DE"
  }
});


const MenuBox = styled(Box)({
  '& .menuicon': {
    width: "18px",
    height: "18px",
    top: "9px",
    left: "16px",
    padding: "2px"
  },
  '& .menuitemcss': {
    fontFamily: "myfont",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
    letterSpacing: "0.08em",
    textAlign: "left"
  },
  "@media(max-width: 600px)": {

  }
});

const BoxCustom2 = styled(Box)({
  fontFamily: "myfont",
  "& .mainBox": {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '4px',
    height: '30px',
    marginBottom: '5px',
    paddingLeft: "10px",
    fontFamily: "Halyard Display",
    fontSize: "12px",
    fontWeight: "400"
  },
  "& .mainBoxDivider": {
    marginLeft: "10px",
    width: "95%"
  },
  "& .mediatitle": {
    margin: "15px",
    width: "370px",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "12px",
    fontFamily: "MyFont"
  },
  "@media (max-width: 600px)": {
    "& .mediatitle": {
      width: "150px",
    },
  },
  "& .content-container": {
    height: "calc(100vh - 200px)", // Adjust height based on your layout
    overflowY: "auto",
    paddingBottom: "80px", // Give some padding at the bottom for better UX
  },
  "& .button-container": {
    position: "fixed",
    bottom: 0,
    width: "400px",
    "@media (max-width: 600px)": {
      width: '200px',
    },
    background: "white",
    padding: "10px 0", // Adjust as needed
    boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
  },
  '& .selectbtn': {
    width: "100%",
    background: "#14362E",
    textTransform: "none",
    color: "white",
  },
  '@media (max-width: 601px)': {
    '& .selectbtn': {
      marginTop: "0", // No need for extra margin
    },
  },
  '& .selecttype': {
    paddingLeft: '5px',
    color: '#14362E',
    fontWeight: "bold",
    fontSize: "24px",
  },
  '& .sideBarlabel': {
    fontFamily: "Sans-serif",
    color: "#475569",
    letterSpacing: "8%"
  },
  '& .flaggingcontent': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start',
    padding: '14px',
    fontWeight: "700",
    fontSize: "16px",
    letterSpacing: "0.08em",
    color: "#0F172A"
  },
  "& .typebox": {
    display: "flex",
    paddingLeft: "18px",
    paddingTop: "15px",
    paddingBottom: "15px",
  },
  "& .typebox:hover": {
    backgroundColor: "#D4E5DE"
  },
  "& .checkedCircle": {
    width: "27.7px",
    height: "27.7px",
    color: "#14362E",
    paddingTop: "10px"
  }



});

const webStyle = {
  innercontentParent: {
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: "20px",
    marginBottom: "10px",
  },
  cardflexbox: { display: "flex", justifyContent: "space-between" },
  imgRound:  { width: "50px", height: "50px", borderRadius: "50%" },
   width: "50px",
    roundBox :{
    height: "50px",
    borderRadius: "50%",
    backgroundColor: "#73A58E",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white"
  },

  imgflexbox :{ display: "flex" },
  inercontentheader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  headercontent: {
    fontFamily: "MyFont",
    letterSpacing: "0.8px",
    color: "#14362E",
    fontWeight: "bold",
    fontSize: "16px"
  },
  headersecondcontent: {
    color: "#73A58E",
    fontFamily: "MyFont",
    letterSpacing: "0.5px",
    fontSize: "11px",

  },
  innermaincontenttext: {
    color: "#0F172A",
    fontSize: "14px",
    fontFamily: "MyFont",
    letterSpacing: "0.7px",
  },

  followBtn: {
    width: "100%",
    height: "36px",
    borderRadius: "16px",
    color: "#D4E5DE",
    fontFamily: "MyFont",
    backgroundColor: "#14362E",
    fontSize: "16px",
    fontWeight: "700",
    letterSpacing: "0.8px",
    textAlign: "center",
    marginTop: "12px",
   cursor: "pointer"
  },

  followedBtn: {
    width: "100%",
    height: "36px",
    borderRadius: "16px",
    color: "#D4E5DE",
    letterSpacing: "0.8px",
    textAlign: "center",
    marginTop: "12px",
    fontFamily: "MyFont",
    backgroundColor: "#73A58E",
    fontSize: "16px",
    fontWeight: "700",
   cursor: "pointer"
  },
  
}
// Customizable Area End
