import React from "react";

// Customizable Area Start
import { Box, Typography, styled, Button, Drawer } from "@material-ui/core";
import { ArrowBack, CheckCircle, Close } from "@material-ui/icons";
import { greenCheck } from "./assets";
// Customizable Area End

import PaymentController, { Props } from "./PaymentController.web";

class Payment extends PaymentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  subscriptionDetails (){
    return(
      <CustomDrawer 
      anchor="right"
      open={this.state.subscriptionDetailsOpen}
      className="mainViewDetailDrawerStyle"
      onClose={this.handleCloseSubscriptionDetails}
      >
      <Box style={{height: "74.85px", borderBottom: "1.04px solid #E2E8F0"}}>
        <Box className="viewdrawerStyle">
        <Box style={{height: "73.85px", padding: "12px 0px 12x 0px",marginLeft:"3%",display: "flex", justifyContent: 'space-between', alignItems: 'center'}}>
          <Box style={{height: "32px", }}>
            <Typography className="TextStyle">Plan details</Typography>
          </Box>
          <Box style={{ width:"24.93px",padding:"12px 0px 12px 0px"}}>
            <Close onClick={this.handleCloseSubscriptionDetails} style={{height: "24.93px", width:"24.93px"}}/>
          </Box>
          </Box>
        </Box>
      </Box>
      <Box className='scrollableCard' style={{ padding: "0px 28px 0px 28px",}}>
        <Box className= "viewdrawerBoxStyle">
          <Typography className="viewDrawerTypoStyle" style={{margin:"8px",height:"24px", width:"152px",}}>KELLA Empower</Typography>
          <Typography
          style={{
            fontWeight:400,
            fontSize:"11px",
            fontFamily:"MyFont",
            lineHeight:"16px",
            color:"#000000",
            margin:"8px",
          }}
          >All memberships are subject to a minimum 12 month contract</Typography>
          <Box style={{height:"308px", margin:"8px"}}>
            <Box style={{height:"36px", display:"flex", paddingTop:"2%", gap:"12px",}}>
              <CheckCircle style={{height:"16px",fill:"#73A58E",  width:"16px"}}/>
              <Box style={{height:"18px",}}>
                  <Typography style={{...webStyle.boxTextFormat}}>KELLA Platform</Typography>
                  <Typography style={{...webStyle.boxTextFormat, fontWeight:500, color:"#64748B"}}>connecting, engaging, supporting</Typography>
              </Box>
            </Box>
            <Box style={{height:"36px",  display:"flex",  paddingTop:"2%", gap:"12px",}}>
              <CheckCircle style={{height:"16px", width:"16px", fill:"#73A58E", }}/>
                <Box style={{height:"18px",}}>
                  <Typography style={{...webStyle.boxTextFormat}}>KELLA Content</Typography>
                  <Typography style={{...webStyle.boxTextFormat, fontWeight:500, color:"#64748B"}}>Updated content and disscusion in your areas of interset</Typography>
                </Box>
            </Box>
            <Box style={{ gap:"12px",height:"36px", paddingTop:"2%", display:"flex",  }}>
              <CheckCircle style={{fill:"#73A58E", width:"16px", height:"16px",}}/>
                <Box style={{height:"18px"}}>
                  <Typography style={{...webStyle.boxTextFormat}}>KELLA Content</Typography>
                  <Typography style={{...webStyle.boxTextFormat, fontWeight:500, color:"#64748B"}}>Find other members at events</Typography>
                </Box>
            </Box>
            <Box style={{height:"36px", display:"flex",  paddingTop:"2%",gap:"12px", }}>
              <CheckCircle style={{fill:"#73A58E", height:"16px", width:"16px"}}/>
                <Box style={{height:"18px", }}>
                  <Typography style={{...webStyle.boxTextFormat}}>KELLA Learn</Typography>
                  <Typography style={{...webStyle.boxTextFormat, fontWeight:500, color:"#64748B"}}>Masterclasses & Speaker Series</Typography>
                </Box>
            </Box>
            <Box style={{ gap:"12px", display:"flex", height:"36px",  paddingTop:"2%"}}>
              <CheckCircle style={{ height:"16px", width:"16px",fill:"#73A58E"}}/>
                <Box style={{height:"18px",}}>
                  <Typography style={{...webStyle.boxTextFormat}}>KELLA Community</Typography>
                  <Typography style={{...webStyle.boxTextFormat, fontWeight:500, color:"#64748B"}}>Connect with leaders all over the world</Typography>
                </Box>
            </Box>
            <Box style={{height:"18px", width:"152px", gap:"12px",  paddingTop:"2%", display:"flex",}}>
              <CheckCircle style={{ width:"16px",fill:"#73A58E", height:"16px",}}/>
              <Box style={{height:"18px",}}>
                <Typography style={{...webStyle.boxTextFormat}}>KELLA Peer-to-peer</Typography>
              </Box>
            </Box>
            <Box style={{height:"36px", display:"flex", gap:"12px", paddingTop:"2%"}}>
              <CheckCircle style={{ height:"16px",fill:"#73A58E", width:"16px"}}/>
                <Box style={{height:"18px", width:"368px"}}>
                  <Typography style={{...webStyle.boxTextFormat}}>My Circle</Typography>
                  <Typography style={{...webStyle.boxTextFormat, fontWeight:500, color:"#64748B"}}>Your handpicked community</Typography>
                </Box>
            </Box>
            <Box style={{height:"18px", width:"152px", paddingTop:"2%", gap:"12px ", display:"flex",}}>
              <CheckCircle style={{fill:"#73A58E", height:"16px", width:"16px"}}/>
                <Box style={{height:"18px", width:"124px"}}>
                  <Typography style={{...webStyle.boxTextFormat}}>Get One Month Free</Typography>
                </Box>
            </Box>
            <Box style={{ paddingTop:"2%",height:"68px",}}>
            <Typography
                style={{
                fontWeight:400,
                color:"#000000",
                margin:"8px",
                fontSize:"11px",
                fontFamily:"MyFont",
                lineHeight:"16px",
              }}
                >You can Pay
            </Typography>
              <Box style={{height:"28px", display:"flex", width:"212px",}}>
                <Typography style={{lineHeight:"28px", fontWeight:700,fontSize:"20px",fontFamily:"Montserrat"}}>&#x20AC;315</Typography>
                <Typography style={{lineHeight:"16px", fontWeight:400,fontSize:"11px",fontFamily:"MyFont",textAlign:"center",color:"#94A3B8",paddingTop:"5px"}}>+VAT </Typography>
                <Typography style={{lineHeight:"22px", fontWeight:500,fontSize:"14px",fontFamily:"MyFont",textAlign:"center",color:"#94A3B8",paddingTop:"1px"}}> / annual</Typography>
              </Box>
              <Box style={{height:"16px" ,display:"flex"}}>
                <Typography style={{textDecoration:"line-through" ,fontFamily:"MyFont",lineHeight:"16px", fontWeight:400,fontSize:"11px",}}>&#x20AC;3,780</Typography>
                <Typography style={{lineHeight:"16px", fontWeight:400,fontSize:"11px",fontFamily:"MyFont",color:"#000000",marginLeft: "4px", }}>only pay for 11 months</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      </CustomDrawer>
    )
  }
  checkItem(title: string, description?: string) {
    return (
      <>
        <Box className="featureBox">
          <img
            src={greenCheck}
            style={{ width: "16px", height: "16px" }}
            alt="greenCheck_img"
          />
          <Typography className="kellaTexts">{title}</Typography>
        </Box>
        {description &&  <Box className="kellaGrayText">
         {description}
        </Box>}
      </>
    )
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Setting>
        <Box className="outerBox">
          <Box className="arrow">
              <ArrowBack onClick={this.goBack} />
          </Box>
          <Box className="heading">Payment</Box>
          <Box className="mainBox">
            <Box className="innerBox">
              <Box className="headBox">
                <Typography className="headingMembership">
                  Membership plan
                </Typography>
                <Typography className="headingEdit" onClick={this.handleEdit}>Edit</Typography>
              </Box>
              <Box className="membershipBox">
                <Box className="headBox">
                  <Typography className="kellaEmpower">
                    Kella Empower
                  </Typography>
                  <Button
                    className="activeButton"
                    variant="contained"
                    disableElevation
                  >
                    Active
                  </Button>
                </Box>
                {this.checkItem('Kella App',)}
                {this.checkItem('Kella Content', ' Updated content and discussions in your areas of interest')}
                {this.checkItem('Kella Connect')}
                <Box className="headBox">
                  <Typography className="kella400">
                    €400{" "}
                    <span className="subs-billing-right-text-olive">+ VAT</span>
                    <span className="subs-billing-right-text-olive">
                      / monthly
                    </span>
                  </Typography>
                  <Typography className="viewDetails" onClick={this.handleOpenSubscriptionDetails}>View details</Typography>
                </Box>
              </Box>
              <Box className="subs-billing" sx={{mt: '20px'}}>
                <Box className="headBox">
                  <Typography className="subscriptionBilling">
                    Subscription billing
                  </Typography>
                  <Typography className="viewDetails" onClick={this.goToBilling}>View all</Typography>
                </Box>
                <Box className="subscriptionBillingBox">
                  <Box className="subscriptionsBillingInnerBox">
                    <Box className="headBox">
                      <Typography className="subs-billing-left-text">
                        Payment date
                      </Typography>
                      <Typography className="subs-billing-right-text">
                        Apr 14, 2024
                      </Typography>
                    </Box>
                    <Box className="headBox">
                      <Typography className="subs-billing-left-text">
                        Plan End date
                      </Typography>
                      <Typography className="subs-billing-right-text">
                        Sept 14, 2024
                      </Typography>
                    </Box>
                    <Box className="headBox">
                      <Typography className="subs-billing-left-text">
                        Promo code
                      </Typography>
                      <Typography className="subs-billing-right-text">
                        STANDARD10
                      </Typography>
                    </Box>
                    <Box className="headBox">
                      <Typography className="subs-billing-left-text">
                        Promo discount
                      </Typography>
                      <Typography className="subs-billing-right-text">
                        -€60
                      </Typography>
                    </Box>
                    <Box className="headBox">
                      <Typography className="subs-billing-left-text">
                        Total amount
                      </Typography>
                      <Typography className="subs-billing-right-text-olive">
                        €540<span color="#73A58E">+ VAT</span>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {this.subscriptionDetails()}
      </Setting>
      // Customizable Area End
    );
  }
}
// Customizable Area Start

export default Payment;

const Setting = styled(Box)({
  "& .outerBox": {
    backgroundColor: "#f2fff2",
    width: "100%",
    height: "100%",  
  },
  "& .arrow": {
    height: "48px",
    padding: "0px 2%",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",  
  },
  "& .heading": {
    fontFamily: "MyFont",
    backgroundColor: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    paddingLeft: "6%",
    height: "56px",
    fontSize: "24px",
    fontWeight: 700,   
  },
  "& .mainBox": {
    maxWidth: "816px",
    margin: "2% auto",
    borderRadius: "16px",
    backgroundColor: "#FFFFFF", 
    padding: "24px 20px",
    boxSizing: 'border-box'
  },
  "& .innerBox": {
    margin: "auto",
    maxWidth: "450px",
    display: "flex",
    flexDirection: "column",  
  },
  "& .headBox": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .promo": {
    display: "flex",
    alignItems: "center",
    marginTop: "2%",
    height: "64px",
  },
  "& .membershipBox": {
    borderRadius: "8px 8px 32px 8px",
    border: "2px solid #73A58E",
    padding: "16px",
    margin: "2% 0",
  },
  "& .headingMembership": {
    color: " #334155",
    fontSize: "16px",
    fontWeight: 900,
    letterSpacing: "0.05em",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  "& .headingEdit": {
    color: "#14362E",
    fontSize: "16px",
    fontWeight: 900,
    letterSpacing: "0.05em",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  "& .kellaEmpower": {
    color: "#0F172A",
    fontSize: "16px",
    fontWeight: "bolder",
    letterSpacing: "0.05em",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  "& .activeButton": {
    textTransform: "none",
    backgroundColor: "#14362E",
    color: "#FFFFFF",
    borderRadius: "20px",
    height: "20px",
    fontWeight: "bolder",
    letterSpacing: "0.05em",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  "& .featureBox": {
    display: "flex",
    marginTop: "8px",
    marginBottom: "8px",
  },
  "& .kellaTexts": {
    color: "#14362E",
    marginLeft: "20px",
    fontSize: "14px",
    letterSpacing: "0.05em",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  "& .kellaGrayText": {
    color: "#64748B",
    fontSize: "12px",
    letterSpacing: "0.05em",
    marginLeft: "25px",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  "& .kella400": {
    color: "#14362E",
    fontSize: "16px",
    fontWeight: "bolder",
    letterSpacing: "0.05em",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  "& .promoCode": {
    color: "#0F172A",
    fontSize: "16px",
    letterSpacing: "0.08em",
    fontFamily: "'MyFont', 'Halyard Display'",
    marginLeft: "5px",
  },
  "& .viewDetails": {
    color: "#14362E",
    fontSize: "16px",
    fontWeight: "bolder",
    letterSpacing: "0.08em",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  "& .subscriptionBilling": {
    color: "#334155",
    fontWeight: "bolder",
    letterSpacing: "0.08em",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  "& .subscriptionBillingBox": {
    marginTop: "10px",
    backgroundColor: "#f2fff2",
    borderRadius: "10px",
    height: "160px",
  },
  "& .subscriptionsBillingInnerBox": {
    padding: "15px",
  },
  "& .subs-billing-left-text": {
    color: "#000000",
    fontSize: "14px",
    letterSpacing: "0.08em",
    fontFamily: "'MyFont', 'Halyard Display'",
    marginTop: "6px",
  },
  "& .subs-billing-right-text": {
    color: "#14362E",
    fontSize: "14px",
    letterSpacing: "0.08em",
    fontWeight: "bolder",
    fontFamily: "'MyFont', 'Halyard Display'",
    marginTop: "6px",
  },
  "& .subs-billing-right-text-olive": {
    color: "#73A58E",
    fontSize: "14px",
    letterSpacing: "0.08em",
    fontWeight: "bolder",
    fontFamily: "'MyFont', 'Halyard Display'",
    marginTop: "6px",
  },
});

const CustomDrawer = styled(Drawer)({
  '@media (max-width: 600px)': {
    '& .MuiDrawer-paper': {
      bottom: '0',
      top: 'unset',
      height: '80vh',
      paddingBottom: '24px'
    },
},
'@media (max-height: 585px)': {
  '& .scrollableCard':{
    height: "468px",
    overflowY: 'auto'
  },
},
  '& .MuiDrawer-paper': {
    maxWidth: '484px',
    width:'100%',
    borderRadius: "24px 0px 0px 0px",
  },
  '& .TextStyle': {
    fontSize:"24px",
    fontFamily: "MyFont !important",
    fontWeight:500,
    lineHeight:"32px",
  },
  '& .TextStyle2': {
    fontSize:"20px",
    fontFamily: "MyFont !important",
    fontWeight:500,
    lineHeight:"28px",
  },
  '& .boxTextStyle': {
    height: "18px",
    paddingTop: "3%",
    fontSize:"12px",
    fontFamily: "MyFont !important",
    fontWeight:400,
    lineHeight:"18px",
    color: "#73A58E"
  },
  '& .Typostyle':{
    fontFamily:"MyFont !imporatnt",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#334155",
  },
  '& .boxTypoStyle1':{
   
    fontFamily:"MyFont !important",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "8px",
  },
  '& .boxTypoStyle2':{
    fontFamily:"MyFont !important",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "8px",
  },
  '& .buttonStyle':{
    height: "56px",
    margin:'15px',
    boderRadius:"8px",
    backgroundColor: "#14362E",
    color:"#FFFFFF"
  },
  '& .buttonStyle2':{
    height: "56px",
    margin:'15px',
    width: "350px",
    boderRadius:"8px",
    backgroundColor: "#14362E",
    color:"#FFFFFF",
    top:"79%",
  },
  '& .mainViewDetailDrawerStyle':{
    height: "1024px",
    left:"956px",
    borderRadiusadius:"24px, 0px, 0px, 0px",
    Padding:"0px, 0px, 40px, 0px",
    Gap:"24px",
    boxShadow: "0px 8.31px 33.24px 0px #0000000F",
  },
  
  '& .viewdrawerStyle':{
    height: "73px",
    padding: "12px 28px 12px 28px !important"

  },
  '& .viewdrawerBoxStyle':{
    height: "468px",
    marginTop:"25px",
    gap: "6px !important",
    border : "1px solid #73A58E",
    borderRadius: '8px 8px 32px 8px'
  },
  '& .viewdrawerBoxStyle2':{
    height: "480px",
    marginTop:"25px",
    gap: "6px !important",
    border : "1px solid #73A58E",
    borderRadius: '8px 8px 32px 8px'
  },
  '& .viewDrawerTypoStyle':{
    fontFamily:"MyFont !important",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  '& .viewDrawerButton':{
    height: "56px",
    margin:'15px',
    boderRadius:"8px",
    backgroundColor: "#14362E",
    color:"#FFFFFF",
    top:"79%",
  },
  '& .viewDrawerButton2':{
    height: "56px",
    margin:'15px',
    boderRadius:"8px",
    backgroundColor: "#14362E",
    color:"#FFFFFF",
    top:"79%",
    right:"20px"
  },
})
const webStyle = {
  boxTextFormat:{
    fontSize:"12px",
    fontFamily: "MyFont ",
    fontWeight:700,
    letterSpacing:"8%",
    lineHeight:"18px",
  },
}
// Customizable Area End
