import React from "react";



// Customizable Area Start
import { Box, Button, Typography, Grid } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { bannerpost, logo, mobilebanner, mobileHeading } from "./assets";
import LandingPageController, { Props } from "./LandingPageController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class LandingPageBlock extends LandingPageController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    let data = [
      {
        mainHeading: "Communications",
        smallHeading: "Women in leadership roles connect easily, communicate anytime.",
      },
      {
        mainHeading: "Collaboration",
        smallHeading: "Immersive luxury retreats and a peer-to-peer network.",
      },
      {
        mainHeading: "Community Building",
        smallHeading: "Facilitate network, connect, and events among its users.",
      },
    ];
    return (
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Box>
          <Grid container>
            <Grid
              item
              xs={12}
              md={8}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {/* first column */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  // height: "100%",
                  width: { xs: "100%", md: "auto", lg: "auto" },
                  position: {
                    xs: "absolute",
                    md: "none",
                    lg: "none",
                    xl: "none",
                  },
                  zIndex: { xs: "555", md: "none", lg: "none", xl: "none" },
                  bottom: { xs: "30px" },
                }}
              >
                <Box
                  sx={{
                    display: {
                      xs: "none",
                      md: "block",
                      lg: "block",
                      xl: "block",
                    },
                  }}
                >
                  <Box>
                    <img src={logo} alt="" />
                  </Box>
                  <Box>
                    <Typography
                      style={{
                        fontSize: "11px",
                        letterSpacing: "1.5px",
                        marginBottom: "7px",
                        marginTop: "10px",
                        fontFamily:"MyFont"
                      }}
                    >
                      {this.state.logoHeader}
                    </Typography>
                  </Box>
                </Box>
                {/* mobile view */}
                <Box
                  sx={{
                    display: {
                      xs: "block",
                      md: "none",
                      lg: "none",
                      xl: "none",
                    },
                  }}
                >
                  <Box>
                    <img src={mobileHeading} alt="" />
                  </Box>
                </Box>

                {/* Slider content */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "15px",
                  }}
                >
                  <Box
                    sx={{
                      color: {
                        xs: "white",
                        md: "black",
                        lg: "black",
                        xl: "black",
                      },
                    }}
                  >
                    <h2
                      style={{
                        textAlign: "center",
                        fontWeight: "600",
                        letterSpacing: "0.8px",
                        fontSize:"30px",
                        fontFamily:"MyFont"
                      }}
                    >
                      {data[this.state.currentSlide]?.mainHeading}
                    </h2>
                  </Box>
                  <Box
                    sx={{
                      color: {
                        xs: "white",
                        md: "black",
                        lg: "black",
                        xl: "black",
                      },
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "14px",
                        width: "255px",
                        textAlign: "center",
                        letterSpacing: "0.8px",
                        fontWeight:"400",
                        fontFamily:"MyFont"
                      }}
                    >
                      {data[this.state.currentSlide]?.smallHeading}
                    </Typography>
                  </Box>
                </Box>
                {/* Dots navigation */}
                <Box
                  sx={{
                    display: { xs: "none", md: "flex", lg: "flex" },
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  {data.map((item, index) => (
                    <span
                      key={index}
                      style={{
                        width: "10px",
                        height: "10px",
                        backgroundColor:
                          index === this.state.currentSlide
                            ? "#14362E"
                            : "#73A58E",
                        borderRadius: "50%",
                        margin: "0 5px",
                        cursor: "pointer",
                        transition: "background-color 0.3s ease",
                      }}
                      onClick={this.changeSlide.bind(this, index)}
                    />
                  ))}
                </Box>
                {/* dot navigation for mobile  */}
                <div>
                  <Box
                    sx={{
                      display: { xs: "flex", md: "none", lg: "none" },
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    {data.map((item, index) => (
                      <span
                        key={index}
                        style={{
                          cursor: "pointer",
                          width: "10px",
                          transition: "background-color 0.3s ease",
                          backgroundColor:
                            index === this.state.currentSlide
                              ? "white"
                              : "#73A58E",
                          height: "10px",
                          borderRadius: "50%",
                          margin: "0 5px",
                        }}
                        onClick={this.changeSlide.bind(this, index)}
                      />
                    ))}
                  </Box>
                </div>
                <Box
                  sx={{
                    marginTop: "12px",
                    display: {
                      xs: "none",
                      md: "block",
                      lg: "block",
                      xl: "block",
                    },
                  }}
                >
                  <Button
                    data-test-id={"btnEmailLogIn1"}
                    variant="contained"
                    style={{
                      background: "#14362E",
                      color: "white",
                      fontWeight:"500",
                        fontFamily:"MyFont",
                        fontSize:"16px",
                      width: "288px",
                      textTransform: "capitalize",
                      letterSpacing:"0.4px"
                    }}
                    onClick={()=>this.handleButtonClick()}
                  >
                    {data[this.state.currentSlide]?.mainHeading === "Community Building" ? "Get Started" : "Next"}
                  </Button>
                </Box>
                <Box
                  sx={{
                    marginTop: "12px",
                    bgcolor: { xs: "white" },
                    display: { md: "none", lg: "none" },
                    borderRadius:"5px"
                  }}
                >
                  <Button
                    data-test-id={"btnEmailLogIn"}
                    variant="contained"
                    style={{
                      background: "white",
                      color: "#14362E",
                      fontWeight:"500",
                        fontFamily:"MyFont",
                        fontSize:"16px",
                      width: "288px",
                      textTransform: "capitalize",
                      borderRadius:"5px",
                      letterSpacing:"0.4px"
                    }}
                    onClick={()=>this.handleButtonClick()}
                  >
                    {data[this.state.currentSlide]?.mainHeading ==
                      "Community Building"
                      ? "Get Started"
                      : "Next"}
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  display: {
                    xs: "none",
                    md: "block",
                    lg: "block",
                    xl: "block",
                  },
                }}
              >
                <img
                  src={bannerpost}
                  alt="login-screen"
                  style={{
                    maxWidth: "100%",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: { xs: "block", md: "none", lg: "none", xl: "none" },
                  position: { xs: "relative" },
                  height: { xs: "100vh" },
                }}
              >
                <img
                  src={mobilebanner}
                  alt="login-screen"
                  style={{
                    maxWidth: "100%",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
    );
  }
}
// Customizable Area End
