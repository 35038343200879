Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "subscriptionbilling2";
exports.labelBodyText = "subscriptionbilling2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.subscriptionAPiEndPoint = "/bx_block_stripe_integration/plans"
exports.subscriptionShowAPiEndPoint = "/bx_block_stripe_integration/plans/"
exports.subscriptionPromoAPiEndPoint = "/bx_block_stripe_integration/plans/apply_promo"
exports.orderSummaryAPiEndPoint = "/bx_block_stripe_integration/plans/get_order_summary"
exports.subscriptionCheckoutAPiEndPoint = "/bx_block_stripe_integration/plans/checkout"
// Customizable Area End