import React from "react";

//Customizable Area Start
import OTPInputAuthController from "../../otp-input-confirmation/src/OTPInputAuthController";
export default class ForgotPasswordBlockOTP extends OTPInputAuthController {

  render() {
    return (
        <>
        </>
    );
  }
}
//Customizable Area End