import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from 'react-hot-toast';
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  termsName:string;
  termsDescription:string;
  policyTitle:string;
  policyDescription:string;
  termsDrawerOpen:boolean;
  policyDrawerOpen:boolean;
  termsConditionOpen:boolean;
  heading: string;
  name: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class HelpCentreController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTermsConditionApiCallID: any;
  getPolicyApiCallID: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      termsName:"",
      termsDescription:"",
      policyTitle:"",
      policyDescription:"",
      termsDrawerOpen: false,
      policyDrawerOpen: false,
      termsConditionOpen: false,
      heading: "",
      name: "",
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getTermsConditionApiCallID) {
          this.handleTermsData(responseJson);
      }
      else if (apiRequestCallId === this.getPolicyApiCallID) {
          this.handlePolicyData(responseJson);
        }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleTermsOnClick = (name:string) => {
    this.setState({ termsDrawerOpen: true, name }, () => {
      this.getTermsConditions(name);
    });
  };

  termsDrawerClose = () => {
    this.setState({ termsDrawerOpen: false });
  };
  
  handlePrivacyOnClick = (heading:string) => {
    this.setState({ policyDrawerOpen: true, heading }, () => {
      this.getPolicies(heading);
    });
  };

  policyDrawerClose = () => {
    this.setState({ policyDrawerOpen: false, policyTitle:"",policyDescription:"" });
  };

  getTermsConditions(name:string) {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("accessToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTermsConditionApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.termsAndConditionApiEndPoint}?name=${name}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getPolicies(heading:string) {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("accessToken"),
      };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPolicyApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.policiesApiEndPoint}?title=${heading}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleTermsData = (responseJson: any) => {
    const { data } = responseJson || {};
    const { attributes } = data || {};
  
    if (attributes) {
      const { name, description } = attributes;
      this.setState({
        termsName: name || '',
        termsDescription: description || '',
      });
    } else {
      toast.error("Failed to retrieve terms data from API response.");
    }
  };
  

  handlePolicyData = (responseJson: any) => {
    const policy = responseJson?.policy;
  
    if (policy) {
      const { title, description } = policy;
      if (title) {
        this.setState({ policyTitle: title });
      }
      if (description) {
        this.setState({ policyDescription: description });
      }
    } else {
      toast.error("Failed to retrieve policy data from API response.");
    }
  };
  

  navigateToFAQ = () => {
    window.location.href = "FAQ"
  }
  // Customizable Area End
}
