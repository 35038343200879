import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, logo, bannerpost } from "./assets";
import MergeEngineUtilities from "../../utilities/src/MergeEngineUtilities";
import { setStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  logo: any,
  bannerpost: any,
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  showAlertError: boolean,
  alertErrorMessage: string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      bannerpost: bannerpost,
      logo: logo,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      showAlertError: false,
      alertErrorMessage: ""
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  goToTermsAndConditionPage = async (newsfeedd: string) => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        newsfeedd
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage),
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    runEngine.sendMessage(message.id, message);

  };
  handleRedirecterAfterLogin(is_application_status_approved: string, is_personal_profile_completed: boolean) {
     setStorageData("applicationStatus", is_application_status_approved);
    if(is_application_status_approved === 'approved') {
      if(!is_personal_profile_completed) {
        this.goToTermsAndConditionPage("OnboardingScreen")
      }else {
        this.goToTermsAndConditionPage("NewsFeed")
      }
    }else {
     this.goToTermsAndConditionPage("ApprovalStates")
    }
  }
  handleEmailLoginApiCall(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      localStorage.setItem("accessToken", responseJson.meta.token);
      localStorage.setItem("account_id", responseJson.meta.id);
      this.saveLoggedInUserData(responseJson);
      this.sendLoginSuccessMessage();
      this.handleRedirecterAfterLogin(responseJson.meta.application_status, responseJson.meta.is_personal_profile_completed)
    } else {
      this.setState({
        showAlertError: true,
        alertErrorMessage: responseJson["errors"][0]["failed_login"]
      });
      this.sendLoginFailMessage();
    }
  }  
  handleValidationApiCall(responseJson: any) {
    const arrayholder = responseJson.data;
    if (arrayholder && arrayholder.length !== 0) {
      const regexData = arrayholder[0];
      if (regexData && regexData.email_validation_regexp) {
        this.emailReg = new RegExp(regexData.email_validation_regexp);
      }
    }
  }

  isApiResponseMessage(message: Message): boolean {
    return getName(MessageEnum.RestAPIResponceMessage) === message.id;
  }
  handleUserCredentials(message: Message) {
    const userName = message.getData(getName(MessageEnum.LoginUserName));
    const password = message.getData(getName(MessageEnum.LoginPassword));
    const countryCode = message.getData(getName(MessageEnum.LoginCountryCode));
    if (!countryCode && userName && password) {
      this.setState({
        email: userName,
        password: password,
        checkedRememberMe: true,
      });
    }
  }
  isUserCredentialsMessage(message: Message): boolean {
    return getName(MessageEnum.ReciveUserCredentials) === message.id;
  }
  handleApiResponse(message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
  
    if (apiRequestCallId != null) {
      this.handleApiRequestCall(apiRequestCallId, responseJson, errorReponse);
    }
  }
  handleApiRequestCall(apiRequestCallId: string, responseJson: any, errorReponse: any) {
    if (apiRequestCallId === this.validationApiCallId && responseJson !== undefined) {
      this.handleValidationApiCall(responseJson);
    }
    if (apiRequestCallId === this.apiEmailLoginCallId) {
      this.handleEmailLoginApiCall(responseJson);
    }
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if(this.isUserCredentialsMessage(message)) {
      this.handleUserCredentials(message);
    } else if (this.isApiResponseMessage(message)){
      this.handleApiResponse(message);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));   
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    window.location.href = "ForgotPassword"
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  async doEmailLogIn() {
    if (
      this.state.email === null ||
      this.state.email.length === 0 || 
      !this.emailReg.test(this.state.email)
    ) {
      this.setState({ showAlertError: true, alertErrorMessage: configJSON.errorEmailNotValid })
      return false;
    }

    if(this.state.password === null || this.state.password === ""){
      this.setState({ showAlertError: true, alertErrorMessage: configJSON.errorPasswordNotProper })
      return false;
    }
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };
    
    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  handlesignupNavigation(){
    window.location.href = "EmailAccountRegistration"
  }
  // Customizable Area End
  
}
