import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { setStorageData } from "framework/src/Utilities";

const dummy1 = [{ label: 'CEO/President', value: 'CEO/President', },
{ label: 'C-Suite Level', value: 'C-Suite Level' },
{ label: 'Director', value: 'Director' },
{ label: 'Manager', value: 'Manager' },
{ label: 'Founder', value: 'Founder' },
]
const dummy2 = [{ label: '0', value: '0', },
{ label: '1 - 10', value: '1 - 10' },
{ label: '11 - 15', value: '11 - 15' },
{ label: '16 - 49', value: '16 - 49' },
{ label: '50 - 99', value: '50 - 99' },
{ label: '100+', value: '100+' }]
const dummy3 = [{ label: 'Large Enterprise Leaders (5,000+ employees)', value: 'Large Enterprise Leaders (5,000+ employees)', },
{ label: 'Medium Enterprise Leaders (1,000-4,999 employees)', value: 'Medium Enterprise Leaders (1,000-4,999 employees)' },
{ label: 'Small Enterprise Leaders (Up to 999 employees)', value: 'Small Enterprise Leaders (Up to 999 employees)' },
{ label: 'Entrepreneurial Leaders', value: 'Entrepreneurial Leaders' }]
const dummy4 = [{ label: 'Kella event', value: 'Kella event', },
{ label: 'Kella member', value: 'Kella member' },
{ label: 'Colleague/friend', value: 'Colleague/friend' },
{ label: 'Linkedin', value: 'Linkedin' },
{ label: 'Social', value: 'Social' },
{ label: 'Press coverage', value: 'Press coverage' },
{ label: 'Other', value: 'Other' }]
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showLastGrid: boolean;
  activeStep: number;
  anchorEl: null;
  menuOpen: boolean;
  webDrawerOpen: boolean;
  searchQuery: string;
  options: any;
  drawerHeading: string;
  mobileDrawerOpen: boolean;
  dummyRadioOptions: { label: string, value: string }[];
  selectedNumberValue: number,
  selectedOption: string;
  dummyState: number;
  professionalLevel: string;
  employeesReporting: string;
  organizationSize: string;
  supportedByCompany: string;
  heardAboutKellaFrom: string;
  showModel:boolean;
  errorCheckBox:boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ProfessionalDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  updateAccountAPICallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      showLastGrid: false,
      activeStep: 0,
      anchorEl: null,
      menuOpen: false,
      webDrawerOpen: false,
      searchQuery: "",
      options: 'qwet',
      drawerHeading: '',
      mobileDrawerOpen: false,
      dummyRadioOptions: [],
      dummyState: 0,
      selectedNumberValue: 0,
      selectedOption: '',
      professionalLevel: '',
      employeesReporting: '',
      organizationSize: '',
      supportedByCompany: '',
      heardAboutKellaFrom: '',
      showModel:false,
      errorCheckBox:false
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    // Customizable Area End
  }

  // Customizable Area Start

  toggleDrawer = (anchor: string, open: any, heading: string, dummyState: number) => () => {
    if (dummyState === 1) {
      this.setState({ drawerHeading: configJSON.profDetailsHeading1, dummyRadioOptions: dummy1, selectedNumberValue: 1 })
    }
    else if (dummyState === 2) {
      this.setState({ drawerHeading: configJSON.profDetailsHeading2, dummyRadioOptions: dummy2, selectedNumberValue: 2 })
    }
    else if (dummyState === 3) {
      this.setState({ drawerHeading: configJSON.profDetailsHeading3, dummyRadioOptions: dummy3, selectedNumberValue: 3 })
    }
    else if (dummyState === 4) {
      this.setState({ drawerHeading: configJSON.profDetailsHeading4, dummyRadioOptions: dummy4, selectedNumberValue: 4 })
    }
    if (anchor === 'web') {
      this.setState({ webDrawerOpen: open, drawerHeading: heading, dummyState });
    } else if (anchor === 'mobile') {
      this.setState({ mobileDrawerOpen: open, drawerHeading: heading, dummyState });
    }
  };

  handleSelect = () => {
    const { selectedOption } = this.state
    this.setState({ webDrawerOpen: false, mobileDrawerOpen: false });

    if (this.state.selectedNumberValue === 1) {
      this.setState({ professionalLevel: selectedOption })
    } else if (this.state.selectedNumberValue === 2) {
      this.setState({ employeesReporting: selectedOption })
    } else if (this.state.selectedNumberValue === 3) {
      this.setState({ organizationSize: selectedOption })
    } else if (this.state.selectedNumberValue === 4) {
      this.setState({ heardAboutKellaFrom: selectedOption })
    }
  };

  updateProfessionalDetails = () => {
    const { professionalLevel, employeesReporting, organizationSize, supportedByCompany, heardAboutKellaFrom } = this.state
    const token = localStorage.getItem("token")
    const header = {
      token: token
    };
    let attrs = new FormData();
    if (professionalLevel) {
      attrs.append("account[professional_level]", professionalLevel);
    }
    if (employeesReporting) {
      attrs.append("account[employees_reporting]", employeesReporting);
    }
    if (organizationSize) {
      attrs.append("account[organization_size]", organizationSize);
    }
    if (supportedByCompany) {
      attrs.append("account[supported_by_company]", supportedByCompany);
    }
    if (heardAboutKellaFrom) {
      attrs.append("account[heard_about_kella_from]", heardAboutKellaFrom);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateAccountAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateUserProfile
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      attrs
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePatch
    );

    setStorageData('applicationStatus', 'pending')
    window.location.href = "ApprovalStates"
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleOptionChange = (event: any) => {
    this.setState({ selectedOption: event.target.value })
  }
  handleChange = (event: any) => {
    this.setState({ supportedByCompany: event.target.value })
  }
  handleTermsCondsOpen = () => {
    this.setState({ showModel: true });
  };

  handleDialogClose = () => {
    this.setState({ showModel: false });
  };
  // Customizable Area End
}
