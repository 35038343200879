import React from "react";

//Customizable Area Start
import {
  Box,
  Button,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  InputLabel,
  Grid,
  Modal,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { logo, women, Success, Warning, initialCheckBoxes, greeTickImg } from "./assets";
import Alert from "@material-ui/lab/Alert";
import ForgotPasswordController from "./ForgotPasswordController";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
//istanbul ignore next
export default class NewPasswordBlock extends ForgotPasswordController {
  validatePassword() {
    if (this.state.errorPasswordFlag) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            marginLeft: {xs: "",md: "-10%"},
            fontFamily: "'MyFont', 'Halyard Display'",
          }}
        >
          <Typography style={webStyle.headingErrorBox}>
            Your password must contain
          </Typography>
          <Box style={webStyle.newErrorBox}>
            {this.state.isOneCapital ? (
              <>
                <Box>
                  <img
                    src={Success}
                    alt="warningIcon"
                    style={webStyle.imgSize}
                  />
                </Box>
                <Box>
                  <Typography style={webStyle.newPasswordSuccessTypo}>
                    At least one capital letter
                  </Typography>
                </Box>
              </>
            ) : (
              <>
                <Box>
                  <img
                    src={Warning}
                    alt="warningIcon"
                    style={webStyle.imgSize}
                  />
                </Box>
                <Box>
                  <Typography style={webStyle.newPasswordSuccessTypoError}>
                    At least one capital letter
                  </Typography>
                </Box>
              </>
            )}
          </Box>
          <Box style={webStyle.newErrorBox}>
            {this.state.isOneLower ? (
              <>
                <Box>
                  <img
                    src={Success}
                    alt="warningIcon"
                    style={webStyle.imgSize}
                  />
                </Box>
                <Box>
                  <Typography style={webStyle.newPasswordSuccessTypoError}>
                    At least one lowercase letter
                  </Typography>
                </Box>
              </>
            ) : (
              <>
                <Box>
                  <img
                    src={Warning}
                    alt="warningIcon"
                    style={webStyle.imgSize}
                  />
                </Box>
                <Box>
                  <Typography style={webStyle.newPasswordSuccessTypoError}>
                    At least one lowercase letter
                  </Typography>
                </Box>
              </>
            )}
          </Box>
          <Box style={webStyle.newErrorBox}>
            {this.state.isOneNumber ? (
              <>
                <Box>
                  <img
                    src={Success}
                    alt="warningIcon"
                    style={webStyle.imgSize}
                  />
                </Box>
                <Box>
                  <Typography style={webStyle.newPasswordSuccessTypo}>
                    At least one number
                  </Typography>
                </Box>
              </>
            ) : (
              <>
                <Box>
                  <img
                    src={Warning}
                    alt="warningIcon"
                    style={webStyle.imgSize}
                  />
                </Box>
                <Box>
                  <Typography style={webStyle.newPasswordSuccessTypoError}>
                    At least one number
                  </Typography>
                </Box>
              </>
            )}
          </Box>
          <Box style={webStyle.newErrorBox}>
            {this.state.isValidLength ? (
              <>
                <Box>
                  <img
                    src={Success}
                    alt="warningIcon"
                    style={webStyle.imgSize}
                  />
                </Box>
                <Box>
                  <Typography style={webStyle.newPasswordSuccessTypo}>
                    Minimum character length is 8 characters
                  </Typography>
                </Box>
              </>
            ) : (
              <>
                <Box>
                  <img
                    src={Warning}
                    alt="warningIcon"
                    style={webStyle.imgSize}
                  />
                </Box>
                <Box>
                  <Typography style={webStyle.newPasswordSuccessTypoError}>
                    Minimum character length is 8 characters
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Box>
      );
    } else {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            marginLeft: {xs: "",md: "-10%"}
          }}
        >
          <Typography style={webStyle.headingErrorBox}>
            Your password must contain
          </Typography>
          <Box style={webStyle.newErrorBox}>
            <Box>
              <img
                src={initialCheckBoxes}
                alt="intial_icon"
                style={webStyle.imgSize}
              />
            </Box>
            <Box>
              <Typography style={webStyle.newPasswordSuccessTypo}>
                At least one capital letter
              </Typography>
            </Box>
          </Box>
          <Box style={webStyle.newErrorBox}>
            <Box>
              <img
                src={initialCheckBoxes}
                alt="intial_icon"
                style={webStyle.imgSize}
              />
            </Box>
            <Box>
              <Typography style={webStyle.newPasswordSuccessTypoError}>
                At least one lowercase letter
              </Typography>
            </Box>
          </Box>
          <Box style={webStyle.newErrorBox}>
            <Box>
              <img
                src={initialCheckBoxes}
                alt="intial_icon"
                style={webStyle.imgSize}
              />
            </Box>
            <Box>
              <Typography style={webStyle.newPasswordSuccessTypo}>
                At least one number
              </Typography>
            </Box>
          </Box>
          <Box style={webStyle.newErrorBox}>
            <Box>
              <img
                src={initialCheckBoxes}
                alt="intial_icon"
                style={webStyle.imgSize}
              />
            </Box>
            <Box>
              <Typography style={webStyle.newPasswordSuccessTypo}>
                Minimum character length is 8 characters
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    }
  }
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            flexDirection: "column",
            alignItems: "center",
            height: "100%",
            fontFamily: "'MyFont', 'Halyard Display'",
            display: "flex",
          }}
        >
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid
              item
              xs={12}
              md={8}
              style={{
                alignItems: "center",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{ width: { xs: "92%", md: "380px" }, paddingTop: "20px" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        marginTop: { xs: "50", md: "50px", lg: "50" },
                      }}
                    >
                      <img src={logo} alt="kella_logo_txt" />{" "}
                    </Box>
                  </Box>

                  <Box sx={{ marginTop: "45", fontSize: { xs: "16px" } }}>
                    <Typography
                      variant="subtitle1"
                      component="div"
                      text-align="left"
                      line-height="28px"
                      style={{
                        fontSize: "20px",
                        color: "#14362E",
                        fontWeight: 700,
                        fontFamily: "'MyFont', 'Halyard Display'",
                        letterSpacing: "0.08em"
                      }}
                    >
                      {this.state.labelNewPassword}
                    </Typography>
                  </Box>
                  <Box sx={{ marginTop: "7" }}>
                    <Typography
                      variant="subtitle1"
                      component="div"
                      text-align="left"
                      line-height="24px"
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#0F172A",
                        fontFamily: "'MyFont', 'Halyard Display'",
                        letterSpacing: "0.05em",
                      }} >
                     Enter a new password for {this.state.storedEmail}
                    </Typography>
                  </Box>
                  <Box sx={{ padding: "40px 0px 0px 0px", fontFamily: "'MyFont', 'Halyard Display'", letterSpacing: "0.05em"
                }}>
                    <InputLabel
                      style={{
                        marginBottom: "10px",
                        color: "#334155",
                        fontWeight: "bold",
                        fontFamily: "'MyFont', 'Halyard Display'",
                        letterSpacing: "0.05em"
                      }}
                    >
                      New password
                    </InputLabel>
                    <TextField
                      data-test-id="txtInputPassword"
                      type={this.state.enablePassword ? "text" : "password"}
                      placeholder="Your new password"
                      autoComplete="off"
                      error={
                        !this.state.errorPasswordFlag
                          ? this.state.isValidPasswordField
                          : !(this.state.isOneCapital && this.state.isOneLower && this.state.isOneNumber && this.state.isValidLength)
                      }
                      value={this.state.newPassword}
                      fullWidth
                      variant="outlined"
                      onChange={(e) => this.setNewPassword(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={this.handleClickShowPassword}
                              aria-label="toggle password visibility"
                              edge="end"
                              data-test-id="showPass"
                            >
                              {this.state.enablePassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                        style: {
                          marginTop: "5px",
                          borderRadius: "8px",
                          height: "45px",
                          padding: "10px",
                        },
                      }}
                    />
                  </Box>

                  <Alert
                    severity="warning"
                    onClose={() => {
                      this.setState({ passwordMatch: false });
                    }}
                    style={{
                      position: "relative",
                      top: "9%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      zIndex: 9999,
                      fontFamily: "'MyFont', 'Halyard Display'",
                      display: this.state.passwordMatch ? "flex" : "none",
                    }}
                  >
                    Password do not match.
                  </Alert>
                  <Box sx={{ padding: "20px 0px 0px 0px", fontFamily: "'MyFont', 'Halyard Display'", letterSpacing: "0.05em" }}>
                    <InputLabel
                      style={{
                        marginBottom: "10px",
                        color: "#334155",
                        fontWeight: 900,
                        fontFamily: "'MyFont', 'Halyard Display'",
                        letterSpacing: "0.05em"
                      }}
                    >
                      Confirm new password
                    </InputLabel>
                    <TextField
                      autoComplete="off"
                      data-test-id="changePasswordForm"
                      error={
                        !this.state.errorConfirmPasswordFlag
                          ? this.state.isValidPasswordField
                          : !(this.state.isOneCapital && this.state.isOneLower && this.state.isOneNumber && this.state.isValidLength)
                      }
                      type={this.state.enableConfirmPassword ? "text" : "password"}
                      placeholder="Confirm your new password"
                      value={this.state.confirmNewPassword}
                      onChange={(e) => this.setConfirmPassword(e.target.value)}
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              data-test-id="showConfirmPass"
                              onClick={this.handleClickConfirmShowPassword}
                              aria-label="toggle password visibility"
                              edge="end">
                              {this.state.enableConfirmPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                        style: { height: "45px", padding: "10px", marginTop: "5px", borderRadius: "8px", }
                      }} />
                  </Box>
                </Box>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "2px",
                  }}
                >
                  {this.validatePassword()}
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: {xs: "60px"},
                  }}
                >
                </Box>
                <Box sx={{ width: "310px" }}>
                  <Button
                    fullWidth
                    id="web-login-button"
                    variant="contained"
                    data-test-id="setPassword"
                    style={{
                      fontWeight: 590,
                      fontSize: "16px",
                      height: "56px",
                      fontFamily: "'MyFont', 'Halyard Display'",
                      textTransform: "none",
                      backgroundColor: this.state.newPassword
                        ? "#14362E"
                        : "#E2E8F0",
                      color: "white",
                      borderRadius: "8px",
                      letterSpacing: "0.08em",
                    }}
                    disabled={!this.state.newPassword} 
                    onClick={this.resetPasswordApi}
                  >
                    Set new password
                    
                  </Button>
                  <Modal
                    open={this.state.successPopupOpen}
                    onClose={this.handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                  >
                    <Box sx={{ ...webStyle.modalStyle, borderRadius: "0 10px 0 0" }}>
                    <img src={greeTickImg} alt="Img" style={webStyle.modalImg}/>
                    <Box sx={{...webStyle.modalStyleTypo}}>
                        New password set Successfully
                    </Box>
                    <Box sx={{...webStyle.modalStyleTypoSecond}}>
                        Congratulations, your new password set successfully. You can signing with new password.
                    </Box>
                    </Box>
                  </Modal>
                </Box>

                <Box
                  style={{
                    fontWeight: "500",
                    flexDirection: "row",
                    display: "flex",
                    fontSize: "16px",
                    lineHeight: "24px",
                    padding: "8px 0px",
                    marginTop: "8px",
                    alignItems: "center",
                    justifyContent: "center",
                    letterSpacing: "0.08em",
                    fontFamily: "'MyFont', 'Halyard Display'",
                  }}
                >
                  Back to &nbsp;
                  <span style={{ cursor: "pointer" }}>
                    <a
                      href="/EmailAccountLoginBlock"
                      style={{
                        fontWeight: "bold",
                        color: "#14362E",
                        textDecoration: "none",
                      }}
                    >
                      Log in
                    </a>
                  </span>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ display: { xs: "none", md: "flex", lg: "flex" } }}>
                <img
                  alt="forgot-password-screen"
                  src={women}
                  style={{
                    width: "100%",
                    height: "100%",
                    maxWidth: "100%",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
    );
  }
}

const webStyle = {
  headingErrorBox: {
    marginTop: "20px",
    fontSize: "16px",
    letterSpacing: "0.08em",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  newErrorBox: {
    marginTop: "6px",
    display: "flex",
    justifyContent: "flex-start",
    gap: "10px",
    alignItems: "center",
  },
  newPasswordSuccessTypo: {
    fontSize: "13px",
    fontWeight: 400,
    letterSpacing: "0.08em",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  newPasswordSuccessTypoError: {
    fontSize: "13px",
    fontWeight: 400,
    letterSpacing: "0.08em",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  imgSize: {
    height: "15px",
  },
  modalStyle: {
    position: "absolute" as "absolute",
    top: "50%",
    left: {xs: "50%",md: "55%"},   
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    width: {xs: "70%",md: "26%"},
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: 24,
    p: 4,
  },
  modalImg: {
    width: "14%",
    height: "auto"
  },
  modalStyleTypo: {
    fontSize: "24px", 
    fontWeight: "bold",
    color: "#14362E",
    marginTop: "5%",
    width: {xs: "90%", md: "70%"},
    fontFamily: "'MyFont', 'Halyard Display'",
    textAlign: "center", 
    letterSpacing: "0.08em"
  },
  modalStyleTypoSecond: {
    fontSize: "14px",  
    marginTop: "3%",
    color: "#0F172A",
    width: "100%",
    fontFamily: "'MyFont', 'Halyard Display'",
    textAlign: "center", 
    letterSpacing: "0.08em"
  }
};
// Customizable Area End
