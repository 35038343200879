import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-hot-toast";
import moment from 'moment';
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  connections: any[];
  requests: any[];
  invitations: any[];
  blockedUsers: any[];
  searchQuery: string;
  currentView: string;
  anchorEl: any;
  selectedIndex: any;
  openInvited: boolean;
  isModalOpen: boolean;
  connectionToRemove: any;
  isDrawerOpen: boolean;
  connectionToBlock: any;
  cancelInvite: any;
  reasonTitle: string;
  reasonDescription: string;
  continueBlockUserModal: boolean;
  continueBlockDrawer: boolean;
  unblockingUserId:any;
  requestedUserId:any;
  declinedUserId:any;
  removingUserId: number | null;
  actionType: 'removeFromCircle' | 'cancelInvite' | null;
  isMenuOpen: boolean;
  profile: any;
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class AddFriendsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getConnectionsApiCallID: any;
  getRequestsApiCallID: any;
  getInvitationsApiCallID: any;
  getBlockedUserApiCallID: any;
  acceptRequestApiCallID: any;
  declineRequestApiCallID: any;
  removeFromMyCircleApiCallID: any;
  blockUserApiCallID: any;
  unBlockUserApiCallID: any;
  getuserdataCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
        connections: [],
        requests: [],
        invitations: [],
        blockedUsers: [],
        searchQuery: "",
        currentView: "connections",
        anchorEl: null,
        selectedIndex: null,
        openInvited: false,
        isModalOpen: false,
        connectionToRemove: null,
        isDrawerOpen: false,
        connectionToBlock: null,
        cancelInvite: null,
        reasonTitle: "",
        reasonDescription: "",
        continueBlockUserModal: false,
        continueBlockDrawer: false,
        unblockingUserId:null,
        requestedUserId:null,
        declinedUserId:null,
        removingUserId:null,
        actionType:null,
        isMenuOpen: false,
        profile: [],
        isLoading: true,
      };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiRequestCallId === this.getConnectionsApiCallID) {
          this.handleConnectionData(responseJson);
        } else if (apiRequestCallId === this.getRequestsApiCallID) {
          this.handleRequestData(responseJson);
        } else if (apiRequestCallId === this.getInvitationsApiCallID) {
          this.handleInvitationData(responseJson);
        } else if (apiRequestCallId === this.getBlockedUserApiCallID) {
          this.handleBlockedUserData(responseJson);
        } else if (apiRequestCallId === this.acceptRequestApiCallID) {
          this.handleAccepRequestData(responseJson);
        } else if (apiRequestCallId === this.declineRequestApiCallID) {
          this.handleDeclineRequestData(responseJson);
        } else if (apiRequestCallId === this.removeFromMyCircleApiCallID) {
          this.handleRemoveUserResponse(responseJson);
        } else if (apiRequestCallId === this.blockUserApiCallID) {
          this.handleBlockedUserResponse(responseJson);
        } else if (apiRequestCallId === this.unBlockUserApiCallID) {
          this.handleUnBlockedUserResponse(responseJson);
        } else if(apiRequestCallId === this.getuserdataCallId) {
          this.handleProfileData(responseJson);
        }

      }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getRequests();
    this.getConnections();
    this.getInvitations();
    this.getBlockedUsers();
    this.getViewProfileData();
  }

  async componentDidUpdate(){
  }
  getButtonStyle = (buttonName: any) => {
    const { currentView } = this.state;
    const baseStyle = {
      fontWeight: "bold" as "bold",
      fontFamily: "MyFont",
      letterSpacing: "1px",
      fontSize: "16px",
      textTransform: "capitalize" as "capitalize",
    };

    if (buttonName === currentView) {
      return {
        ...baseStyle,
        border: "1px solid #73A58E",
        borderRadius: "50px",
        color: "#14362E",
      };
    } else {
      return {
        ...baseStyle,
        color: "#64748B",
      };
    }
  };

  getButtonStyleMobile = (buttonName: any) => {
    const { currentView } = this.state;
    const baseStyle = {
      fontWeight: 500,
      fontFamily: "MyFont",
      letterSpacing: "1px",
      fontSize: "12px",
      textTransform: "capitalize" as "capitalize",
    };

    if (buttonName === currentView) {
      return {
        ...baseStyle,
        border: "1px solid #73A58E",
        borderRadius: "50px",
        color: "#14362E",
      };
    } else {
      return {
        ...baseStyle,
        color: "#64748B",
      };
    }
  };
    //istanbul ignore next
  formatDuration = (createdAt: string) => {
    const now = moment();
    const createdAtDate = moment(createdAt);
    const duration = moment.duration(now.diff(createdAtDate));
  
    const days = duration.asDays();
    const weeks = duration.asWeeks();
  
    if (weeks >= 1) {
      const roundedWeeks = Math.floor(weeks);
      return `${roundedWeeks} Week${roundedWeeks > 1 ? 's' : ''}`;
    } else if (days >= 1) {
      const roundedDays = Math.floor(days);
      return `${roundedDays} Day${roundedDays > 1 ? 's' : ''}`;
    } else {
      const hours = duration.asHours();
      const roundedHours = Math.floor(hours);
      return `${roundedHours} Hour${roundedHours > 1 ? 's' : ''}`;
    }
  };

  setView = (view: any) => {
    this.getRequests();
    this.getConnections();
    this.getInvitations();
    this.getBlockedUsers();
    this.getViewProfileData();
    this.setState({ currentView: view });
  };

  handleClick = (event: any, index: number) => {
    this.setState({ anchorEl: event.currentTarget, selectedIndex: index });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, selectedIndex: null });
  };

  redirectToChat = (data: any) => {
    localStorage.setItem("room_id", data.room_id)
    window.location.href = "/ViewChat"
  }

  handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchQuery: event.target.value });
  };

  clearSearch = () => {
    this.setState({ searchQuery: "" });
  };

  handleInviteClick = (event: any, index: number, cancelInvite: any) => {
    console.log(cancelInvite)
    this.setState({
      anchorEl: event.currentTarget,
      selectedIndex: index,
      cancelInvite: cancelInvite,
      isMenuOpen: true,
    });
    
  };

  handleInviteClose = () => {
    this.setState({ anchorEl: null, selectedIndex: null, cancelInvite: null, isMenuOpen: false});
  };

  handleClickAway = (event: any) => {
    this.setState({ isMenuOpen: false });
  };

  handleOpenModal = (connection: any) => {
    this.setState({ isModalOpen: true, connectionToRemove: connection });
  };

  handleCloseModal = () => {
    this.setState({ isModalOpen: false, connectionToRemove: null });
  };

  handleBlockDrawer = (user: any) => {
    this.setState({ isDrawerOpen: true, connectionToBlock: user });
  };

  closeBlockDrawer = () => {
    this.setState({ isDrawerOpen: false });
  };

  handleReasonSelect = (title: string, description: string) => {
    this.setState({ reasonTitle: title, reasonDescription: description });
  };

  handleContinueBlock = () => {
    const { reasonTitle, reasonDescription, connectionToBlock } = this.state;
    if (reasonTitle && reasonDescription && connectionToBlock) {
      this.setState({ continueBlockUserModal: true });
      this.closeBlockDrawer();
    } else {
      toast.error("Please select a reason for blocking the user.");
    }
  };

  handleCloseContinueBlockModal = () => {
    this.setState({ continueBlockUserModal: false, connectionToBlock: null });
  };

  handleContinueBlockMobile = () => {
    const { reasonTitle, reasonDescription, connectionToBlock } = this.state;
    if (reasonTitle && reasonDescription && connectionToBlock) {
      this.setState({ continueBlockDrawer: true });
      this.closeBlockDrawer();
    } else {
      toast.error("Please select a reason for blocking the user.");
    }
  };

  handleCloseContinueBlockDrawer = () => {
    this.setState({ continueBlockDrawer: false, connectionToBlock: null });
  };

  getConnections = () => {
    const header = {
      "Content-Type": configJSON.myCircleApiContentType,
      token: localStorage.getItem("accessToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getConnectionsApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getConnectionEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getRequests = () => {
    const header = {
      "Content-Type": configJSON.myCircleApiContentType,
      token: localStorage.getItem("accessToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRequestsApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRequestEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getInvitations = () => {
    const header = {
      "Content-Type": configJSON.myCircleApiContentType,
      token: localStorage.getItem("accessToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getInvitationsApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getInvitationEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getBlockedUsers = () => {
    const header = {
      "Content-Type": configJSON.myCircleApiContentType,
      token: localStorage.getItem("accessToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBlockedUserApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBlockedUserEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getViewProfileData(){
    const account_id = localStorage.getItem("account_id");
    const header = {
      "Content-Type": configJSON.myCircleApiContentType,
      token: localStorage.getItem("accessToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getuserdataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${account_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  acceptUsersRequest = (sender_id: number) => {
    const header = {
      "Content-Type": configJSON.myCircleApiContentType,
      token: localStorage.getItem("accessToken"),
    };

    const httpBody = {};

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.acceptRequestApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.acceptRequestEndPoint}${sender_id}/request_accepted`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({ requestedUserId: sender_id });
  };

  declineUsersRequest = (sender_id: number) => {
    const header = {
      "Content-Type": configJSON.myCircleApiContentType,
      token: localStorage.getItem("accessToken"),
    };

    const httpBody = {};

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.declineRequestApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.declineRequestEndPoint}${sender_id}/request_rejected`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({ declinedUserId: sender_id });
  };

  handleRemoveUser = (
    user_id: number,
    actionType: "removeFromCircle" | "cancelInvite"
  ) => {
    const header = {
      "Content-Type": configJSON.accountDetailsAPiContentType,
      token: localStorage.getItem("accessToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removeFromMyCircleApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.removeFromMyCircleEndPoint}${user_id}/request_remove`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({ removingUserId: user_id, actionType });
  };

  handleBlockUser = (user_id: any) => {
    const header = {
      "Content-Type": configJSON.myCircleApiContentType,
      token: localStorage.getItem("accessToken"),
    };

    const httpBody = {
      blocker_id: user_id,
      reason_title: this.state.reasonTitle,
      reason_description: this.state.reasonDescription,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.blockUserApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.blockUserEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleUnblockUser = (user_id: any) => {
    const header = {
      "Content-Type": configJSON.myCircleApiContentType,
      token: localStorage.getItem("accessToken"),
    };

    const httpBody = {
      account_id: user_id,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.unBlockUserApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.unBlockUserEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({ unblockingUserId: user_id });
  };

  handleConnectionData = (responseJson: any) => {
    if (responseJson && Array.isArray(responseJson.accounts)) {
      this.setState({ connections: responseJson.accounts });
    } else {
      console.error("Failed to retrieve connections data from API response.");
    }
  };

  handleRequestData = (responseJson: any) => {
    if (responseJson && Array.isArray(responseJson.data)) {
      this.setState({ requests: responseJson.data });
    } else if (
      responseJson.meta &&
      responseJson.meta.message === "No connection requests"
    ) {
      this.setState({ requests: [] });
      toast.error("No connection requests found.");
    } else {
      toast.error("Invalid response data from API");
      this.setState({ requests: [] });
    }
  };

  handleInvitationData = (responseJson: any) => {
    if (responseJson && Array.isArray(responseJson.accounts)) {
      this.setState({ invitations: responseJson.accounts });
    } else {
      console.error("Failed to retrieve invitations data from API response.");
    }
  };

  handleBlockedUserData = (responseJson: any) => {
    if (responseJson && Array.isArray(responseJson.accounts)) {
      this.setState({ blockedUsers: responseJson.accounts });
    } else {
      console.error("Failed to retrieve BlockedUser data from API response.");
    }
  };

  handleAccepRequestData = (responseJson: any) => {
    if (responseJson) {
      this.setState((prevState) => ({
        requests: prevState.requests.filter(
          (user: any) => user.id !== prevState.requestedUserId
        ),
        requestedUserId: null,
      }));
    } else {
      toast.error("Failed to accept user's request");
    }
  };

  handleDeclineRequestData = (responseJson: any) => {
    if (responseJson) {
      this.setState((prevState) => ({
        requests: prevState.requests.filter(
          (user: any) => user.id !== prevState.declinedUserId
        ),
        declinedUserId: null,
      }));
    } else {
      toast.error("Failed to decline user's request");
    }
  };

  handleRemoveUserResponse = (responseJson: any) => {
    if (responseJson) {
      this.setState((prevState) => {
        const updatedState: Partial<S> = {
          removingUserId: null,
          actionType: null,
        };
        this.getConnections();
        this.getInvitations();
        if (prevState.actionType === "removeFromCircle") {
          updatedState.connections = prevState.connections.filter(
            (user) => user.id !== prevState.removingUserId
          );
        } else if (prevState.actionType === "cancelInvite") {
          updatedState.invitations = prevState.invitations.filter(
            (user) => user.id !== prevState.removingUserId
          );
        }

        return updatedState as Pick<S, keyof S>;
      }, () => {
      this.handleCloseModal();
    });
    } else {
      toast.error("Failed to remove user");
    }
  };

  handleBlockedUserResponse = (responseJson: any) => {
    if (responseJson) {
      this.handleCloseContinueBlockModal();
    } else {
      toast.error("Failed to block user");
    }
  };

  handleUnBlockedUserResponse = (responseJson: any) => {
    if (responseJson) {
      this.setState((prevState) => ({
        blockedUsers: prevState.blockedUsers.filter(
          (user: any) => user.id !== prevState.unblockingUserId
        ),
        unblockingUserId: null,
      }));
    } else {
      toast.error("Failed to unBlock user");
    }
  };

  handlecheckboxfilled = () => {
    let check2 = true;
    if (check2) {
     this.handleunchecked()
    }
  }
  handleunchecked = () => {
    let check1 = false
    if (!check1) {
     this. getViewProfileData()
    }
  }

  handleProfileData =(responseJson: any)=>{
    if(responseJson){
      localStorage.setItem("userdata", JSON.stringify(responseJson.data));
      this.setState({ profile: responseJson.data, isLoading: false });
    }
  }
  
  // Customizable Area End
}
