import React from "react";
import NewsFeedController, {
  Props
} from "./NewsFeedController";
// Customizable Area Start
import { AppHeader } from "../../../components/src/AppHeader.web";
import { AppFooter } from "../../../components/src/AppFooter.web"
import { FormControlLabel, Checkbox, Card, CardHeader, Avatar, CardContent, Typography, Box, MenuItem, Menu, IconButton, styled, Drawer, Container, Button, Modal, Divider, InputAdornment, List, ListItem, Collapse, TextField, StyledComponentProps, CircularProgress } from "@material-ui/core";
import { avatar, LinkImg } from "./assets";
import ReactHtmlParser from 'react-html-parser';
import DOMPurify from 'dompurify';
import Alert from '@material-ui/lab/Alert';
import moment from "moment";
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import ReportIcon from '@material-ui/icons/Report';
import BlockIcon from '@material-ui/icons/Block';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import { Toaster } from 'react-hot-toast';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';
import {
  EmojiEmotions,
  MoreVert as MoreVertIcon,
} from "@material-ui/icons";
import { Mention, MentionsInput, MentionsInputProps, SuggestionDataItem } from "react-mentions";
import CloseIcon from "@material-ui/icons/Close";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import EmojiPicker from "emoji-picker-react";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

interface UserSuggestion extends SuggestionDataItem {
  profile_picture: string;
  email: string;
}
interface DrawerHeaderProps {
  title: string;
  onClose: () => void;
}

interface SearchInputProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
}

interface SelectedMembersProps {
  members: Array<{ value: string }>;
  onDelete: (index: number) => void;
}

interface ContinueButtonProps {
  onClick: () => void;
}

export default class NewFeedBlock extends NewsFeedController {
  constructor(props: Props) {
    super(props);
  }

  handleReplyClick = (index: number,element: any)=>{
    if(element.attributes.replies_counts < 5){
      this.handleReplySection(index,element.attributes.id)
    }
  }

  likeCount=(element:any)=>{
    return element.attributes.like_counts === "0" ? "Like": element.attributes.like_counts;
  }

  Comment=(element:any)=>{
    return element.attributes.comment_counts == "0" ? "Comment" : element.attributes.comment_counts
  }
  
  formSubmittedSuccessfully = () => {
    return (
      <Modal
        data-test-id="testmodalclose"
        open={this.state.successModalOpen}
        onClose={() => this.handleCloseSuccessModal()}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box sx={{ ...webStyle.modalStyle, borderRadius: "0 10px 0 0" }}>
          <CheckCircleOutlineOutlinedIcon style={webStyle.checkCircleCss} />
          <Box sx={{ ...webStyle.modalStyleTypo }}>
            Report Added
          </Box>
          <Box sx={{ ...webStyle.modalStyleTypoSecond }}>
            Thank you for added report for  harmful content. Our moderation team will be looking into it
          </Box>
          <Button
            data-test-id="closeReportPop"
            fullWidth
            variant="contained"
            style={{
              ...webStyle.contactDrawerSubmitBtn,
              position: "relative",
              textTransform: "capitalize",
              backgroundColor: "#14362E"
            }}
            onClick={this.closeOkayModal}
          >
            Okay
          </Button>
        </Box>
      </Modal>
    );
  }

  blockSuccessfully = () => {
    return (
      <Modal
        open={this.state.openBlockModal}
        onClose={() => this.handleCloseSuccessModal()}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box sx={{ ...webStyle.modalStyle, borderRadius: "0 10px 0 0" }}>
          <Box sx={{ ...webStyle.modalStyleTypo }}>
            User Blocked
          </Box>
          <Box sx={{ ...webStyle.modalStyleTypoSecond }}>
            They won't be able to find your profile or posts. We won't let them know you blocked them.
          </Box>
          <Button
            fullWidth
            variant="contained"
            data-test-id="closeReportPop"
            style={{
              ...webStyle.contactDrawerSubmitBtn,
              position: "relative",
              textTransform: "capitalize",
              backgroundColor: "#14362E"
            }}
            onClick={this.closeOkayModal}
          >
            Understand
          </Button>
        </Box>
      </Modal>
    );
  }

  replyRender = (index: number) => {
    return (<Collapse data-test-id="collapse" in={this.state.commentReplyOpen[index]} timeout="auto" unmountOnExit>
      <Box style={{
        padding: "10px", margin: "auto", display: "flex", alignItems: "baseline",
        gap: "15px", justifyContent: "end"
      }}>
        <TextField
          data-test-id="replytext"
          fullWidth
          maxRows={4}
          variant="outlined"
          placeholder="Write a Reply...."
          value={this.state.replyComment}
          onChange={this.handleReplyCommentChange}

          style={{ marginBottom: "10px", width: "50%", height: "48px" }}
          InputProps={{
            style: {
              fontSize: "16px",
              fontWeight: "500",
              fontFamily: "MyFont",
              borderRadius: "50px"
            },
            endAdornment: (
              <InputAdornment position="end">
                <i className="fa-regular fa-face-smile custom-icon"
                  style={{ cursor: "pointer", right: "auto" }}
                  onClick={this.onClickReplyEmoji.bind(this)}>
                </i>
                {this.state.replyShowEmoji && <EmojiPicker
                  onEmojiClick={this.ReplyEmojiClick.bind(this)}

                  className="reply-emoji-picker"
                />}
                <IconButton
                  aria-label="Submit"
                  edge="end"
                  onClick={this.handleReplycomment}
                  style={{
                    borderRadius: "8px",
                    padding: "0",
                  }}
                  disabled={!this.state.replyComment.trim()}

                >
                  <ArrowUpwardIcon
                    style={{
                      borderRadius: "50px",
                      background: this.state.replyComment.trim() ? '#73A58E' : '#E0E0E0',
                      color: this.state.replyComment.trim() ? '#FFFFFF' : '#9E9E9E',
                      padding: "6px 17px",
                      margin: "6px"
                    }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Collapse>)
  }

  renderReplycomment = (element: any, index: number) => {
    return (
      element.attributes.replies.slice().reverse().map((reply: any, replyIndex: number) => {
        const commentUser = reply.attributes.comment_user;
        if (!commentUser || !commentUser.first_name || !commentUser.last_name) {
          return null;
        }
        return (
          <Collapse in={this.state.isReplyOpen} timeout="auto" unmountOnExit>
          <Box key={replyIndex} sx={{ pl: 4, display: 'flex', flexDirection: 'column' }}>
            <Box style={{ display: "flex", fontFamily: "MyFont", justifyContent: "end" }}>
              <Avatar style={{ margin: "16px" }} src={reply.attributes.comment_user.profile_picture.url} aria-label="recipe" />
              <Box style={{ fontSize: "14px", backgroundColor: "#F1F5F9", padding: "12px 16px", width: "50%" }}>
                <Box style={{ display: "flex", justifyContent: "space-between", alignContent: "center", alignItems: "center" }}>
                  <Typography style={{ fontSize: "16px", fontWeight: "500", fontFamily: "MyFont" }}>
                    {`${commentUser.first_name} ${commentUser.last_name}`}
                  </Typography>
                  <Typography style={{ fontSize: "12px", fontWeight: "500" }}>
                    {this.calculateTimeGap(reply.attributes.created_at)}
                  </Typography>
                </Box>
                {reply.attributes.comment}
              </Box>
            </Box>
            <CardContent>
              <Box style={{ display: "flex", justifyContent: "end", alignItems: "center", fontFamily: "MyFont", gap: "50px", marginRight: "50px" }}>
                <Box>
                  {reply.attributes.is_liked ? (
                    <>
                      <i className="fa-solid fa-heart" onClick={this.likeComment.bind(this, reply)} style={{ color: "red", marginRight: "5px", cursor: "pointer" }}></i>
                      {reply.attributes.like_counts}
                    </>
                  ) : (
                    <>
                      <i className="fa-regular fa-heart" style={{ marginRight: "5px", cursor: "pointer" }} onClick={this.likeComment.bind(this, reply)}></i>
                      {reply.attributes.like_counts === 0 ? "Like" : reply.attributes.like_counts}
                    </>
                  )}
                  &nbsp;&nbsp;
                </Box>
                <Box>
                </Box>
              </Box>
            </CardContent>
          </Box>
          </Collapse>
        );
      })
    );
  }

  renderSharefunction = () => {
    const DrawerHeader: React.FC<DrawerHeaderProps> = ({ title, onClose }) => (
      <Box style={webStyle.categoryDropdown}>
        <Typography className="selecttype">{title}</Typography>
        <span onClick={onClose}>
          <i className="fa-solid fa-xmark"></i>
        </span>
      </Box>
    );
    const SearchInput: React.FC<SearchInputProps> = ({ onChange, placeholder }) => (
      <Box style={{
        display: 'flex',
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "myfont",
        padding: "15px"
      }}>
        <Box style={{
          width: "99%",
          marginBottom: "9px",
          border: "1px solid #CBD5E1",
          marginTop: "21px",
          display: "flex",
          alignItems: "center",
          borderRadius: "8px"
        }}>
          <i className="fa-solid fa-magnifying-glass" style={{ marginLeft: "9px" }}></i>
          <input type="text" style={{
            width: "99%",
            fontFamily: "myfont",
            padding: "11px 4px",
            borderRadius: "6px",
            border: "none",
            outline: "none"
          }} onChange={onChange} placeholder={placeholder} />
        </Box></Box>
    );
    const SelectedMembers: React.FC<SelectedMembersProps> = ({ members, onDelete }) => (
      <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
        {members.map((member, index) => (
          <Box key={index} style={{ borderRadius: '19px', display: 'flex', alignItems: 'center', background: '#F1F5F9', margin: '6px', padding: '6px' }}>
            <Typography style={{ fontSize: '11px', fontFamily: "myfont", marginRight: '9px', color: '#475569' }}>{member.value}</Typography>
            <span onClick={() => onDelete(index)}>
              <i className="fa-solid fa-xmark" style={{ fontSize: '11px' }}></i>
            </span>
          </Box>
        ))}
      </Box>
    );
    const ContinueButton: React.FC<ContinueButtonProps> = ({ onClick }) => (
      <Box style={{ display: "flex", justifyContent: "center" }}>
        <Button variant="contained" style={{
          width: "90%",
          background: "#14362E",
          textTransform: "none",
          fontFamily: "myfont",
          color: "white",
          margin: "auto"
        }} onClick={onClick}>
          Continue
        </Button></Box>
    );
    return (
      <>
        <CustomDrawer
          anchor="right" open={this.state.open3} onClose={this.drawerSidebaarClose3}>
          <BoxCustom2>
            <DrawerHeader title="Share comment" onClose={this.drawerSidebaarClose3} />
            <Box style={webStyle.borderBottomStyle}></Box>
            <SearchInput onChange={this.handlesearchTag} placeholder="search by Name" />
            <SelectedMembers members={this.state.selectedmember3} onDelete={this.handleselectmemberDelete3} />
            <Box>
              {this.state.shareconnectiondata2.length > 0 && this.state.shareconnectiondata2.map((element1: any, index: any) => {
                return (<Box className="box5" key={index}>
                  <Box className="box6">
                    <Avatar src={element1.profile_picture?.url} />
                    <Box style={{ marginLeft: "11px" }}><span>{element1.name}</span><span>{element1.rol}</span></Box></Box>
                  <span><input type="checkbox" value={element1.e} onClick={(elementvalue) => this.getvalueconnectioncheck3(elementvalue, element1)} /></span>
                </Box>)
              })}
            </Box>
            <Box className="box7">
              <Box className="box8"><input className="input2" type="text" onChange={this.handlewriteCommentmessage} name="exlink" placeholder="Message" id="" /></Box>
            </Box>
            <ContinueButton onClick={this.handleCommentshare} />
          </BoxCustom2>
        </CustomDrawer>
      </>
    )
  }

  getSendIconStyle = (comment: string) => {
    return {
      borderRadius: '50px',
      background: comment.trim() ? '#73A58E' : '#E0E0E0',
      color: comment.trim() ? '#FFFFFF' : '#9E9E9E',
      padding: '7px 17px',
      margin: '6px',
    };
  };

  rederPosts = () => {
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 0 },
        items: 1,
        slidesToSlide: 1
      },
    };

    return this.state.NewsFeedData.map((element: any, index: any) => {
      function mapMediaItems(mediaItems: { content_type: string; url: string; }[]) {
        return mediaItems.map((item: { content_type: string; url: string; }) => ({
          type: item?.content_type?.startsWith('image/') ? 'image' : 'video',
          url: item?.url,
        }));
      }

      const imagesAndVideos = element.attributes.images_and_videos ? mapMediaItems(element.attributes.images_and_videos) : [];
      const media = element.attributes.media ? mapMediaItems(element.attributes.media) : [];

      const combinedMedia = [...imagesAndVideos, ...media];
      const images = combinedMedia.filter(media => media.type === 'image');
      const videos = combinedMedia.filter(media => media.type === 'video');
      const isFavorite = element.attributes.is_favorite;

      return (
        <Card key={element.id} id={`card-${index}`}  style={{ maxWidth: 750, marginBottom: "25px", width: "100%", marginTop: index === 0 ? "84px" : "0px", fontFamily: "MyFont" }}>
          <Box style={webStyle.cardflexbox} >
            <CardHeader
              style={{ fontFamily: "MyFont" }}

              avatar={
                <a href={`UserProfileBasicBlock/${element?.attributes?.account_id}`}>
                  <Avatar src={element.attributes?.posted_user?.profile_picture?.url} aria-label="recipe">

                  </Avatar>
                </a>
              }

              title={<div style={{ fontFamily: "MyFont", fontSize: "18px", fontWeight: "700", letterSpacing: "1px" }}>{element.attributes.posted_user.first_name + element.attributes.posted_user.last_name} . <span style={{ fontFamily: "MyFont", fontSize: "11px", fontWeight: "500", letterSpacing: "1px", color: "#334155" }}>{element.attributes.posted_user?.role}</span></div>}
              subheader={<div style={{ fontFamily: "MyFont", fontSize: "14px", fontWeight: "400", letterSpacing: "1px" }}>{moment(element.attributes.updated_at).format('dddd, hh:mm A')} <span style={{ fontFamily: "MyFont", fontSize: "11px", fontWeight: "500", letterSpacing: "1px", color: "#14362E" }}>{element.attributes?.sub_category}</span>
                <span style={webStyle.citytest}>{element.attributes?.city}</span></div>}
            />

            <Box key={element.id} style={{ cursor: "pointer", textAlign: "right" , display:"flex"}}>
              {isFavorite && (
                <IconButton
                  data-test-id="icon-button"
                  edge="start"
                  aria-haspopup="true"
                  aria-controls={`menu-${index}`}
                  aria-label="more"
                  onClick={() => this.getToFavriouteData(element.id)}
                >
                  <BookmarkIcon style={{ color: "#14362E" }} />
                </IconButton>
              )}

              <IconButton
                data-test-id="handleOpenMenu"
                edge="start"
                aria-haspopup="true"
                aria-controls={`menu-${index}`}
                aria-label="more"
                onClick={(event) => this.handleOpenMenu(event, element.id)}
              >
                <MoreVertIcon />
              </IconButton>

              <MenuBox style={{ position: "relative" }}>
                <Menu
                  key={index}
                  anchorEl={this.state.openMenu}
                  id={`menu-${index}`}
                  open={this.state.openMenuId === element.id}
                  onClick={this.handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'top',
                  }}
                  transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top',
                  }}
                  style={{
                    marginTop: "34px"
                  }}
                >
                  <MenuItem
                    data-test-id="addtofavBtn"
                    onClick={() => this.getToFavriouteData(element.id)}
                    style={{
                      fontFamily: "myfont",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "22px",
                      letterSpacing: "0.08em",
                      textAlign: "left",
                    }}
                  >
                    {isFavorite ? (
                      <>
                        <BookmarkBorderIcon
                          style={{
                            height: "20px",
                            fontWeight: "500",
                          }}
                        />
                        Remove from favourites
                      </>
                    ) : (
                      <>
                        <BookmarkBorderIcon
                          style={{
                            height: "20px",
                            fontWeight: "500",
                          }}
                        />
                        Add to favourites
                      </>
                    )}
                  </MenuItem>
                  {element?.attributes?.account_id != this.state.accountId && (<><MenuItem
                    data-test-id="addtoReportBtn"
                    onClick={(event) => this.handlePostReport(element.id)}
                    style={{
                      color: "#D1584C",
                      fontFamily: "myfont",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "22px",
                      letterSpacing: "0.08em",
                      textAlign: "left",
                    }}
                  >
                    <ReportIcon
                      style={{
                        height: "20px",
                        fontWeight: "500",
                      }} />
                    &nbsp; Report
                  </MenuItem><MenuItem
                    data-test-id="addtoBlockBtn"
                    onClick={() => this.handleBlockUser(element.id)}
                    style={{
                      color: "#D1584C",
                      fontFamily: "myfont",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "22px",
                      letterSpacing: "0.08em",
                      textAlign: "left",
                    }}
                  >
                      <BlockIcon
                        style={{
                          height: "20px",
                          fontWeight: "500",
                        }} />
                      &nbsp; Block user
                    </MenuItem></>)}
                </Menu>
              </MenuBox>
            </Box>
          </Box>
          {element.attributes.is_recommendation ? <Box style={webStyle.recommendationBox}>Recommendation</Box> : ""}

          {element.attributes.is_recommendation ? <Box style={{ marginTop: "20px" }}>
            <Box style={webStyle.recommendationBoxtitle}>{element.attributes.name}</Box>

            <Box style={{ display: 'flex', alignItems: 'center', marginLeft: "10px" , gap: "10px" }}>
              <Box style={webStyle.recommendationBoxType}>
                {element.attributes.recommendation_type?.name}
              </Box>
              <div style={{ width: '4px', height: '4px', background: '#334155' }} />
              <Box style={webStyle.recommendationBoxCity}>
                {element.attributes.city}
              </Box>
            </Box>

          </Box> : ""}

          <Typography style={{
            fontSize: "14px", paddingLeft: "16px", fontFamily: "MyFont", fontWeight: 500, letterSpacing: "1px",
            fontStyle: "normal", marginTop: "10px"
          }}>{element.attributes.body}</Typography>
          {
            (element.attributes.link_title && element.attributes.external_link) && (
              <Box style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", display: "flex", justifyContent: "space-between", padding: "4px", alignItems: "center", height: "60px", marginBottom: "10px", marginTop: "10px", width: "95%", margin: "15px auto", borderRadius: "10px" }}>
                <Box style={{ display: "flex", borderRadius: "10px", alignItems: "center" }}>
                  <img data-test-id="handleRedirect" src={LinkImg} onClick={() => this.handleRedirect(element.attributes.external_link)} alt="" />
                  <Box style={{ marginLeft: "10px" }}>
                    <p>{element.attributes.link_title}</p>
                  </Box>
                </Box>
                <Button data-test-id="handleCopyLink" onClick={() => this.handleCopyLink(element.attributes.external_link)}>
                  <span style={{ fontSize: "20px" }}><i className="fa-regular fa-copy"></i></span>

                </Button>
              </Box>
            )
          }
          <Carousel
            responsive={responsive}
            autoPlay={false}
            swipeable={true}
            draggable={true}
            showDots={false}
            infinite={false}
            partialVisible={false}
            dotListClass="custom-dot-list-style"
          >
            {images.map((media, index) => (
              <div key={`image-${index}`} style={{ display: "flex", alignItems: "center", height: "200px" }}>
                <img style={{ width: "100%" }} src={media?.url} alt={`media-${index}`} />
              </div>
            ))}
            {videos.map((media, index) => (
              <div key={`video-${index}`}>
                <video style={{ width: "100%", height: "250px" }} controls>
                  <source src={media.url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            ))}
          </Carousel>
          <CardContent>
            <Box style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", fontFamily: "MyFont" }}>
              <Box>
                {element.attributes.is_liked ? (
                  <>
                    <i className="fa-solid fa-heart" onClick={this.likePost.bind(this, element)} style={{ color: "red", marginRight: "5px", cursor: "pointer" }}></i>{element.attributes.like_counts}
                  </>
                ) : (<><i className="fa-regular fa-heart" style={{ marginRight: "5px", cursor: "pointer" }} onClick={this.likePost.bind(this, element)}></i> {this.likeCount(element)} </>)}&nbsp;&nbsp;
              </Box>
              <Box>

                <i data-test-id="testing" className="fa-regular fa-message" onClick={this.handleClick.bind(this, element.id)}></i>&nbsp;&nbsp;
                {this.Comment(element)} &nbsp;&nbsp;
              </Box>
              <Box onClick={this.drawerSidebaar.bind(this, element.id)}>
                <i className="fa-solid fa-arrow-up-from-bracket" ></i>&nbsp;&nbsp;
                Share
              </Box>
            </Box>
          </CardContent>
        </Card>
      )
    })
  }

  noDatScreen = () => {

    if (this.state.flagNoData) {
      return (<Box style={webStyle.NoitemBox}>

        <Box style={webStyle.noitemdiv as React.CSSProperties}>
          <YoutubeSearchedForIcon style={webStyle.searchIcon} />
          <p style={webStyle.notfound}>No results found</p>
          <p style={webStyle.othertry as React.CSSProperties}>Try other keywords or look into
            other sections</p>
        </Box>
      </Box>)
    }
  }
  termCondUpdated = () => {
    return (
      <Modal open={this.state.isTermsOpen} onClose={this.handleCloseTerms}>
        <Card style={{ padding: '40px 24px 24px 24px', maxWidth: '450px', margin: "0 auto", marginTop: '100px', boxSizing: 'border-box', borderEndEndRadius: '32px', outline: 'none' }}>
          {this.state.termsStep === 1 && <div>
            <Box sx={{ color: '#D1584C', width: '56px', height: '56px', margin: '0 auto' }}>
              <InfoOutlinedIcon style={{ width: '100%', height: '100%' }} />
            </Box>
            <Typography style={{ fontSize: '24px', fontWeight: 'bold', color: '#14362E', marginBottom: '26px', textAlign: 'center' }}>Terms and Conditions have been updated</Typography>
            <Typography style={{ color: '#14362E', fontSize: '16px', fontWeight: '500', textAlign: 'center' }}>
              Please take a moment to review the changes in our Terms & Conditions in order to keep using Kella.
            </Typography>
            <StyledButton variant="contained" fullWidth onClick={this.handleNextStepTerms}>Review</StyledButton>
          </div>}
          {this.state.termsStep === 2 && <div>
            {this.state.termsError && <Alert style={{ background: '#EEC3BE', color: '#D1584C', borderLeft: '4px solid #D1584C' }} icon={false} onClose={this.handleCloseError} severity="error">
              Please confirm that you have read the terms and conditions
            </Alert>}

            <Typography style={{ fontSize: '24px', fontWeight: 'bold', color: '#14362E', }}>Terms and Conditions</Typography>
            <Typography component='div' style={{ maxHeight: '200px', overflow: 'auto' }}>
              {ReactHtmlParser(DOMPurify.sanitize(this.state.termsDescription))}
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  style={{ color: this.state.isChecked ? 'green' : 'inherit' }}
                  checked={this.state.isChecked}
                  onChange={this.handleCheck}
                />
              }
              style={{ marginTop: '24px', color: this.state.termsError ? '#D1584C' : '' }}
              label="I have read and agree to these Terms and Conditions"
            />
            <Box style={{ display: 'flex', gap: '12px' }}>
              <Button style={{ textTransform: 'none', marginTop: '48px', fontSize: '16px', fontFamily: 'MyFont', borderColor: '#73A58E', color: '#14362E' }} variant="outlined" fullWidth onClick={this.handleCloseTerms}>Cancel</Button>
              <StyledButton variant="contained" fullWidth onClick={this.handleSubmitTerms}>Agree</StyledButton>
            </Box>
          </div>}

        </Card>
      </Modal>
    )
  }
  render() {

    return (
      // Required for all blocks
      <>

        <AppHeader component={"Home"} dummyPeopleData={this.state.peopleData} searchHistoryData={this.state.searchHistoryApiResponse} searchFilterData={this.getSearchFilterData} inviteCircle={this.inviteCircle} roleData={() => this.getRoleList()} industryData={() => this.getIndustryList()} categoryData={() => this.getCategoryList()} subCategoryData={() => this.getSubCategoryList()} citiesData={() => this.getCityList()} typesData={() => this.getTypesList()} />
        {this.termCondUpdated()}
        <Box key={1} style={{ display: "flex", flexDirection: "column", alignItems: "center", background: "#F8FAFC" }}>
          {
            this.state.NewsFeedData.length > 0 ?
              (
                <>
                  {this.rederPosts()}
                  {this.state.isLoading && <CircularProgress />}
                  {!this.state.hasMore && !this.state.isLoading && <p>No more data</p>}
                </>
              )
              : this.noDatScreen()}
        </Box>
        {this.renderDrawerfunction()}
        {this.renderSharefunction()}

        <Drawer style={webStyle.drawer as any} anchor="right" open={this.state.open} onClose={this.toggleDrawer.bind(this, false, "")} >
          <BoxMain>
            <Box style={webStyle.categoryDropdown}>
              <Typography className="selecttype">Report Content</Typography>
              <span onClick={this.drawerClose}>
                <i className="fa-solid fa-xmark"></i>
              </span>
            </Box>
            <Box style={webStyle.borderBottomStyle}></Box>
            <Box className="flaggingcontent">Why are you flagging this content?</Box>
            <Box style={webStyle.borderBottomStyle}></Box>
            <Container className="content-container">
              {
                this.state.reportListData.map((item: { id: number, reason: string, description: string }, index: number) => {
                  return <Box
                    data-test-id="reportSelectedId"
                    className="typebox"
                    key={index}
                    onClick={() => this.handleSelect(item, index)}
                    style={{ backgroundColor: this.state.selected === `${index}` ? '#D4E5DE' : 'transparent' }}
                  >
                    <Box>
                      <Typography className="titlemyfont">{item.reason}</Typography>
                      <Typography className="titleReason">{item.description}</Typography>
                    </Box>
                    {this.state.selected === `${index}` && <CheckCircleOutlineOutlinedIcon className="checkedCircle" />}
                  </Box>
                })
              }
              <Divider />

            </Container>
            <Container className="button-container">
              <Button data-test-id="continueBtn" disabled={this.state.reportId == 0} variant="contained" className="selectbtn" onClick={this.addedReport}>Continue</Button>
            </Container>
          </BoxMain>
        </Drawer>
        <Drawer style={webStyle.drawer as any} anchor="right" open={this.state.open1} onClose={this.toggleDrawer1.bind(this, false, "")} >
          <BoxMain>
            <Box style={webStyle.categoryDropdown}>
              <Typography className="selecttype">Block User</Typography>
              <span onClick={this.drawerClose}>
                <i className="fa-solid fa-xmark"></i>
              </span>
            </Box>
            <Box style={webStyle.borderBottomStyle}></Box>
            <Box className="flaggingcontent">Why are you blocking this user?</Box>
            <Box style={webStyle.borderBottomStyle}></Box>
            <Container className="content-container">
              {
                this.state.deleteReasonData.map((item: { id: number, title: string, description: string }, index: number) => {
                  return <Box
                    data-test-id="selectBlockMsg"
                    className="typebox"
                    key={index}
                    onClick={() => this.handleSelect1(item, index)}
                    style={{ backgroundColor: this.state.indexBlockList === index ? '#D4E5DE' : 'transparent' }}
                  >
                    <Box>
                      <Typography className="titlemyfont" >{item.title}</Typography>
                      <Typography className="titleReason">{item.description}</Typography>
                    </Box>
                    {this.state.indexBlockList === index && <CheckCircleOutlineOutlinedIcon className="checkedCircle" />}
                  </Box>
                })
              }
              <Divider />

            </Container>
            <Container className="button-container">
              <Button data-test-id="addedToBlock" variant="contained" className="selectbtn" onClick={this.addedToBlock}>Continue</Button>
            </Container>
          </BoxMain>
        </Drawer>
        {this.formSubmittedSuccessfully()}
        {this.blockSuccessfully()}
        <Drawer style={webStyle.drawer as any} anchor="right" open={this.state.sidebaarOpen} onClose={this.drawerSidebaarClose} >
          <BoxMain>
            <Box style={webStyle.categoryDropdown}>
              <Typography className="selecttype">Share Post</Typography>
              <span onClick={this.drawerSidebaarClose}>
                <i className="fa-solid fa-xmark"></i>
              </span>
            </Box>
            <Box style={webStyle.borderBottomStyle}></Box>

            <Container>
              <Box style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "14px" }}>
                <Box style={{ width: "100%", marginBottom: "10px", border: "1px solid #CBD5E1", position: "relative", marginTop: "20px", display: "flex", alignItems: "center", borderRadius: "5px", padding: "0 5px", fontFamily: "MyFont" }}>
                  <i className="fa-solid fa-magnifying-glass" style={{ color: "#94A3B8" }}></i>
                  <input type="text" style={{ width: "100%", padding: "10px 5px", borderRadius: "5px", border: "none", outline: "none", fontFamily: "MyFont" }} value={this.state.setSearchvalue} onChange={this.handlesearchTag} name="" id="" />
                  {
                    this.state.setSearchvalue ? <i className="fa-solid fa-xmark" style={{ cursor: "pointer" }} data-test-id="removesearch" onClick={this.removesearchdata}></i> : <i className="fa-solid fa-xmark" style={{ color: "#94A3B8" }}></i>

                  }
                </Box>
              </Box>

              <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                {
                  this.state.selectedmember && this.state.selectedmember.map((element: any, index: any) => (
                    <Box key={index} style={{ borderRadius: "20px", display: "flex", alignItems: "center", width: "80px", background: "#F1F5F9", flexWrap: "wrap", marginBottom: "15px" }}>
                      <Typography style={{ fontSize: "10px", marginRight: "8px", color: "#475569", padding: "5px" }}>{element.value}</Typography>
                      <span onClick={this.handleselectmemberDelete.bind(this, index)}><i className="fa-solid fa-xmark" style={{ fontSize: "10px" }}></i></span>
                    </Box>
                  ))
                }
              </Box>
              <Box>
                {
                  this.state.shareconnectiondata.length > 0 && this.state.shareconnectiondata.map((element: any, index: any) => {
                    return (
                      <>
                        <Box key={index} style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", display: "flex", justifyContent: "space-between", padding: "4px", alignItems: "center", height: "60px", marginBottom: "10px", marginTop: "10px" }}>
                          <Box style={{ display: "flex", borderRadius: "10px", alignItems: "center" }}>
                            <img src={element.profile_picture?.url} style={{ width: "40px", height: "40px", borderRadius: "50%" }} alt="" />
                            <Box style={{ marginLeft: "10px" }}>
                              <p>{element?.name}</p>
                              <p>{element?.role}</p>
                            </Box>
                          </Box>
                          <span><input type="checkbox" value={element.e} onClick={(elementvalue) => this.getvalueconnectioncheck(elementvalue, element)} name="" id="" /></span>
                        </Box>
                      </>
                    )
                  })
                }

              </Box>

              <Box style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "14px" }}>
                <Box style={{ width: "100%", marginBottom: "10px", border: "1px solid #CBD5E1", marginTop: "20px", display: "flex", alignItems: "center", borderRadius: "5px", padding: "0 5px" }}>
                  <input type="text" style={{ width: "100%", padding: "10px 5px", borderRadius: "5px", border: "none", outline: "none" }} onChange={this.handlewritemessage} name="exlink" placeholder="Write Message" id="" />
                </Box>
              </Box>



              <Box style={{ padding: "5px", position: "absolute", width: "100%", bottom: "0" }}>
                <Button data-test-id="addedToBlock" variant="contained" className="selectbtn" onClick={this.handlePostshare}>Send</Button>
              </Box>
            </Container>
          </BoxMain>
          <Toaster />
        </Drawer>

        <AppFooter component={"Home"} />
      </>
    );
  }
  renderDrawerfunction() {
    return (
      <CustomDrawer
        anchor="right"
        open={this.state.isCommentDrawerOpen}
        onClose={() => this.toggleCommentDrawer(false, 0)}
      >
        <Box

          role="presentation"
        >
          <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
              <IconButton onClick={this.handleClose2} className="display_arrow"
              >
                <ArrowBackIcon />
              </IconButton>
            </Box>
            <Typography style={{ fontSize: "24px", fontWeight: "900", color: "#14362E", paddingLeft: "25px" }}>
              Post
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
              <IconButton onClick={this.handleClose2}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Divider />
          <Box>
            {
              this.state.selectedPostId !== null && (
                <Box>
                  <Typography>
                    {this.state.NewsFeedDataSet.filter((element: any) => element.id === this.state.selectedPostId)
                      .map((element: any, key: any) => (
                        <Card
                          key={key}
                          style={{
                            maxWidth: 750,
                            marginBottom: "25px",
                            width: "100%",
                            fontFamily: "MyFont",
                            boxShadow: 'none'
                          }}
                        >
                          <CardHeader
                            data-test-id="commentsCard"
                            style={{ fontFamily: "MyFont" }}
                            avatar={
                              <Avatar src={element.attributes.posted_user.profile_picture.url} aria-label="recipe">
                              </Avatar>
                            }
                            action={
                              <i className="fa-solid fa-ellipsis-vertical" style={{ marginTop: "12px" }}
                              data-test-id="handleOpenMenu"
                              onClick={(event) => this.handleOpenMenu(event, element.id)}
                              ></i>
                            }
                            title={
                              <div style={{ fontFamily: "MyFont", fontSize: "16px", fontWeight: "700", letterSpacing: "1px" }}>
                                {element.attributes.posted_user.first_name + ' ' + element.attributes.posted_user.last_name} .
                                <span style={{ fontFamily: "MyFont", fontSize: "11px", fontWeight: "500", letterSpacing: "1px", color: "#334155" }}>
                                  {element.attributes.posted_user.role}
                                </span>
                              </div>
                            }
                            subheader={
                              <div style={{ fontFamily: "MyFont", fontSize: "14px", fontWeight: "400", letterSpacing: "1px" }}>
                                {moment(element.attributes.updated_at).format('dddd, hh:mm A')}
                                <span style={{ fontFamily: "MyFont", fontSize: "11px", fontWeight: "500", letterSpacing: "1px", color: "#14362E" }}>
                                  {element.attributes.category}
                                </span>
                              </div>
                            }
                          />
                          <Typography
                            style={{
                              fontSize: "14px",
                              paddingLeft: "17px",
                              fontFamily: "MyFont",
                              fontWeight: 500,
                              letterSpacing: "1px",
                              fontStyle: "normal"
                            }}
                          >
                            {element.attributes.body}
                          </Typography>
                          {
                            (element.attributes.link_title && element.attributes.external_link) && (
                              <Box style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", display: "flex", justifyContent: "space-between", padding: "4px", alignItems: "center", height: "60px", marginBottom: "10px", marginTop: "10px", width: "95%", margin: "15px auto", borderRadius: "10px" }}>
                                <Box style={{ display: "flex", borderRadius: "10px", alignItems: "center" }}>
                                  <img src={LinkImg} alt="" />
                                  <Box style={{ marginLeft: "10px" }}>
                                    <p>{element.attributes.link_title}</p>
                                  </Box>
                                </Box>
                                <Button data-test-id="handleCopyLink" onClick={() => this.handleCopyLink(element.attributes.external_link)}>
                                  <span style={{ fontSize: "20px" }}><i className="fa-regular fa-copy"></i></span>
                              </Button>
                              </Box>
                            )
                          }
                          <CardContent>
                            <Box style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", fontFamily: "MyFont" }}>
                              <Box>
                                {element.attributes.is_liked ? (
                                  <>
                                    <i data-test-id="likecomment" className="fa-solid fa-heart" onClick={this.likePost.bind(this, element)} style={{ color: "red", marginRight: "5px", cursor: "pointer" }}></i>{element.attributes.like_counts}
                                  </>
                                ) : (
                                  <>
                                    <i className="fa-regular fa-heart" style={{ marginRight: "5px", cursor: "pointer" }} onClick={this.likePost.bind(this, element)}></i>
                                    {this.likeCount(element)}
                                  </>
                                )}
                                &nbsp;&nbsp;
                              </Box>
                              <Box>
                                <i className="fa-regular fa-message"></i>&nbsp;&nbsp;
                                {this.Comment(element)} &nbsp;&nbsp;
                              </Box>
                              <Box>
                                <i className="fa-solid fa-arrow-up-from-bracket"></i>&nbsp;&nbsp;
                                Share
                              </Box>
                            </Box>
                          </CardContent>
                        </Card>
                      ))}

                  </Typography>
                </Box>
              )
            }

            <Box style={{ display: 'flex', alignItems: 'center', gap: "1", justifyContent: "center" }}>
              <Avatar
                src={this.state.profile?.personal_information?.profile_picture?.url}
                aria-label="recipe"
              />
              <StyledMentionsInput
                value={this.state.comment}
                onChange={this.handleCommentChange}
                placeholder="Write a comment..."
              >
                <Mention
                  trigger="@"
                  data={this.state.users}
                  renderSuggestion={(suggestion: SuggestionDataItem) => (<>
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <Avatar style={{ margin: "8px" }} src={(suggestion as UserSuggestion).profile_picture} />
                      <Box style={{ display: "flex", alignItems: "center" }}>{suggestion.display}</Box></Box>
                  </>
                  )}
                />

              </StyledMentionsInput>


              <InputAdornment position="end"
                style={{ position: "absolute", marginLeft: "75%" }}
              >
                <i className="fa-regular fa-face-smile custom-icon "
                  style={{ paddingRight: "0px", cursor: "pointer", }}
                  onClick={this.onClickEmoji.bind(this)}>
                </i>
                {this.state.showEmoji && <EmojiPicker
                  onEmojiClick={this.EmojiClick.bind(this)}

                  className="emoji-picker"
                />}
                <IconButton
                  data-test-id="commentSubmit"
                  aria-label="send comment"
                  edge="end"
                  onClick={this.handleCommentSubmit}
                  className="button-icon custom-icon"
                  disabled={!this.state.comment?.trim()}
                >
                  <ArrowUpwardIcon
                    style={this.getSendIconStyle(this.state.comment)}
                  />
                </IconButton>
              </InputAdornment>

            </Box>
            <List>
              <ListItem button onClick={this.toggleComments} disableRipple
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  fontFamily: "MyFont"
                }}
              >
                <Box>All Comments</Box>
                {this.state.isCommentsOpen ? <ExpandMoreIcon /> : < ExpandLessIcon />}
              </ListItem>
              <Collapse in={this.state.isCommentsOpen} timeout="auto" unmountOnExit>
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  {this.state.commentData.filter((comment: { attributes: { parent_comment_id: null; comment: string; }; }) => comment.attributes.parent_comment_id === null).map((element: {
                    attributes: {
                      comment_user: { first_name: string, last_name: string, profile_picture: { url: string } };
                      created_at: string;
                      like_counts: number;
                      is_liked: boolean;
                      replies: any;
                      replies_counts:number;
                      id: number; comment: string | null | undefined; parent_comment_id: number;
                    };
                  }, index: number) => (
                    <Box key={index}>
                      <Box style={{ display: "flex", fontFamily: "MyFont" }}>
                        <Avatar style={{ margin: "16px" }} src={element.attributes.comment_user.profile_picture.url} aria-label="recipe" />
                        <Box style={{ fontSize: "14px", backgroundColor: "#F1F5F9", padding: "12px 16px", width: "90%" }}>
                          <Box style={{ display: "flex", justifyContent: "space-between", alignContent: "center", alignItems: "center" }}>
                            <Typography style={{ fontSize: "16px", fontWeight: "500", fontFamily: "MyFont" }}>{`${element.attributes.comment_user.first_name} ${element.attributes.comment_user.last_name}`}</Typography>
                            <Typography style={{ fontSize: "12px", fontWeight: "500" }}>{this.calculateTimeGap(element.attributes.created_at)}</Typography>
                          </Box>
                          {element.attributes.comment}
                        </Box>
                      </Box>
                      <CardContent>
                        <Box style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", fontFamily: "MyFont" }}>
                          <Box>
                            {element.attributes.is_liked ? (
                              <>
                                <i className="fa-solid fa-heart" onClick={this.likeComment.bind(this, element)} style={{ color: "red", marginRight: "5px", cursor: "pointer" }}></i>{element.attributes.like_counts}
                              </>
                            ) : (
                              <>
                                <i className="fa-regular fa-heart" style={{ marginRight: "5px", cursor: "pointer" }} onClick={this.likeComment.bind(this, element)}></i>
                                {this.likeCount(element)}
                              </>
                            )}
                            &nbsp;&nbsp;

                          </Box>
                          <Box>
                            <i data-test-id="likeReply" className="fa-regular fa-message"
                             onClick={
                             this.handleReplyClick.bind(this,index,element)
                            }></i>&nbsp;&nbsp;
                              Reply
                          </Box>
                          <Box>
                            <i className="fa-solid fa-arrow-up-from-bracket" onClick={this.handleShareCommentClick.bind(this, element.attributes.id)}></i>&nbsp;&nbsp;
                            Share
                          </Box>
                        </Box>
                      </CardContent>
                      <List>
                        <ListItem button onClick={this.toggleReply} disableRipple
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end",
                            fontFamily: "MyFont"
                          }}
                        >
                          <Box>All Replies</Box>
                          {this.state.isReplyOpen ? <ExpandMoreIcon /> : < ExpandLessIcon />}
                        </ListItem>
                      </List>
                      {this.replyRender(index)}
                      {this.renderReplycomment(element, index)}
                    </Box>
                  ))}
                </Box>
              </Collapse>
            </List>
          </Box>

        </Box>
      </CustomDrawer>)
  }

}

const StyledMentionsInput = styled(MentionsInput)<MentionsInputProps & StyledComponentProps>(() => ({
  marginLeft: "8px",
  width: '385px',
  height: '48px',
  backgroundColor: '#F1F5F9',
  border: '1px solid #E2E8F0',
  borderRadius: "50px",
  "& textarea": {
    padding: '12px 16px',
    width: '100%',
    height: '100%',
    fontSize: "16px",
    fontWeight: "500",
    fontFamily: "MyFont",
    border: "none",
    borderRadius: "50px",
    "&:focus": {
      outline: 'none',
      border: 'none',
    },
    "&:hover": {
      border: 'none',
    },
  },
  "&:focus": {
    outline: 'none',
    border: '1px solid #E2E8F0',
  },
  "&:hover": {
    border: '1px solid #E2E8F0',
  },
  '& [class*="__suggestions"]': {
    top: "25px !important",
    minWidth: "333px",
    fontFamily: "myfont",
    fontSize: "16px",
    fontWeight: "500",
    borderRadius: "16px",
    border: "1px solid #E2E8F0",
    padding: "8px 12px",
  },
  '& [class*="__suggestions__list"]': {
    border: "none",
  },
  '& [class*="__suggestions__item"]': {
    border: "none",
    borderBottom: "1px solid #E2E8F0",
    borderRadius: "0",
    padding: "8px"
  },

  "@media(max-width: 600px)": {
    "& textarea": {
      fontSize: "13px !important",
      width: "200px "
    },
    '& [class*="__suggestions"]': {
      minWidth: "100%",
      fontSize: "14px",
    },
  },



}));

const BoxMain = styled(Box)({
  width: "400px",
  fontFamily: "myfont",
  "@media(max-width: 600px)": {
    width: '100%',
  },
  '& .titleReason': {
    fontSize: "14px",
    fontFamily: "myfont"
  },
  '& .titlemyfont': {
    fontFamily: "myfont"
  },
  "& .mainBox": {
    padding: '4px',
    fontWeight: "400",
    height: '30px',
    fontFamily: "Halyard Display",
    justifyContent: 'space-between',
    marginBottom: '5px',
    paddingLeft: "10px",
    display: 'flex',
    fontSize: "12px"
  },
  "& .mainBoxDivider": {
    width: "95%",
    marginLeft: "10px"
  },
  "& .mediatitle": {
    display: "flex",
    width: "370px",
    alignItems: "center",
    justifyContent: "space-between",
    fontWeight: "bold",
    fontFamily: "MyFont",
    margin: "15px",
    paddingLeft: "12px",
  },
  "& .content-container": {
    paddingBottom: "80px", // Give some padding at the bottom for better UX
    overflowY: "auto",
    height: "calc(100vh - 200px)", // Adjust height based on your layout
  },
  "@media (max-width: 600px)": {
    "& .mediatitle": {
      width: "150px",
    },
  },
  "& .button-container": {
    "@media (max-width: 600px)": {
      width: '200px',
    },
    width: "400px",
    position: "fixed",
    background: "white",
    boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
    bottom: 0,
    padding: "10px 0", // Adjust as needed
  },
  '@media (max-width: 601px)': {
    '& .selectbtn': {
      marginTop: "0", // No need for extra margin
    },
  },
  '& .selectbtn': {
    color: "white",
    textTransform: "none",
    background: "#14362E",
    width: "100%",
  },
  '& .selecttype': {
    fontSize: "24px",
    color: '#14362E',
    fontWeight: "bold",
    paddingLeft: '5px',
    fontFamily: "myfont"
  },
  '& .sideBarlabel': {
    letterSpacing: "8%",
    fontFamily: "Sans-serif",
    color: "#475569",
  },
  '& .flaggingcontent': {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'start',
    padding: '14px',
    justifyContent: 'center',
    color: "#0F172A",
    letterSpacing: "0.08em",
    fontWeight: "700",
    fontSize: "16px",
    fontFamily: "myfont"
  },
  "& .typebox": {
    paddingLeft: "18px",
    paddingBottom: "15px",
    display: "flex",
    paddingTop: "15px",
  },
  "& .checkedCircle": {
    paddingTop: "10px",
    color: "#14362E",
    width: "27.7px",
    height: "27.7px",
  },
  "& .typebox:hover": {
    backgroundColor: "#D4E5DE"
  }
});

const MenuBox = styled(Box)({
  '& .menuicon': {
    width: "18px",
    height: "18px",
    top: "9px",
    left: "16px",
    padding: "2px"
  },
  '& .menuitemcss': {
    fontFamily: "myfont",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
    letterSpacing: "0.08em",
    textAlign: "left"
  },
  "@media(max-width: 600px)": {

  }
});

const BoxCustom2 = styled(Box)({
  fontFamily: "myfont",
  "& .mainBox": {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '4px',
    height: '30px',
    marginBottom: '5px',
    paddingLeft: "10px",
    fontFamily: "Halyard Display",
    fontSize: "12px",
    fontWeight: "400"
  },
  "& .mainBoxDivider": {
    marginLeft: "10px",
    width: "95%"
  },
  "& .mediatitle": {
    margin: "15px",
    width: "370px",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "12px",
    fontFamily: "MyFont"
  },
  "@media (max-width: 600px)": {
    "& .mediatitle": {
      width: "150px",
    },
  },
  "& .content-container": {
    height: "calc(100vh - 200px)", // Adjust height based on your layout
    overflowY: "auto",
    paddingBottom: "80px", // Give some padding at the bottom for better UX
  },
  "& .button-container": {
    position: "fixed",
    bottom: 0,
    width: "400px",
    "@media (max-width: 600px)": {
      width: '200px',
    },
    background: "white",
    padding: "10px 0", // Adjust as needed
    boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
  },
  '& .selectbtn': {
    width: "100%",
    background: "#14362E",
    textTransform: "none",
    color: "white",
  },
  '@media (max-width: 601px)': {
    '& .selectbtn': {
      marginTop: "0", // No need for extra margin
    },
  },
  '& .selecttype': {
    paddingLeft: '5px',
    color: '#14362E',
    fontWeight: "bold",
    fontSize: "24px",
  },
  '& .sideBarlabel': {
    fontFamily: "Sans-serif",
    color: "#475569",
    letterSpacing: "8%"
  },
  '& .flaggingcontent': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start',
    padding: '14px',
    fontWeight: "700",
    fontSize: "16px",
    letterSpacing: "0.08em",
    color: "#0F172A"
  },
  "& .typebox": {
    display: "flex",
    paddingLeft: "18px",
    paddingTop: "15px",
    paddingBottom: "15px",
  },
  "& .typebox:hover": {
    backgroundColor: "#D4E5DE"
  },
  "& .checkedCircle": {
    width: "27.7px",
    height: "27.7px",
    color: "#14362E",
    paddingTop: "10px"
  }



});

const StyledButton = styled(Button)({
  background: '#14362E',
  color: 'white',
  fontSize: '16px',
  fontFamily: 'MyFont',
  fontWeight: 'bold',
  marginTop: '48px',
  textTransform: 'none',
  "&.MuiButton-contained:hover": {
    background: '#14362E'
  }
})

const BoxCustom1 = styled(Box)({
  "& .slick-cloned": {
    display: "none"
  }
})

const webStyle = {
  drawer: {
    position: "relative",
    fontFamily: "MyFont",
    width: "500px",
    display: "block",
    '@media (min-width: 600px)': {
      display: "none", // For 'sm'
    },
    "& .MuiDrawer-paper": {
      boxSizing: "border-box",
      width: "240px",
    },
    '@media (max-width: 600px)': {
      '& .MuiDrawer-paper': {
        width: '100%',
      },
    }
  },

  slide: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "200px",
    background: "#ececec",
    border: "1px solid #ddd",
    fontSize: "24px",
  },
  InputAdornmcss: {
    marginTop: "2%",
    borderRadius: "8px",
  },
  xmarkicon: {
    marginLeft: "-12px",
    color: "#64748B",
    background: "white",
    borderRadius: "50%",
    padding: "2px 5px",
    marginRight: "10px",
    border: "1px solid #E2E8F0"
  },
  postcss: {
    width: '20%',
    textAlign: 'center' as 'center',
    marginLeft: "10px",
    background: "#F8FAFC",
    color: "#64748B",
    fontFamily: "MyFont"
  },
  categoryDropdown: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px'
  },
  cardflexbox: { display: "flex", justifyContent: "space-between" },
  recommendation: {
    background: "#F8FAFC",
    color: "#64748B",
    marginLeft: "10px",
    fontFamily: "MyFont",
    width: "20%",
  },
  recommendationBox: {
    width: "129px",
    height: "22px",
    borderRadius: "40px",
    backgroundColor: "#D4E5DE",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "5px",
    paddingBottom: "5px",
    display: "flex",
    justifyContent: "center",
    fontSize: "16px",
    fontWeight: "700",
    fontFamily: "'MyFont', 'Halyard Display'",
    marginLeft: "15px"
  },

  recommendationBoxtitle: {
    color: "#000000",
    display: "flex",
    justifyContent: "start",
    fontSize: "14px",
    fontWeight: "700",
    fontFamily: "'MyFont', 'Halyard Display'",
    marginLeft: "16px"
  },
  recommendationBoxType: {
    color: "#334155",
    display: "flex",
    justifyContent: "start",
    fontSize: "15px",
    fontWeight: "500",
    fontFamily: "'MyFont', 'Halyard Display'",
    marginLeft: "5px",
  },
  recommendationBoxCity: {
    color: "#64748B",
    display: "flex",
    justifyContent: "start",
    fontSize: "14px",
    fontWeight: "500",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  borderBottomStyle: {
    borderBottom: '1px solid #CBD5E1'
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '14px'
  },
  searchBox: {
    width: '100%',
    marginBottom: '10px',
    border: '1px solid #CBD5E1',
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center'
  },
  searchInput: {
    width: '100%',
    padding: '10px 5px',
    borderRadius: '5px',
    border: 'none',
    outline: 'none'
  },
  selectedTag: {
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    width: '80px',
    background: '#F1F5F9',
    flexWrap: 'wrap',
    marginBottom: '15px'
  },
  selectedTagText: {
    fontSize: '10px',
    marginRight: '8px',
    color: '#475569',
    padding: '5px'
  },

  tagImage: {
    width: '40px',
    height: '40px',
    borderRadius: '50%'
  },
  tagInfo: {
    marginLeft: '10px'
  },

  citytest: {
    fontFamily: "MyFont", fontSize: "11px", fontWeight: "500", letterSpacing: "1px", color: "#334155"
  },

  uploadmedia: {
    display: 'inline-block',
    height: "24px",
    fontFamily: "Halyard Display",
    fontSize: "16px",
    fontWeight: 900,
    lineHeight: "24px",
    letterSpecing: '0.08em',
    textAlign: 'left' as 'left',
    color: '#14362E'
  },
  mediabox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '-15px'
  },
  cardBoxSide: { display: "flex", justifyContent: "space-between" },
  plusIcon: {
    width: "17px",
    height: "17px",
    color: "#14362E"
  },
  buckupicon: {
    width: "48",
    height: "32",
    color: '#94A3B8',
    marginTop: "-15px"
  },
  desxcriptiontext: {
    marginTop: '-15px',
    color: "#64748B",
    fontFamily: "Halyard Display",
    fontSize: "14px",
    fontWeight: 500,
    letterSpecing: "0.08em",
  },
  recommendationtab: {
    border: "2px solid #73A58E",
    width: "50%",
    borderRadius: "35%",
    cursor: "pointer",
    fontFamily: "MyFont"
  },
  postmainparent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  postmainbox: {
    display: "flex",
    alignItems: "center"
  },
  postheader: {
    paddingLeft: "10px",
    fontWeight: "700",
    fontFamily: "MyFont"
  },
  btnShare: {
    background: "#F1F5F9",
    color: "#CBD5E1",
    width: "90px",
    fontFamily: "MyFont"
  },
  firstname: {
    fontSize: "16px", fontWeight: 700, color: "#334155", fontFamily: "MyFont", letterSpacing: "1.4"
  },
  titlbox: {
    paddingTop: "10px"
  },
  borderBottomstyle: {
    border: "1px solid #E2E8F0"
  },
  contactUsDrawerTxt: {
    fontFamily: "MyFont",
    letterSpacing: "1.4",
    color: "#1E1E1E",
    fontSize: "14px",
    marginBottom: "20px",
  },
  contactUsDrawerInputLabel: {
    fontSize: "16px",
    fontWeight: 700,
    color: "#334155",
  },
  contactDrawerSubmitBtn: {
    height: "45px",
    color: "white",
    fontWeight: 700,
    fontSize: "16px",
    borderRadius: "8px",
    fontFamily: "'MyFont', 'Halyard Display'",
    letterSpacing: "1px",
    marginTop: "20px",
    width: "80%",
  },
  searchIcon: {
    width: "116.88px",
    height: "100.05px",
    top: "22.47px",
    left: "14.05px",
    gap: "0px",
    opacity: "0px",
    color: "#64748B"
  },

  notfound: {
    fontFamily: "'MyFont', 'Halyard Display'",
    fontSize: "24px",
    fontWeight: "600",
    lineHeight: "10px",
    letterSpacing: "0.08em",
    color: "#64748B"
  },
  othertry: {
    fontFamily: "'MyFont', 'Halyard Display'",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    letterSpacing: "0.08em",
    color: "#64748B",
    textAlign: "center",
  },
  NoitemBox:
  {
    display: "flex",
    backgroundColor: "#FFFFFF",
    height: "600px",
    justifyContent: "center",
    alignContent: "center",
    maxWidth: 750, marginBottom: "25px", width: "100%", marginTop: "84px", fontFamily: "'MyFont', 'Halyard Display'"
  }
  ,
  noitemdiv: {
    display: "flex",
    width: "240px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  contactDrawerSubmitBtnMobile: {
    height: "45px",
    color: "white",
    fontWeight: 700,
    fontSize: "16px",
    borderRadius: "8px",
    fontFamily: "'MyFont', 'Halyard Display'",
    letterSpacing: "1px",
    marginTop: "20px",
    width: "90%",
  },
  modalStyle: {
    position: "absolute" as "absolute",
    top: "50%",
    left: { xs: "50%", md: "50%" },
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    width: { xs: "70%", md: "25%" },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: 24,
    p: 4,
  },
  modalImg: {
    width: "14%",
    height: "auto"
  },
  modalStyleTypo: {
    fontSize: "24px",
    fontWeight: "600",
    color: "#14362E",
    marginTop: "5%",
    width: { xs: "90%", md: "70%" },
    fontFamily: "'MyFont', 'Halyard Display'",
    textAlign: "center",
    letterSpacing: "0.05em"
  },
  modalStyleTypoSecond: {
    fontSize: "14px",
    marginTop: "3%",
    color: "#0F172A",
    width: "100%",
    fontFamily: "'MyFont', 'Halyard Display'",
    textAlign: "center",
    letterSpacing: "0.08em"
  },
  checkCircleCss: {
    width: " 66.67px",
    height: "66.67px",
    color: "#73A58E"
  }
}


const CustomDrawer = styled(Drawer)({

  '& .MuiDrawer-paper': {
    width: '540px',
  },
  '& .button-icon': {
    borderRadius: "8px",
    padding: "0",
    position: "absolute",
    right: "17%"
  },
  '& .custom-icon': {
    borderRadius: "8px",
    padding: "0",
    position: "relative",
    right: "44%",
    display: 'block'
  },
  '& .emoji-picker': {
    height: "400px !important",
    width: "400px !important",
    position: "absolute",
    right: "32% ",
    top: "26px",
    zIndex: 1000
  },
  '& .reply-emoji-picker': {
    height: "400px !important",
    width: "450px !important",
    position: "absolute",
    top: '103% ',
    right: '0%',
    zIndex: 1000
  },
  '& .display_arrow': {
    display: 'none'
  },
  '@media (max-width: 601px)': {
    '& .MuiDrawer-paper': {
      width: '100%',
    },
    '& .button-icon': {
      right: "7%"
    },
    '& .emoji-picker': {
      right: '5%',
      width: "370px !important",
    },
    '& .reply-emoji-picker': {
      top: "103%",
      right: '0%',
      height: '400px !important',
      width: '415px !important'
    },
    '& .display_arrow': {
      display: 'flex'
    }
  },
  '& .box7': {
    display: 'flex',
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "14px"
  },
  '& .box8': {
    width: "100%",
    marginBottom: "10px",
    border: "1px solid #CBD5E1",
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
    borderRadius: "8px"
  },
  '& .input2': {
    width: "100%",
    padding: "11px 4px",
    borderRadius: "7px",
    border: "none",
    outline: "none"
  },
  '& .box5': {
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 4px 9px",
    display: "flex",
    justifyContent: "space-between",
    padding: "5px",
    alignItems: "center",
    height: "55px",
    marginBottom: "9px",
    marginTop: "11px"
  },
  '& .box6': {
    display: "flex",
    borderRadius: "9px",
    alignItems: "center"
  },
})

// Customizable Area End