import React from "react";

//Customizable Area Start
import {
  Box,
  Button,
  Input,
  Typography,
  InputLabel,
  Grid,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { logo, women } from "./assets";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";

import ForgotPasswordController, { Props } from "./ForgotPasswordController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class ForgotPasswordBlock extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    this.handleEmail = this.handleEmail.bind(this);
  }

  render() {
    return(
      <ThemeProvider theme={theme}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          md={8}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "0%",
          }}
        >
          <Box sx={{marginTop:{xs:"3rem", md:"-2rem"}}}>
            <img
              src={logo}
              alt="kella_logo_txt"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </Box>
          <Box sx={{width: {xs:"93%",md:"45%"}}}>
            {this.state.showAlertError && (
                <Alert
                  style={{ fontSize: "16px", marginTop: "20px", color: "#D1584C", backgroundColor: "#EEC3BE", fontFamily: "'MyFont', 'Halyard Display'"}}
                  severity="error"
                  action={
                    <Button
                      color="inherit"
                      size="small"
                      onClick={this.handleCloseAlert}
                      startIcon={<CloseIcon />}
                      style={{paddingLeft: '15px'}}
                    >
                    </Button>
                  }
                >
                  {this.state.alertErrorMessage}
                </Alert>
              )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              width: {xs: "94%", md: "40%"}
            }}
          >
            <Typography
              variant="subtitle1"
              component="div"
              style={{
                fontWeight: 700,
                letterSpacing: "0.08em",
                textAlign: "left",
                lineHeight: "28px",
                fontSize: "20px",
                fontFamily: "'MyFont', 'Halyard Display'",
                color: "#14362E",
                marginTop: this.state.showAlertError ? "1.5rem" : "3rem",
              }}
            >
              {this.state.labelHeader}
            </Typography>
            <Typography
              variant="subtitle1"
              component="div"
              style={{
                marginTop: "0.5rem",
                fontSize: "16px",
                fontWeight: "500",
                color: "#0F172A",
                fontFamily: "'MyFont', 'Halyard Display'",
                letterSpacing: "0.05em",
              }}
            >
              {this.state.labelHeaderText}
            </Typography>
            <InputLabel
              style={{
                color: "#334155",
                fontWeight: "bold",
                marginTop: "1.5rem",
              }}
            >
              Email 
            </InputLabel>
            <Input
              id="txtInputEmail"
              placeholder={"Enter your email here"}
              fullWidth
              value={this.state.emailValue}
              disableUnderline
              autoComplete="off"
              onChange={this.handleEmail}
              style={{
                border: this.state.inputBorderStyle,
                padding: "5px",
                borderRadius: "10px",
                marginTop: "1rem"
              }}
            />
          </Box>
          <Box sx={{ width: "310px", marginTop: {xs:"100%", md:"25%"} }}>
            <Button
              variant="contained"
              onClick={this.forgotPassword}
              fullWidth
              style={{
                borderRadius: "8px",
                height: "56px",
                fontFamily: "'MyFont', 'Halyard Display'",
                backgroundColor: "#14362E",
                color: "white",
                fontSize: "16px",
                textTransform: "none",
                fontWeight: 590,
                letterSpacing: "0.08em",
              }}
            >
              Send recovery link
            </Button>
          </Box>
          <Box
            style={{
              alignItems: "center",
              fontWeight: "500",
              display: "flex",
              fontSize: "16px",
              flexDirection: "row",
              marginTop: "8px",
              lineHeight: "24px",
              justifyContent: "center",
              padding: "8px 0px",
              fontFamily: "'MyFont', Halyard Display",
              letterSpacing: "0.08em",
            }}
          >
            Don't have an account? &nbsp;
            <span style={{ cursor: "pointer" }}>
              <a
                href="/EmailAccountRegistration"
                style={{
                  fontWeight: "bold",
                  color: "#14362E",
                  textDecoration: "none",
                }}
              >
                Sign up
              </a>
            </span>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <img
              src={women}
              alt="forgot-password-screen"
              style={{ maxWidth: "100%", width: "100%", height: "100%" }}
            />
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* Adjust styling for mobile view */}
      </Box>
      </ThemeProvider>
    );
  }
}
//Customizable Area End