import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface IChat {
  id: string;
  muted: boolean;
  unreadCount: number;
  lastMessage: string;
  name: string;
}
interface IChatResponse {
  id: string;
  attributes: {
    name: string;
    accounts_chats: [
      {
        id: string;
        attributes: {
          account_id: number;
          muted: boolean;
          unread_count: number;
        };
      }
    ];
    messages: {
      id: string;
      attributes: { id: number; message: string };
      message: string;
    };
  };
}
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  accountId: number;
  chatName: string;
  chatList: any;
  groupchatList: any;
  isVisibleModal: boolean;
  Tabview: any;
  open: boolean;
  MemberListData: any;
  groupcreate:boolean;
  selectedmember: any;
  finalImg: any;
  selectedImage: any;
  groupData: any;
  fileInputKey: any;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getChatListApiCallId: string = "";
  getGroupChatListApiCallId: string = "";
  getmemberListApiCallId: string = "";
  createChatRoomApiCallId: string = "";
  groupcreateCallId: string = "";
  refreshChatInterval: unknown;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      accountId: -1,
      chatName: "",
      chatList: [],
      groupchatList: [],
      isVisibleModal: false,
      Tabview: "chats",
      open: false,
      MemberListData: [],
      groupcreate:false,
      selectedmember: [],
      finalImg: "",
      selectedImage: "",
      fileInputKey: 0,
      groupData: {
        groupname: "",
        groupdescription: ""
      },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const token = localStorage.getItem("accessToken")


    if (this.state.Tabview == "chats") {
      await this.getChatList(token)
    }
    if (this.state.Tabview === "groups") {
      await this.getGroupChatList(token);
    }

    this.refreshChatInterval = setInterval(
      () => {
        if (this.state.Tabview == "chats") {
          this.getChatList(token)
        }
        if (this.state.Tabview === "groups") {
          this.getGroupChatList(token);
        }
      },
      4000
    );

    super.componentDidMount();
    this.getToken();

  }

  async componentWillUnmount() {
    clearInterval(this.refreshChatInterval as number);
  }

  handleTabChange = (e: any) => {
    this.setState({ Tabview: e })
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };




  getChatList = async (token: any) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChatListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMyChatsApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getGroupChatList = async (token: any) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGroupChatListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGroupChatsApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleGotoChat = (tab: any, data: any) => {
    localStorage.setItem("tabs", tab)
    localStorage.setItem("conversation_sid", data.conversation_sid)
    localStorage.setItem("room_id", data.room_id)
    localStorage.setItem("receiver_id", data.id);

    window.location.href = "ViewChat"
  }

  handleadddrawer = () => {
    this.setState({ open: true })
  }

  toggleDrawer = (data: boolean) => {
    this.setState({ open: data })
  }

  drawerClose = () => {
    this.setState({ open: false })
  }

  searchvalue = (event2: any) => {
    this.getmemberList(event2.target.value)
  }

  getmemberList = async (searchvalue2: any) => {

    const token2 = localStorage.getItem("accessToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      token:token2,
    };

    const requestMessage2 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getmemberListApiCallId = requestMessage2.messageId;

    requestMessage2.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_addfriends/connections?search=${searchvalue2}`
    );

    requestMessage2.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage2.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage2.id, requestMessage2);
  };

  getvaluemembercheck = (elementdata: any, data: any) => {

    if (elementdata.target.checked) {
      const IteamIndex = this.state.selectedmember.findIndex((iteam: any) => iteam.id === data.id);


      if (IteamIndex < 0) {
        const storetype = [...this.state.selectedmember, { id: data.id, value: data.first_name + data.last_name }];
        this.setState({ selectedmember: storetype });
      }
    } else {
      const deleteSelectedType = this.state.selectedmember.filter((element: any) => {
        return element.id !== data.id
      })

      this.setState({ selectedmember: deleteSelectedType });
    }
  }

  handleselectmemberDelete = (elementdata: number) => {
    const deleteSelectedType = this.state.selectedmember.filter((element: any, index: number) => {
      return index !== elementdata
    })

    this.setState({ selectedmember: deleteSelectedType });
  }


  handleImageselect = (event: any) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const fileReader = new FileReader();

      this.setState({ finalImg: file })

      fileReader.onload = (event: any) => {
        const imageUrl = event.target.result;
        this.setState({ selectedImage: imageUrl });
      };

      fileReader.readAsDataURL(file);
    }
  };


  handleImage = () => {
    this.setState((prevState) => ({
      fileInputKey: prevState.fileInputKey + 1, // Increment the key to force a re-render
    }));
  };


  handleinputchange = (event: any) => {
    const { value, name } = event.target;

    this.setState({ groupData: { ...this.state.groupData, [name]: value } })
  }

  saveButton = () => {
    this.drawerClose()
    this.createGroup()
  }

  createGroup = () => {
    const token = localStorage.getItem("accessToken")
    const header = {
      // "Content-Type": configJSON.apiContentType,
      token,
    };

    const memberid = this.state.selectedmember.map((element: any) => element.id);
    let formdata = new FormData();
    formdata.append("data[attributes[room_name]]", this.state.groupData.groupname);
    formdata.append("data[attributes[description]]", this.state.groupData.groupdescription);
    formdata.append("data[attributes[id_list]]", JSON.stringify(memberid));
    formdata.append("data[attributes[group_profile_image]]", this.state.finalImg);
    formdata.append("data[attributes[room_type]]", "groupconversation");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.groupcreateCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_chat/groups`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `POST`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
  
    this.handleErrors(errorResponse, responseJson);
  
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      this.handleSessionResponse(message);
    }
  
    this.handleApiResponse(apiRequestCallId, responseJson);
  }
  
  handleErrors(errorResponse:any, responseJson:any) {
    if (errorResponse) this.parseApiCatchErrorResponse(errorResponse);
    if (responseJson?.errors) this.parseApiErrorResponse(responseJson);
  }
  
  handleSessionResponse(message: Message) {
    const token: string = message.getData(getName(MessageEnum.SessionResponseToken));
    runEngine.debugLog("TOKEN", token);
    
  }
  
  handleApiResponse(apiRequestCallId: string, responseJson: any) {
    switch (apiRequestCallId) {
      case this.getChatListApiCallId:
        if (responseJson.accounts) {
          this.setState({ chatList: responseJson.accounts });
        }
        break;
  
      case this.getGroupChatListApiCallId:
        if (responseJson.data) {
          this.setState({ groupchatList: responseJson.data.conversations });
        }
        break;
  
      case this.getmemberListApiCallId:
        if (responseJson.accounts) {
          this.setState({ MemberListData: responseJson.accounts });
        }
        break;
  
      case this.groupcreateCallId:
        this.setState({groupcreate:true})
        break;
  
      default:
        this.setState({groupcreate:true})
        break;
    }
  }
  
  // Customizable Area End
}
