import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
export interface IpersonalInforamtionGroup {
  id: number;
  title: string;
  created_at: string;
  updated_at: string;
}
export interface Interests {
  id: number;
  title: string;
  created_at: string;
  updated_at: string;
}
export interface Expertise {
    id: number;
    title: string;
    created_at: string;
    updated_at: string;
  }
export interface Hobbies {
  id: number;
  title: string;
  created_at: string;
  updated_at: string;
}
export interface Worries {
    id: number;
    title: string;
    created_at: string;
    updated_at: string;
  }
  export interface Passions {
    id: number;
    title: string;
    created_at: string;
    updated_at: string;
  }
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  interests: Interests[];
  hobbies:Hobbies[];
  checkedItems:string[];
  checkedHobbies:string[];
  worries:Worries[];
  checkedWorries:string[];
  currentScreen:number;
  age:string;
  passions:Passions[];
  checkedPassions:string[];
  expertise:Expertise[];
  checkedExpertise:string[];
  subject:string;
  ethnicity:string;
  ethnicities: IpersonalInforamtionGroup[];
  birthday:string;
  identities: IpersonalInforamtionGroup [];
  selectDrawerType: string | null;
  drawerSelectedOption: string;
  drawerData: {title: string; options: IpersonalInforamtionGroup[]}
  helperText: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
 
  addProfileDataApiCallID: string = '';
  getEthnicitiesApiCallId: string ='';
  getMyuserprofileDataId: string = '';
  identitiesApiCallId: string = '';
  validationApiCallId: string = "";
  interestApiCallId:string="";
  hobbiesApiCallId:string="";
  worriesApiCallID:string="";
 passionsApiCallID:string="";
expertiseApiCallID:string="";
  welcomeBelowText:string=configJSON.welcomeBelowText;
  welcomeText:string=configJSON.welcomeText;
 
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      subject:"",
      birthday:"",
      ethnicity:"",
      age:"",
      interests:[],
      hobbies:[],
      worries:[],
      passions:[],
      checkedItems:[],
      checkedHobbies:[],
      checkedWorries:[],
      checkedPassions:[],
      expertise:[],
      checkedExpertise:[],
      currentScreen:0,
      identities: [],
      ethnicities: [],
      drawerSelectedOption: '',
      selectDrawerType: null,
      drawerData: {
        title: '',
        options: [],
      },
      helperText: ''
      // Customizable Area End
    };

    // Customizable Area Start

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const {RestAPIResponceDataMessage, RestAPIResponceSuccessMessage } = MessageEnum;
    const apiRequestCallId = message.getData(getName(RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(RestAPIResponceSuccessMessage));

    if (!apiRequestCallId || !responseJson) return;
    if (apiRequestCallId === this.interestApiCallId) {
      this.setState({interests:responseJson.data})
    }
    else if (apiRequestCallId === this.hobbiesApiCallId) {
      this.setState({hobbies:responseJson.data})
    }
    else if (apiRequestCallId === this.worriesApiCallID) {
        this.setState({worries:responseJson.data})
      }
      else if (apiRequestCallId === this.passionsApiCallID) {
        this.setState({passions:responseJson.data})
      }
      else if (apiRequestCallId === this.expertiseApiCallID) {
        this.setState({expertise:responseJson.data})
      }
      else if (apiRequestCallId === this.addProfileDataApiCallID) {
        window.location.href = "NewsFeed";
      }
      else if(apiRequestCallId === this.getMyuserprofileDataId) {
        this.handleMyProfileData(responseJson)
      }
      else if(apiRequestCallId === this.identitiesApiCallId) {
        if(responseJson.data) {
          this.setState({
            identities: responseJson.data
          })
        }
      }
      else if(apiRequestCallId === this.getEthnicitiesApiCallId) {
        if(responseJson.data) {
          this.setState({
            ethnicities: responseJson.data
          })
        }

      }
     // Customizable Area End
  }

  // Customizable Area Start
  handleMyProfileData(responseJson: any){
    const personalProfile = responseJson.data.attributes.personal_profile;
        
    const formatedDate = personalProfile.date_of_birth ? this.formatDate(personalProfile.date_of_birth): '';
    this.setState({
      birthday: formatedDate,
      ethnicity:personalProfile.ethnicity,
      subject: personalProfile.sexual_identity,
      checkedExpertise: personalProfile.expertise,
      checkedHobbies: personalProfile.hobbies,
      checkedItems: personalProfile.interests,
      checkedPassions: personalProfile.passions,
      checkedWorries: personalProfile.worries
    })
  }
  formatDate(dateString: string) {
    // Create a Date object from the input string
    const date = new Date(dateString);
    
    // Get the day, month, and year
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
    const year = date.getFullYear();
    
    // Return the formatted date
    return `${day}/${month}/${year}`;
  }
  handleChangeDrawerSelect = ({target: {value}}: any) => {
    this.setState({drawerSelectedOption: value})
  }
  handleOpenSelectDrawer = (type: string) => {
    const isIdentity = type === 'identity';
    this.setState({selectDrawerType: type, drawerSelectedOption: isIdentity ? this.state.subject : this.state.ethnicity, drawerData: {title: isIdentity ? 'I Identify As:': 'Ethnicity:', options: isIdentity ? this.state.identities: this.state.ethnicities}});

  }
  handleCloseSelectDrawer = () =>{
    this.setState({selectDrawerType: null, drawerSelectedOption: ''});
  }
  handleSelectDrawer = () => {
    const isIdentity = this.state.selectDrawerType === 'identity';
    if(isIdentity) {
      this.setState({subject: this.state.drawerSelectedOption});
    } else {
      this.setState({ethnicity: this.state.drawerSelectedOption});
    }
    this.handleCloseSelectDrawer();
  }
  handleSubjectChange = (event: any) => {
    this.setState({ subject: event.target.value });
  };
  handleEthnicity = (event: any) => {
    this.setState({ ethnicity: event.target.value });
  };
  handleCheckboxChangeExpertise = (title:string) => {
    const currentIndex = this.state.checkedExpertise.indexOf(title);
    const newCheckedExpertise = [...this.state.checkedExpertise];

    if (currentIndex === -1) {
        newCheckedExpertise.push(title);
    } else {
        newCheckedExpertise.splice(currentIndex, 1);
    }

    this.setState({checkedExpertise:newCheckedExpertise});
  };

   handleCheckboxChange = (title:string) => {
    const currentIndex = this.state.checkedItems.indexOf(title);
    const newCheckedItems = [...this.state.checkedItems];

    if (currentIndex === -1) {
      newCheckedItems.push(title);
    } else {
      newCheckedItems.splice(currentIndex, 1);
    }

    this.setState({checkedItems:newCheckedItems});
  };

  handleCheckboxChangeHobbies = (title:string) => {
    const currentIndex = this.state.checkedHobbies.indexOf(title);
    const newCheckedHobbies = [...this.state.checkedHobbies];

    if (currentIndex === -1) {
      newCheckedHobbies.push(title);
    } else {
      newCheckedHobbies.splice(currentIndex, 1);
    }

    this.setState({checkedHobbies:newCheckedHobbies});
  };
  

  handleCheckboxChangeWorries = (title:string) => {
    const currentIndex = this.state.checkedWorries.indexOf(title);
    const newCheckedWorries = [...this.state.checkedWorries];

    if (currentIndex === -1) {
      newCheckedWorries.push(title);
    } else {
      newCheckedWorries.splice(currentIndex, 1);
    }

    this.setState({checkedWorries:newCheckedWorries});
  };

  handleCheckboxChangePassions = (title:string) => {
    const currentIndex = this.state.checkedPassions.indexOf(title);
    const newCheckedPassions = [...this.state.checkedPassions];

    if (currentIndex === -1) {
      newCheckedPassions.push(title);
    } else {
      newCheckedPassions.splice(currentIndex, 1);
    }

    this.setState({checkedPassions:newCheckedPassions});
  };
 
 
  async componentDidMount(): Promise<void> {
   
  
    this.getInterests();
    this.getHobbies();
    this.getWorries();
    this.getPassions();
    this.getExpertise();
    this.getMyuserprofileData();
    this.getIdentities();
    this.getEthnicities();
  }

  getEthnicities() {
    
    const headers = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("accessToken")
    };
   
    const getEthnicitiesMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getEthnicitiesApiCallId = getEthnicitiesMsg.messageId;

    getEthnicitiesMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEthnicitiesApiEndPoint
    );

    getEthnicitiesMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getEthnicitiesMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(getEthnicitiesMsg.id, getEthnicitiesMsg);
  }
  getIdentities() {
    const headers = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("accessToken")
    };
   
    const getIdentitiesMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.identitiesApiCallId = getIdentitiesMsg.messageId;

    getIdentitiesMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getIdentitiesApiEndPoint
    );

    getIdentitiesMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getIdentitiesMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(getIdentitiesMsg.id, getIdentitiesMsg);
  }
 
  getInterests() { 
   
    const headers = {
      "Content-Type": configJSON.interestApiContentType,
      token: localStorage.getItem("accessToken")
    };
   
    const getInterestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.interestApiCallId = getInterestMsg.messageId;

    getInterestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.interestApiEndPoint
    );

    getInterestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getInterestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.interestApiMethodType
    );
    runEngine.sendMessage(getInterestMsg.id, getInterestMsg);
  }
  getHobbies() {
    const headers = {
      "Content-Type": configJSON.hobbiesApiContentType,
      token: localStorage.getItem("accessToken")
    };
   
    const getHobbiesMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.hobbiesApiCallId = getHobbiesMsg.messageId;

    getHobbiesMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.hobbiesApiEndPoint
    );

    getHobbiesMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getHobbiesMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.hobbiesApiMethodType
    );
    runEngine.sendMessage(getHobbiesMsg.id, getHobbiesMsg);
  }

  handleBirthdayChange = (event:any) => {
    let value = event.target.value.replace(/\D/g, ''); // Remove any non-digit characters

    // Format the input as DD.MM.YYYY
    if (value.length >= 3) {
        value = value.slice(0, 2) + '/' + value.slice(2);
    }
    if (value.length >= 6) {
        value = value.slice(0, 5) + '/' + value.slice(5, 9);
    }

    if (value.length === 10) {
      const [day, month, year] = value.split('/');
      const parsedDate = new Date(`${year}-${month}-${day}`);
      const currentDate = new Date();

      if (parsedDate > currentDate) {
          this.setState({helperText: 'Future dates are not allowed.'})
      } else if (!isNaN(parsedDate.getTime())) {
          this.setState({helperText: ''})
      } else {
          this.setState({helperText: 'Invalid date. Please enter a valid date.'})
      }
  } else {
    this.setState({helperText: 'Incomplete date. Use dd/mm/yyyy'})
  }
    this.setState({birthday: value}); 
  };
  getWorries() {
    const headers = {
      "Content-Type": configJSON.worriesApiContentType,
      token: localStorage.getItem("accessToken")
        };
   
    const getWorriesMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.worriesApiCallID = getWorriesMsg.messageId;

    getWorriesMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.worriesApiEndPoint
    );

    getWorriesMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getWorriesMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.hobbiesApiMethodType
    );
    runEngine.sendMessage(getWorriesMsg.id, getWorriesMsg);
  }
  getExpertise() {
    const headers = {
      "Content-Type": configJSON.expertiseApiContentType,
      token: localStorage.getItem("accessToken")  
    };
   
    const getExpertiseMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.expertiseApiCallID = getExpertiseMsg.messageId;

    getExpertiseMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.expertiseApiEndPoint
    );

    getExpertiseMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getExpertiseMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.hobbiesApiMethodType
    );
    runEngine.sendMessage(getExpertiseMsg.id, getExpertiseMsg);
  }
  getPassions() {
    const headers = {
      "Content-Type": configJSON.passionsApiContentType,
      token: localStorage.getItem("accessToken")  
    };
   
    const getPassionsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.passionsApiCallID = getPassionsMsg.messageId;

    getPassionsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passionsApiEndPoint
    );

    getPassionsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getPassionsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.passionsApiMethodType
    );
    runEngine.sendMessage(getPassionsMsg.id, getPassionsMsg);
  }

  getMyuserprofileData(){
    const token = localStorage.getItem("accessToken")
    const accountId = localStorage.getItem("account_id")


    const getMyuserMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getMyuserprofileDataId = getMyuserMessage.messageId;

    getMyuserMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${accountId}`
    );

    getMyuserMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.applicationJsonContentType,
        token: `${token}`,
      })
    );

    getMyuserMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(getMyuserMessage.id, getMyuserMessage);
  }

  handleStartButtonClick = () => {
    // Here you can implement logic to change to the next screen
    this.setState((prevState) => ({
      currentScreen: prevState.currentScreen + 1
    }));
  };
  handleBackButtonClick = () => {
    // Here you can implement logic to go back to the previous screen
    this.setState((prevState) => ({
      currentScreen: prevState.currentScreen - 1
    }));
  };
  handleChange = (event:any) => {
    this.setState({age:event.target.value});
  };
  goToHomePage = () => {
    window.location.href = "NewsFeed"
  };
  handleSave = () => {
    const headers = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("accessToken")  
    };
    const body = {
      profile: {
        "date_of_birth": this.state.birthday,
        "sexual_identity": this.state.subject,
        "ethnicity": this.state.ethnicity,
        "interests": this.state.checkedItems,
        "hobbies": this.state.checkedHobbies,
        "worries": this.state.checkedWorries,
        "passions": this.state.checkedPassions,
        "expertise": this.state.checkedExpertise
    }
    };
    const addProfileDataMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addProfileDataApiCallID = addProfileDataMsg.messageId;

    addProfileDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addProfileDataApiEndPoint
    );

    addProfileDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    addProfileDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    addProfileDataMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(addProfileDataMsg.id, addProfileDataMsg);
  }

  // Customizable Area End
}
