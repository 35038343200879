import React from "react";
// Customizable Area Start
import AllEventDetailController, {
    Props,
} from "./AllEventDetailController";
import {
    Container,
    Box,
    Card,
    Button,
    CardMedia,
    CardContent,
    Typography,
    Drawer,
    styled,
    Modal,
    IconButton
} from "@material-ui/core";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { promocode, NewPromo ,corssImg} from "../src/assets"
import { Alert } from "@material-ui/lab";

export default class AllEventDetail extends AllEventDetailController {
    constructor(props: Props) {
        super(props);
    }

    puchaseDisplay() {
        return (
            <>
                {
                    Object.keys(this.state.eventData).length > 0 && (
                        <>
                            <Card style={{ maxWidth: 700, marginBottom: "20px", width: "100%" }}>
                                <CardMedia
                                    style={{ height: 180 }}
                                    image={this.state.eventData?.attributes.image.url}
                                    title="green iguana"
                                />
                                <CardContent style={{ display: "flex", justifyContent: "space-evenly" }}>
                                    <Box style={{ padding: "10px" }}>
                                        <Box style={{ padding: "10px" }}>
                                            <Typography style={{ fontFamily: "MyFont" }}>{this.state.eventData.attributes.formatted_date}</Typography>

                                        </Box>

                                    </Box>
                                    <Box style={{ padding: "10px" }}>
                                        <Typography>{this.state.eventData.attributes.title}</Typography>
                                        <Typography style={{ color: "#64748B", fontSize: "12px", fontFamily: "MyFont", letterSpacing: "0.8px" }}>{this.state.eventData.attributes.notes}</Typography>
                                        <Typography style={{ fontFamily: "MyFont", fontSize: "12px" }}>{this.state.eventData.attributes.time_duration}</Typography>
                                        <Typography style={{ fontFamily: "MyFont", fontSize: "12px" }}>{this.state.eventData.attributes.going} Going - {this.state.eventData.attributes.tickets_left} Tickets left</Typography>
                                    </Box>
                                    <Box>
                                        <Typography style={{ fontFamily: "MyFont", fontSize: "15px", marginTop: "40px", width: "80px", fontWeight: "bold", cursor: "pointer" }} >View details</Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        </>
                    )
                }

            </>
        )
    }

    QuantityBox() {
        return (
            <>
                <Card style={{ maxWidth: 380, marginBottom: "20px", width: "100%", boxShadow: "none" }}>

                    <CardContent style={{ display: "flex", flexDirection: "column" }}>
                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography>Quntity</Typography>
                            <Typography style={{ display: "flex", border: "1px solid #CBD5E1", width: "50px", justifyContent: "space-between", borderRadius: "4px", padding: "3px" }}>
                                <span onClick={this.handleQuantityDecrease}>-</span>
                                <span>{this.state.quantity}</span>
                                <span onClick={this.handleQuantityIncrease}>+</span>
                            </Typography>

                        </Box>
                        <Box style={webStyles.borderBottomstyle as any}></Box>

                    {
                        this.state.promocodeadded && (
                            <>
                                 <Alert
                                 style={{margin:"20px 0px"}}
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                   
                                >
                                    <i className="fa-solid fa-angle-right"></i>
                                </IconButton>
                            }
                        >
                            Promo code: {this.state.Promocode}
                        </Alert>
                            </>
                        )
                    }
                       
                        <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "4px 0px" }}>
                            <Box style={{ display: "flex", alignItems: "center", marginTop: "4px" }}>
                                <img src={promocode} alt="" />
                                <Typography>Promo code</Typography>
                            </Box>
                            <Typography style={{ cursor: "pointer" }} onClick={this.handlePromocodeOpen}>
                                <i className="fa-solid fa-angle-right"></i>
                            </Typography>

                        </Box>
                        <Box style={webStyles.borderBottomstyle as any}></Box>
                        <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", marginTop: "5px", color: "#334155", fontWeight: "bold" }}>Billing Confirmation</Typography>
                        <Box style={{ background: "#F8FAFC", borderRadius: "4px", padding: "5px", marginTop: "5px" }}>
                            <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "13px", marginTop: "3px" }}>Tickets quantity</Typography>
                                <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "13px", color: "#0F172A" }}>{this.state.quantity}</Typography>
                            </Box>
                            <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "13px", marginTop: "3px" }}>Payment date</Typography>
                                <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "13px", fontWeight: "bold", color: "#0F172A" }}>{this.formatDate(this.state.currentDate)}</Typography>
                            </Box>
                            <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "13px", marginTop: "3px" }}>Total amount</Typography>
                                <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "13px", color: "#73A58E" }}>€ {this.state.sucessalertmsg ? this.state.finalamount : this.state.eventData?.attributes?.amount * this.state.quantity}</Typography>
                            </Box>
                        </Box>

                    </CardContent>
                </Card>
            </>
        )
    }

    PromocodeSidebar() {
        return (
            <>
                <BoxMain>
                    <Box style={{ display: "flex", alignItems: "center", padding: "10px" }}>
                        <span onClick={this.drawerClose} style={{ cursor: "pointer" }}><i className="fa-solid fa-arrow-left"></i></span>
                        <Typography style={{ fontFamily: "MyFont", fontWeight: "bold", letterSpacing: "0.8px", fontSize: "21px", margin: "auto" }}>Promo code</Typography>
                    </Box>
                    <Box style={webStyles.borderBottomstyle as any}></Box>
                    <Box style={{ padding: "20px" }}>
                        {
                            this.state.showALert && (
                                <>
                                    {
                                        this.state.sucessalert ? (
                                            <>
                                                <Alert
                                                    action={
                                                        <IconButton
                                                            aria-label="close"
                                                            color="inherit"
                                                            size="small"
                                                            onClick={this.handleAlertClose}
                                                        >
                                                            <i className="fa-solid fa-xmark"></i>
                                                        </IconButton>
                                                    }

                                                >
                                                    {this.state.sucessalertmsg}
                                                </Alert>
                                            </>
                                        ) : (
                                            <Alert
                                                severity="error"
                                                action={
                                                    <IconButton
                                                        aria-label="close"
                                                        color="inherit"
                                                        size="small"
                                                        onClick={this.handleAlertClose}
                                                    >
                                                        <i className="fa-solid fa-xmark"></i>
                                                    </IconButton>
                                                }

                                            >
                                                {this.state.erroralertmsg}
                                            </Alert>
                                        )
                                    }
                                </>
                            )
                        }


                    </Box>
                    <Container >
                        <Box style={{ padding: "20px", position: "relative" }}>

                            <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px" }}>Promo code</Typography>
                            <input type="text" style={{ width: "100%", padding: "10px 5px", borderRadius: "5px", border: "1px solid #CBD5E1", outline: "none", fontFamily: "MyFont" }} value={this.state.Promocode} onChange={this.handlePromocodeGet} placeholder="Enter Code" name="" id="" />
                            <img src={NewPromo} style={{ position: "absolute", right: "25px", top: "53px" }} alt="" />
                            <Box style={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "20px" }}>
                                {
                                    this.state.Promocode.length > 0 ? (
                                        <>
                                <Box style={{ cursor: "pointer", color: "#73A58E", margin: "auto", fontFamily: "MyFont", letterSpacing: "0.8px" }} onClick={this.applycode}>Apply code</Box>

                                        </>
                                    ):(
                                        <>
                                <Box style={{ cursor: "pointer", color: "#CBD5E1", margin: "auto", fontFamily: "MyFont", letterSpacing: "0.8px" }}>Apply code</Box>

                                        </>
                                    )
                                }
                            </Box>
                        </Box>

                    </Container>

                    <Box style={webStyles.borderBottomstyle as any}></Box>
                    {
                        this.state.discount && (
                            <>
                                 <Container>
                        <Box style={{ padding: "20px" }}>
                            <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", marginTop: "5px", color: "#334155", fontWeight: "bold" }}>Coupon details</Typography>
                            <Box style={{ background: "#F8FAFC", borderRadius: "4px", padding: "5px", marginTop: "5px" }}>
                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "13px", marginTop: "3px" }}>Promo code</Typography>
                                    <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "13px", color: "#0F172A" }}>{this.state.discount.promo_code}</Typography>
                                </Box>
                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "13px", marginTop: "3px" }}>Amount off</Typography>
                                    <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "13px", fontWeight: "bold", color: "#0F172A" }}>{this.state?.discount.payment_date}</Typography>
                                </Box>
                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "13px", marginTop: "3px" }}>Tota Amount</Typography>
                                    <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "13px", color: "#73A58E" }}>€ {this.state.discount.total_amount}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Container>
                            </>
                        )
                    }
                   

                    <Box>
                        <Button variant="contained" style={{ width: "100%", background: "black", color: "white", textTransform: "capitalize", fontFamily: "MyFont" }} onClick={this.drawerClose}>Add Promo Code</Button>
                    </Box>
                </BoxMain>
            </>
        )
    }

    ErrorModel() {
        return (
            <>
                <Modal
                    open={this.state.errorCheckout}
                    onClose={this.handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, background: '#fff', padding: 4, }}>
                        <Box style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Typography style={{ marginTop: "10px" }}><img src={corssImg} alt="" /></Typography>

                            <Typography style={{ marginTop: "10px", textAlign: "center", fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "13px" }}>{this.state.errormsgcheckout}</Typography>
                            <Box style={{ width: "100%", marginTop: "10px" }}>
                                <Button variant="contained" style={{ width: "100%", background: "black", color: "white", textTransform: "capitalize", fontFamily: "MyFont" }} onClick={this.handleCloseModal}>Understand</Button>
                            </Box>
                        </Box>

                    </Box>
                </Modal>
            </>
        )
    }
    render() {
        return (
            <>
                <AppHeader />

                <Box key={1} style={{ display: "flex", alignItems: "center", background: "#F8FAFC", padding: "10px", flexDirection: "column", }}>
                    <Card style={{ maxWidth: 750, marginBottom: "10px", marginTop: "24px", fontFamily: "MyFont", width: "100%", borderRadius: "12px", minHeight: "100vh", padding: "10px", position: "relative" }}>
                        <Box style={webStyles.tabStyle as any}>
                            <Typography style={{ fontFamily: "MyFont", fontWeight: "bold", fontSize: "18px", letterSpacing: "0.8px" }}>Purchase Ticket</Typography>
                        </Box>

                        <Container>
                            <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", background: "#F8FAFC", marginTop: "10px" }}>

                                {this.puchaseDisplay()}
                                {this.QuantityBox()}
                                <Box style={{ width: '80%', margin: "auto", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Button variant="outlined" style={{ width: "50%", border: "1px solid #73A58E", color: "#14362E", fontFamily: "MyFont", letterSpacing: "0.8px" }}>Cancel</Button>
                                    <Button variant="contained" style={{ width: "50%", background: "#14362E", color: "#fff", fontFamily: "MyFont", letterSpacing: "0.8px" }} onClick={this.handlecheckout}>Pay</Button>
                                </Box>
                            </Box>
                        </Container>
                    </Card>
                    <Drawer style={webStyles.drawer as any} anchor="right" open={this.state.open} onClose={this.toggleDrawer.bind(this, false)}>
                        {this.PromocodeSidebar()}
                    </Drawer>
                    {this.ErrorModel()}
                </Box>
            </>
        )
    }
}

const BoxMain = styled(Box)({

    width: "420px",
    "@media(max-width: 600px)": {
        width: '100vw',
    },
})

const webStyles = {
    tabStyle: {
        display: "flex",
        backgroundColor: "#F8FAFC",
        justifyContent: "center",
        margin: "auto",
        marginTop: "0px",
        padding: "10px 8px",
        fontFamily: "MyFont",
        fontWeight: "bold",
        width: "300px",
    },
    borderBottomstyle: {
        marginTop: "10px",
        border: "1px solid #E2E8F0",
    },
    drawer: {
        width: "501px",
        display: "block",
        overflowY: "unset",
        '@media (min-width: 600px)': {
            display: "none",
        },
        "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: "240px",
        },
    }

}
// Customizable Area End