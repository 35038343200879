import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Platform } from "react-native";
import { AllEvents } from "./AllEventController";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  quantity: number;
  eventData: any;
  currentDate: Date,
  open:boolean,
  Promocode:string,
  sucessalert:boolean,
  sucessalertmsg:"",
  erroralertmsg:"",
  errorAlert:boolean,
  showALert:boolean,
  finalamount:string,
  discount:any,
  promocodeadded:boolean,
  errorCheckout:boolean,
  errormsgcheckout:string
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class AllEventDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  geteventId: string = "";
  geteventCheckoutId: string = "";
  promocodeId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      quantity: 1,
      eventData: {},
      currentDate: new Date(),
      open:false,
      Promocode:"",
      sucessalert:false,
      sucessalertmsg:"",
      erroralertmsg:"",
      errorAlert:false,
      showALert:false,
      finalamount:"",
      discount:{},
      promocodeadded:false,
      errorCheckout:false,
      errormsgcheckout:""
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getToken();
    if (Platform.OS !== "web") {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    const event_id = localStorage.getItem("event_id");
    if (event_id) {
      this.handleGetEvent(event_id)
    }
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
    }


    if (this.isRestApiResponse(message, responseJson)) {
      this.handleRestApiResponse(apiRequestCallId, responseJson);
    }
    // Customizable Area End
  }

  navigateToEventDetail = (selectedUpcomingEvent: AllEvents) => {
    this.props.navigation.navigate("AddEventDetail", {
      event: selectedUpcomingEvent,
    });
  };

  // Customizable Area Start

  isRestApiResponse(message: Message, responseJson: any): boolean {
    return responseJson && getName(MessageEnum.RestAPIResponceMessage) === message.id;
  }

  handleRestApiResponse(apiRequestCallId: string, responseJson: any) {
    switch (apiRequestCallId) {
      case this.geteventId:
        this.getEventData(responseJson);
        break;
      case this.geteventCheckoutId:
        this.getCheckoutData(responseJson)
        break;
      case this.promocodeId:
        this.getApplypromoData(responseJson)
        break;
      default:
        break;
    }
  }

  handleGetEvent = (event_id: any) => {
    const token = localStorage.getItem("accessToken")

    const getEventMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.geteventId = getEventMessage.messageId;

    getEventMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_events/events/${event_id}`
    );

    getEventMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: `${token}`,
      })
    );

    getEventMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );


    runEngine.sendMessage(getEventMessage.id, getEventMessage);
  }

  getEventData = (responseJson: any) => {
    this.setState({ eventData: responseJson.data })
  }

  handleQuantityIncrease = () => {
    this.setState({ quantity: this.state.quantity + 1 })
  }

  handleQuantityDecrease = () => {
    if (this.state.quantity > 0) {
      this.setState({ quantity: this.state.quantity - 1 })
    }
  }

  handlecheckout = () => {
    const token = localStorage.getItem("accessToken")


    const checkout = {
      "event_id": this.state.eventData?.id,
      "quantity": this.state.quantity,
      "promo_code": this.state.Promocode
    }

    const getEventCheckoutMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.geteventCheckoutId = getEventCheckoutMessage.messageId;

    getEventCheckoutMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_events/events/checkout`
    );

    getEventCheckoutMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: `${token}`,
      })
    );
    getEventCheckoutMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(checkout)
    );

    getEventCheckoutMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `POST`
    );


    runEngine.sendMessage(getEventCheckoutMessage.id, getEventCheckoutMessage);
  }

  getCheckoutData = (responseJson: any) => {
    if (responseJson.message) {
      localStorage.setItem("eventPurchase", "1")
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), 'AllEvents');
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg)
    } else if(responseJson.url) {
      window.open(responseJson.url)
    }else{
      this.setState({errormsgcheckout:responseJson.error})
      this.setState({errorCheckout:true})
    }
  }

  formatDate = (date: any) => {
    return date?.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  };

  handlePromocodeOpen=()=>{
    this.setState({ open: true })
  }

  toggleDrawer = (data: boolean) => {
    this.setState({ open: data })
  }
  
  drawerClose = () => {
    if(this.state.discount.success){
      this.setState({promocodeadded:true})
    }
    this.setState({ open: false })
  }

  handlePromocodeGet = (data:any)=>{
    this.setState({Promocode:data.target.value})
  }

  applycode = ()=>{
    const token = localStorage.getItem("accessToken")


    const promocodeapply = {
      "event_id": this.state.eventData?.id,
      "promo_code": this.state.Promocode,
      "quantity": this.state.quantity
    }

    const PromocodeapplyMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.promocodeId = PromocodeapplyMessage.messageId;

    PromocodeapplyMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_events/events/apply_promo`
    );

    PromocodeapplyMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: `${token}`,
      })
    );
    PromocodeapplyMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(promocodeapply)
    );

    PromocodeapplyMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `POST`
    );


    runEngine.sendMessage(PromocodeapplyMessage.id, PromocodeapplyMessage);
  }

  getApplypromoData=(responseJson:any)=>{
    
    if(responseJson.success){
      
      this.setState({showALert:true})
      this.setState({sucessalert:true})
      this.setState({discount:responseJson})
      this.setState({finalamount:responseJson.total_amount})
      this.setState({sucessalertmsg:responseJson.message})
    }else{
      this.setState({showALert:true})
      this.setState({errorAlert:true})

      this.setState({erroralertmsg:responseJson.error})
    }
  }

  handleAlertClose=()=>{
    this.setState({showALert:false})
  }

  handleCloseModal = ()=>{
    this.setState({errorCheckout:false})
  }

  // Customizable Area End
}
