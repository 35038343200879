import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
import { imgPasswordVisible, imgPasswordInVisible, logo, women } from "./assets";
import { getStorageData, setStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  id:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountType: string;
  emailSchema: any;
  phoneSchema: any;
  otpSchema: any;
  passwordSchema: any;
  accountStatus: any;
  passwordRules: any;
  emailValue: any;
  phoneValue: any;
  countryCodeSelected: any;
  token: any;
  enablePasswordField: Boolean;
  btnConfirmPasswordShowHide: Boolean;
  logo: any;
  women: any;
  labelHeader: string;
  labelHeaderText: string;
  labelNewPassword: string;
  newPassword: any;
  confirmNewPassword: any;
  labelCheckEmail: string;
  labelCheckEmailText: string;
  errorEmailFlag: boolean;
  errorPasswordFlag: boolean;
  errorConfirmPasswordFlag: boolean;
  isValidEmailField: boolean;
  isValidPasswordField: boolean;
  isOneCapital: boolean;
  isOneLower: boolean;
  isOneNumber: boolean;
  isValidLength: boolean;
  enablePassword: boolean;
  enableConfirmPassword: boolean;
  passwordError: any;
  isValidEmail: boolean;
  showAlertError: boolean;
  alertErrorMessage: string
  emailResetSuccess: boolean;
  inputBorderStyle: string;
  isTypingPassword: boolean;
  successPopupOpen: boolean;
  passwordMatch: boolean;
  storedEmail: string;
  redirectToLogin: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  id:any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationAPICallId: any;
  requestEmailOtpCallId: any;
  requestPhoneOtpCallId: any;
  requestChangePasswordCallId: any;
  requestGoToConfirmationCallId: any;
  otpToken: any;
  isChangePassword: boolean = false;
  forgotPasswordApiCallId: any;
  resetPasswordApiCallId: any;

  //Properties from config
  labelTextIsAccountRecovery: string = configJSON.labelTextIsAccountRecovery;
  secondLabelText: string = configJSON.secondLabelText;
  thirdLabelText: string = configJSON.thirdLabelText;
  forthLabelText: string = configJSON.forthLabelText;
  fifthLabelText: string = configJSON.fifthLabelText;
  sixthLabelText: string = configJSON.sixthLabelText;
  firstInputAutoCompleteType: any = configJSON.firstInputAutoCompleteType;
  firstInputKeyboardStyle: any = configJSON.firstInputKeyboardStyle;
  firstInputPlaceholder: string = configJSON.firstInputPlaceholder;
  firstInputErrorColor: any = configJSON.firstInputErrorColor;
  buttonTextIsNext: string = configJSON.buttonTextIsNext;
  buttonColorForNextButton: any = configJSON.buttonColorForNextButton;
  secondInputAutoCompleteType: any = configJSON.secondInputAutoCompleteType;
  secondInputKeyboardType: any = configJSON.secondInputKeyboardType;
  secondInputPlaceholder: string = configJSON.secondInputPlaceholder;
  secondInputErrorColor: any = configJSON.secondInputErrorColor;
  thirdInputPlaceholder: string = configJSON.thirdInputPlaceholder;
  thirdInputErrorColor: any = configJSON.thirdInputErrorColor;
  buttonTitleIsSMSPhoneAccount: string =
    configJSON.buttonTitleIsSMSPhoneAccount;
  buttonTitleIsEmailAccount: string = configJSON.buttonTitleIsEmailAccount;
  labelTextIsPleaseEnterYourNewPassword: string =
    configJSON.labelTextIsPleaseEnterYourNewPassword;
  labelTextIsYourPasswordHasBeenSuccessfullyChanged: string =
    configJSON.labelTextIsYourPasswordHasBeenSuccessfullyChanged;
  placeholderIsPassword: string = configJSON.placeholderIsPassword;
  imgPasswordInVisible: any = imgPasswordInVisible;
  imgPasswordVisible: any = imgPasswordVisible;
  placeholderIsReTypePassword: string = configJSON.placeholderIsReTypePassword;
  buttonTitleIsOk: string = configJSON.buttonTitleIsOk;
  buttonColorForOkButton: any = configJSON.buttonColorForOkButton;
  countryCodeSelectorPlaceholder: string =
    configJSON.countryCodeSelectorPlaceholder;
  passwordError: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    //email schema
    let emailSchema = {
      email: Yup.string()
        .email(configJSON.pleaseEnterAValidEmail)
        .required(configJSON.emailIsRequired),
    };

    //phone schema
    let phoneSchema = {
      // countryCode: Yup.number()
      // .required("Country code is required"),

      phone: Yup.string()
        .matches(configJSON.phoneRegExp, configJSON.phoneNumberIsNotValid)
        .required(configJSON.phoneNumberIsRequired),
    };

    //otpSchema
    let otpSchema = {
      otpCode: Yup.number().min(4).required(configJSON.otpCodeIsRequired),
    };

    //passwordSchema
    let passwordSchema = {
      password: Yup.string()
        .required(configJSON.pleaseEnterAPassword)
        .min(2, configJSON.passwordMustBeAtLeast2Characters),
      confirmPassword: Yup.string()
        .required(configJSON.pleaseConfirmYourPassword)
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            configJSON.passwordsMustMatch
          ),
        }),
    };

    this.state = {
      accountType: "sms",
      accountStatus: "ChooseAccountType",
      emailValue: "",
      phoneValue: "",
      countryCodeSelected: "",
      passwordRules: "",
      emailSchema: emailSchema,
      phoneSchema: phoneSchema,
      otpSchema: otpSchema,
      passwordSchema: passwordSchema,
      token: "",
      enablePasswordField: true,
      btnConfirmPasswordShowHide: true,
      logo: logo,
      women: women,
      labelHeader: configJSON.labelHeader,
      labelHeaderText: configJSON.labelHeaderText,
      labelNewPassword: configJSON.labelNewPassword,
      newPassword: "",
      confirmNewPassword: "",
      labelCheckEmail: configJSON.labelCheckEmail,
      labelCheckEmailText: configJSON.labelCheckEmailText,
      errorEmailFlag: false,
      errorPasswordFlag: false,
      errorConfirmPasswordFlag: false,
      isValidEmailField: false,
      isValidPasswordField: false,
      isOneCapital: false,
      isOneLower: false,
      isOneNumber: false,
      isValidLength: false,
      enablePassword: false,
      enableConfirmPassword: false,
      passwordError: "",
      isValidEmail: false,
      showAlertError: false,    
      alertErrorMessage: "",
      emailResetSuccess: false,
      inputBorderStyle: "1px solid black",
      isTypingPassword: false,
      successPopupOpen: false,
      passwordMatch: false,
      storedEmail: "",
      redirectToLogin: false
    };
    // Customizable Area End
  }
  // Customizable Area Start
  // istanbul ignore next
  async componentDidMount() {
    super.componentDidMount();

    const url = new window.URL(window.location.href);
    let token = url.searchParams.get("token");
    this.setState({ token });

    const storedEmail = await getStorageData('email');
    if (storedEmail) {
      this.setState({ storedEmail: JSON.parse(storedEmail) });
    }
  }
  componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.showAlertError !== this.state.showAlertError) {
      const inputBorderStyle = this.state.showAlertError ? "2px solid #D1584C" : "2px solid black";
      this.setState({ inputBorderStyle });
    }
  }

  forgotPassword = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const data = {
      attributes: {
        email: this.state.emailValue,
      },
    };

    const httpBody = {
      data: data,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.forgotPasswordApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ForgotPasswordApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  resetPasswordApi = () => {
    const { newPassword, confirmNewPassword } = this.state;
    if (newPassword !== confirmNewPassword) {
      this.setState({passwordMatch: true});
        return;
    }
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const data = {
        token: this.state.token,
        new_password: this.state.newPassword,
        password_confirmation: this.state.confirmNewPassword
    };

    const httpBody = {
      data: data,
      
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    ); 
    
    this.resetPasswordApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetPasswordApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );

      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.handleChangePassword(otpAuthTkn)
      } else {
        const accountType = message.getData(
          getName(MessageEnum.NavigationForgotPasswordPageInfo)
        );
        if (accountType) {
          this.startForgotPassword(accountType);
        }
      }
    }  
    else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    this.handleForgotPasswordApiResponse(message)
    }
    if(getName(MessageEnum.RestAPIResponceMessage) === message.id){
      this.handleResetPasswordApiResponse(message)
    }
  }
  handleResetPasswordApiResponse = (message: any) => {
    const apiRequestID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if(apiRequestID === this.resetPasswordApiCallId){
      if (responseJson && responseJson.message) {
        this.setState({ successPopupOpen: true }); 
      }
    }
  }
  handleForgotPasswordApiResponse = (message: any)=>{
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId == this.forgotPasswordApiCallId) {
      if (responseJson && responseJson.message) {
        this.goToCheckEmailPage();
      } else {
        this.handleLoginFailure(responseJson)
      }
    }
  }

  handleLoginFailure = (responseJson: any) => {
    this.setState({ showAlertError: true, alertErrorMessage: responseJson["errors"][0]["otp"] })
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));   
    this.send(msg);
  }
  
  handleChangePassword = (otpAuthTkn: any) => {
    this.setState({ token: otpAuthTkn });
        if (this.isChangePassword) {
          this.setState({ accountStatus: "ChangePassword" });
        }
        this.otpToken = this.state.token;
  }

  startForgotPassword(accountType: String) {
    this.setState({
      accountStatus: accountType === "sms" ? "EnterPhone" : "EnterEmail",
    });
  }
  handleClose = () => {
    this.setState({ successPopupOpen: false, });
    this.redirectToLoginPage()
  };  
  redirectToLoginPage = () => {
    this.setState({ redirectToLogin: true }, () => {
      window.location.href = "/EmailAccountLoginBlock";
    });
  };
  handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = e.target.value;
    this.setState({ emailValue });
    setStorageData('email', JSON.stringify(emailValue))
  }
  handleCloseAlert = () => {
    this.setState({ showAlertError: false });
  };
  goToCheckEmailPage = () => {
    window.location.href = "ForgotPasswordCheckEmail"
  }
  setNewPassword = (newPassword: any) => {
    if (newPassword) {
      this.setState({
        errorPasswordFlag: true, isValidPasswordField: false
      })
    }
    const regexOneCapital = /^(?=.*[A-Z]).+$/;
    const regexOneLower = /^(?=.*[a-z]).+$/;
    const regexOneNumber = /^(?=.*\d).+$/;
    const regexLength = /^.{8,}$/;
    this.setState({
      isOneCapital: regexOneCapital.test(newPassword),
      isOneLower: regexOneLower.test(newPassword),
      isOneNumber: regexOneNumber.test(newPassword),
      isValidLength: regexLength.test(newPassword),
    })
    this.setState({ newPassword });
  };
  setConfirmPassword = (confirmNewPassword: any) => {
    const { newPassword } = this.state;
    if (confirmNewPassword === newPassword) {
      this.setState({
        errorConfirmPasswordFlag: true,
        errorPasswordFlag: true, 
        isValidPasswordField: false
      })
    }
    this.setState({ confirmNewPassword });
  };
  handleClickShowPassword = () => {
    this.setState({ enablePassword: !this.state.enablePassword });
  };
  
  handleClickConfirmShowPassword = () => {
    this.setState({ enableConfirmPassword: !this.state.enableConfirmPassword });
  };
  
  goToOtpAfterEmailValidation(values: { accountType: string; email: string }) {
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestEmailOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryStartOtpAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({
      emailValue: values.email ? values.email : "",
    });

    const data = {
      type: values.accountType ? values.accountType : "email_account",
      attributes: {
        email: values.email ? values.email : "",
      },
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToOtpAfterPhoneValidation(values: { phone: string }) {
    if (
      !this.state.countryCodeSelected ||
      this.state.countryCodeSelected.length === 0
    ) {
      this.showAlert(
        configJSON.goToOtpAfterPhoneValidationErrorTitle,
        configJSON.goToOtpAfterPhoneValidationErrorBody
      );
      return;
    }
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestPhoneOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryStartOtpAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({
      phoneValue:
        this.state.countryCodeSelected && values.phone
          ? this.state.countryCodeSelected + values.phone
          : "",
    });

    const data = {
      type: "sms_account",
      attributes: {
        full_phone_number: this.state.phoneValue,
      },
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToChangePasswordAfterOtp(values: { otpCode: string }) {
    //change status to change password
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestChangePasswordCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryConfirmOtpAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      token: this.otpToken ? this.otpToken : "",
      otp_code: values.otpCode ? values.otpCode : "",
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToConfirmationAfterPasswordChange(values: {
    password: any;
    confirmPassword: any;
  }) {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestGoToConfirmationCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryChangePasswordAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      token: this.otpToken ? this.otpToken : "",
      new_password: values.password,
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  // Customizable Area End
  // Customizable Area Start
  // Customizable Area End
}
