import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  countryCode: string; 
  phoneNumber: any;
  subject: string;
  subjects: string[];
  description: string;
  contactUsDrawerOpen: boolean;
  successModalOpen: boolean;
  failedModalOpen: boolean;
  formValid: boolean; 
  errorFirstNameFlag:boolean;
  errorLastNameFlag:boolean;
  errors: {
    firstName: boolean;
    lastName: boolean;
    phoneNumber: boolean;
    subject: boolean;
    description: boolean;
  }
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ContactUsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  subjectsApiCallID: any;
  contactUsApiCallID: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      firstName: "",
      lastName: "",
    //   dialCode: "",
      countryCode:"",
      phoneNumber: "",
      subject: "",
      subjects: [],
      description: "",
      contactUsDrawerOpen: false,
      successModalOpen: false,
      failedModalOpen: false,
      formValid: false,
      errorFirstNameFlag:false,
      errorLastNameFlag:false,
      errors: {
        firstName: false,
        lastName: false,
        phoneNumber: false,
        subject: false,
        description: false
      }
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.subjectsApiCallID) {
        this.handleSubjectsData(responseJson);
      } else if (apiRequestCallId === this.contactUsApiCallID) {
        this.handleContactUsData(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getSubjectsList();
  }

  toggleContactUsDrawer = (open: any) => () => {
    this.setState({ contactUsDrawerOpen: open });
  };

  handleFirstName = (firstName: any) => {
    const newfirstname = firstName.trim()
    if (newfirstname) {
      this.setState({ errorFirstNameFlag: true })
    }
    this.setState({ firstName: newfirstname });
  }
  
  handleLastName = (lastName: any) => {
    const newlastname = lastName.trim()
    if (newlastname) {
      this.setState({ errorLastNameFlag: true })
    }
    this.setState({ lastName: newlastname });
  }
  
  handleSubjectChange = (event: any) => {
    this.setState({ subject: event.target.value }, this.validateForm);
  };
  
  handleDescriptionChange = (event: any) => {
    this.setState({ description: event.target.value }, this.validateForm);
  };

  validateForm = () => {
    const { firstName, lastName, phoneNumber, subject, description } = this.state;
    const formValid = firstName || lastName || phoneNumber || subject || description;
    this.setState({ formValid });
  };

  handleSubjectsData = (response: any) => {
    console.log("Received API Response:", response);

    if (response && Array.isArray(response.data) && response.data.length > 0) {
      const subjectsFromApi = response.data.map(
        (subject: any) => subject.title
      );
      this.setState({ subjects: subjectsFromApi });
    } else {
      console.error(
        "No subjects received from API or incorrect data structure."
      );
      this.setState({ subjects: [] });
    }
  };

  getSubjectsList() {
    try {
      const header = {
        "Content-Type": configJSON.accountDetailsAPiContentType,
        token: localStorage.getItem("accessToken"),
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.subjectsApiCallID = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.subjectsApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  }

  validateFormData = () => {
    const { firstName, lastName, phoneNumber, subject, description } = this.state;
    const errors = {
      firstName: !firstName,
      lastName: !lastName,
      phoneNumber: !phoneNumber,
      subject: !subject,
      description: !description
    };
  
    this.setState({ errors });
  
    return !Object.values(errors).some(error => error);
  };

  handleSubmitButton = () => {
    if (!this.validateFormData()) {
      console.error("Form data is invalid");
      return;
    }
  
    try {
      const header = {
        "Content-Type": configJSON.contactUsAPiContentType,
        token: localStorage.getItem("accessToken"),
      };
      
      const phn = this.state.phoneNumber.slice((this.state.countryCode).length)
      const form = {
          first_name: this.state.firstName,
          last_name: this.state.lastName,
          country_code: this.state.countryCode,
          phone_number: phn,
          subject: this.state.subject,
          description: this.state.description,
      };
      const httpBody = {
        form: form,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.contactUsApiCallID = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.contactUsApiEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    } catch (error) {
      console.error("Error submitting contact us form:", error);
    }
  };

  handleContactUsData = (responseJson: any) => {
    console.log("Contact Us API Response:", responseJson);
    if (responseJson && responseJson.data && responseJson.data.attributes) {
      this.setState({ successModalOpen: true, });
    } else {
      this.setState({ failedModalOpen: true, });
    }
  };

  handleCloseSuccessModal = () => {
    this.setState({ successModalOpen: false, contactUsDrawerOpen: false, lastName: "", firstName: "", phoneNumber: "", subjects:[""], description:""});
  }; 

  handleCloseFailedModal = () => {
    this.setState({ failedModalOpen: false, });
  }; 

  handleRetryBtn = () => {
    this.setState({ failedModalOpen: false });
  };

}
