export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const payment = require("../assets/payment_icon.png");
export const help = require("../assets/help_icon.png");
export const notification = require("../assets/notification_icon.png");
export const logout = require("../assets/logout_icon.png");
export const contactus = require("../assets/contact_icon.png");
export const privacy = require("../assets/privacy_icon.png");
export const image_chevron = require("../assets/image_chevron.png");
export const image_chevron_gray = require("../assets/image_chevron_gray.png");
export const greenCheck = require("../assets/greenCheck.png")
export const termsandcondi = require("../assets/termsandcondi.png")
export const successCheck = require("../assets/olive_green_check.png")
export const failedCheck = require("../assets/failed_img.png")
